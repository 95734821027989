import { LiveInfo } from '@/store/modules/live/types';
import { defineStore } from 'pinia';

interface LiveState {
  liveInfo: LiveInfo;
  aiSubitSwitch: boolean;
}

export const useLiveStore = defineStore('live', {
  state: (): LiveState => ({
    liveInfo: {
      id: 0,
      title: '',
      status: 0,
      qr_code_pic: '',
      qr_code_url: '',
      studio_pid: 0,
      start_at: '',
      end_at: '',
      play_url: ['', '', ''],
      uv: 0,
      filing_status: 0, // 0不显示 1未备案  2已备案
      data_page_rout: 0, // 1 显示新数据统计 0显示旧版
      vr_play_status: 0,
      vr_play_switch: 0,
      live_url_status: 0,
    },
    aiSubitSwitch: false,
  }),
});
