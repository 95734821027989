import { ref } from 'vue';
import { message } from 'ant-design-vue';
import http from '@/api/conentCenter/communal';
import emitter from '@/utils/mitt';
const sortId = ref();
emitter.on('changeSortId', (e) => {
  sortId.value = e;
});

/** 移动分类 */
export const moveSort = (paramsInfo: any) => {
  const isMove = ref(false);
  const moveStatus = ref(0);
  const selectedRowKeys = ref();
  emitter.on('selectedRowKeys', (e) => {
    selectedRowKeys.value = e;
  });
  const createMove = () => {
    if (!selectedRowKeys.value || selectedRowKeys.value.length == 0) {
      message.error('你未选择要移动的内容！');
      return;
    }
    moveStatus.value = 0;
    const move: any = {
      sort_id: sortId.value,
      type: paramsInfo.type,
      id: selectedRowKeys.value.join(','),
      portal_id: paramsInfo.gatewayId,
    };
    http
      .move_sort(move)
      .then((res) => {
        message.success(res.data.message);
        isMove.value = false;
        moveStatus.value = paramsInfo.type;
      })
      .catch((res) => {
        isMove.value = false;
        message.error(res.data.error);
      });
  };
  return {
    isMove,
    moveStatus,
    createMove,
  };
};
/** 创建分类 */
export const createSort = (paramsInfo: any) => {
  const sortInfo = ref();
  const createStatus = ref(0);
  const isVisible = ref<boolean>(false);
  emitter.on('createSortName', (e) => {
    sortInfo.value = e;
  });
  const getCreate = () => {
    const params: any = {
      type: sortInfo.value.type,
      name: sortInfo.value.name,
      portal_id: paramsInfo.gatewayId,
    };
    createStatus.value = 0;
    http
      .create_sort(params)
      .then((res) => {
        isVisible.value = false;
        createStatus.value = sortInfo.value.type;
        message.success(res.data.message);
      })
      .catch((res) => {
        message.error(res.data.error);
      });
  };
  return {
    isVisible,
    createStatus,
    getCreate,
  };
};

/** 获取分类列表 */
export const sortList = (paramsInfo: any) => {
  interface sortType {
    id: number;
    name: string;
    visible: boolean;
    count: number;
    default: number;
  }
  const sortListArr = ref<sortType[]>([]);
  const getSortList = () => {
    const params: any = {
      type: paramsInfo.type,
      portal_id: paramsInfo.gatewayId,
    };
    http.get_sort_list({ params }).then((res) => {
      sortListArr.value = res.data.data;
    });
  };
  return {
    sortListArr,
    getSortList,
  };
};
