
import { computed, defineComponent, reactive, ref } from 'vue';
import { message, Input } from 'ant-design-vue';
import { useStore } from 'vuex';
import SingHeader from '@/components/SingHeader.vue';
import http from '@/api';
import { useRoute, useRouter } from 'vue-router';
import { getMenuVisibleRouterKey, removeToken, setHelperUrl } from '@/plugins/cookie';
import { isMobile } from '@/utils/format';
import { formatRoutes } from '@/router';

interface Ilogin {
  username: number | string;
  password: string;
  code: string;
}
export default defineComponent({
  components: {
    aInput: Input,
    aInputPassword: Input.Password,
    SingHeader,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const customData = computed(() => store?.state.home.customData);
    const headerNavVisiable = computed(() => route.query[getMenuVisibleRouterKey()] != '1');

    const formData: Ilogin = reactive({
      username: '',
      password: '',
      code: '',
    });
    // 登录
    const isShowform = ref(true);
    const LoginCover = reactive({
      url: '',
      title: '',
    });
    const topLogoUrl = ref<string>('');
    const targetStudioId = ref();
    const errMsg = ref('');
    const getHelper = () => {
      http.login
        .getHelperLoginUrl({
          params: {
            code: route.query?.code,
            studio_id: route.query?.studio_id,
          },
        })
        .then(() => {
          return http.login.getHelperLoginInfo({
            params: {
              studio_id: route.query?.studio_id,
            },
          });
        })
        .then((res) => {
          LoginCover.title = res.data.data.title;
          LoginCover.url = res.data.data.image_player_cover_url;
          topLogoUrl.value = res.data.data.top_logo_url;
          targetStudioId.value = res.data.data.target_studio_id;
        })
        .catch((err) => {
          isShowform.value = false;
          errMsg.value = err.data.error;
        });
    };
    getHelper();
    const dragStatus = ref(1);
    const aliVerification = (data: any) => {
      let params = {
        session_id: data.sessionId,
        sig: data.sig,
        token: data.token,
        scene: 1,
      };
      http.login
        .aliLoginVerification(params)
        .then(() => {
          dragStatus.value = 0;
        })
        .catch((res) => {
          dragStatus.value = 2;
          (window as any).ic.reset();
          message.error(res.data.error);
        });
    };
    const getDragData = (data: any) => {
      aliVerification(data);
    };

    const store = useStore();
    const loginShake = ref<boolean>(false);
    // console.log('path',location.pathname + location.search);
    // 客管登陆接口
    const login = () => {
      if (loginShake.value == true) {
        return;
      }
      if (formData.username == '') {
        message.info('手机号不能为空');
        return;
      }
      if (formData.password == '') {
        message.info('密码不能为空');
        return;
      }
      // if (dragStatus.value) {
      //   message.info('请先点击验证！');
      //   return;
      // }
      const requestData = {
        account: formData.username,
        password: formData.password,
        studio_id: route.query?.studio_id,
        target_studio_id: targetStudioId.value,
      };
      http.login
        .loginHelper(requestData)
        .then(async (res) => {
          removeToken();
          const resData = res.data.data;
          const url = location.pathname + location.search;
          setHelperUrl({
            url,
            studioId: route.query?.studio_id,
            targetStudioId: targetStudioId.value,
          });
          store.commit('user/helperSaveUserInfo', resData);
          await formatRoutes();
          router.addRoute({
            path: '/:catchAll(.*)',
            redirect: '/404',
          });
          message.success(res.data.message);
          store.dispatch('home/getDefaultCustomized').finally(() => {
            loginShake.value = false;
            router.push({ name: 'Layout' });
          });
        })
        .catch((err) => {
          message.error(err.data.error);
          loginShake.value = false;
        });
    };
    const adminUserVisit = () => {
      http.login.adminUserVisit({}).then(() => {
        sessionStorage.setItem('visit', 'pc_login');
      });
    };
    const user_visit = sessionStorage.getItem('visit');
    if (user_visit == null) {
      adminUserVisit();
    }

    return {
      customData,
      headerNavVisiable,
      isShowform,
      formData,
      login,
      errMsg,
      LoginCover,
      getDragData,
      isMobile,
      topLogoUrl,
    };
  },
});
