
import CUpload from '@/components/CUpload.vue';
import CUploads from '@/components/CUploads.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
// import Crumb from '@/components/Crumb.vue';
import { getToken } from '@/plugins/cookie';
import { liveItemBg } from '@/assets';
import http from '@/api/home';
import { useDebounce } from '@/hooks/useDebounce';
import { useStore } from 'vuex';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  components: {
    CUpload,
    CUploads,
    PlusCircleOutlined,
    MinusCircleOutlined,
  },
  props: {},
  setup() {
    const loading = ref<boolean>(false);
    const loading0 = ref<boolean>(false);
    const loading2 = ref<boolean>(false);
    const loading3 = ref<boolean>(false);
    const loading4 = ref<boolean>(false);
    const value = ref<string>('');
    const fileList = ref();
    let obj = ref({
      definition: '',
      PageFooter: '',
      copyright: '',
      catenate: '',
      channelpicture: '',
      chan: 0,
      siteicon: '',
      site: 0,
      Navigationlogo: '',
      navId: 0,
      defaultcover: '',
      imgId: 0,
      imageUrl: '',
      uploadLoading: '',
      loadingId: 0,
      wechat_authorize_title: '',
      wechat_authorize_list: [''],
      wechat_authorize_btn: [],
      wechat_public_avatar_url: '',
      wechat_public_avatar_id: 0,
    });
    let token = getToken();
    // 上传图片
    const handleChange = (info: FileInfo, id: number) => {
      if (info.file?.response?.code === 200) {
        if (id == 0) {
          obj.value.channelpicture = info.file?.response?.data.url;
          obj.value.chan = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
          // loadingId.value = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
        } else if (id == 1) {
          obj.value.siteicon = info.file?.response?.data.url;
          obj.value.site = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
        } else if (id == 2) {
          obj.value.Navigationlogo = info.file?.response?.data.url;
          obj.value.navId = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
        } else if (id == 3) {
          obj.value.defaultcover = info.file?.response?.data.url;
          obj.value.imgId = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
        } else if (id == 4) {
          obj.value.uploadLoading = info.file?.response?.data.url;
          obj.value.loadingId = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
        } else if (id === 6) {
          obj.value.wechat_public_avatar_url = info.file?.response?.data.url;
          obj.value.wechat_public_avatar_id = info.file?.response?.data.id || 0;
        } else obj.value.imageUrl = info.file?.response?.data.url;
      }
      if (info.file.status === 'uploading') {
        if (id === 0) {
          loading0.value = true;
        } else if (id === 2) {
          loading2.value = true;
        } else if (id === 3) {
          loading3.value = true;
        } else if (id === 4) {
          loading4.value = true;
        } else loading.value = true;
      } else {
        loading0.value = false;
        loading2.value = false;
        loading3.value = false;
        loading.value = false;
        loading4.value = false;
      }
    };
    const imageDel = (info: any, id: number) => {
      if (id === 0) {
        obj.value.channelpicture = '';
        obj.value.chan = 0;
      } else if (id === 2) {
        obj.value.Navigationlogo = '';
        obj.value.navId = 0;
      } else if (id === 3) {
        obj.value.defaultcover = '';
        obj.value.imgId = 0;
      } else if (id === 4) {
        obj.value.uploadLoading = '';
        obj.value.loadingId = 0;
      } else obj.value.imageUrl = '';
    };
    // 保存
    const keep = useDebounce(async () => {
      let parms = {
        copyright_link: obj.value.copyright,
        copyright: obj.value.PageFooter,
        wechat_name: obj.value.definition,
        top_logo_link: obj.value.catenate,
        video_id: obj.value.imgId, //频道默认封面id
        logo_id: obj.value.chan, //频道logo  id
        icon: obj.value.site,
        top_logo_id: obj.value.navId,
        qr_code: obj.value.imageUrl,
        loading_id: obj.value.loadingId,
        loading_url: obj.value.uploadLoading,
        wechat_authorize_title: obj.value.wechat_authorize_title,
        wechat_authorize_list: obj.value.wechat_authorize_list,
        wechat_authorize_btn: obj.value.wechat_authorize_btn,
        wechat_public_avatar_url: obj.value.wechat_public_avatar_url,
        wechat_public_avatar_id: obj.value.wechat_public_avatar_id,
      };
      //保存定制配置信息
      const res = await http.getCustomizedUpload(parms);
      message.success(res.data.message);
      await store.dispatch('home/getDefaultCustomized');
      var link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = customData.value.icon_url;
      // obj.channelpicture = resData.icon_url;
    });
    // 获取定制配置
    const store = useStore();
    const customData = computed(() => store.state.home.customData);
    const getDefault = (resData: any) => {
      obj.value = {
        PageFooter: resData.copyright as string,
        definition: resData.wechat_name as string,
        copyright: resData.copyright_link as string,
        catenate: resData.top_logo_link as string,
        channelpicture: resData.logo_url as string, //频道logo  url
        chan: resData.logo_id as number, //频道logo  id
        siteicon: resData.icon_url as string,
        site: resData.icon as number,
        Navigationlogo: resData.top_logo_url as string,
        navId: resData.top_logo_id as number,
        defaultcover: resData.video_url, //频道默认封面url
        imgId: resData.video_id as number, //频道默认封面id
        imageUrl: resData.qr_code as string,
        loadingId: resData.loading_id as number,
        uploadLoading: resData.loading_url as string,
        wechat_authorize_title: resData.wechat_authorize_title as string,
        wechat_authorize_list: resData.wechat_authorize_list || [''],
        wechat_authorize_btn: resData.wechat_authorize_btn || [],
        wechat_public_avatar_url: resData.wechat_public_avatar_url,
        wechat_public_avatar_id: resData.wechat_public_avatar_id,
      };
    };
    getDefault(customData.value);
    watch(customData, (newVal) => {
      getDefault(newVal);
    });

    const updataauthorizeList = (type: string, index?: number) => {
      if (type === 'add') {
        obj.value.wechat_authorize_list.push('');
      } else {
        index ? obj.value.wechat_authorize_list.splice(index, 1) : '';
      }
    };

    return {
      fileList,
      loading,
      loading0,
      loading2,
      loading3,
      loading4,
      // imageUrl,
      handleChange,
      liveItemBg,
      value,
      keep,
      obj,
      token,
      imageDel,
      updataauthorizeList,
    };
  },
});
