import { ActionTree, MutationTree, StoreOptions } from 'vuex';
import axios from '@/api/home';
import { BaseSearchFace } from '@/types/base';
import { DefaultCustomized, MsgClass, RootMsg, SuMenuType } from './type';
import { RouteRecordRaw } from 'vue-router';

class State {
  searchForm: BaseSearchFace = {};

  msgList: RootMsg[] = [];

  customData: Partial<DefaultCustomized> = {
    copyright: 'Copyright © 2022 Bonwey Ltd. All Rights Reserved.',
    logo_url: '',
    top_logo_url: '',
    top_logo_link: '/',
    icon_url: 'https://images.innocomn.com/favicon.ico',
    video_url: 'https://images.innocomn.com/kv.png',
    refresh_status: 0, //是否显示 手动刷新推流地址
    video_storage:1, // 阿里上传还是腾讯云上传
  };

  customStatus = false;

  black_top_logo_url = 'https://images.innocomn.com/top_logo_black.svg';

  customFootStyle = false;

  customLayoutStyle = false;

  menu: Array<RouteRecordRaw> = [];

  billMenu: Array<SuMenuType> = [];
}

class Home implements StoreOptions<State> {
  namespaced = true;
  state = new State();
  mutations: MutationTree<State> = {
    updateMsgList(state, data: RootMsg[]) {
      state.msgList = data.map((v) => v);
    },
    getCustomData(state, data: DefaultCustomized) {
      state.customData = data;
      state.customData.logo_url = data.logo_url ? data.logo_url : 'https://images.innocomn.com/no_bg_logo.png';
      state.customData.icon_url = data.icon_url ? data.icon_url : 'https://images.innocomn.com/favicon.ico';
      state.customData.top_logo_url = data.top_logo_url ? data.top_logo_url : 'https://images.innocomn.com/top_logo.svg';
      state.customData.video_url = data.video_url ? data.video_url : 'https://images.innocomn.com/kv.png';
      state.customData.video_storage = data.video_storage ? data.video_storage : 1;
      // state.customData.top_logo_link = data.top_logo_link ? data.top_logo_link : 'https://www.qingtv.com';
    },
    setCustomFooter(state, data: boolean) {
      state.customFootStyle = data;
    },
    customLayoutStyle(state, data: boolean) {
      state.customLayoutStyle = data;
    },
    setMenu(state, data: Array<RouteRecordRaw>) {
      state.menu = data;
    },
    setBillMenu(state, data: Array<SuMenuType>) {
      state.billMenu = data;
    },
    setCustomStatus(state, data: boolean) {
      state.customStatus = data;
    },
  };

  actions: ActionTree<State, unknown> = {
    // 获取消息列表
    getMessageList({ commit }, data) {
      let searchData = {};
      if (data) {
        searchData = new MsgClass(data);
      }
      return new Promise((resolve) => {
        axios.getAllMsg({ params: searchData }).then((res) => {
          commit('updateMsgList', res.data.data.data || []);
          resolve(res.data.data);
        });
      });
    },
    getDefaultCustomized({ commit }) {
      return new Promise((resolve) => {
        axios
          .getDefaultCustomized()
          .then((res) => {
            commit('getCustomData', res.data.data);
            commit('setCustomStatus', true);
            resolve(res);
          })
          .catch((err) => {
            commit('getCustomData', {
              copyright: 'Copyright © 2022 Bonwey Ltd. All Rights Reserved.',
              logo_url: 'https://images.innocomn.com/white_bg_logo.png',
              top_logo_url: 'https://images.innocomn.com/top_logo.svg',
              top_logo_link: '/',
              icon_url: 'https://images.innocomn.com/favicon.ico',
              video_url: 'https://images.innocomn.com/kv.png',
              video_storage:1
            });
            commit('setCustomStatus', false);
            resolve(err);
            // console.error('无权访问自定义配置');
          });
      });
    },
  };
}
export default new Home();
