
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { Result, Button } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { getHelperData, getHelperStatus, getHelperUrl, removeToken } from '@/plugins/cookie';

export default defineComponent({
  components: {
    aResult: Result,
    aButton: Button,
  },
  setup() {
    const helperUrl = getHelperUrl();
    const helperStatus = getHelperStatus();
    const router = useRouter();
    const helperInfo = getHelperData();
    const back = () => {
      try {
        if (helperStatus !== 1) {
          router.push('/');
          return;
        }
        if (helperUrl?.url) {
          let urlName = 'BroadCastControl';
          if (helperInfo?.set !== 1) {
            urlName = 'Console';
          }
          router.push({ name: urlName, params: { studioId: helperUrl?.studioId } });
        }
      } catch {
        removeToken();
        router.removeRoute('Layout');
        router.replace('Login');
      }
    };
    const backTime = ref(3);
    const internalKey = ref<any>(0);
    const autoback = () => {
      internalKey.value = setInterval(() => {
        if (backTime.value === 1) {
          clearInterval(internalKey.value);
          back();
        }
        backTime.value -= 1;
      }, 1000);
    };
    onMounted(() => {
      autoback();
    });
    onUnmounted(() => {
      clearInterval(internalKey.value);
    });
    return {
      back,
      backTime,
    };
  },
});
