import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 后台获取微信分享内容
  getShareContent(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/get_share_content', data);
  }

  // 修改微信分享link
  getUpdWeChatShare(data: unknown) {
    return http.post('/api/v1/wechat/upd_we_chat_share', data);
  }
  // 缩略图
  getUploadImages(data: unknown) {
    return http.post(' /api/v1/common/upload_images', data);
  }
  // 直播助手详情
  getViewLiveHelper(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/view_live_helper', data);
  }
  // 直播助手更新
  getEditLiveHelper(data: unknown) {
    return http.post(' /api/v1/live/edit_live_helper', data);
  }
  // 直播助手删除
  getDeleteLiveHelper(data: unknown) {
    return http.post('/api/v1/live/delete_live_helper', data);
  }
  // 直播助手添加
  getAddLiveHelper(data: unknown) {
    return http.post('/api/v1/live/add_live_helper', data);
  }
}
export default new Api();
