import { computed, ref, watch, watchEffect } from 'vue';
import { useDebounce } from '@/hooks/useDebounce';
import { GridBufferL } from './type';
import { ConsoleClass } from '@/pinia/types/console';

export const frameWidth = ref(0.003); // 帧宽度 ??

const gridWidth = ref(10); //格子宽度

export const gridBufferList = ref<GridBufferL[]>([]);

export const timeLine_width = ref(0); // 时间轴宽度：用户能看见的宽度

export const timescale_width = ref(0); // 时间刻度总宽度：包含用户看不见的宽度

const timeLineOffsetLeft = ref(0); // 时间轴滚动轴左偏移量

export const gridFrame = ref(0); // 格子内帧数

export const maxFrameOfMaterial = ref(0); // 时间轴素材最大帧数

export const gridTime = ref(); //一个格子的时长

export const gridUrlWidth = ref(); //帧图片宽度

// 项目核心数据
export const coreData = ref({
  // 系统参数
  systemParam: {
    // 产品代号
    appType: 0,
    // 版本号，注意：这里是视频结构化数据格式版本，当前版本 2
    versionCode: 0,
    // 平台：ios/android/pc
    platform: 'pc',
  },
  // 视频元数据
  videoMetaData: {
    // 标题
    title: '',
    // 合成视频宽
    videoWidth: 0,
    // 合成视频高
    videoHeight: 0,
    // 封面
    coverUrl: '',
    // 合成视频总时长，单位：微秒
    duration: 0,
    // 素材总大小，单位：byte，服务端返回结构化数据时，会计算该参数的值
    materialTotalSize: 0,
    // 是否添加片尾（1：是，0：否）
    appendTailStatus: 0,
    // 画布类型（1：原始，2：9比16，3：1比1，4：16比9）
    canvasType: 0,
  },
  // 全局设置
  globalSetting: {
    // 画布类型（1：原始，2：9比16，3：1比1，4：16比9）
    canvasType: 1,
    // 是否添加片尾（1：是，0：否）
    appendTailStatus: 0,
    // 是否添加自动特效（1：是，0：否）
    addAutoEffect: 0,
    // 视频音量增益，保留一位小数，取值范围 0.0 - 10.0，大于1，表示音量增强。小于1，表示音量减小。0.0 表示静音
    videoVolumeGain: 0.0,
    // 字幕位置布局：(bottom/center/top)
    subtitleLayoutType: '',
    // 字幕字体
    subtiitleFontSize: 0,
    // 字幕字体颜色
    subtitleFontColor: '',
    // 字幕背景色
    subtitleBgColor: '',
  },
  // 段落列表
  sections: [
    {
      // 段落类型（普通段：normal/全局段：global）
      sectionType: 'normal',
      // 段落序号，从1开始
      sectionIndex: 1,
      // 段落文字
      sectionText: '',
      // 段落相对于项目时间线的入点，单位：微秒
      projecttimelineIn: 0,
      // 段落相对于项目时间线的出点，单位：微秒
      projecttimelineOut: 0,
      // 段落时长，单位：微秒
      duration: 0,
      // 段落时间线
      sectionTimeline: {
        // 视觉轨
        visionTrack: {
          // 素材数量
          count: 0,
          // 视觉轨道时长，单位：微秒
          duration: 0,
          // 视觉轨素材列表
          visionTrackMaterials: [],
        },
        // 音频轨
        audioTrack: {
          // 素材数量
          count: 0,
          // 音频轨道时长
          duration: 0,
          // 音频轨素材列表
          audioTrackMaterials: [],
        },
      },
      // 是否添加自动特效（1：是，0：否）
      addAutoEffect: 0,
      // 扩展字段，json string
      sectionExtData: '',
    },
  ],
});

export const currentSectionIndex = ref(1); // 当前段落焦点，初始值为 1

export const fitFrameWidth = ref(0); // 合适帧宽度

export const maxFrameWidth = ref(200); // 最大帧宽度

export const minFrameWidth = ref(0); // 最小帧宽度

// 当前绘制的是第几个格子
const gridBufferFirstIndex = (offsetLeft: number, gridWidth: number) => {
  const placeholderWidth = Math.floor(offsetLeft / gridWidth) * gridWidth;
  return Math.floor(placeholderWidth / gridWidth) + 1;
};

export function useTimeLine(timeLineContainer_width: number, duration: any): void {
  try {
    timeLine_width.value = timeLineContainer_width;

    gridFrame.value = frameWidth2Grid(frameWidth.value).gridFrame;

    timescale_width.value = getTimeScaleWidth(timeLine_width.value, duration);

    // 初始位置
    const firstIndex = gridBufferFirstIndex(timeLineOffsetLeft.value, gridWidth.value);

    // 结束位置
    const gridBufferNumber = Math.ceil(timescale_width.value / 10);

    // 动态计算数组长度
    if (gridBufferNumber > gridBufferList.value.length) {
      const dValue = gridBufferNumber - gridBufferList.value.length;
      for (let i = 1; i <= dValue; i++) {
        gridBufferList.value.push({
          frame: 0,
          showNumber: false,
          width: 0,
        });
      }
    } else if (gridBufferNumber < gridBufferList.value.length) {
      const dValue = gridBufferList.value.length - gridBufferNumber;
      gridBufferList.value.splice(gridBufferList.value.length - dValue, dValue);
    } else {
      // doing nothing
    }
    // 渲染过程
    for (let i = firstIndex; i <= gridBufferNumber + firstIndex - 1; i++) {
      const grid = gridBufferList.value[i - firstIndex];
      if (i - 1 === 0) {
        grid.frame = 0;
        grid.showNumber = true;
        grid.width = gridWidth.value;
      } else if ((i - 1) % 10 === 0) {
        grid.frame = (i - 1) * gridFrame.value;
        grid.showNumber = true;
        grid.width = gridWidth.value;
      } else {
        grid.frame = (i - 1) * gridFrame.value;
        grid.showNumber = false;
        grid.width = gridWidth.value;
      }
    }
    // 小格的显示时间
    const frame2Time = (frame: number, fps: number) => (frame % fps === 0 ? second2hms(frame / fps) : (frame / fps).toFixed(2));
    gridTime.value = computed(() => frame2Time(gridFrame.value ? gridFrame.value : 0, 30));
  } catch (err) {
    //
  }
}

/**
 * 格子渲染器需要把帧，渲染为格子：
 * @param {number} frameWidth 帧宽度
 * @returns {Object}
 *  result.gridWidth 格子宽度
 *  result.gridFrame 格子所包含的帧数
 *  result.groupGridFrame 格子组所包含的帧数
 */
const frameWidth2Grid = (frameWidth: number) => {
  // 常量，用于表明渲染分段函数
  const gridSegmentList = [
    // // level4
    {
      maxWidth: 27,
      minWidth: 20,
      gridFrame: 1,
      groupGridFrame: 10,
    },
    // level6
    {
      maxWidth: 10,
      minWidth: 5,
      gridFrame: 3,
      groupGridFrame: 30,
    },
    // level7
    {
      maxWidth: 5,
      minWidth: 30 / 9,
      gridFrame: 6,
      groupGridFrame: 60,
    },
    // level8
    {
      maxWidth: 30 / 9,
      minWidth: 2,
      gridFrame: 9,
      groupGridFrame: 90,
    },
    // level9
    {
      maxWidth: 2,
      minWidth: 1,
      gridFrame: 15,
      groupGridFrame: 150,
    },
    // level10
    {
      maxWidth: 1,
      minWidth: 30 / 90,
      gridFrame: 30,
      groupGridFrame: 300,
    },
    // level11
    {
      maxWidth: 30 / 90,
      minWidth: 30 / 180,
      gridFrame: 90,
      groupGridFrame: 900,
    },
    // level12
    {
      maxWidth: 30 / 180,
      minWidth: 30 / 360,
      gridFrame: 180,
      groupGridFrame: 1800,
    },
    // level13
    {
      maxWidth: 30 / 360,
      minWidth: 30 / 540,
      gridFrame: 360,
      groupGridFrame: 3600,
    },
    // level14
    {
      maxWidth: 30 / 540,
      minWidth: 30 / 900,
      gridFrame: 540,
      groupGridFrame: 5400,
    },
    // level15
    {
      maxWidth: 30 / 900,
      minWidth: 30 / 1800,
      gridFrame: 900,
      groupGridFrame: 9000,
    },
    // level16
    {
      maxWidth: 30 / 1800,
      minWidth: 30 / 5400,
      gridFrame: 1800,
      groupGridFrame: 18000,
    },
    // level17
    {
      maxWidth: 30 / 5400,
      minWidth: 30 / 21600,
      gridFrame: 5400,
      groupGridFrame: 54000,
    },
    // level18
    {
      maxWidth: 30 / 21600,
      minWidth: 30 / 32400,
      gridFrame: 21600,
      groupGridFrame: 216000,
    },
    // level19
    {
      maxWidth: 30 / 32400,
      minWidth: 30 / 54000,
      gridFrame: 32400,
      groupGridFrame: 324000,
    },
    // level20
    {
      maxWidth: 30 / 54000,
      minWidth: 30 / 108000,
      gridFrame: 54000,
      groupGridFrame: 540000,
    },
    // level21
    {
      maxWidth: 30 / 108000,
      minWidth: 30 / 324000,
      gridFrame: 108000,
      groupGridFrame: 1080000,
    },
    // level22
    {
      maxWidth: 30 / 324000,
      minWidth: 30 / 32400000000000000000,
      gridFrame: 324000,
      groupGridFrame: 3240000,
    },
  ];

  // 临时变量，用于存储函数结果
  let tempGridSegment;

  // 遍历常量，查找 frameWidth 所在的分段函数
  for (const gridSegment of gridSegmentList) {
    if (frameWidth <= gridSegment.maxWidth && frameWidth > gridSegment.minWidth) {
      tempGridSegment = gridSegment;
      break;
    }
  }

  if (!tempGridSegment) {
    throw new Error('Please input frameWidth in [0,200]');
  }

  return {
    gridWidth: tempGridSegment.gridFrame * frameWidth,
    gridFrame: tempGridSegment.gridFrame,
    groupGridFrame: tempGridSegment.groupGridFrame,
  };
};

/**
 * 时间刻度总宽度
 * input @帧宽度 frameWidth
 * input @时间轴宽度 timeLineWidth
 * input @素材最大时刻 materialMaxFrame
 * output @时间轴格子数量 girdTotalNumber
 */
const getTimeScaleWidth = (timeLineWidth: number, duration: string) => {
  // 计算一屏幕有多少格
  const oneScreenGridNumber = Math.floor(timeLineWidth / gridWidth.value);

  // 计算空白处有多少格
  const emptyScreenGridNumber = Math.floor(oneScreenGridNumber / 3);

  // 计算素材有多少格
  const num = hmsSecond(duration) / (gridFrame.value / 30);

  // 总格数
  const gridTotalNumber = emptyScreenGridNumber + num;

  const result = gridTotalNumber * gridWidth.value;

  return timeLineWidth >= result ? timeLineWidth : result;
};

/**
 *  获取当前最大的素材占据的帧
 * @param {Object} coreData 核心数据
 */
// TODO 本函数目前仅仅计算了视频中的最大素材，实际上应该计算全部素材的最大素材
function getMaxFrameOfMaterial(coreData: any, currentSectionIndex: number) {
  const visionTrackMaterials = coreData.sections[currentSectionIndex - 1].sectionTimeline.visionTrack.visionTrackMaterials;

  const audioTrackMaterials = coreData.sections[currentSectionIndex - 1].sectionTimeline.audioTrack.audioTrackMaterials;

  let maxtimelineOut = 0;
  if (visionTrackMaterials) {
    for (let i = 0; i < visionTrackMaterials.length; i++) {
      const timelineOut = visionTrackMaterials[i].timelineOut;
      if (timelineOut > maxtimelineOut) {
        maxtimelineOut = timelineOut;
      }
    }
  }

  return visionTrackMaterials.length === 0 && audioTrackMaterials.length === 0 ? 0 : μs2Frame(maxtimelineOut, 30);
}

/**
 * 获取时间轴合适帧宽度
 * @param {number} maxMaterialFrame
 * @param {number} timeLineWidth
 * @returns
 */
function getFitFrameWidth(maxMaterialFrame: number, timeLineWidth: number) {
  const maxMaterialFrameWidth = timeLineWidth * (4 / 5);
  return maxMaterialFrameWidth / maxMaterialFrame;
}

watchEffect(() => {
  maxFrameOfMaterial.value = getMaxFrameOfMaterial(coreData.value, currentSectionIndex.value);
});

watchEffect(() => {
  fitFrameWidth.value = getFitFrameWidth(maxFrameOfMaterial.value, timeLine_width.value);
});

/**
 * 获取时间轴最小帧宽度
 * @param {number} maxMaterialFrame 时间轴上素材的最大帧数，单位 frame
 * @param {number} timeLineWidth 时间轴的长度，单位 px
 * @returns
 */
export function getMinFrameWidth(maxMaterialFrame: number, timeLineWidth: number): number {
  const maxMaterialFrameWidth = timeLineWidth * (1 / 3);
  return maxMaterialFrameWidth / maxMaterialFrame;
}
watchEffect(() => {
  minFrameWidth.value = getMinFrameWidth(maxFrameOfMaterial.value, timeLine_width.value);
});
/**
 * 放大时间轴
 * @param {*} frameWidth
 * @param {*} minFrameWidth
 * @param {*} maxFrameWidth
 */
export const zoomIn = () => {
  const targetFrameWidth = frameWidth.value * 2;
  frameWidth.value = targetFrameWidth > maxFrameWidth.value ? maxFrameWidth.value : targetFrameWidth;
};

/**
 * 缩小时间轴
 * @param {*} frameWidth
 * @param {*} minFrameWidth
 * @param {*} maxFrameWidth
 */
export const zoomOut = () => {
  const targetFrameWidth = frameWidth.value / 2;
  frameWidth.value = targetFrameWidth < minFrameWidth.value ? minFrameWidth.value : targetFrameWidth;
};

/**
 * 缩放时间轴到合适距离
 * @param {*} frameWidth
 * @param {*} fitFrameWidth
 */
export const zoomFit = () => {
  frameWidth.value = fitFrameWidth.value;
};
/**
 * 微秒转帧数
 * @param {*} μs 需要转换的微秒数
 * @param {number} fps 帧率
 * @returns 帧数
 */
const μs2Frame = (μs: number, fps: number) => Math.round(μs * (fps / 1000000));

// 时间转换
export const second2hms = (second: number): string => {
  const h = Math.floor(second / 3600) >= 10 ? String(Math.floor(second / 3600)) : '0' + Math.floor(second / 3600);
  const m = Math.floor((second / 60) % 60) >= 10 ? String(Math.floor((second / 60) % 60)) : '0' + Math.floor((second / 60) % 60);
  const s = Math.floor(second % 60) >= 10 ? String(Math.floor(second % 60)) : '0' + Math.floor(second % 60);

  // return h === '00' ? m + ':' + s : h + ':' + m + ':' + s;
  return h + ':' + m + ':' + s;
};

// 时间转换
export const hmsSecond = (time: any) => {
  if (time.length < 8) {
    return time;
  } else {
    const hour = time.split(':')[0];
    const min = time.split(':')[1];
    const sec = time.split(':')[2];
    return Number(hour * 3600) + Number(min * 60) + Number(sec);
  }
};

// 图片格式转换
export function dataURItoBlob(dataURI: string): any {
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // mime类型
  const byteString = window.atob(dataURI.split(',')[1]); //base64 解码
  const arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
  const intArray = new Uint8Array(arrayBuffer); //创建视图

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], { type: mimeString });
}

/**
 * 上传 VideoList
 * @param {*} videoList
 * @param {*} currentVideoUrl
 * @param {*} coreData
 * @param {*} frameWidth
 * @param {*} fitFrameWidth
 */
export const addVideoOnCurrentSection = async (video: any, currentVideoUrl: any, videoRef: any) => {
  // 暂时先仅加载第一个视频的 URL

  await addVideoToCoreData(coreData.value, video, currentSectionIndex.value, currentVideoUrl, videoRef);

  frameWidth.value = fitFrameWidth.value;
};

export const clearVideoOfCurrentSection = () => {
  // 清空当前的 coreDaata
  coreData.value.sections[currentSectionIndex.value - 1].sectionTimeline.visionTrack.visionTrackMaterials = [];

  frameWidth.value = fitFrameWidth.value;
  // 清空当前的 currentVideoUrl
};

/**
 * 将 videoFileList 中的视频，加入到 CoreData 中去
 * @param {*} coreData
 * @param {*} videoFileList
 * @param {*} currentSectionIndex
 * @returns
 */
const addVideoToCoreData = async (
  coreData: any,
  videoFile: any,
  currentSectionIndex: number,
  currentVideoUrl: any,
  videoRef: any
) => {
  try {
    // 准备加入 CoreData 的视频素材
    const tempVisionTrackMaterials: any[] = [];

    // 获取视频素材中最大的 timelineOut
    let maxtimelineOut = 0;

    const visionTrackMaterials = coreData.sections[currentSectionIndex - 1].sectionTimeline.visionTrack.visionTrackMaterials;

    for (let i = 0; i < visionTrackMaterials.length; i++) {
      const timelineOut = visionTrackMaterials[i].timelineOut;
      if (timelineOut > maxtimelineOut) {
        maxtimelineOut = timelineOut;
      }
    }
    // 构建 tempVisionTrackMaterials
    for (let i = 0; i < 1; i++) {
      // 当前素材的时长
      const duration = await getVideoDuration(videoRef);

      // 当前素材的 timelineIn 为 maxtimelineOut，当然如果没有前面的素材，timelineIn 为 0
      const timelineIn: any = i === 0 ? maxtimelineOut : tempVisionTrackMaterials[i - 1].timelineOut;

      const timelineOut = timelineIn + duration;

      const res: any = await getVideoHeightWidth(videoRef);

      tempVisionTrackMaterials.push({
        type: 'video',
        width: res.width,
        height: res.height,
        duration: duration,
        timelineIn: timelineIn,
        timelineOut: timelineOut,
        url: currentVideoUrl,
        file: videoFile,
      });
    }

    // 将 tempVisionTrackMaterials 加入 coreData
    for (let i = 0; i < tempVisionTrackMaterials.length; i++) {
      coreData.sections[currentSectionIndex - 1].sectionTimeline.visionTrack.visionTrackMaterials.push(
        tempVisionTrackMaterials[i]
      );
    }
    //   resolve({});
  } catch (error) {
    //   reject(error);
  }
};

/**
 * 获取一个视频的时长，单位微秒
 * @returns
 */
const getVideoDuration = (videoRef: any) => {
  return new Promise((resolve, reject) => {
    try {
      videoRef.addEventListener('loadedmetadata', () => {
        resolve(videoRef.duration * 1000000);
      });
    } catch (error) {
      console.log('getVideoDuration error', error);
      throw error;
    }
  });
};

/**
 * 获取一个视频的宽和高
 * @returns
 */
const getVideoHeightWidth = (videoRef: any) => {
  return new Promise((resolve, reject) => {
    try {
      resolve({ width: videoRef.videoWidth, height: videoRef.videoHeight });
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 获取时间轴上，视觉素材的宽度
 * @param {number} timelineIn 素材在时间轴上的入点
 * @param {*} timelineOut 素材在时间轴上的出点
 * @param {*} frameWidth 帧宽度
 * @returns
 */
export const getMaterialWidth = (timelineIn: number, timelineOut: number, frameWidth: number) =>
  μs2Frame(timelineOut - timelineIn, 30) * frameWidth;

const flatFrameList = ref([]); // 扁平帧图列表：决定了当前屏幕上应该显示的帧图+ 前后该缓存的帧图
// 视频帧图的宽度
const VIDEO_FRAME_WIDTH = 124;
// 视频帧图的高度
export const VIDEO_FRAME_HEIGHT = 70;
const getflatFrameList = () => {
  const videoFrameWidth = VIDEO_FRAME_WIDTH;
  // 输入数据检查
  if (coreData.value.sections.length === 0) {
    return [];
  }

  // 输入数据检查
  if (coreData.value.sections[currentSectionIndex.value - 1].sectionTimeline.visionTrack.visionTrackMaterials.length === 0) {
    return [];
  }

  // 第一步：计算全部视频，在当前 frameWidth 下的全部帧图
  const visionTrackMaterials =
    coreData.value.sections[currentSectionIndex.value - 1].sectionTimeline.visionTrack.visionTrackMaterials;

  const tempFramesList = [];

  for (let i = 0; i < visionTrackMaterials.length; i++) {
    // 获取视频素材
    const material: any = visionTrackMaterials[i];

    // 计算视频素材的宽度
    const materialWidth = getMaterialWidth(material.timelineIn, material.timelineOut, frameWidth.value);

    // 计算视频素材内所需要的帧图数
    const framesNumber = Math.ceil(materialWidth / videoFrameWidth);

    const tempFrames = [];

    for (let i = 0; i < framesNumber; i++) {
      const frame = Math.floor(i * (videoFrameWidth / frameWidth.value));
      const position = `${i * videoFrameWidth + 'px'} 0px`;

      tempFrames.push({
        frame: frame,
        position: position,
        file: material.file,
      });
    }
    tempFramesList.push(tempFrames);
  }

  // 第二步：根据屏幕偏移量，计算出当前应该渲染的帧图
  // 计算屏幕前方有多少图片
  // 计算屏幕内有多少图片
  const screenFramesNumber = Math.ceil(timeLine_width.value / videoFrameWidth);

  // 当前是第几个视频的第几帧
  let currentVideoIndex = 0;
  let currentFrameIndex = 0;

  let tempTotalMaterialWidth = 0;
  for (let i = 0; i < visionTrackMaterials.length; i++) {
    // 获取视频素材
    const material: any = visionTrackMaterials[i];

    // 计算视频素材的宽度
    const materialWidth = getMaterialWidth(material.timelineIn, material.timelineOut, frameWidth.value);

    tempTotalMaterialWidth += materialWidth;

    if (timeLineOffsetLeft.value < tempTotalMaterialWidth) {
      currentVideoIndex = i;
      currentFrameIndex = Math.floor((timeLineOffsetLeft.value - (tempTotalMaterialWidth - materialWidth)) / videoFrameWidth);
      break;
    } else if (timeLineOffsetLeft.value === tempTotalMaterialWidth) {
      currentVideoIndex = i + 1;
      currentFrameIndex = 0;
      break;
    }
  }

  // 计算本次我需要渲染哪一帧
  // 先计算纯数字，再构建成 flatFrameList 结构
  // 需要 currentVideoIndex 的 currentFrameIndex 向前找 screenFramesNumber 个帧图
  // 需要 currentVideoIndex 的 currentFrameIndex 向后找 2*screenFramesNumber 个帧图
  // 做一个步骤：先拆分，再组装
  // 拆分 framesList 为 frames
  const tempList = [];

  // 拆分后的 index
  let flatCurrentFrameIndex: any;
  let count = 0;

  for (let i = 0; i < tempFramesList.length; i++) {
    const tempFrames = tempFramesList[i];
    for (let j = 0; j < tempFrames.length; j++) {
      const tempFrame = tempFrames[j];
      tempList.push({
        videoIndex: i,
        frame: tempFrame.frame,
        position: tempFrame.position,
        file: tempFrame.file,
      });
      if (currentVideoIndex === i && currentFrameIndex === j) {
        flatCurrentFrameIndex = count;
      }
      count++;
    }
  }

  // 开始的帧图
  const startFrameIndex = flatCurrentFrameIndex - screenFramesNumber > 0 ? flatCurrentFrameIndex - screenFramesNumber : 0;

  // 结束的帧图
  const endFrameIndex =
    flatCurrentFrameIndex + 2 * screenFramesNumber - 1 > tempList.length - 1
      ? tempList.length - 1
      : flatCurrentFrameIndex + 2 * screenFramesNumber - 1;

  // 切分数组：称为一个扁平化的数据
  const flatFrameList: any = tempList.slice(startFrameIndex, endFrameIndex + 1);
  // console.log("切分数组成功", flatFrameList);

  // 切分后的数组的当前帧
  const afterSliceFlatCurrentFrameIndex = flatCurrentFrameIndex - startFrameIndex;

  // 第三步：获取读帧优先级
  for (let i = 0; i < flatFrameList.length; i++) {
    if (i >= 0 && i < afterSliceFlatCurrentFrameIndex) {
      flatFrameList[i].priority = 2;
    } else if (i >= afterSliceFlatCurrentFrameIndex && i < afterSliceFlatCurrentFrameIndex + screenFramesNumber) {
      flatFrameList[i].priority = 0;
    } else if (i >= afterSliceFlatCurrentFrameIndex + screenFramesNumber) {
      flatFrameList[i].priority = 1;
    }
  }
  return flatFrameList;
};

watchEffect(() => {
  flatFrameList.value = getflatFrameList();
});
