import { ref } from 'vue';
import { message } from 'ant-design-vue';
import http from '@/api/conentCenter/communal';
import emitter from '@/utils/mitt';
const sortId = ref();
emitter.on('changeSortId', (e) => {
  sortId.value = e;
});

/** 创建分类 */
export const createSort = (paramsInfo: any) => {
  const sortInfo = ref();
  const createStatus = ref(0);
  const isVisible = ref<boolean>(false);
  emitter.on('createSortName', (e) => {
    sortInfo.value = e;
  });
  const getCreate = () => {
    const params: any = {
      type: 1,
      name: sortInfo.value.name,
      portal_id: paramsInfo.gatewayId,
    };
    createStatus.value = 0;
    http
      .create_sort(params)
      .then((res) => {
        isVisible.value = false;
        createStatus.value = 1;
        message.success(res.data.message);
      })
      .catch((res) => {
        message.error(res.data.error);
      });
  };
  return {
    isVisible,
    createStatus,
    getCreate,
  };
};

/** 获取分类列表 */
export const sortLists = (paramsInfo: any) => {
  interface sortType {
    id: number;
    name: string;
    visible: boolean;
    count: number;
    default: number;
  }
  const sortListArr = ref<sortType[]>([]);
  const getSortList = () => {
    const params: any = {
      type: paramsInfo.type,
      portal_id: paramsInfo.gatewayId,
    };
    http.get_sort_list({ params }).then((res) => {
      sortListArr.value = res.data.data;
    });
  };
  return {
    sortListArr,
    getSortList,
  };
};
