import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  // 获取直播回放视频列表
  getRecordVideoList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/demand/get_record_video_list', data);
  };

  // 删除直播录制视频
  delRecordVideo = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/demand/del_record_video', data);
  };

  // 直播录制视频移至视频库
  moveRecordVideo = (data: unknown) => {
    return http.post('/api/v1/demand/move_record_video', data);
  };
  // 获取媒体库视频列表
  getDemandVideoList = (data?: AxiosRequestConfig) => {
    return http.get('/api/v1/demand/get_demand_video_list', data);
  };
  // 删除视频
  getDelDemandVideo = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/demand/del_demand_video', data);
  };

  // 获取媒体库列表
  getMediaFileList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/media/get_media_file_list', data);
  };

  // 媒体库文件删除
  delMediaFile = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/del_media_file', data);
  };

  // 修改视频信息
  getupdDemandVideo = (data: unknown) => {
    return http.post('/api/v1/demand/upd_demand_video', data);
  };
  // 创建云剪辑工程
  getBuildTimeLine = (store: any) => {
    return http.post('/api/v1/build_time_line', store);
  };

  // 获取图片上传地址和凭证
  createUploadImage = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/create_upload_image', data);
  };
  // 获取视频上传地址和凭证响应数据
  createUploadVideo = (data: unknown) => {
    return http.post('/api/v1/media/create_upload_video', data);
  };
  getApplyVideoUpload = (data: unknown) => {
    return http.post('/api/v2/tencent_vod/apply_video_upload', data);
  };
  getVideoInfos = (data: unknown) => {
    return http.post('/api/v2/tencent_vod/video_infos', data);
  };
  // 轮询请求查看视频转码状态
  getVideoStatus = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/media/get_video_status', data);
  };
  // 刷新视频上传凭证
  refreshUploadVideo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/refresh_upload_video', data);
  };

  // 阿里云剪辑
  buildMediaMetadata = (data: unknown) => {
    return http.post('/api/v1/clip/clip_synthesis', data);
  };
  // 腾讯云剪辑合成
  tencent_clip_synthesis = (data: unknown) => {
    return http.post('/api/v1/clip/tencent_clip_synthesis', data);
  };

  // 批量获取视频详情
  getVideosInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/demand/get_videos_info', data);
  };
  // 视频合并
  aliyunVideoMerge = (data: unknown) => {
    return http.post('/api/v1/clip/aliyun_video_merge', data);
  };

  // 获取预告回放添加后的打点视频
  getDotingList(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/video_dotting_list', data);
  }
  //导播台视频预处理(用于直播导播)
  submit_preprocess_jobs(data: unknown) {
    return http.post('/api/v1/common/submit_preprocess_jobs', data);
  }
}

export default new Api();
