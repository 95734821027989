import liveItemBg from './images/liveImg.png';
import Rubbin from './medias/rubbin.png';
import Rubbins from './medias/rubbins.png';
import DownLoad from './medias/xiazai.png';
import Edit from './medias/bianji.png';
import Clip from './medias/jianji.png';
import Embed from './medias/qianru.png';
import PatrickStar from './medias/liveImg.png';
import RangePickerIcon from './RangePickerIcon.png';
import PlaceSquareBg from './svg/place_bg.svg';
import emptyData from './svg/empty_data.svg';
import emptyDataSec from './svg/empty_data2.svg';
import nullImg from './svg/null_img.svg';
import playing from './images/playing.gif';
import mailbox from './account/mailbox.png';
import password from './account/password.png';
import phone from './account/phone.png';
import mail from './account/mail.png';
import emptyMsg from './svg/msg_bg.svg';
import customMenuView from './svg/custom_menu_view.svg';
import emptyDataImgTxt from './svg/empty_data_imgtxt.svg';
import loginCover from './svg/logoicon.svg';
import loginIcon from './svg/logo.svg';
import logoicon from './svg/login_cover.svg';
import documentPpt from './svg/ppt.svg';
import documentPdf from './svg/pdf.svg';
import documentPreview from './svg/delete.svg';
import documentDelete from './svg/document.svg';
import emptyAdverst from './svg/emptyAdverst.svg';
import punchEmpty from './svg/punchEmpty.svg';
import redPacketBg from '@/assets/red_packet_bg.png';
// logo
import FullNmaeLogo from './base/qingtv_logo.png';
import Logo from './base/qtv_logo.png';
import notFound from './base/404.png';
export { FullNmaeLogo, Logo, notFound };

// 账户充值
import WeChatpay from './images/resourceCenter/WeChat.svg';
import Alipay from './images/resourceCenter/Alipay.svg';
import Balancepay from './images/resourceCenter/Balance.svg';
import Bankpay from './images/resourceCenter/Bank.svg';
import sucMsg from './images/resourceCenter/sucMsg.svg';
import failMsg from './images/resourceCenter/failMsg.svg';
import bankch from './images/resourceCenter/bankch.png';
import FormDoc from './svg/form_doc.svg';
import VersionPlate from './svg/version_plate.svg';

import LuckMoney from './images/luck_money.png'; // 竞答红包图片
import Prize from './images/prize.png'; // 竞答红包-礼物图片
import Packet from './images/packet.png'; // 红包图片
import RedPackLogo from './images/redpacklogo.png'; // 红包logo印花
import Crown from './images/crown.png'; // 红包手气最佳

import SwitchMoon from './svg/vt-switch-moon.svg';
import SwitchSun from './svg/vt-switch-sun.svg';

//门户
import articleEmpty from './svgIcons/articleEmpty.svg';

// 直播总览二维码
import code1 from './dataCenter/code1.png';
import code2 from './dataCenter/code2.png';
import code3 from './dataCenter/code3.png';
import code4 from './dataCenter/code4.png';
import code5 from './dataCenter/code5.png';
import code6 from './dataCenter/code6.png';
import code7 from './dataCenter/code7.png';
import code8 from './dataCenter/code8.png';

export { code1, code2, code3, code4, code5, code6, code7, code8 };

// 点赞
import fabulous1 from './likeSetting/fabulous1.svg';
import fabulous2 from './likeSetting/fabulous2.svg';
import fabulous3 from './likeSetting/fabulous3.svg';
import fabulous4 from './likeSetting/fabulous4.svg';
import fabulous5 from './likeSetting/fabulous5.svg';
import fabulous6 from './likeSetting/fabulous6.svg';
import fabulous7 from './likeSetting/fabulous7.svg';

export { fabulous1, fabulous2, fabulous3, fabulous4, fabulous5, fabulous6, fabulous7 };

export { WeChatpay, Alipay, Balancepay, Bankpay, failMsg, sucMsg, bankch };

// 页面装饰
import pageStyle1 from './pageDirector/style1.png';
import pageStyle2 from './pageDirector/style2.png';
import pageStyle3 from './pageDirector/style3.png';
import pageStyle4 from './pageDirector/style4.png';
import pageStyle5 from './pageDirector/style5.png';
export { pageStyle1, pageStyle2, pageStyle3, pageStyle4, pageStyle5 };

import emptyEcharts from '../assets/emptyEcharts.svg'; // 空状态图 有文字  用于图表

// 版本购买
import planV1 from './images/plan_1.webp';
import planV2 from './images/plan_2.webp';
import planV3 from './images/plan_3.webp';
import planV4 from './images/plan_4.webp';
import planV5 from './images/plan_top.webp';
import planV6 from './images/plan_bot.webp';
import planT1 from './images/plan_t1.webp';
import planT2 from './images/plan_t2.webp';
import planT3 from './images/plan_t3.webp';
import planT4 from './images/plan_t4.webp';
import planC1 from './images/plan_c1.webp';
import planC2 from './images/plan_c2.webp';
import planC3 from './images/plan_c3.webp';
import planC4 from './images/plan_c4.webp';

// 引导页
import guideOne from './guide/guideOne.png';
import guideTwo from './guide/guideTwo.png';
import guideThree from './guide/guideThree.png';
import guideFour from './guide/guideFour.png';
import guideFive from './guide/guideFive.png';
import guideSix from './guide/guideSix.png';

export { planV1, planV2, planV3, planV4, planV5, planV6, planT1, planT2, planT3, planT4, planC1, planC2, planC3, planC4 };

export {
  guideOne,
  guideTwo,
  guideThree,
  guideFour,
  guideFive,
  guideSix,
  liveItemBg,
  Rubbin,
  DownLoad,
  Edit,
  Clip,
  Embed,
  PatrickStar,
  Rubbins,
  RangePickerIcon,
  PlaceSquareBg,
  emptyData,
  emptyDataSec,
  nullImg,
  playing,
  mailbox,
  password,
  phone,
  mail,
  emptyMsg,
  customMenuView,
  emptyDataImgTxt,
  loginCover,
  loginIcon,
  logoicon,
  documentPpt,
  documentPdf,
  documentPreview,
  documentDelete,
  FormDoc,
  VersionPlate,
  emptyAdverst,
  punchEmpty,
  redPacketBg,
  LuckMoney,
  Packet,
  Prize,
  RedPackLogo,
  Crown,
  articleEmpty,
  SwitchMoon,
  SwitchSun,
  emptyEcharts,
};
