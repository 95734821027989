<template>
  <div class="box">
    <h1>服务条款及隐私协议</h1>
    <h4>1、使用协议的接受</h4>
    <p>
      通过访问和/或使用本网站，您表示同意接受本协议的所有条件和条款。上海邦纬科技有限公司（以下简称“邦纬”）作为平台(qing.tv，以下简称“”)的运营者依据本协议为您提供服务。如果您不愿接受本协议的全部条件和条款，请您不要访问或使用本网站、平台服务等。如您对于本协议中部分条款存在疑义或建议，您可发送邮件至service@bonwey.com，邦纬将在7个工作日内对您的建议或疑义进行反馈。
    </p>
    <h4>2、本协议的变更和修改</h4>
    <p>
      邦纬有权随时对本协议进行修改，并且一旦发生协议条款的变动，邦纬将在相关页面上提示修改的内容；用户如果不同意本协议的修改，可以放弃使用或访问本网站或取消已经获得的服务；如果用户选择在本协议变更后继续访问或使用本网站，则视为用户已经接受本协议的修改。如果用户对于更新的协议中条款存在疑义或建议，您可发送邮件至service@bonwey.com，邦纬将在7个工作日内对您的建议或疑义进行反馈。
    </p>
    <h4>3、服务说明</h4>
    <div>
      （1）您知晓并同意，是一个向广大用户提供网络直播的服务平台，本身不直接生产内容。
      <br />（2）您知晓并同意，运用自己的系统通过互联网向用户提供服务，故除非另有明确规定，对于目前服务的任何新功能的增强或强化，包括但不限于新产品的推出以及新增加的服务，均无条件地适用本协议。
      <br />（3）您知晓并同意，因提供平台服务，并不直接生产内容，故对网站服务承担操作系统以下的底层部分及平台的运营维护责任。受限于当前行业技术水平，不保证服务一定会满足每一位用户的使用要求，也不保证服务不会被中断（尤其因非可归因于乙方的场地、电力、网络供应商或当前技术限制等客观原因所导致的中断），对服务的绝对及时性、绝对安全性、绝对准确性也不作担保。
    </div>
    <h4>4、用户行为</h4>
    <h5>4.1用户帐号、密码安全</h5>
    <p>
      用户一旦注册成功并通过审核，便成为的合法用户，将得到一个密码和帐号。用户应采取合理措施维护其密码和帐号的安全。您知晓并同意，您对利用服务进行的包括但不限于经营活动、召开的内外部会议等一切活动负全部责任，在内容或信息采集、处理、发布时，须对信息内容进行严格审查，遵守《计算机信息网络国际联网安全保护管理办法》《中华人民共和国计算机信息网络国际联网管理暂行规定》《中华人民共和国计算机信息系统安全保护条例》《中华人民共和国电信条例》《互联网信息服务管理办法》和国家其他有关法律、法规、条例等，不得做任何违法经营活动。如因违反上述规定及相关法律法规所导致的任何损失或损害，用户自负责任。
    </p>
    <p>
      用户的密码和帐号遭到未授权的使用或发生其他任何安全问题时，用户可立即通知。为免风险，提醒，用户在每次连线结束后，应结束帐号使用，否则用户可能无法得到的安全保护。对于用户长时间未使用的帐号，有权在采取合理措施后予以关闭。
    </p>
    <h5>4.2用户应遵守以下法律及法规</h5>
    <p>
      用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规、实施办法。在任何情况下，合理地认为用户的行为可能违反上述法律、法规时，可以在任何时候，不经事先通知终止向该用户提供服务。用户对于上述违反情况存在疑义的，可向提出相反证据证明。
    </p>
    <p>
      欢迎用户举报任何违反上述法律或侵犯他人权利的上传内容，一经发现违法或侵权的上传内容，将依法删除。举报邮箱：service@bonwey.com。
    </p>
    <h5>4.3用户申请提现平台规则制度</h5>
    <div>
      （1）平台单笔提现额度为最低50元（伍拾圆）到最高20000元（贰万圆）之间；<br />
      （2）提现时，平台收取总提现金额9%的手续费,其中已包含第三方手续费；<br />
      （3）从申请提现时间起，7-15个工作日提现金额将会转入用户指定微信账户。<br />
    </div>
    <h5>4.4禁止用户从事以下行为</h5>
    <div>
      （1）直播、录播、预约直播、上载、张贴、发送电子邮件或传送包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他另人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、视频、信息或其他资料（以下简称内容）。
      <br />（2）以任何方式危害未成年人。 <br />（3）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关。
      <br />（4）伪造标题或以其他方式操控识别资料，使人误认为该内容为直播所传送。
      <br />（5）将无权传送的内容（例如内部资料、机密资料）进行直播、录播、预约直播或以其他方式传送。
      <br />（6）将侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容加以直播、录播、预约直播或以其他方式传送。
      <br />（7）将广告函件、促销资料、"垃圾邮件"等，加以直播、录播、预约直播或以其他方式传送。供前述目的使用的专用区域除外。
      <br />（8）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以直播、录播、预约直播或以其他方式传送。
      <br />（9）干扰或破坏本网站服务或与本网站服务相连的服务器和网络，或不遵守本网站使用之规定。
      <br />（10）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。
    </div>
    <h5>
      4.5用户对经由本网站直播、录播、预约直播、上载、张贴、发送电子邮件或评论传送的内容承担全部责任对于经由本网站而传送的内容，不保证前述其合法性、正当性、准确性、完整性或品质。用户在接受本网站服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，均不对任何内容承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。有权（但无义务）自行拒绝或删除经由本网站提供的任何不当内容。用户使用上述内容，应自行承担风险。有权利在下述情况下，对内容进行保存或披露：
    </h5>
    <div>
      （1）法律程序所规定；<br />
      （2）本服使用协议规定；<br />
      （3）被侵害的第三人提出权利主张；<br />
      （4）为保护直播、其使用者及社会公众的权利、财产或人身安全；<br />
      （5）其他直播认为有必要的情况。
    </div>
    <h5>4.6对用户信息的存储和限制</h5>
    <p>
      不对用户所发起直播的删除或储存失败承担责任。对于免费用户，仅保存其回放内容30天。有权判断用户的行为是否符合本网站使用协议条款之规定，如果认为用户违背了协议条款的规定，有终止向其提供网站服务的权利。
    </p>
    <h4>5、用户隐私权制度</h4>
    <h5>5.1提示条款</h5>
    <div>
      （1）用户理解并同意：为向用户提供服务的目的，将按照合法、正当、必要原则收集用户信息。
      <br />（2）用户理解并同意：为给用户提供更好的产品和/或服务体验，将持续改进技术并不时推出新功能或优化原有功能，为此如需收集、使用新的用户个人信息或变更用户个人信息使用目的或方式的，将通过更新本服务条款或弹窗提示等方式向用户告知该等变更，并在征得用户同意后收集、使用。
      <br />（3）用户理解并同意：如用户为未成年人，建议请未成年用户父母或监护人阅读本政策，并在征得父母或监护人同意的前提下使用或向提供用户的信息。如未成年用户监护人不同意未成年用户按照本服务条款使用服务或向提供信息，请未成年用户立即终止使用的服务并及时通知，以便采取相应措施。对于经父母或监护人同意而收集未成年用户信息的情况，只会在受到法律允许、或父母或监护人明确同意、或为保护未成年用户权益所必要的情况下使用或公开披露此信息。如未成年用户的父母或监护人对其所监护的未成年用户的信息处理存在疑问时，请通过本服务条款第5.6条的个人数据处理的建议及投诉渠道联系。
    </div>
    <h5>5.2定义</h5>
    <div>
      （1）“个人数据“或”个人信息”是指任何被识别为与数据主体相关的信息，或者可以直接或间接与特定数据主体合理关联的信息，这些信息由代表用户进行处理。为免歧义，个人数据包括个人敏感信息，但不包括匿名数据。
      <br />（2）“个人敏感信息”是指数据主体的以下信息：
      <div>
        A、政府机构下发的身份号码(包括社会保险号、驾驶执照号或国家颁发的身份号码)；
        <br />B、金融账号、信用卡号、借记卡号、信用报告信息，不论以上信息是否设置任何必要的安全代码、进入代码、个人身份号码或密码；
        <br />C、遗传和生物特征数据或健康数据
      </div>
      <br />
    </div>
    <h5>5.3 数据收集方式</h5>
    <div>
      （1）在用户注册账号时，用户需提供手机号码及电子邮箱作为账号登录名。如用户不提供前述信息，可能无法注册账号并使用服务。
      <br />（2）为用户能使用充值的余额支付对应服务进行消费的目的，需记录您的银行账户信息以及交易信息。如用户不同意记录前述信息，可能无法使用充值的余额支付并使用服务。
      <br />（3）为保障向用户提供的服务的安全性，预防病毒或其他恶意程序，可能需要记录用户使用的服务类别、方式及设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符、所在地区、网络使用习惯、设备相关应用信息以及其他与服务相关的日志信息。如用户不同意记录前述信息，可能无法完成风控验证。
      <br />（4）下述情形中，根据相关法律法规，可能会依法收集并使用用户的个人信息而无需征得用户的同意:
      <div>
        A、与履行国家法律法规及行业主管部门有关规定的义务相关的；
        <br />B、与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />C、与国家国防安全、公共安全、公共卫生、重大公共利益直接相关的； <br />D、该等个人信息是用户自行向社会公众公开的；
        <br />E、根据用户签订和履行合同所必需的；
      </div>
    </div>
    <h5>5.4 数据使用方式及处理措施</h5>
    <div>
      （1）处理目的: 代表用户处理个人数据，以便根据服务协议提供相应服务。
      <br />（2）处理时限:本协议的期限加上直到根据本协议应删除所处理的所有个人数据的期限。
      <br />（3）数据主体范围:用户或用户的最终用户通过服务向提供的个人数据的主体，包括但不限于用户的员工、承包商和最终用户。
      <br />（4）个人数据类型:由用户或用户的最终用户通过服务向提供的个人数据，包括但不限于以下内容:
      <div>
        A、用户简介:姓名、电话、电子邮件、密码、简介图片;
        <br />B、用户会议、课堂或直播数据中的:主题、描述、参与者IP地址、设备/硬件信息；
        <br />C、云记录:用户制作的视频、音频、文本文件、即时聊天日志； <br />D、主机名、主机电子邮件、所用设备的媒体访问控制地址。
      </div>
      （5）处理方式
      <div>
        <span>A.共享</span>
        <br />不会在未经合法用户授权时，公开、编辑或透露其个人信息及保存在邦纬公司中的非公开内容，但以下情况除外：
        <div>
          a、法定共享：可能将根据法律法规规定、诉讼或争议解决需要，或按行政、司法机关依法要求，对外共享用户个人信息。
          <br />b、获取用户明确同意后共享：事先获得用户明确同意后，将在法律法规允许且不违背公序良俗的范围内，与其他方共享用户个人信息。如：客户选择参与和第三方联合开展的抽奖、竞赛或类似推广活动，可能与其共享活动过程中产生的、为完成活动所必要的信息，以便第三方能及时为客户提供服务，会依据法律法规要求，通过适当途径向用户明确告知需要向第三方提供何种信息；
          如为向用户提供服务的目的而需要将用户信息共享至第三方或雇佣其他第三方提供服务，将评估第三方收集信息的合法性、正当性、必要性，并要求第三方对用户信息采取保护措施并严格遵守相关法律法规与监管要求。将按照法律法规要求以本服务条款及弹窗形式征得用户同意或确认第三方已征得用户的同意。雇佣其他第三方或聘用其关联公司并授权其作为个人信息次级处理者的，如为提供服务的目的需授权的个人信息处理范围超出用户向提供个人信息时的授权范围，将向客户发出书面通知征求用户的同意，客户可以在收到该书面通知后十(10)天内以书面形式反对此类约定。如果客户在发出通知后十(10)天内未反对第三方的参与，则该第三方将被视为本协议中的授权个人信息次级处理者。
          <br />c、用户主动选择共享：用户通过平台购买第三方商品或服务的，会根据用户选择，将授权的个人信息中与交易有关的必要信息共享给第三方商品或服务的提供者，以实现用户的服务需求。
          <br />d、与关联公司间共享：为便于基于直播平台向用户提供产品和服务，推荐用户可能感兴趣的信息，识别账号异常，用户的个人信息可能会与的关联公司共享。但仅共享必要的个人信息，且受本隐私权制度5.4（1）项所列目的的约束，如果共享用户个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求用户授权同意。
        </div>
        <span>B.公开披露</span>
        <br />仅会在以下情况公开披露用户个人信息：
        <div>
          a、获得用户明确同意或基于用户的主动选择，可能会公开披露用户个人信；
          <br />b、如果确定用户出现违反法律法规，或严重违反本服务条款或平台已公布的相关规则的情况，或为保护公众人身财产安全免遭侵害，可能依据法律法规或征得用户同意的情况下披露关于用户的个人信息，包括相关违规行为以及已对用户采取的措施。
        </div>
        <span>C.共享与公开披露个人信息时事先征得授权同意的例外</span>
        <div>
          a、与履行国家法律法规及行业主管部门有关规定的义务相关的；
          <br />b、与犯罪侦查、起诉、审判和判决执行等直接相关的；
          <br />c、与国家国防安全、公共安全、公共卫生、重大公共利益直接相关的； <br />d、该等个人信息是用户自行向社会公众公开的；
          <br />e、根据用户签订和履行合同所必需的；
          用户理解并同意，根据可适用的数据保护相关法律法规及本服务条款5.2条定义，若对个人信息采取技术措施和其他必要措施进行处理使其匿名化（为免歧义，匿名化指使数据接收方无法重新识别特定个人且不能复原），
          则匿名数据的共享与公开披露无需另行向用户通知并征得用户同意。
        </div>
        <span>D.服务消息推送</span>
        <br />会向用户发送关于不同产品和服务的信息或者认为用户会感兴趣的其他产品和服务。如果用户不希望收到这样的邮件，只需在提供个人信息时或其他任何时候告知即可。
        <h5>5.5 数据保留期限</h5>
        <p>
          服务完成后，根据客户的选择，可返还或删除个人数据。相关法律法规或条例要求保留的或经授权保留的除外。如果返还或销毁不可行或受到法律、法规或条例的禁止，将采取措施阻止此类个人数据的任何进一步处理(法律法规或条例要求继续处理的除外)，并应继续适当保护其拥有、保管或控制的个人数据。
        </p>
        <h5>5.6 数据主体请求</h5>
        <p>
          如收到数据主体对于访问、纠正、限制、删除、转移或停止处理其数据的请求，或撤回对数据处理的同意
          (此类请求单独或统称为“数据主体请求”)，应在适用的数据保护法允许的范围内及时通知客户。
        </p>
        <p>
          如收到与客户数据相关的数据主体的上述请求，将建议数据主体将其请求提交给客户，客户应响应和解决此类请求。为免歧义，如果数据主体无权获得数据保护法下之救济，则没有义务接受数据主体此类请求。
        </p>
        <p>个人数据处理的建议及投诉渠道 邮箱： service@bonwey.com 电话：400-920-1599</p>
      </div>
    </div>
    <h4>6、关于用户在发起直播的内容：</h4>
    <div>
      （1）用户发起直播的内容是指用户在网站及移动客户端以自助形式发布的视频或其它任何形式的内容包括文字、图片、音频、信息、资料等。
      <br />（2）除非收到相反通知，将用户视为其在本网站上载或发布的内容的版权拥有人。用户在本网站上载或发布内容即视为其同意授予所有上述内容的在全球范围内的免费、不可撤销、无限期、可转让的非独家使用权许可，有权展示、散布及推广前述内容，有权对前述内容进行任何形式的复制、修改、出版、发行及以其他方式使用或者授权第三方进行复制、修改、出版、发行及以其他方式使用。
      <br />（3）因用户就上述内容在本网站的上载或发布而导致任何第三方提出索赔要求或衍生的任何损害或损失，由用户承担全部责任。
    </div>
    <h4>7、关于第三方链接</h4>
    <p>
      本网站服务可能会提供与其他国际互联网网站或资源进行链接。对于前述网站或资源是否可以利用，承担担保责任。因使用或依赖上述网站或资源所生的损失或损害，也不负担任何责任。
    </p>
    <h4>8、知识产权及其他权利</h4>
    <div>
      （1）对网站服务及本网站所使用的软件包含的受知识产权或其他相关法律保护的资料享有相应的权利；除用户依法就其内容享有的版权及用户基于与间合同关系而产生的付费、可撤销、有限期、不可转让的使用许可之外，本网站的整体内容版权归所有。
      <br />（2）经由本网站发起的及其它内容，受到著作权法、商标法、专利法或其他法律的保护；除该直播及其它内容上载的用户所享有的版权，未经明示授权许可，其他用户不得进行修改、出租、散布或衍生其他作品。
      <br />（3）用户对本网站所使用的软件有非专属性使用权，但不得自行或许可任何第三方复制、修改、出售或衍生产品。
      <br />（4）本网站所有设计图样以及其他图样、产品及服务名称，均为及/或其关联公司所享有的商标、标识。任何人不得使用、复制或用作其他用途。
      <br />（5）对其专有内容、原创内容和其他通过授权取得的独占或独家内容享有完全知识产权。未经许可，任何单位和个人不得私自转载、传播和提供观看服务或其他侵犯知识产权的行为，侵权主体将承担法律责任。
    </div>
    <h4>9、免责声明</h4>
    <div>
      （1）对于任何自本网站而获得的他人的信息、内容或者广告宣传等任何资讯（以下统称“信息”），不保证其真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，须自行甄别真伪和谨慎预防风险，否则，无论何种原因，本网站不对任何非与本网站直接发生的交易和/或行为承担任何直接、间接、附带或衍生的损失和责任。
      <br />（2）有权利但无义务，改善或更正本网站任何部分之任何疏漏、错误。 <br />（3）不保证（包括但不限于）：
      <div>
        a.本网站适合每一位用户的使用要求；
        <br />b.受限于当前行业技术水平，本网站不受干扰，及时、安全、可靠或不出现错误；
        <br />c.用户经由本网站取得的任何产品、服务或其他材料符合用户的期望。
      </div>
      （4）用户使用经由本网站下载的或取得的任何资料，其风险自行负担；因该等使用导致用户电脑系统损坏或资料流失，用户应负完全责任；
      <br />（5）基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，不承担任何直接、间接、附带、衍生或惩罚性的赔偿：
      <div>
        a.本网站使用或无法使用；
        <br />b.经由本网站购买或取得的任何产品、资料或服务； <br />c.用户资料遭到未授权的使用或修改；
        <br />d.其他与本网站相关的事宜。
      </div>
      （6）用户在浏览互联网时自行判断使用本网站的搜索或检索目录。该搜索或检索目录可
      能会引导用户进入到被认为具有攻击性或不适当的网站，没有义务查看检索目录所列网站的内容，因此，对其正确性、合法性、正当性不负任何责任。
    </div>
    <h4>10、第三方责任</h4>
    <p>
      由于用户经由本网站上载或发布内容、与本网站连线、违反本服务条款或侵害其他人的任何权利导致任何第三人提出权利主张，用户同意赔偿及其分公司、关联公司、代理人或其他合作伙伴及员工，并使其免受损害。
    </p>
    <h4>11、服务的终止</h4>
    <p>
      直播有权在任何时候，暂时或永久地终止本网站服务（或任何一部分），无论是否通知。直播对本网站服务的终止对用户和任何第三人不承担任何责任。直播有权基于任何理由，终止用户的帐号、密码或使用本服务，或删除、转移用户存储、发布在本服务的内容，直播采取上述行为均不需通知，并且对用户和任何第三人不承担任何责任。
    </p>
    <h4>12、通知</h4>
    <p>
      向用户发出的通知将采用电子邮件或页面公告的形式。本协议条款的修改或其他事项变更，将会以上述某一形式进行通知。
    </p>
    <h4>13、法律的适用和管辖</h4>
    <p>
      本使用协议的生效、履行、解释及争议的解决均适用中华人民共和国法律，与本协议有关的争议提交由邦纬公司所在地的法院管辖。本服务条款中部分条款如因与中华人民共和国现行法律相抵触而导致无效的，不影响本服务条款其他部分的效力。
    </p>
  </div>
</template>
<style lang="less" scoped>
.box {
  width: 95%;
  margin: 0 auto;
  // padding: 100px 0;
  line-height: 35px;
  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 20px;
  }
  h4 {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    // margin: 20px 0;
  }
  h5 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    // text-indent: 1rem;
    line-height: 30px;
  }
  span {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  div {
    font-size: 14px;
    color: #666;
    // font-weight: bold;
    // margin-left: 20px;
  }
  p {
    font-size: 14px;
    color: #666;
    // font-weight: bold;
    text-indent: 2px;
  }
}
</style>
