import http from '../../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取门户导航样式
  get_navigation_style = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/get_navigation_style', data);
  };
  // 获取门户装修素材
  get_default_config_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/get_default_config_list', data);
  };
  // 添加素材
  create_material(data: unknown) {
    return http.post('/api/v2/portal/create_material', data);
  }
  // 删除素材
  delete_material(data: unknown) {
    return http.post('/api/v2/portal/delete_material', data);
  }
  // 自定义素材列表
  list_material = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/list_material', data);
  };
}

export default new Api();
