
import { defineComponent, ref, reactive, createVNode, watch } from 'vue';
import MobileHeader from '../components/MobileHeader.vue';
import { message, Input, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import http from '@/api/login';
import router, { formatRoutes } from '@/router';
import { getDisplay } from '@/plugins/cookie';
import CAwsc from '@/components/CAwsc.vue';
import { getVersionName, grtPrivacyAgree } from '@/plugins/cookie';
import CookieVue from '@/components/Cookie.vue';

interface Ilogin {
  username: number | string;
  password: string;
  code: string;
}
export default defineComponent({
  components: {
    aInput: Input,
    aInputPassword: Input.Password,
    MobileHeader,
    CAwsc,
    // CookieVue,
  },
  setup() {
    const store = useStore();
    // const defaultTopLogo = 'https://images.innocomn.com/top_logo.svg';
    const defaultTopLogo = store.state.home.black_top_logo_url;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const formData: Ilogin = reactive({
      username: '',
      password: '',
      code: '',
    });
    const dragStatus = ref(1);
    const getDragData = (data: any) => {
      aliVerification(data);
    };
    const aliVerification = (data: any) => {
      let params = {
        session_id: data.sessionId,
        sig: data.sig,
        token: data.token,
        scene: 2,
      };
      http
        .aliLoginVerification(params)
        .then(() => {
          dragStatus.value = 0;
        })
        .catch((res) => {
          dragStatus.value = 2;
          (window as any).ic.reset();
          message.error(res.data.error);
        });
    };
    // 登录
    const transmissions = ref(false);
    watch(
      () => transmissions.value,
      () => {
        return transmissions.value;
      }
    );
    const handlSubmitShark = ref<boolean>(false);
    const handlSubmit = async () => {
      if (formData.username == '') {
        message.info('手机号不能为空');
        return;
      }
      if (isShowform.value == true && formData.password == '') {
        message.info('密码不能为空');
        return;
      }
      if (isShowform.value == false && formData.code == '') {
        message.info('验证码不能为空');
        return;
      }
      if (dragStatus.value) {
        message.info('请先滑动验证！');
        return;
      }
      if (handlSubmitShark.value) {
        return;
      }
      // if (!grtPrivacyAgree()) {
      //   message.info('请先同意Cookie隐私协议');
      //   Modal.confirm({
      //     title: '提示',
      //     icon: createVNode(ExclamationCircleOutlined),
      //     content: '为了保障用户操作流程正常运行,需同意本网站存储Cookie操作',
      //     centered: true,
      //     okText: '确认',
      //     cancelText: '取消',
      //     onOk() {
      //       transmissions.value = true;
      //     },
      //   });
      //   return;
      // }
      let enroll = {
        mobile: formData.username,
        password: formData.password,
        type: 1,
      };
      let valid = {
        mobile: formData.username,
        type: 0,
        code: formData.code,
      };
      http
        .getLogin(isShowform.value ? enroll : valid)
        .then(async (res) => {
          handlSubmitShark.value = false;
          const resData = res.data.data;
          store.commit('user/loginSaveUserInfo', resData);
          await formatRoutes();
          router.addRoute({
            path: '/:catchAll(.*)',
            redirect: '/404',
          });
          store.dispatch('home/getDefaultCustomized').finally(() => {
            message.success(res.data.message);
            if (getVersionName()) {
              window.location.host.includes('test')
                ? (window.location.href = `http://test-login.lighos.com/${getVersionName()}?lighostoken=${resData.token}`)
                : (window.location.href = `https://login.qingtv.com/${getVersionName()}?lighostoken=${resData.token}`);
            } else {
              router.push({ name: 'Layout' });
            }
          });
        })
        .catch((err) => {
          message.error(err.data.error);
          handlSubmitShark.value = false;
        });
    };
    const isShowform = ref(true);
    const formItemLayout = reactive({
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    });
    const buttonItemLayout = reactive({
      wrapperCol: {
        span: 14,
        offset: 4,
      },
    });
    // 倒计时
    const isShowcode = ref(true);
    const timer: any = ref(0);
    const TIME_COUNT = 60;
    const count = ref(TIME_COUNT);
    const getCode = () => {
      // 正则表达式
      var reg = /^1[0-9]{10}$/;
      if (formData.username != '') {
        if (!reg.test(formData.username as string)) {
          message.info('您输入的手机号不合法，请重新输入');
          return false;
        }
        if (!timer.value) {
          count.value = TIME_COUNT;
          isShowcode.value = false;
          timer.value = setInterval(() => {
            if (count.value > 0 && count.value <= TIME_COUNT) {
              count.value--;
            } else {
              isShowcode.value = true;
              clearInterval(timer.value);
              timer.value = 0;
            }
          }, 1000);
        }
      } else {
        message.info('请输入手机号');
      }
      let send = {
        type: -1,
        mobile: formData.username,
      };
      http.getSendCaptcha(send);
    };
    const Rem = () => {
      let docEl = document.documentElement,
        oSize = docEl.clientWidth / 7.5;
      if (oSize > 100) {
        oSize = 100; //  限制rem值   640 / 6.4 =100
      }
      docEl.style.fontSize = oSize + 'px';
    };
    window.addEventListener('resize', Rem, false);
    Rem();
    //用户访问记录
    const newTime = Date.parse(new Date().toString()) / 1000;
    const adminUserVisit = () => {
      http.adminUserVisit({ params: { entry_time: newTime } }).then(() => {
        sessionStorage.setItem('visit', 'mobile_login');
      });
    };
    const user_visit = sessionStorage.getItem('visit');
    if (user_visit == null) {
      adminUserVisit();
      window.onbeforeunload = () => {
        let data = { entry_time: newTime, exit_page: 1 };
        http.adminUserVisit({ params: data });
      };
    }
    return {
      isShowform,
      formItemLayout,
      buttonItemLayout,
      formData,
      handlSubmit,
      getCode,
      isShowcode,
      count,
      Rem,
      defaultTopLogo,
      getDisplay,
      getDragData,
      transmissions,
    };
  },
});
