import live from './live';
import home from './home';
import media from './media';
import liveMode from './control/liveMode';
import liveChat from './control/liveChat';
import recordBroad from './control/recordBroad';
import liveInformation from './control/liveInformation';
import liveExpress from './control/liveExpress';
import lottery from './control/lottery';
import login from './login';
import redpacket from './control/redpacket';
import pagedirect from './pagedirect';
import user from './user';
import liveDirect from './live/direct';
import liveInteractive from './live/Interactive';
import liveeventreservation from './live/eventreservation';
import liveBullet from './live/bullet';
import livebroadcast from './live/livebroadcast';
import liveAuthorizView from './live/live';
import livePlayback from './live/playback';
import code from './live/code';
import likesetting from './live/likesetting';
import sensitive from './sensitive';
import liveAnswer from './live/liveAnswer';
import whiteList from './live/whiteList';
import videoDot from './videoDot';
import dotCensus from './live/dotCensus';
import application from './application';
// import articalCategory from './articalCategory';
const api = {
  live,
  home,
  media,
  liveMode,
  liveChat,
  liveInformation,
  liveExpress,
  lottery,
  redpacket,
  login,
  pagedirect,
  user,
  liveDirect,
  liveInteractive,
  liveeventreservation,
  liveBullet,
  livebroadcast,
  liveAuthorizView,
  livePlayback,
  code,
  likesetting,
  sensitive,
  liveAnswer,
  whiteList,
  videoDot,
  dotCensus,
  application,
  recordBroad,
};
export default api;
