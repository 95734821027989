import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77d8e5eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isExternal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.styleExternalIcon),
        class: "svg-external-icon svg-icon"
      }, null, 4))
    : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        class: _normalizeClass(_ctx.svgClass),
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", { "xlink:href": _ctx.iconName }, null, 8, _hoisted_1)
      ], 2))
}