import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// 导出pdf
const options = {
  scale: 2, // 设置缩放比例，提高生成图片清晰度
  useCORS: true, // 允许加载跨域的图片
  //   allowTaint: false, // 允许图片跨域，和 useCORS 二者不可共同使用
  //   tainttest: true, // 检测每张图片已经加载完成
  logging: false, // 打印日志用的 可以不加默认为false
  //  // dpi: 96, // 分辨率
  //  // bgcolor: '#ffffff', // 应该这样写
};

export function exportPDF(ele: HTMLElement | null, title: string): void {
  if (ele) {
    html2canvas(ele as HTMLElement, options)
      .then((canvas) => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (contentWidth / 592.28) * 841.89;
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // 页面偏移
        let position = 0;
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 595.28;
        const imgHeight = (595.28 / contentWidth) * contentHeight;
        const ctx: any = canvas.getContext('2d');
        // 添加水印
        //   ctx.textAlign = 'center';
        //   ctx.textBaseline = 'middle';
        //   ctx.rotate((25 * Math.PI) / 180);
        //   ctx.font = '20px Microsoft Yahei';
        //   ctx.fillStyle = 'rgba(184, 184, 184, 0.8)';
        //   for (let i = contentWidth * -1; i < contentWidth; i += 240) {
        //     for (let j = contentHeight * -1; j < contentHeight; j += 100) {
        //       // 填充文字，x 间距, y 间距
        //       ctx.fillText('水印', i, j);
        //     }
        //   }
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          // 在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          // 分页
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        // 可动态生成
        pdf.save(`${title}.pdf`);
      })
      .catch((error) => {
        console.log('打印失败', error);
      });
  }
}
