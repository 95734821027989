import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from './plugins/ant';
import SvgIcon from './plugins/svgIcon';
import './style/index.less';
import 'normalize.css/normalize.css';
import 'element-plus/dist/index.css';
import { Swipe, SwipeItem } from 'vant';
import { createPinia } from 'pinia';

const app = createApp(App);
app.config.globalProperties.$exportUrl = '/api/v1/common/excel_regional_distribution'; // 导出地址
app.config.globalProperties.$exportRecord = '/api/v1/live/robot_list'; //机器人使用记录导出
app.config.globalProperties.$exportUrlTwo = '/api/v1/live/we_chat_reserve_user'; // 活动预约 微信预约导出地址
app.config.globalProperties.$exportHis = '/api/v1/live/live_pull_stream_list'; // 拉流直播历史记录  导出地址
app.config.globalProperties.$exportCustomMenu = '/api/v1/live/get_menu'; //获取自定义菜单信息 and 导出功能 {export = 3} | 导出功能
app.config.globalProperties.$exportWhiteList = '/api/v2/live/whitelist_export'; // 授权观看手机白名单导出名单
app.config.globalProperties.$exportDotCount = '/api/v1/common/export_dotting_data'; // 打点统计导出接口
app.config.globalProperties.$exportNewDataCenter = '/api/v1/common/get_access_running_water_by_time'; //新数据中心导出
app.config.globalProperties.$exportNewDataShare = '/api/v1/common/get_access_list_data_by_time'; //新数据中心分享榜贡献榜导出

app.config.globalProperties.$httpName = process.env.VUE_APP_HTTP_NAME_LINK ? process.env.VUE_APP_HTTP_NAME_LINK : '';
app.config.globalProperties.$virtualLink = process.env.VUE_APP_VIRTUAL_LINK;
// app.config.globalProperties.$httpName = 'https://test-login.qingtv.com';
// app.config.globalProperties.$httpName = 'https://login.qingtv.com'; // 正式测试域名
// 'https://test-login.qingtv.com'; // 旧版本线上域名
app.use(store);
app.use(router);
app.use(Antd);
app.use(SvgIcon);
app.use(createPinia());
app.mount('#app');

app.use(Swipe);
app.use(SwipeItem);
