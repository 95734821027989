
import { defineComponent, ref, computed } from 'vue';
import { Button, Modal, Checkbox, Switch, message, Form } from 'ant-design-vue';
import { useLiveStore } from '@/pinia/live';
import Crumb from '@/components/Crumb.vue';
import http from '@/api/livebroadcast';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons-vue';
import router from '@/router';
import ClipboardJS from 'clipboard';
import CDelModal from '@/components/CDelModal.vue';
import CLoading from '@/components/CLoading.vue';
export default defineComponent({
  components: {
    Crumb,
    PlusOutlined,
    aButton: Button,
    aModal: Modal,
    aCheckbox: Checkbox,
    CloseOutlined,
    aSwitch: Switch,
    aFormItem: Form.Item,
    CLoading,
    CDelModal,
  },
  setup() {
    const showLoading = ref<boolean>(false);
    const liveInfo = router.currentRoute.value.params.studioId;
    // 弹框
    const confirm = ref<boolean>(false);

    const disabled = ref(false);

    // 开关
    const DownClose = () => {
      let arr = JSON.stringify(accountList.value);
      let arr2 = JSON.parse(arr);
      var re = new RegExp('"', 'g');
      var fxg = new RegExp('\\\\', 'g');
      for (let item = 0; item < arr2.length; item++) {
        arr2[item].account = arr2[item].account.replace(fxg, '\\\\');
        arr2[item].account = arr2[item].account.replace(re, '\\"');
      }
      let edit = {
        helper: JSON.stringify(arr2),
        status: obj.value.status,
        studio_id: liveInfo,
      };
      http
        .getEditLiveHelper(edit)
        .then((res) => {
          if (res.data.code == 200) {
            const resData = res.data;
            message.destroy();
            message.success(resData.message);
            viewLiveHelper();
          }
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };
    // 关闭
    const splicindex = ref();
    const deletes = ref<boolean>(false);
    const closeClick = (id: any) => {
      splicindex.value = id;
      deletes.value = true;
    };
    const openDelModal = () => {
      deletes.value = false;
    };
    const close = () => {
      let helper = {
        studio_id: liveInfo,
        ids: splicindex.value,
      };
      http.getDeleteLiveHelper(helper).then((res) => {
        const resData = res.data;
        message.success(resData.message);
        viewLiveHelper();
      });
      accountList.value.splice(splicindex.value, 1);
      deletes.value = false;
    };
    // 弹框保存
    const keep = () => {
      confirm.value = false;
      let addlive = {
        studio_id: liveInfo,
        account: prompt.value.realm,
        password: prompt.value.passwords,
        chat: prompt.value.checks ? 1 : 0,
        set: prompt.value.direct ? 1 : 0,
        control: prompt.value.lived ? 1 : 0,
        data: prompt.value.Data ? 1 : 0,
      };
      http
        .getAddLiveHelper(addlive)
        .then(() => {
          DownClose();
        })
        //错误提示
        .catch((res) => {
          message.error(res.data.error);
        });

      let reg = /^[0-9A-Za-z]{6,}$/;
      let pass = reg.test(prompt.value.passwords);
      if (!pass) {
        confirm.value = true;
        message.info('密码不能少于6位字符！');
        return false;
      }

      // 清空状态
      prompt.value.realm = prompt.value.passwords = '';
      prompt.value.checks = prompt.value.direct = prompt.value.lived = prompt.value.Data = false;
    };

    const getAuthority = (e: any) => {
      // console.log(e);
    };
    const showModal = () => {
      confirm.value = true;
    };
    let obj = ref({
      status: 0,
      login_url: '',
    });
    let prompt = ref({
      realm: '',
      passwords: '',
      checks: false,
      direct: false,
      lived: false,
      Data: false,
    });

    interface AccountObj {
      account: string;
      reqAccount: string;
      alias: string;
      chat: number;
      control: number;
      data: number;
      id: number;
      password: string;
      reqPassword: string;
      set: number;
      studio_id: number;
    }
    const accountList = ref<AccountObj[]>([
      // {
      //   id: 0,
      //   password: '',
      //   account: '',
      //   chat: false,
      //   set: false,
      //   control: false,
      //   data: false,
      //   authority: [{ chat: false }, { set: false }, { control: false }, { data: false }],
      // },
    ]);
    const loginUrl = ref('');
    // 直播助手详情
    const viewLiveHelper = () => {
      disabled.value = true;
      let views = {
        studio_id: liveInfo,
      };
      showLoading.value = true;
      http
        .getViewLiveHelper({ params: views })
        .then((res) => {
          res.data.data.helper.map((res: any) => {
            res.chat = res.chat == 0 ? false : true;
            res.control = res.control == 0 ? false : true;
            res.data = res.data == 0 ? false : true;
            res.set = res.set == 0 ? false : true;
            res.reqAccount = res.account ?? '';
            res.reqPassword = res.password ?? '';
          });

          accountList.value = [...res.data.data.helper];
          loginUrl.value = res.data.data.login_url;
          obj.value = res.data.data;
          showLoading.value = false;
          disabled.value = false;
        })
        .catch((err) => {
          message.error(err.data.error);
          showLoading.value = false;
          disabled.value = false;
        });
    };
    viewLiveHelper();
    // 一键复制
    const liveStore = useLiveStore();
    const liveData = computed(() => liveStore.liveInfo);
    const Copylist = (btns: string) => {
      let ClipboardJSObj = new ClipboardJS(btns);
      ClipboardJSObj.on('success', function (e) {
        message.destroy();
        message.info('复制成功');
        e.clearSelection();
      });
      ClipboardJSObj.on('error', function () {
        message.destroy();
        message.info('复制失败');
      });
    };
    return {
      showLoading,
      keep,
      showModal,
      confirm,
      obj,
      prompt,
      accountList,
      close,
      getAuthority,
      DownClose, //关闭
      // assist,   //保存
      loginUrl,
      Copylist, //复制
      liveData,
      deletes,
      openDelModal,
      closeClick,
      disabled,
    };
  },
});
