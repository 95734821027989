import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
// 视频打点
class Api {
  //添加视频打点
  addVideoDotting(data: unknown) {
    return http.post('/api/v1/common/add_video_dotting', data);
  }
  //设置视频打点配置
  setDottingConfig(data: unknown) {
    return http.post('/api/v1/common/set_dotting_config', data);
  }
  //获取视频打点配置信息
  getDottingConfigInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_dotting_config_info', data);
  }
  //获取单个视频的打点列表
  getDottingList(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_dotting_list', data);
  }
  // 清空打点视频
  clearDottingList(data: AxiosRequestConfig) {
    return http.delete('/api/v1/common/clear_dotting_list', data);
  }
  // 删除单个打点
  delDottingList(data: AxiosRequestConfig) {
    return http.delete('/api/v1/common/del_dotting_info', data);
  }
  // 获取打点信息
  getDottingInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_dotting_info', data);
  }
  //编辑视频打点
  updataVideoDot(data: unknown) {
    return http.post('/api/v1/common/edit_video_dotting', data);
  }
  //是否跳过打点内容
  jump_over_dotting(data: unknown) {
    return http.post('/api/v1/common/jump_over_dotting', data);
  }
}
export default new Api();
