import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  /**
   *
   *
   * 红包雨
   *
   *
   * */

  // 添加红包雨
  addRedPackRainInfo = (data: unknown) => {
    return http.post('/api/v1/live/add_live_studio_red_pack_rain_info', data);
  };

  // 修改红包雨
  updRedPackRainInfo = (data: unknown) => {
    return http.post('/api/v1/live/upd_live_studio_red_pack_rain_info', data);
  };

  // 获取红包详情
  getRedPackedRainInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_red_packed_rain_info', data);
  };

  // 红包雨列表
  getRedPackRainList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_red_pack_rain_list', data);
  };

  // 红包雨弹出
  popUpImmediatelyRedPackedInfo = (data: unknown) => {
    return http.post('/api/v1/live/pop_up_immediately_red_packed_info', data);
  };

  // 红包雨删除
  delRedPackRain = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/live/del_live_studio_red_pack_rain', data);
  };

  // 现金红包雨领取详情
  cashClaimDetails = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_red_packed_rain_cash_snatch_list', data);
  };

  // 礼包红包雨领取详情
  prizeClaimDetails = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_red_packed_rain_goods_snatch_list', data);
  };

  // 红包雨账单记录
  getAmountLog = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_amount_log', data);
  };

  // 获取余额
  getRemainingAmount = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_remaining_amount', data);
  };

  // 充值
  orderRecharge = (data: unknown) => {
    return http.post('/api/v1/live/red_packed_rain_order_recharge', data);
  };

  // 退款
  refundRainAmount = (data: unknown) => {
    return http.post('/api/v1/live/refund_live_studio_rain_amount', data);
  };

  // 微信支付模式
  wechat_pay_mode(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/wechat_pay_mode', data);
  }

  // 微信扫码支付 旧接口
  weChatScanPay = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/wechat/we_chat_scan_pay', data);
  };
  //  微信扫码支付 新接口
  newWeChatScanPay(data: unknown) {
    return http.post('/api/v1/wechat/partner_scan', data);
  }

  // 轮询支付状态
  pollingOrder = (data: unknown) => {
    return http.post('/api/v1/live/red_pac_polling_order', data);
  };

  // 回退余额至剩余余额
  returnBalance = (data: unknown) => {
    return http.post('/api/v1/live/set_live_studio_amount_return_balance', data);
  };

  // 国外用户红包现金退款
  refundUerPackedAmount = (data: unknown) => {
    return http.post('/api/v1/live/refund_user_packed_amount', data);
  };

  /**
   *
   *
   * 竞答红包
   *
   *
   * */

  // 竞答红包题库列表
  contestList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_list', data);
  };

  // 删除竞答红包题库列表
  delContest = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/live/del_live_studio_contest', data);
  };

  // 获取竞答红包记录
  contestRdpackList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_redpack_list', data);
  };

  // 添加竞答红包题库信息
  addContestQuestion = (data: unknown) => {
    return http.post('/api/v1/live/add_live_studio_contest_question', data);
  };

  // 获取竞答红包题库信息
  getContestQuestion = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_question', data);
  };

  // 获取题库使用记录
  getContestUseInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_use_info', data);
  };

  // 获取竞答红包领取记录
  getContestReceivedList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_received_list', data);
  };

  // 查看答题结果
  getContestAnswerRecord = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_contest_answer_record', data);
  };
  // 红包雨配置前端动态信息
  set_red_packed_config(data: unknown) {
    return http.post('/api/v1/live/set_red_packed_config', data);
  }
  // 获取红包雨动态配置信息
  get_red_packed_config_info(data: AxiosRequestConfig) {
    return http.get('/api/v1/user/get_red_packed_config_info', data);
  }
}

export default new Api();
