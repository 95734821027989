
import CDelModal from '@/components/CDelModal.vue';
import moment from 'moment';
import http from '@/api/report';
import { RangePickerIcon } from '@/assets';
import { DatePicker, Pagination, Table, message, Modal, Radio, Input, Select, Checkbox, notification } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import { defineComponent, reactive, ref, toRefs, unref, computed } from 'vue';
// import { useStore } from 'vuex';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { ResourceBill } from './details';
import router from '@/router';
import { getToken } from '@/plugins/cookie';
import { ElCheckboxGroup, ElCheckbox } from 'element-plus';
import https from '@/api/exportList';
import { useDebounce } from '@/hooks/useDebounce'; //防抖

type Key = ColumnProps['key'];
// interface DataType {
//   key: Key;
//   name: string;
//   value: string;
// }
export default defineComponent({
  components: {
    CDelModal,
    [RangePicker.name]: RangePicker,
    aPagination: Pagination,
    [Table.name]: Table,
    aModal: Modal,
    aRadioGroup: Radio.Group,
    aTextarea: Input.TextArea,
    // aCheckboxGroup: Checkbox.Group,
    // aCheckbox: Checkbox,
    aSelect: Select,
    ElCheckbox,
    ElCheckboxGroup,
  },
  setup() {
    const showLoading = ref<boolean>(false);
    const mediaRef = ref();
    // const store = useStore();
    const token = getToken();
    const studioId = router.currentRoute.value.params.studioId;
    const questId = router.currentRoute.value.query.id;
    const onChange = () => {
      questItemsInfo();
    };
    // 表头数据
    interface Taghead {
      title: string;
      dataIndex: string;
      width: string;
      align: string;
      slots: {
        customRender: string;
      };
    }
    const columns = ref<Taghead[]>([]);
    // 表格数据
    interface Tagdata {
      title: string;
      avatar_url: string;
      uid: string;
      content: string;
      addr: string;
      answer_time: string;
      referrer: string;
      device_type_name: string;
    }
    const dataest = ref<Tagdata[]>([]);
    // 表格选择事件
    const state = reactive<{
      selectedRowKeys: Key[];
    }>({
      selectedRowKeys: [],
    });
    // 分页器
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 12, // 每页条数
      totalSize: 1, // 总页数
      options: ['12', '24', '36', '48', '60'], // 选项
      size: 'small', // 尺寸
      showJumper: false, // 是否显示跳页
      showSize: false, // 是否显示条数切换
    });
    // 地理位置
    interface addr {
      name: string;
      value: string;
    }
    const optioner = ref<addr[]>([]);

    let obj = ref({
      geoLocation: null,
      answer: '',
      topic: '',
      nickname: '',
      id: '',
    });
    const queryForms = ref<Date[]>([]);
    const questItemsInfo = () => {
      let questItem: any = {
        studio_id: studioId,
        quest_id: questId,
        addr: obj.value.geoLocation,
        page: pageConfig.current,
        page_num: pageConfig.pageSize,
        answer_time: obj.value.answer,
        quest_item_title: obj.value.topic,
        user_key: obj.value.nickname,
      };
      showLoading.value = true;
      const postQuest = new ResourceBill(questItem);
      postQuest.setDates(queryForms.value as any);
      http
        .getQuestItemsInfo({ params: postQuest })
        .then((res) => {
          showLoading.value = false;
          pageConfig.totalSize = res.data.data.record_list.total;
          optioner.value = [];
          dataest.value = [];
          columns.value = [];
          res.data.data.addr_list.forEach((item: any) => {
            optioner.value.push({
              name: item,
              value: item,
            });
          });
          res.data.data.header.forEach((item: any) => {
            columns.value.push({
              title: item.name,
              dataIndex: item.value,
              width: '170px',
              align: 'center',
              slots: {
                customRender: item.value,
              },
            });
          });
          columns.value.push({
            title: '操作',
            dataIndex: 'remove',
            width: '100px',
            align: 'center',
            slots: {
              customRender: 'remove',
            },
          });
          columns.value[columns.value.length - 4].width = '350px';
          columns.value[0]['fixed'] = 'left';
          columns.value[columns.value.length - 1]['fixed'] = 'right';
          dataest.value = res.data.data.record_list.data;
        })
        .catch(() => {
          message.destroy();
          showLoading.value = false;
        });
      obj.value.answer = obj.value.nickname = '';
      obj.value.topic = '';
      obj.value.geoLocation = null;
    };
    questItemsInfo();

    // 分页器点击事件
    const showSize = () => {
      questItemsInfo();
    };
    const trigger = () => {
      questItemsInfo();
    };

    // 移除一行
    const removeRow = (item: any) => {
      obj.value.id = item.id;
      deletes.value = true;
    };

    // 删除
    const deletes = ref(false);
    const openDelModal = () => {
      deletes.value = !deletes.value;
    };
    const deleteLive = () => {
      if (selectedRowKeys.value.length == 0) {
        message.info('你还没有选择要删除的内容！');
      } else {
        deletes.value = true;
      }
    };

    // 确认删除
    const dels = ref({});
    const questInfoDel = () => {
      deletes.value = false;
      let questDel = {
        studio_id: studioId,
        id: obj.value.id,
      };
      http.getquestInfoDel(questDel).then((res) => {
        if (res.data.code == 200) {
          message.success(res.data.message);
          pageConfig.current = 1;
          questItemsInfo();
        }
        dels.value = res.data.data.record_list.data;
      });
    };
    const selectedRowKeys = ref<Key[]>([]);
    selectedRowKeys.value = [];
    const onSelectChange = (changableRowKeys: Key[]) => {
      obj.value.id = changableRowKeys.join(',');
      selectedRowKeys.value = changableRowKeys;
    };
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
      };
    });
    // 导出数据
    const corn = ref(1);
    const messageTip = ref();
    const Export = useDebounce((status: number) => {
      const data = {
        studio_id: studioId,
        export: status,
        is_cron_download: corn.value,
        quest_id: questId,
      };
      https
        .quest_items_info({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };
    const editVisible = ref<boolean>(false);
    const recordId = ref<number>();
    const quesdatas = ref<any>();
    const quesdatasValue = ref<any>();
    const gradetype = ref({
      '1': '满意',
      '2': '认同',
      '3': '重要',
      '4': '愿意',
      '5': '符合',
    });

    const reviseContent = (row: any, item: any) => {
      recordId.value = row.id;
      questItemInfo(row.id, item.quest_item_id);
    };
    const questItemInfo = (quest_id: any, quest_item_id: any) => {
      let params = {
        studio_id: studioId,
        quest_id: questId,
        quest_item_id: quest_item_id,
      };
      http.questItemInfo({ params: params }).then((res) => {
        if (res.data.data.type == 6) {
          Modal.confirm({
            title: () => '附件题不支持编辑。',
            okText: () => '确定',
            centered: true,
          });
        } else {
          Modal.confirm({
            title: () => '编辑提示',
            content: () => '内容修改后将覆盖原始答案，且该操作不可撤销，确定继续编辑吗？',
            okText: () => '继续编辑',
            cancelText: () => '放弃',
            centered: true,
            onOk() {
              editVisible.value = true;
              quesdatas.value = res.data.data;
              quesdatas.value.type == 1 ? (quesdatasValue.value = []) : (quesdatasValue.value = '');
            },
          });
        }
      });
    };

    // 填空题
    const fills = (title: any) => {
      return title.replace(/_+/g, '<span class="mod_fillblank" contenteditable="true" ></span>');
    };
    const fillschange = (e: any) => {
      quesdatasValue.value = e.currentTarget.innerText;
    };

    const questItemsAnswer = () => {
      const params = {
        record_id: recordId.value,
        studio_id: studioId,
        item_id: quesdatas.value.id,
        quest_item_answer: JSON.stringify(quesdatasValue.value),
      };
      http
        .questItemsAnswer(params)
        .then((res) => {
          if (res.data.code == 200) {
            message.success(res.data.message);
            editVisible.value = false;
            questItemsInfo();
          }
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };

    return {
      moment,
      RangePickerIcon,
      showLoading,
      onChange,
      optioner, //选择假数据
      columns,
      dataest,
      pageConfig,
      ...toRefs(state),
      showSize,
      trigger,
      obj,
      queryForms,
      deleteLive,
      removeRow,
      deletes,
      dels,
      questInfoDel,
      mediaRef,
      Export,
      onSelectChange,
      rowSelection,
      openDelModal,
      editVisible,
      reviseContent,
      quesdatas,
      quesdatasValue,
      gradetype,
      fills,
      fillschange,
      questItemsAnswer,
    };
  },
});
