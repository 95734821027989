
import { defineComponent, onMounted, onUnmounted, reactive, ref } from 'vue';
import { DatePicker, Input, Pagination, Upload, Modal, message, Row, Col, Alert, Statistic } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import { CheckOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import { Edit, Clip, Embed, DownLoad } from '@/assets';
import { useStore } from 'vuex';
import type { RootLive } from '@/store/modules/live/types';
import http from '@/api/media';
import { useDebounce } from '@/hooks/useDebounce';
import 'video.js/dist/video-js.css';
import { emptyDataSec } from '@/assets';
import CDelModal from '@/components/CDelModal.vue';
import CLoading from '@/components/CLoading.vue';

interface LiveSearch {
  keywords: string;
  status?: number;
  liveDate: any;
}
export default defineComponent({
  components: {
    CDelModal,
    [RangePicker.name]: RangePicker,
    [Input.Search.name]: Input.Search,
    [Upload.name]: Upload,
    [Pagination.name]: Pagination,
    CheckOutlined,
    [Modal.name]: Modal,
    [Row.name]: Row,
    [Col.name]: Col,
    [Alert.name]: Alert,
    CLoading,
    aStatisticCountdown: Statistic.Countdown,
  },
  setup() {
    const mediaRef = ref();
    let live: any = ref();
    // 分页器
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 12, // 每页条数
      totalSize: 0, // 总页数
      options: ['12', '24', '36', '48', '60'], // 选项
      size: 'small', // 尺寸
      showJumper: false, // 是否显示跳页
      showSize: false, // 是否显示条数切换
    });
    const showLoading = ref<boolean>(false);
    const span = ref(4);
    // 计算直播间列表项宽度
    let getWidth = () => {
      let width = document.documentElement.clientWidth;
      width > 2600 ? (span.value = 5) : width > 2200 ? (span.value = 6) : (span.value = 8);
    };
    getWidth();

    // 获取数据列表
    const store = useStore();
    const queryForm = reactive<LiveSearch>({
      keywords: '',
      liveDate: [],
    });
    const liveData: any = ref([]);

    const getVideoList = useDebounce(async () => {
      let liveDate;
      if (queryForm.liveDate[0]) {
        liveDate = [
          moment(queryForm.liveDate[0]).format('YYYY-MM-DD HH:mm'),
          moment(queryForm.liveDate[1]).format('YYYY-MM-DD HH:mm'),
        ];
      }
      const { current, pageSize } = pageConfig;
      const search = {
        keywords: queryForm.keywords,
        liveDate: liveDate,
        page: current,
        page_num: pageSize,
      };

      showLoading.value = true;
      const { current_page, total, data, error } = await store.dispatch('media/getRecordVideoList', search);
      choice.value = false;
      choice_all.value = false;
      data ? (showLoading.value = false) : '';
      if (error) {
        showLoading.value = false;
        message.error(error);
      }

      live.value = [];
      liveData.value = [];
      live.value = [...data];
      live.value.map((res: RootLive) => {
        liveData.value.push({
          id: res.id,
          choice: false,
        });
      });
      live.value.forEach((item: any) => {
        let num: any = new Date(item.updated_at.replace(/-/g, '/')).getTime();
        item['count_down'] = num + 14 * 24 * 60 * 60 * 1000;
      });
      pageConfig.current = current_page;
      pageConfig.totalSize = total;
      visible.value = false;
    });

    // 直播列表点击事件
    let liveId = ref();
    let choice = ref(false);
    let choice_all = ref(false);
    let deleteSelect = ref(false);
    // 选择、全选
    const getOperation = (id: number) => {
      liveId.value = null;
      if (id == 0) {
        liveData.value.map((res: any) => {
          res.choice = false;
        });
        choice_all.value = false;
      } else {
        choice.value = false;
        if (liveData.value.length !== 0) {
          liveData.value.map((res: any) => {
            res.choice = choice_all.value;
          });
          deleteSelect.value = choice_all.value;
        }
      }
    };
    // 点击
    const getLive = (index: number) => {
      if (choice.value || choice_all.value) {
        liveData.value[index].choice = !liveData.value[index].choice;
        let choice = liveData.value.find((res: any) => {
          return res.choice;
        });
        if (choice) {
          deleteSelect.value = true;
        } else {
          deleteSelect.value = false;
        }
      }
    };
    // 删除
    const visible = ref(false);
    const openDelModal = () => {
      visible.value = !visible.value;
    };
    const deleteLive = () => {
      visible.value = deleteSelect.value ? !visible.value : visible.value;
    };
    const getId = () => {
      let id: any = ref([]);
      liveData.value.map((res: any) => {
        if (res.choice) {
          id.value.push(res.id);
        }
      });
      let data = {
        id: id.value.toString(),
      };
      return data;
    };
    // 确定删除
    const removeLiveData = () => {
      let data = getId();
      http.delRecordVideo({ params: data }).then(() => {
        getVideoList();
        deleteSelect.value = false;
        message.success('删除成功！');
      });
    };
    // 移至视频库
    const moveVideo = () => {
      if (deleteSelect.value) {
        let data = getId();
        http
          .moveRecordVideo(data)
          .then((res) => {
            getVideoList();
            deleteSelect.value = false;
            message.success(res.data.message);
          })
          .catch((res) => {
            message.error(res.data.error);
          });
      }
    };

    // 监听窗口变化
    onMounted(() => {
      window.addEventListener('resize', () => {
        getWidth();
      });
    });
    // 销毁监听
    onUnmounted(() => {
      window.removeEventListener('resize', () => {
        getWidth();
      });
    });
    //视频显示窗
    const modal2Visible = ref<boolean>(false);
    //实例化播放器
    const playStatus = ref<boolean>(false);
    // const mediaPlay = ref();
    const videoRef = ref();
    const videoPlayer = ref();
    const Mplayer = (play_data: any) => {
      videoPlayer.value = new window.videojs(videoRef.value, {
        // poster: poster,//海报
        playbackRates: [0.5, 0.75, 1, 1.5, 2], //播放速度
        language: 'zh-CN',
        notSupportedMessage: '此视频暂时无法播放,请稍后再试',
        controls: true,
        techOrder: ['html5'], //html5模式
        fluid: true, //流式布局,适应父元素大小
        aspectRatio: '16:9', //流式布局时，自适应大小的比例
        preload: true, //预加载
        autoplay: true, //是否自动播放
        loop: true,
      });
      videoPlayer.value.src({
        src: play_data.video_url,
      });
    };
    //点击蒙层销毁播放器
    const modal2cancel = () => {
      playStatus.value = false;
      videoPlayer.value.dispose();
      modal2Visible.value = false;
    };
    //点击图片获取视频地址
    let play_data = {
      video_url: '',
    };
    const videoPlay = (item: any) => {
      playStatus.value = true;
      play_data.video_url = item.video_url;
      modal2Visible.value = true;
      setTimeout(() => {
        Mplayer(play_data);
      });
    };
    getVideoList();
    return {
      mediaRef,
      showLoading,
      live,
      getOperation,
      getLive,
      choice,
      choice_all,
      Edit,
      Clip,
      Embed,
      DownLoad,
      deleteLive,
      deleteSelect,
      pageConfig,
      getVideoList,
      queryForm,
      visible,
      liveData,
      removeLiveData,
      moveVideo,
      videoPlay,
      modal2Visible,
      modal2cancel,
      span,
      emptyDataSec,
      openDelModal,
      videoRef,
      playStatus,
      moment,
    };
  },
});
