import axios from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  // 获取评论类型列表
  get_comment_type_list() {
    return axios.get('/api/v1/console/get_comment_type_list');
  }
  //获取身份库类型列表
  get_robot_identity_list() {
    return axios.get('/api/v1/console/get_robot_identity_library_list');
  }
  // 添加评论类型
  add_comment_type(data: unknown) {
    return axios.post('/api/v1/console/add_comment_type', data);
  }
  // 修改评论类型
  edit_comment_type(data: unknown) {
    return axios.post('/api/v1/console/edit_comment_type', data);
  }
  //获取评论内容列表
  get_comment_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_comment_list', data);
  }
  // 获取身份库论内容列表
  get_comment_robot_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_comment_robot_list', data);
  }
  // 添加评论内容
  add_comment_content(data: unknown) {
    return axios.post('/api/v1/console/add_comment_content', data);
  }
  // 修改评论内容
  edit_comment_content(data: unknown) {
    return axios.post('/api/v1/console/edit_comment_content', data);
  }
  // 身份库添加类别
  add_robot_identity(data: unknown) {
    return axios.post('/api/v1/console/add_robot_identity_library', data);
  }
  // 修改身份库
  edit_robot_identity_library(data: unknown) {
    return axios.post('/api/v1/console/edit_robot_identity_library', data);
  }
  // 添加身份库内容
  add_user_comment_robot(data: unknown) {
    return axios.post('/api/v1/console/add_user_comment_robot', data);
  }
  // 修改身份库内容
  edit_user_review_robot(data: unknown) {
    return axios.post('/api/v1/console/edit_user_review_robot', data);
  }
  // 删除评论类型
  del_comment_type(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_comment_type', data);
  }
  // 删除评论内容
  del_comment_content(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_comment_content', data);
  }
  // 删除身份库
  del_robot_identity_library(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_robot_identity_library', data);
  }
  // 删除机器人
  del_comment_robot(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_comment_robot', data);
  }
}

export default new Api();
