import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
// 互动打赏api
class Api {
  // 获取直播间打赏配置
  getStudioReward(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_studio_reward', data);
  }
  // 添加OR修改直播间打赏礼物配置
  getUpdateOrInsertReward(data: unknown) {
    return http.post('/api/v1/live/update_or_insert_reward', data);
  }
  // 获取直播间打赏记录
  getStudioRewardList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_studio_reward_list', data);
  }
}
export default new Api();
