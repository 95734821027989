import { BaseSearchClass } from '@/types/base';
import { Moment } from 'moment';

interface Stream {
  id: number;
  image_player_cover_id: number;
  image_player_cover_url: string;
  live_url_status: number;
  title: string;
}
interface Language {
  id: number;
  image_player_cover_id: number;
  language_image_url: string;
  live_url_status: number;
  title_sub: string;
}

/**
 * @description 直播列表对象
 *
 * @export
 * @interface RootLive
 * @id 直播间ID
 * @file_class 直播分类
 * @mode 直播模式 0 视频 1 文档 2 音频 3互动 4多频 5多语种 6竖屏直播
 *
 */
export interface RootLive {
  id: number;
  contain_stream_ids: string;
  created_at: string;
  image_player_cover_url: string;
  live_url_status: number;
  mode: number;
  number: number;
  push_stream_duration: number;
  qr_code_url: string;
  start_at: string;
  status: number;
  studio_status: number;
  title: string;
  watch_number?: number; // 首页观看数
  time?: string; // 首页开始时间
  watch_url?: string; // 首页观看地址
  file_class?: number;
  stream_list?: Stream[]; //多频道列表
  language_list?: Language[];
  studio_pid: number;
}

/**
 * @description 直播间搜索对象
 *
 * @export
 * @class SearchLiveClass
 * @extends {BaseSearchClass}
 */
export class SearchLiveClass extends BaseSearchClass {
  status?: number; // 直播状态

  start_at?: Date; // 开始时间

  end_at?: Date; // 结束时间

  file_class?: number | ''; // 直播分类

  mode?: number; // 直播模式

  push_type?: number; // 直播延迟

  create_at_order?: number; // 创建时间排序

  start_at_order?: number; // 开播时间

  status_order?: number; // 直播状态排序

  file_class_status?: number;

  start_time?: Date;

  end_time?: Date;

  sub_studio?: number;

  // 设置日期
  setDate(liveDate: Moment[]): void {
    if (liveDate && liveDate.length > 0) {
      this.start_at = liveDate[0].toDate();
      this.end_at = liveDate[1].toDate();
    }
  }

  constructor(data: SearchLiveClass) {
    super(data);
    this.status = data.status || data.status === 0 ? data.status : void 0;
    this.start_at = data.start_at;
    this.end_at = data.end_at;
    this.file_class = data.file_class || data.file_class === 0 ? data.file_class : void 0;
    this.mode = data.mode || data.mode === 0 ? data.mode : void 0;
    this.push_type = data.push_type || data.push_type === 0 ? data.push_type : void 0;
    this.create_at_order = data.create_at_order;
    this.start_at_order = data.start_at_order;
    this.status_order = data.status_order;
    this.file_class_status = data.file_class_status;
    this.start_time = data.start_time;
    this.end_time = data.end_time;
    this.sub_studio = data.sub_studio;
  }
}

export interface StudioStream {
  id: number;
  title: string;
}
export interface StudioLanguage {
  id: number;

  language_ch_name: string;

  language_image_url: string;

  title_sub: string;
}
export class StudioLanguageClass {
  id: number;

  label: string;

  img?: string;

  constructor(data: StudioStream | StudioLanguage) {
    this.id = data.id;
    this.label = (data as StudioStream).title ? (data as StudioStream).title : (data as StudioLanguage).title_sub;
    this.img = (data as StudioLanguage).language_image_url ? (data as StudioLanguage).language_image_url : undefined;
  }
}

/**
 * @description 直播间信息对象
 *
 * @export
 * @interface LiveInfo
 * @id {number} 直播间ID
 * @title {string} 标题
 * @status {number} 直播状态
 * @qr_code_pic {string} 观看二维码
 * @qr_code_url {string} 观看地址
 * @image_player_cover_url {string}  直播间封面
 * @studio_language 直播间语言对象数组
 * @mode 模式
 * @multi_type 未知
 * @stream_type 未知
 * @stream_list 多频道数组
 * @studio_pid 多频道与多语言的父级 0无父级
 * @studio_status 直播间状态
 * @end_at 结束时间
 * @filing_status 是否备案
 */
export interface LiveInfo {
  id: number;
  title: string;
  status: number;
  qr_code_pic: string;
  qr_code_url: string;
  image_player_cover_url?: string;
  studio_language?: StudioLanguage[];
  mode?: number;
  multi_type?: number;
  stream_type?: number;
  stream_list?: StudioStream[];
  studio_pid: number;
  studio_status?: number;
  start_at: string;
  end_at: string;
  play_url: string[];
  uv?: number;
  filing_status: number;
  data_page_rout: number;
  vr_play_status: number;
  vr_play_switch: number;
  live_url_status: number;
}
