import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 资源包分类
  getPackType = () => {
    return http.get('/api/v1/common/get_pack_type');
  };

  // 可购买资源包列表
  getPackByIndex = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/get_pack_by_index', data);
  };

  // 购买前订单详情
  payPackageInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/pay_package_info', data);
  };

  // 账户总览
  getAllAccountInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/account/get_all_account_info', data);
  };

  // 并发列表
  concurrentList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/concurrent_list', data);
  };

  // 收益转入余额
  transferInBalance = (data: unknown) => {
    return http.post('/api/v1/account/transfer_in_balance', data);
  };

  // 媒体库流水记录
  mediaBill = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/media_bill', data);
  };

  // 资源概览
  allAccountInfo = () => {
    return http.get('/api/v2/platform_bill/all_account_info');
  };

  //获取专属客户
  getAccountManagerInfo = () => {
    return http.get('/api/v2/platform_bill/get_account_manager_info');
  };

  // 资源概览V2 - 账户余额
  getAccountNew() {
    return http.get('/api/v2/platform_bill/all_account_info_new');
  }
  // 新版资源概况
  getNewResourceOverview() {
    return http.get('/api/v2/platform_bill/new_resource_overview');
  }
  // 新版资源概览明细
  getResourceDetailed(data: AxiosRequestConfig) {
    return http.get('/api/v2/platform_bill/resource_detailed', data);
  }
}
export default new Api();
