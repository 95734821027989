import axios from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class liveChat {
  // 消息渲染限制
  getConfigLimit = () => {
    return axios.get('/api/v1/common/config_limit');
  };
  // 获取控制台详情
  getLiveConsoleInfo = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/get_live_console_info', data);
  };
  // 设置观看页显示开关
  chatSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/chat_switch', data);
  };
  // 允许观众聊天开关
  groupChatSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/group_chat_switch', data);
  };
  // 允许观众匿名聊天
  anonymousSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/anonymous_switch', data);
  };
  // 允许观众私聊
  privateChatSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/private_chat_switch', data);
  };
  // 显示弹幕
  barrageSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/barrage_switch', data);
  };
  // 弹幕需要审核
  barrageReviewSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/barrage_review_switch', data);
  };
  // 发送内容需要审核
  groupReviewSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/group_review_switch', data);
  };
  // 显示评论数
  commentNumSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/comment_num_switch', data);
  };
  // 观众进入直播间提示
  notitySwitch = (data: unknown) => {
    return axios.post('/api/v1/live/notity_switch', data);
  };
  // 允许发送图片
  picSwitch = (data: unknown) => {
    return axios.post('/api/v1/live/pic_switch', data);
  };
  // 聊天设置聚合请求
  onSetchatGroup = (data: any) => {
    const apiArr = [
      'group_chat_switch',
      'anonymous_switch',
      'private_chat_switch',
      'barrage_switch',
      'barrage_review_switch',
      'group_review_switch',
      'comment_num_switch',
      'notity_switch',
      'pic_switch',
      'set_addr_switch',
    ];
    let key = 0;
    if (data.key) {
      key = data.key;
    }
    return axios.post(`/api/v1/live/${apiArr[key]}`, {
      status: data.status,
      studio_id: data.studio_id,
    });
  };
  // 聊天设置清空记录
  deleteChat = (data: unknown) => {
    return axios.post('/api/v1/live/delete_chat', data);
  };
  // 获取公共聊天记录
  groupChatList = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/group_chat_record', data);
  };
  // 获取公共聊天-评论记录
  getMoreComment = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/more_comment', data);
  };
  // 获取公共聊天-观众私聊记录
  getMorePrivate = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/more_private', data);
  };

  // 获取锁定机器人信息
  getLockRobotInfo(data: AxiosRequestConfig) {
    return axios.get('/api/v1/live/get_lock_robot_info', data);
  }
  // 修改锁定机器人信息
  getEditLockRobotInfo(data: unknown) {
    return axios.post(' /api/v1/live/edit_lock_robot_info', data);
  }
  // 修改机器人地址
  setRobotAdree(data: unknown) {
    return axios.post('/api/v1/live/set_random_area', data);
  }
  // 获取随机机器人信息
  getRandomRobotInfo() {
    return axios.get(' /api/v1/live/get_random_robot_info');
  }
  // 获取私信聊天记录
  privateChatlist = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/private_list', data);
  };
  // 获取私聊详细
  privateView = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/private_view', data);
  };
  // 公共消息点赞
  setChatLike = (data: unknown) => {
    return axios.post('/api/v1/live/like', data);
  };
  // 公共消息取消点赞
  setChatUnLike = (data: unknown) => {
    return axios.post('/api/v1/live/un_like', data);
  };

  // 加入聊天室
  joinChat(data: unknown) {
    return axios.post('/api/v1/live/join', data);
  }
  // 发送公聊消息
  sendGroup(data: unknown) {
    return axios.post('/api/v1/live/send_group', data);
  }
  // 发送私聊消息
  sendPrivate(data: unknown) {
    return axios.post('/api/v1/live/send_private', data);
  }
  // 互动聊天-隐藏（消息）弹幕
  msgReview(data: unknown) {
    return axios.post('/api/v1/live/msg_review', data);
  }
  // 互动聊天-弹幕弹出
  msgBarrage(data: unknown) {
    return axios.post('/api/v1/live/msg_barrage', data);
  }
  // 互动聊天-置顶
  msgTop(data: unknown) {
    return axios.post('/api/v1/live/msg_top', data);
  }
  // 互动聊天-删除
  msgDelete(data: unknown) {
    return axios.post('/api/v1/live/msg_delete', data);
  }
  // 互动聊天-禁言
  groupMute(data: unknown) {
    return axios.post('/api/v1/live/group_mute', data);
  }
  // 互动聊天-拉黑
  pullBlack(data: unknown) {
    return axios.post('/api/v1/live/pull_black', data);
  }
  // 获取官方身份配置信息
  getIdentityConfiguration(data: AxiosRequestConfig) {
    return axios.get('/api/v1/live/get_identity_configuration', data);
  }
  // 添加or修改官方身份信息
  getChangeIdentityConfiguration(data: unknown) {
    return axios.post('/api/v1/live/change_identity_configuration', data);
  }
  // 查看红包
  getRedpackInfo(data: AxiosRequestConfig) {
    return axios.get('/api/v1/live/get_redpack_info_console', data);
  }
  // 红包中奖列表
  getRedpackRankConsole(data: AxiosRequestConfig) {
    return axios.get('/api/v1/live/get_redpack_rank_console', data);
  }
  // 竞答红包-中奖信息
  getRedpackSnatchList(data: AxiosRequestConfig) {
    return axios.get('/api/v1/live/get_live_studio_contest_snatch_list', data);
  }

  /**
   * 轻聊版
   */
  // 获取公共聊天记录
  lightGroupChatList = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/get_console_light_chat_list', data);
  };
  // 获取置顶-公共聊天记录
  lightTopChatList = (data: AxiosRequestConfig) => {
    return axios.get('/api/v1/live/get_console_light_top_chat_list', data);
  };
  // 轻聊版-发送公聊消息
  sendLightGroup(data: unknown) {
    return axios.post('/api/v1/live/console_send_message', data);
  }
  // 轻聊版-互动聊天-隐藏（消息）弹幕
  lightMsgReview(data: unknown) {
    return axios.post('/api/v1/live/set_hide_light_chat_messages', data);
  }
  // 轻聊版-互动聊天-弹幕弹出
  lightMsgBarrage(data: unknown) {
    return axios.post('/api/v1/live/set_barrage_light_chat_messages', data);
  }
  // 轻聊版-互动聊天-置顶
  lightMsgTop(data: unknown) {
    return axios.post('/api/v1/live/set_top_light_chat_messages', data);
  }
  // 轻聊版-互动聊天-删除
  lightMsgDelete(data: unknown) {
    return axios.post('/api/v1/live/set_del_light_chat_messages', data);
  }

  // // 互动聊天-拉黑
  // pullBlack(data: unknown) {
  //   return axios.post('/api/v1/live/pull_black', data);
  // }
  // 公共聊天-私聊-禁言
  privateMute(data: unknown) {
    return axios.post('/api/v1/live/private_mute', data);
  }
}
export default new liveChat();
