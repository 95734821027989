import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取信息
  reserve_msg_view = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/reserve_msg_view', data);
  };
  // 获取预约名单
  reserve_msg_user = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/reserve_msg_user', data);
  };
  // 删除
  reserve_msg_user_delete(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_user_delete', data);
  }
  // 搜索
  reserve_msg_user_search = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/reserve_msg_user_search', data);
  };
  // 清空
  reserve_msg_user_clean(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_user_clean', data);
  }
  // 添加预约号码
  reserve_msg_user_create(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_user_create', data);
  }
  // 修改预约号码
  reserve_msg_user_update(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_user_update', data);
  }
  // 手动发送
  reserve_msg_send(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_send', data);
  }
  // 发送记录
  reserve_msg_record = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/reserve_msg_record', data);
  };
  // 批量导入
  reserve_msg_import(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_import', data);
  }
  // 保存
  reserve_msg_save(data: unknown) {
    return http.post('/api/v1/live/reserve_msg_save', data);
  }
  // 直播间创建时间
  getLiveInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_live_info', data);
  }
  // 公众号预约名单
  we_chat_reserve_user = (data: AxiosRequestConfig) => {
    return http.get('/api/v1//live/we_chat_reserve_user', data);
  };
  // 微信发送
  we_chat_send_message(data: unknown) {
    return http.post('/api/v1/wechat/we_chat_send_message', data);
  }
  // 微信批量导入
  weChatImport(data: unknown) {
    return http.post('/api/v1/live/we_chat_user_import', data);
  }
}

export default new Api();
