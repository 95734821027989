import { createStore } from 'vuex';
import home from './modules/home';
import user from './modules/user';
import live from './modules/live';
import authorizView from './modules/authorizView';
import media from './modules/media';

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    home,
    user,
    live,
    authorizView,
    media,
  },
});

declare module 'vuex' {
  type StoreStateType = typeof store.state;
  type ModulesType = {
    home: typeof home.state;
    user: typeof user.state;
    live: typeof live.state;
    authorizView: typeof authorizView.state;
    media: typeof media.state;
  };
  export function useStore<S = StoreStateType & ModulesType>(): Store<S>;
}

export default store;
