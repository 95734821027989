/**
 * 防抖函数
 * @param func 执行函数
 * @param delay 延迟时间 ms
 * @param immediate 是否立即执行
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function useDebounce<T extends Function>(func: T, delay = 500, immediate = false): T {
  if (delay <= 0) return func;

  let timer: ReturnType<typeof setTimeout>;

  function wrapper(this: any, ...args: any[]) {
    if (immediate) {
      func.apply(this, args); // 确保引用函数的指向正确，并且函数的参数也不变
      immediate = false;
      return;
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      return func.apply(this, args);
    }, delay);
  }

  return wrapper as any as T;
}
