export interface Overview {
  key: number;
  info: string;
  icon: string;
  num: number | string;
  background: string;
}

export interface StatisticsData {
  time_list: string[];
  view_list: any[];
}

export interface WatchRankData {
  node_title: string[];
  views: number[];
}
export interface Column {
  title: string;
  align: string;
  dataIndex: string;
  width?: string;
  ellipsis?: boolean;
  slots?: { customRender: string };
}

export interface MapSource {
  id: number;
  province: string;
  value: number;
  day_to_day: string;
  week_to_week: string;
}

export class MapSourceClass {
  ranking: number;
  province: string;
  value: number;
  dayOnDay: string;
  yearOnWeek: string;
  constructor(data: MapSource) {
    this.ranking = data.id;
    this.province = data.province;
    this.value = data.value;
    this.dayOnDay = data.day_to_day;
    this.yearOnWeek = data.week_to_week;
  }
}

export class RegionRankDataClass {
  name: string;
  value: number;
  constructor(data: MapSource) {
    this.name = data.province;
    this.value = data.value;
  }
}

export interface WaterSource {
  avatar_url: string;
  user_name: string;
  user_id: number;
  province: string;
  browser: string;
  device: string;
}

// 访问来源数据
export interface AccessSource {
  browser: string;
  people_num: number;
}
export class AccessSourceClass {
  name: string;
  value: number;
  constructor(data: AccessSource) {
    this.name = data.browser;
    this.value = data.people_num;
  }
}

// 设备占比数据
export interface Equipment {
  device: string;
  people_num: number;
}
export class EquipmentClass {
  name: string;
  value: number;
  constructor(data: Equipment) {
    this.name = data.device;
    this.value = data.people_num;
  }
}
