
import { defineComponent, ref } from 'vue';
import lighvideo from '@/components/Lighvideo.vue';
import { ElColorPicker } from 'element-plus';

export default defineComponent({
  components: {
    [ElColorPicker.name]: ElColorPicker,
    lighvideo,
  },
  props: ['title'],
  setup() {
    const colorList = ref(['#00CCB0', '#1890FF', '#FF4D4F', '#FF9337','#FBD793']);
    const color = ref(colorList.value[0]);
    // 选择频道配色
    const getColor = (colors: any) => {
      color.value = colors;
    };

    const preview = ref(false);
    const toPreview = () => {
      preview.value = !preview.value;
    };
    return {
      color,
      colorList,
      getColor,
      preview,
      toPreview,
    };
  },
});
