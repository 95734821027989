
import {
  defineAsyncComponent,
  inject,
  ref,
  shallowRef,
  unref,
  computed,
  onMounted,
  createVNode,
  reactive,
  watch,
  defineComponent,
} from 'vue';
import { RangePickerIcon } from '@/assets';
import { Alert, message, Radio, Popover, Modal, DatePicker, Checkbox } from 'ant-design-vue';
import {
  QuestionCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  ExclamationCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons-vue';
import ColorInstalls from './components/ColorInstall.vue';
import moment, { Moment } from 'moment';
const CultiChannels = defineAsyncComponent(() => import('./components/MultiChannel.vue'));
const MultiLinguals = defineAsyncComponent(() => import('./components/MultiLingual.vue'));
import http from '@/api/live';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useQRCode } from '@vueuse/integrations/useQRCode';
import Crumb from '@/components/Crumb.vue';
import { useDebounce } from '@/hooks/useDebounce';
import { LiveInfo } from '@/store/modules/live/types';
import { useHomeStore } from '@/pinia/home';
import { pageStyle1, pageStyle2, pageStyle3, pageStyle4, pageStyle5 } from '@/assets';

const AnchorNorm = defineAsyncComponent(() => import('@/views/newLive/components/AnchorNorm.vue'));

export default defineComponent({
  components: {
    [Alert.name]: Alert,
    [Radio.Group.name]: Radio.Group,
    [Popover.name]: Popover,
    QuestionCircleFilled,
    CloseCircleFilled,
    InfoCircleFilled,
    CheckCircleFilled,
    ColorInstalls,
    [Modal.name]: Modal,
    [DatePicker.name]: DatePicker,
    Crumb,
    AnchorNorm,
    [Checkbox.Group.name]: Checkbox.Group,
  },

  setup() {
    const route = useRoute();
    let title: any = ref(true);
    title.value = route.params.title == undefined ? true : route.params.title;
    const studioId = route.params.studioId;
    const homeStore = useHomeStore();
    const StandardLiveRole = computed(() => homeStore.funcData?.StandardLive); // 标准版权限
    const VerticalLive = computed(() => homeStore.funcData?.VerticalLive); // 竖屏直播权限
    const VRLiveRole = computed(() => homeStore.funcData?.panoramaLive); // VR直播权限
    const InteractiveLiveStreaming = computed(() => homeStore.funcData?.InteractiveLiveStreaming); //互动直播 
    const disabled = ref(false);
    // 活动名称
    const domain = ref('');
    const getInput = () => {
      // console.log();
    };
    // 时间选择器
    const startTime: any = ref<Moment>();
    const endTime: any = ref<Moment>();
    const endOpen = ref<boolean>(false);
    const disabledStartDate = (startValue: Moment) => {
      if (!startValue || !endTime.value) {
        return false;
      }
      return moment(startValue).format('YYYY-MM-DD').valueOf() > moment(endTime.value).format('YYYY-MM-DD').valueOf();
    };
    const disabledEndDate = (endValue: Moment) => {
      if (!endValue || !startTime.value) {
        return false;
      }
      return moment(startTime.value).format('YYYY-MM-DD').valueOf() > moment(endValue).format('YYYY-MM-DD').valueOf();
    };
    const handleStartOpenChange = (open: boolean) => {
      if (!open) {
        endOpen.value = true;
      }
    };

    const handleEndOpenChange = (open: boolean) => {
      endOpen.value = open;
    };

    // 页面语言
    const page_language = ref('0');

    // 人数
    const people_mode = ref('3');
    const people_num = ref(true);
    const getWatch = () => {
      people_mode.value = people_num.value ? '3' : '';
    };
    const getPeople = () => {
      people_num.value = true;
    };
    // 直播模式
    const modeList = shallowRef<any>([
      {
        id: 0,
        name: '视频直播',
        icon: 'liveVideo',
        component: '',
        show: true,
      },
      {
        id: 3,
        name: '互动直播',
        icon: 'liveInteract',
        component: '',
        show: InteractiveLiveStreaming.value,
      },
      {
        id: 4,
        name: '多频道直播',
        icon: 'liveMulti',
        component: CultiChannels,
        show: true,
      },
      {
        id: 5,
        name: '多语种直播',
        icon: 'liveLanguage',
        component: MultiLinguals,
        show: true,
      },
      {
        id: 6,
        name: '竖屏直播',
        icon: 'liveMobileEnd',
        component: '',
        show: VerticalLive.value,
      },
    ]);
    const modeId = ref(0);
    const studioPid = ref(0);
    const getMode = (id: number) => {
      if (!studioId) {
        modeId.value = id;
        chat_type.value = '1';
      }
    };

    // 直播状态
    const vr_play_status = ref<any>(['0']);
    watch(modeId, (newVal) => {
      if (newVal === 0) {
        vr_play_status.value = '0';
      }
    });
    watch(
      () => vr_play_status.value,
      (newVal: any, oldVal) => {
        if (modeId.value != 3) {
          if (vr_play_status.value.length == 0) {
            message.destroy();
            message.info('至少选择一个！');
            vr_play_status.value = oldVal;
          }
        }
      }
    );

    // 隐藏颜色选择器
    const picker = ref(null);
    const getPicker = () => {
      if (!studioId) {
        (picker.value as any).preview = (picker.value as any).preview ? false : null;
      }
    };

    // 活动规则
    const concurrent = ref('0');
    const concurrentTips = ref(false);
    const getConcurrent = () => {
      concurrentTips.value = Number(concurrent.value) < 0 ? true : false;
    };

    // 序号开关
    const serial = ref(true);
    const componentValue: any = ref(null);
    const getSerial = () => {
      if (modeId.value == 4) {
        (componentValue.value as any).serial = serial.value;
      }
    };

    // 创建验证
    const testVerify = () => {
      return new Promise((res: any) => {
        let next = true;
        const date = [
          {
            name: domain.value,
            message: '活动名称不能为空！',
          },
          {
            name: startTime.value,
            message: '开始时间不能为空！',
          },
          {
            name: endTime.value,
            message: '开始时间不能为空！',
          },
        ];
        date.some((res) => {
          if (!res.name) {
            next = false;
            message.warning(res.message);
            return true;
          }
        });
        res(next);
      });
    };
    // 创建数据
    const channel_style = ref('1');
    const chat_type = ref('1');
    const many_studio_list = ref<any>([]);
    const getData = () => {
      return new Promise((res) => {
        let next = true;
        many_studio_list.value = [];
        const start_at = moment(startTime.value).format('YYYY-MM-DD HH:mm');
        const end_at = moment(endTime.value).format('YYYY-MM-DD HH:mm');
        const theme_color = studioId ? '' : (picker.value as any).color;
        const studio_list_number = serial.value ? 1 : 0;
        if (modeId.value == 4 || modeId.value == 5) {
          const multiList = componentValue.value ? (componentValue.value as any).multiList : [];
          let setStudioList: any = [];
          let title_sub: any = [];
          multiList.some((res: any, index: number) => {
            if (!res.studio_id && !res.input) {
              message.warning('直播间名称不能为空！');
              next = false;
              return true;
            }
            let data: any;
            // 多频
            if (modeId.value == 4) {
              data = {
                image_player_cover_id: res.image_player_cover_id,
                studio_stream_type: 1,
                studio_id: res.selectLive == 0 ? res.studio_id : '',
                studio_name: res.selectLive == 0 ? '' : res.input,
              };
            }
            // 多语
            else {
              data = {
                language_id: res.language_id,
                studio_id: res.selectLive == 0 ? res.studio_id : '',
                studio_name: res.selectLive == 0 ? '' : res.input,
                title_sub: res.alias,
              };
              title_sub.push(res.alias);
              const titleSubNum = new Set(title_sub);
              if (titleSubNum.size < title_sub.length) {
                message.warning('语言类型别名不能重复！');
                next = false;
                return;
              }
            }
            many_studio_list.value.push(data);
            setStudioList.push(res.input || index);
          });
          let setNum = new Set(setStudioList);
          if (setNum.size < setStudioList.length) {
            message.warning('新建直播间名称不能重复！');
            next = false;
            return;
          }
        }
        let data = {
          title: domain.value, //直播间标题
          start_at: start_at, //开始时间
          end_at: end_at, //结束时间
          mode: modeId.value, //直播模式
          theme_color: theme_color, //主题配色
          studio_list_number: studio_list_number, //序号
          channel_style: channel_style.value, //频道样式
          chat_type: chat_type.value, //聊天
          people_mode: people_num.value ? people_mode.value : '0', //人数显示模式
          limit: concurrent.value, //并发限制
          many_studio_list: JSON.stringify(many_studio_list.value), //多频道、多语
          language_type: page_language.value, //页面语言
          industry: industry.value ? options.value[industry.value - 1].label : 0, //所属行业
          dark_mode: dark_mode.value, // 主题模式
          bg_color: bg_color.value, //背景色
          stream_status: vr_play_status.value ? vr_play_status.value.toString() : '0', // 直播状态
        };
        next ? res(data) : res(false);
      });
    };
    const agreementCheckbox = ref(false);
    // 新建直播
    const router = useRouter();
    const toCreate = useDebounce(async (pay: any, order_no: any) => {
      message.destroy();
      let verify = await testVerify();
      if (!verify) {
        return;
      }
      let data = await getData();
      if (!data) {
        return;
      }
      if (!industry.value) {
        Modal.warning({
          content: () =>
            createVNode(
              'div',
              { style: ' fontSize: 13px' },
              '按照国家《网络主播行为规范》的规定，您需要对直播内容所属的行业进行选择，谢谢！'
            ),
          icon: () => createVNode(ExclamationCircleOutlined),
          centered: true,
          class: 'test',
          okText: '确定',
        });
        return;
      }
      if (!agreementCheckbox.value) {
        message.warning('请先勾选隐私协议！');
        return;
      }
      if ((!title.value || title.value == 'false') && pay !== 'pay') {
        visible.value = true;
      } else {
        http
          .Create(data)
          .then((res) => {
            if (pay !== 'pay') {
              message.success('创建成功！');
              router.push(`/live_set/${res.data.data}/broad_cast_control`);
            } else {
              bindingLive(res.data.data, order_no);
            }
          })
          .catch((res) => {
            res.data.error ? message.error(res.data.error) : '';
          });
      }
    });
    // 创建直播间
    const createLoading = ref(false);
    const createLive = useDebounce(async (pay: any, order_no: any) => {
      let data = await getData();
      createLoading.value = true;
      http
        .Create(data)
        .then((res) => {
          if (pay !== 'pay') {
            message.success('创建成功！');
            router.push(`/live_set/${res.data.data}/broad_cast_control`);
            showNorm.value = false;
          } else {
            bindingLive(res.data.data, order_no);
          }
        })
        .catch((res) => {
          res.data.error ? message.error(res.data.error) : '';
        })
        .finally(() => (createLoading.value = false));
    });
    const visible = ref(false);
    const wxVisible = ref(false);
    const amount = ref();
    // 99元开播确认
    const createNinetyNine = () => {
      createOrder();
    };
    // 创建优惠券付费订单
    const createOrder = () => {
      const start_at = moment(startTime.value).format('YYYY-MM-DD HH:mm');
      const end_at = moment(endTime.value).format('YYYY-MM-DD HH:mm');
      const data = {
        card_id: 1,
        type: 2,
        start_at: start_at,
        end_at: end_at,
      };
      http
        .adminPayCreateOrder(data)
        .then((res) => {
          amount.value = res.data.data.amount;
          wePay(res.data.data.order_no);
        })
        .catch((err) => message.error(err.data.error));
    };
    // 获取微信支付二维码
    const QRcode = ref();
    const wePay = (order_no: any) => {
      const data = {
        order_no: order_no,
        pay_type: 5,
      };
      http.weChatScanPay({ params: data }).then((res) => {
        visible.value = false;
        wxVisible.value = true;
        QRcode.value = res.data.data;
        pollingPay(order_no);
      });
    };
    // 查询支付
    let polling_order: any = ref();
    const pollingPay = (order_no: any) => {
      polling_order.value = setInterval(() => {
        const data = {
          order_no: order_no,
        };
        http.pollingOrder({ params: data }).then((res) => {
          if (res.data.data.code !== 40003) {
            clearInterval(polling_order.value);
            // toCreate('pay', order_no);
            createLive('pay', order_no);
          }
        });
      }, 2000);
    };
    // 关闭轮询支付
    const cancelWxpay = () => {
      clearInterval(polling_order.value);
    };
    // 99元付费后绑定直播间
    const bindingLive = (studio_id: any, order_no: any) => {
      const data = {
        order_no: order_no,
        studio_id: studio_id,
      };
      http.cardCouponStudioBuild(data).then(() => {
        message.success('创建成功！');
        router.push(`/live_set/${studio_id}/broad_cast_control`);
      });
    };
    // 取消
    const toCancel = useDebounce(() => {
      router.push('/');
    });

    // 保存编辑
    const setStudio = useDebounce(async () => {
      message.destroy();
      let verify = await testVerify();
      if (!verify) {
        return;
      }
      let awaitData = await getData();
      if (!awaitData) {
        return;
      }
      const start_at = moment(startTime.value).format('YYYY-MM-DD HH:mm');
      const end_at = moment(endTime.value).format('YYYY-MM-DD HH:mm');
      const data = {
        studio_id: studioId,
        title: domain.value,
        start_at: start_at,
        end_at: end_at,
        many_studio_list: JSON.stringify(many_studio_list.value),
        chat_type: chat_type.value,
        language_type: page_language.value, //页面语言
        stream_status: vr_play_status.value.join(','), // 直播状态
      };
      if (modeId.value == 4) {
        data['channel_style'] = Number(channel_style.value);
        data['studio_list_number'] = serial.value ? 1 : 0;
      }
      http
        .setStudioInfo(data)
        .then(() => {
          message.success('保存成功！');
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    });

    // 编辑：获取直播间信息
    const getLive = () => {
      disabled.value = true;
      if (studioId) {
        http
          .getLiveView({ params: { studio_id: studioId } })
          .then((res) => {
            const data = res.data.data;
            modeId.value = data.mode;
            domain.value = data.title;
            startTime.value = moment(data.start_at, 'YYYY-MM-DD HH:mm');
            endTime.value = moment(data.end_at, 'YYYY-MM-DD HH:mm');
            chat_type.value = `${data.chat_type}`;
            serial.value = data.studio_list_number == 1 ? true : false;
            channel_style.value = `${data.channel_style}`;
            vr_play_status.value = data.stream_status.split(','); // 直播状态
            let multiList: any = ref([]);

            if (modeId.value == 4 && data.stream_studio) {
              // 多频道
              data.stream_studio.map((res: any) => {
                multiList.value.push({
                  img: res.image_player_cover_url,
                  studio_id: res.id,
                  image_player_cover_id: res.image_player_cover_id,
                  input: undefined,
                  language_id: 0,
                  studio_name: '',
                  studio_stream_type: 1,
                  title_sub: '',
                  videoCover_loading: false,
                  selectLive: 0,
                  select: res.title,
                  studio_index: null,
                });
              });
              setTimeout(() => {
                componentValue.value ? ((componentValue.value as any).multiList = multiList.value) : '';
              }, 200);
            } else if (modeId.value == 5 && data.studio_language) {
              // 多语
              const studio_language: any = ref([]);
              data.studio_language.map((res: any) => {
                studio_language.value.push({
                  alias: res.title_sub || undefined,
                  selectImg: res.language_image_url,
                  nameSelect: '',
                  language: res.language_ch_name,
                  language_id: res.language_id,
                  studio_id: res.id,
                  studio_name: '',
                  studio_index: null,
                  input: undefined,
                  select: res.title,
                  selectLive: 0,
                });
              });
              setTimeout(() => {
                componentValue.value ? ((componentValue.value as any).multiList = studio_language.value) : '';
              }, 200);
            }
          })
          .finally(() => (disabled.value = false));
      }
    };
    const liveOpen = ref();
    const liveInfo = computed(() => unref(inject('liveInfo')) as LiveInfo);
    // 查询直播间信息 直播间开关
    const getLiveInfo = () => {
      http
        .getLiveInfo({
          params: { studio_id: studioId },
        })
        .then((res) => {
          studioPid.value = res.data.data.studio_pid;
          liveOpen.value = res.data.data.studio_status == 0 || res.data.data.studio_status == 2 ? false : true;
          page_language.value = res.data.data.language_type.toString();
          dark_mode.value = res.data.data.dark_mode;
          vr_play_status.value = res.data.data.stream_status.split(','); // 直播状态
          clickTabs(dark_mode.value);
        });
    };
    studioId ? getLiveInfo() : '';
    const checkLiveOpen = useDebounce(() => {
      message.destroy();
      const data = {
        status: liveOpen.value ? 1 : 2,
        studio_id: studioId,
      };
      http.clickOffStudio(data).then((res) => {
        message.success(res.data.message);
      });
    });
    // 子直播间切换聊天模式权限
    const langSubEdit = computed(() => !liveInfo.value.id || liveInfo.value.studio_pid !== liveInfo.value.id);
    onMounted(() => {
      getLive();
    });

    const qrcodeUrl = useQRCode(QRcode, { margin: 0 });

    const dark_mode = ref(0);
    const industry = ref();
    const options = ref([
      {
        value: 1,
        label: '医疗卫生',
      },
      {
        value: 2,
        label: '财经金融',
      },
      {
        value: 3,
        label: '法律',
      },
      {
        value: 4,
        label: '教育',
      },
      {
        value: 5,
        label: '其他',
      },
    ]);

    // 网络主播行为规范
    const showNorm = ref(false);

    const readDisabled = ref(true);
    const onBottom = () => {
      readDisabled.value = false;
    };

    const styleGlobal = reactive([
      { key: 0, url: pageStyle1, title: '浅色风格' },
      { key: 1, url: pageStyle2, title: '暗色风格' },
      { key: 2, url: pageStyle4, title: '年会风格' },
      { key: 3, url: pageStyle3, title: '科技风格' },
      { key: 4, url: pageStyle5, title: '财经风格' },
    ]);

    const bg_color = ref('#E5E7EB');
    const theme_color = ref(picker.value ? (picker.value as any).color : '#00CCB0');
    const clickTabs = (index: number) => {
      dark_mode.value = index;
      if (index != 2) theme_color.value = '#00CCB0';
      switch (index) {
        case 1:
          bg_color.value = '#030A13';
          break;
        case 2:
          bg_color.value = '#D0514C';
          theme_color.value = '#F2C277';
          break;
        case 3:
          bg_color.value = '#016891';
          break;
        case 4:
          bg_color.value = '#000000';
          theme_color.value = '#FBD793';
          break;
        case 0:
          bg_color.value = '#E5E7EB';
          break;
      }
      picker.value ? ((picker.value as any).color = theme_color.value) : '';
      // 0 默认 浅色  1 深色  2科技  3年会
    };

    return {
      title,
      studioId,
      StandardLiveRole,
      VerticalLive,
      InteractiveLiveStreaming,
      VRLiveRole,
      domain,
      getInput,
      // 日期选择器
      RangePickerIcon,
      startTime,
      endTime,
      endOpen,
      disabledStartDate,
      disabledEndDate,
      handleStartOpenChange,
      handleEndOpenChange,
      people_mode,
      people_num,
      modeList,
      studioPid,
      modeId,
      getMode,
      getPicker,
      picker,
      concurrent,
      getConcurrent,
      concurrentTips,
      serial,
      getSerial,
      componentValue,
      getWatch,
      getPeople,
      toCreate,
      channel_style,
      page_language, //页面语言
      chat_type,
      toCancel,
      visible,
      createNinetyNine,
      wxVisible,
      QRcode,
      qrcodeUrl,
      cancelWxpay,
      setStudio,
      liveOpen,
      checkLiveOpen,
      amount,
      langSubEdit,
      disabled,
      industry,
      dark_mode,
      options,
      showNorm,
      createLive,
      createLoading,
      readDisabled,
      onBottom,
      styleGlobal,
      clickTabs,
      agreementCheckbox,
      vr_play_status,
    };
  },
});
