
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import type { RefererCharts } from './broadCast';
import { message, Button, Switch, Modal, Popover, notification } from 'ant-design-vue';
import ClipboardJS from 'clipboard';
import { liveItemBg } from '@/assets';
import Crumb from '@/components/Crumb.vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';
import http from '@/api';
import { getCurrentInstance } from 'vue';
import { getToken } from '@/plugins/cookie';
import { useRoute, useRouter } from 'vue-router';
import { useLiveStore } from '@/pinia/live';
import BeautifyCode from './BeautifyCode.vue';
import VideoeEmbed from './VideoeEmbed.vue';
import { useStore } from 'vuex';
import { useHomeStore } from '@/pinia/home';
import { ObjType, ListObj, ListClass } from '@/views/newLive/components/type';
import { useDebounce } from '@/hooks/useDebounce';
import { onExport } from '@/components/CExport'; //导出
import https from '@/api/exportList';


const Qualifications = defineAsyncComponent(() => import('@/views/newLive/components/Qualifications.vue'));

// import { useStore } from 'vuex';
export default defineComponent({
  components: {
    [Button.name]: Button,
    [Switch.name]: Switch,
    [Popover.name]: Popover,
    aModal: Modal,
    Crumb,
    BeautifyCode,
    VideoeEmbed,
    Qualifications,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const liveStore = useLiveStore();
    const homeStore = useHomeStore();
    const liveInfo = computed(() => liveStore.liveInfo);

    const store = useStore();

    const Pulltime = ref([
      {
        id: '0',
        num: '0',
        text: '分钟',
        name: '推流时长',
        color: '#00ccb0',
      },
      {
        id: '1',
        num: '0',
        text: '次',
        name: '观看量（PV）',
        color: '#FA5555',
      },
      {
        id: '2',
        num: '0',
        text: '人',
        name: '观众人数（UV）',
        color: '#FAAD14',
      },
      {
        id: '3',
        num: '0',
        text: '人',
        name: '最高并发',
        color: '#00ccb0',
      },
      {
        id: '4',
        num: '0',
        text: '人',
        name: '注册用户',
        color: '#00ccb0',
      },
      {
        id: '5',
        num: '0',
        text: '元',
        name: '直播收益',
        color: '#FAAD14',
      },
    ]);

    // 复制
    const Copylist = (btns: string) => {
      let ClipboardJSObj = new ClipboardJS(btns);
      ClipboardJSObj.on('success', function (e) {
        message.destroy();
        message.info('复制成功');
        e.clearSelection();
      });
      ClipboardJSObj.on('error', function () {
        message.destroy();
        message.info('复制失败');
      });
    };

    // 复制拉流地址
    const clipboardText = ref('');
    const rtmps = computed(() => liveStore.liveInfo.play_url && liveStore.liveInfo.play_url[0]);
    const mus = computed(() => liveStore.liveInfo.play_url && liveStore.liveInfo.play_url[2]);
    const flvs = computed(() => liveStore.liveInfo.play_url && liveStore.liveInfo.play_url[1]);
    // 二维码
    const showVisible = ref(false);
    const showModal = () => {
      showVisible.value = true;
    };

    // 拉流/播放器嵌入
    const showEmbed = ref(false);
    const openEmbed = () => {
      showEmbed.value = !showEmbed.value;
    };

    const downs = () => {
      window.location.href = liveData.value.qr_code_pic;
    };

    // 更多跳转
    const onChangeRoute = () => {
      router.push(`/data_center/${route.params.studioId}/${homeStore.funcData['LiveData'] ? 'live' : 'questionnaire'}`);
    };
    // 访问来源导出
    const { proxy }: any = getCurrentInstance();
    const token = getToken();
    const corn = ref(1);
    const messageTip = ref();
    const sourceload = useDebounce((type: any) => {
      const data = {
        studio_id: route.params.studioId,
        type: type,
        is_cron_download: corn.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };
    // charts
    const refererData = ref<RefererCharts[]>([]);

    const liveData = computed(() => liveStore.liveInfo);
    // 直播数据观看权限 4多频道  5多语种
    const isShowliveData = computed(() => !(liveData.value.mode === 4 && liveData.value.studio_pid === liveData.value.id));
    const isShowUrl = computed(() => !(liveData.value.mode === 5 && liveData.value.studio_pid === liveData.value.id));
    // 复制拉留地址
    const Cpoyaddress = (btns: string) => {
      clipboardText.value = `rtmp：${rtmps.value}\n` + `m3u8：${mus.value}\n` + `flv：${flvs.value}\n`;
      let ClipboardJSObj = new ClipboardJS(btns);
      ClipboardJSObj.on('success', function (e) {
        message.destroy();
        message.info('复制成功');
        e.clearSelection();
      });
      ClipboardJSObj.on('error', function () {
        message.destroy();
        message.info('复制失败');
      });
    };
    // 直播信息接口
    function getRandomColor(colorKey: string, index: number) {
      const colors = {
        accessColors: ['#5654F5', '#FFBE20', ' #00ccb0', ' #FF3E3E', '#1890FF', '#FF5D22'],
        broswerColors: ['#5654F5', ' #00ccb0', '#FFBE20', '#1890FF', '#5DDECF', ' #FF3E3E'],
      };
      const newColorArr = colors[colorKey];
      return index > newColorArr.length - 1 ? newColorArr[newColorArr.length - 1] : newColorArr[index];
    }
    const accessListData = async () => {
      let access = {
        studio_id: route.params.studioId,
      };
      http.liveDirect.getAccessListData({ params: access }).then((res) => {
        // liveData.value = res.data.data;
        Pulltime.value[0].num = res.data.data.live_time;
        Pulltime.value[1].num = res.data.data.watch_number;
        Pulltime.value[2].num = res.data.data.watch_attendance;
        Pulltime.value[3].num = res.data.data.highest_concurrent;
        Pulltime.value[4].num = res.data.data.registered_users;
        Pulltime.value[5].num = res.data.data.live_earnings;

        const scourceReferer = res.data.data.referer;
        scourceReferer.forEach((e: any, index: number) => {
          refererData.value.push({
            name: e.name,
            itemStyle: { color: getRandomColor('accessColors', index) },
            value: e.value,
          });
        });
      });
    };
    accessListData();

    // 快捷设置模块
    interface ActionBtn {
      // 路由名
      router: string;
      // 按钮名称
      title: string;
      // 开关状态 1开 0关
      state: number;
      // 对接的远程字段
      originName: string;
    }

    const actionBtnGroup = ref<ActionBtn[]>([
      { router: 'SignupWatch', title: '报名表单', state: 0, originName: 'form_status' },
      { router: 'CountDown', title: '开播倒计时', state: 0, originName: 'countdown_status' },
      { router: 'Notice', title: '视频预告', state: 0, originName: 'notice_status' },
      { router: 'LiveGuide', title: '直播引导页', state: 0, originName: 'guide_status' },
      { router: 'MutuallyEngage', title: '礼物打赏', state: 0, originName: 'gift_reward_status' },
      { router: 'LikeSetting', title: '人气点赞', state: 0, originName: 'like_status' },
      { router: 'Console', title: '弹幕', state: 0, originName: 'barrage_status' },
      { router: 'EventReservation', title: '活动预约', state: 0, originName: 'reserve_status' },
      { router: 'SharingSetting', title: '公众号分享', state: 0, originName: 'wechat_share_status' },
      { router: 'Authorization', title: '授权观看', state: 0, originName: 'authorize_status' },
    ]);

    // 当前点赞没有做好，进行过滤
    const filterAcitionGroup = computed(() => actionBtnGroup.value);

    const getSetList = () => {
      http.liveDirect
        .getQuickSettings({
          params: {
            studio_id: route.params.studioId,
          },
        })
        .then((res) => {
          const resData = res.data.data;
          actionBtnGroup.value.forEach((item) => {
            item.state = resData[item.originName] ?? 0;
          });
        })
        .catch((err) => {
          console.error(err.data.error);
        });
    };
    getSetList();

    const convenientSettingAction = new Map([
      // 报名表单
      [
        { routerName: 'SignupWatch' },
        async (key: unknown) => {
          try {
            if (key === -1) {
              router.push({ name: 'SignupWatch', params: { studioId: route.params.studioId } });
            } else {
              const reqData = key ? 0 : 1;
              const res = await http.live.getViewForm({
                params: {
                  studio_id: route.params.studioId,
                },
              });
              const queryData = res.data.data;
              await http.live.saveViewForm({
                studio_id: route.params.studioId,
                status: reqData,
                account_field: queryData.account_field,
                bg_img: queryData.bg_img,
                form: queryData.form,
                head_img: queryData.head_img,
                intro: queryData.intro,
                login_btn_text_en: queryData.login_btn_text_en,
                login_btn_text_zh: queryData.login_btn_text_zh,
                nickname_field: queryData.nickname_field,
                pc_bg_img: queryData.pc_bg_img,
                register_btn_text_en: queryData.register_btn_text_en,
                register_btn_text_zh: queryData.register_btn_text_zh,
                title: queryData.title,
                type: queryData.type,
              });
              actionBtnGroup.value[0].state = reqData;
            }
          } catch (err: any) {
            // 临时将空状态与异常一起处理
            if (err?.data?.error === '暂无数据') {
              router.push({ name: 'SignupWatch', params: { studioId: route.params.studioId } });
              return;
            }
            message.error(err?.data?.error);
          }
        },
      ],
      // 开播倒计时
      [
        { routerName: 'CountDown' },
        async (key: unknown) => {
          try {
            const reqData = key;
            await http.home.addStudioCountdown({
              studio_id: route.params.studioId,
              status: reqData,
            });
            actionBtnGroup.value[1].state = reqData ? 0 : 1;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      // 视频预告
      [
        { routerName: 'Notice' },
        async (key: unknown) => {
          try {
            if (key === -1) {
              router.push({ name: 'Notice', params: { studioId: route.params.studioId } });
            } else {
              const reqData = key ? 0 : 1;
              const res = await http.livePlayback.get_studio_notice_replay_video({
                params: { studio_id: route.params.studioId },
              });
              const queryData = res.data.data;
              if (JSON.stringify(queryData.notice) === '[]' || (queryData.notice && queryData.notice.list.length === 0)) {
                router.push({ name: 'Notice', params: { studioId: route.params.studioId } });
                return;
              }
              const noticeIds = queryData.notice.list.map((item: any) => {
                return item.id;
              });
              await http.livePlayback.set_live_studio_notice({
                studio_id: route.params.studioId,
                status: reqData,
                is_del: 0,
                istop: 0,
                notice_ids: noticeIds.join(','),
                top_notice_id: '',
              });
              actionBtnGroup.value[2].state = reqData;
            }
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      // 直播引导页
      [
        { routerName: 'LiveGuide' },
        async (key: unknown) => {
          try {
            const reqData = key ? 0 : 1;
            const res = await http.live.getLiveView({ params: { studio_id: route.params.studioId } });
            const queryData = res.data.data;
            await http.live.saveGuide({
              studio_id: route.params.studioId,
              status: reqData,
              pc_status: queryData.guide_pc_status,
              mode: queryData.guide_mode,
              pc_title: queryData.title,
              image: '',
              bg_image: queryData.guide_bg_image,
              pc_bg_color: queryData.guide_pc_bg_color ? queryData.guide_pc_bg_color : '#d5d5d5 ',
              content: queryData.guide_content,
              pc_content: queryData.guide_pc_content,
            });
            actionBtnGroup.value[3].state = reqData;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
          // router.push({ name: 'LiveGuide', params: { studioId: route.params.studioId } });
        },
      ],
      // 互动打赏
      [
        { routerName: 'MutuallyEngage' },
        async (key: unknown) => {
          try {
            const reqData = key ? 0 : 1;
            const res = await http.liveInteractive.getStudioReward({ params: { studio_id: route.params.studioId } });
            const queryData = res.data.data.body;
            await http.liveInteractive.getUpdateOrInsertReward({
              studio_id: route.params.studioId,
              status: reqData,
              content: JSON.stringify(queryData.reward_list),
              image_size: queryData.image_size,
              sec_limit_gift: queryData.sec_limit_gift,
            });
            actionBtnGroup.value[4].state = reqData;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      // 点赞设置
      [
        { routerName: 'LikeSetting' },
        async (key: unknown) => {
          try {
            const reqData = key ? 0 : 1;
            const res = await http.likesetting.getFabulousData({ params: { studio_id: route.params.studioId } });
            const queryData = res.data.data;
            await http.likesetting.setFabulousData({
              studio_id: route.params.studioId,
              status: reqData,
              false_switch: queryData.false_switch,
              false_min: queryData.false_min,
              false_max: queryData.false_max,
              false_final: queryData.false_final,
              index: queryData.index,
              real_num: queryData.real_num,
              image_list: queryData.image_list,
            });
            actionBtnGroup.value[5].state = reqData;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      // 弹幕
      [
        { routerName: 'Console' },
        async (key: unknown) => {
          try {
            const reqData = key ? 0 : 1;
            await http.liveChat.barrageSwitch({
              studio_id: route.params.studioId,
              status: reqData,
            });
            actionBtnGroup.value[6].state = reqData;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      [
        { routerName: 'EventReservation' },
        // 活动预约
        async (key: unknown) => {
          try {
            if (key === -1) {
              router.push({ name: 'EventReservation', params: { studioId: route.params.studioId } });
            } else {
              const reqData = key ? 0 : 1;
              await http.liveeventreservation.reserve_msg_save({
                studio_id: route.params.studioId,
                status: reqData,
              });
              actionBtnGroup.value[7].state = reqData;
            }
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      [
        { routerName: 'SharingSetting' },
        // 公众号分享设置
        async (key: unknown) => {
          try {
            const reqData = key;
            const res = await http.livebroadcast.getShareContent({ params: { studio_id: route.params.studioId } });
            const queryData = res.data.data;
            await http.livebroadcast.getUpdWeChatShare({
              studio_id: route.params.studioId,
              status: reqData,
              content: queryData.content,
              logo_id: queryData.logo_id,
            });
            actionBtnGroup.value[8].state = reqData ? 0 : 1;
          } catch (err: any) {
            message.error(err?.data?.error);
          }
        },
      ],
      [
        { routerName: 'Authorization' },
        // 授权设置
        async (key: unknown) => {
          try {
            if (key === -1) {
              router.push({ name: 'Authorization', params: { studioId: route.params.studioId } });
            } else {
              const reqData = key;
              store.commit('authorizView/updateSwitch', Boolean(!reqData));
              const res = await store.dispatch('authorizView/getLiveView', route.params.studioId);
              await store.dispatch('authorizView/saveAothorize');
              await store.dispatch('authorizView/customAuthorization');
              actionBtnGroup.value[9].state = reqData ? 0 : 1;
            }
          } catch (err: any) {
            message.error(err?.data?.error);
          }
          // router.push({ name: 'Authorization', params: { studioId: route.params.studioId } });
        },
      ],
    ]);

    const onChangeQuickSet = function (this: any, routerName: string, state: number) {
      // 根据条件使用find查询
      let action = [...convenientSettingAction].find(([key]) => key.routerName === routerName);
      action && action[1].call(this, state);
    };

    // 资质备案
    const showAttestation = ref(false);
    const openShowAttestation = () => {
      getConfigInfo();
    };

    const qualifications = ref<ObjType>({
      status: false,
      list: [{ useName: '', useType: '', fileList: [], imgageUrl: '', loading: false, imgageName: '', imgageSize: 0 }],
    });
    // 获取数据
    const getConfigInfo = () => {
      http.live.getConfigInfo({ params: { studio_id: route.params.studioId as string } }).then((res) => {
        showAttestation.value = true;
        qualifications.value.status = Boolean(res.data.data.status);
        if (res.data.data.filing_info.length > 0) {
          qualifications.value.list = res.data.data.filing_info.map((item: ListObj) => new ListClass(item));
        }
      });
    };
    const toVerify = () => {
      return new Promise((res) => {
        let next = true;
        qualifications.value.list.some((e) => {
          if (!e.useName || !e.useType || !e.imgageUrl) {
            message.warning('必填项不能为空！');
            next = false;
            return;
          }
        });
        res(next);
      });
    };
    // 保存数据
    const saveQualificat = useDebounce(async () => {
      let verify = await toVerify();
      if (!verify) {
        return;
      } else {
        const formData = qualifications.value.list.map((item) => new ListObj(item));
        const data = {
          studio_id: route.params.studioId as string,
          status: qualifications.value.status,
          filing_info: JSON.stringify(formData),
        };
        http.live
          .setConfigInfo(data)
          .then((res) => {
            message.success(res.data.message);
            liveStore.liveInfo.filing_status = 2;
            showAttestation.value = false;
          })
          .catch((err) => message.error(err.data.error));
      }
    });

    return {
      route,
      Copylist,
      liveItemBg,
      Pulltime,
      rtmps,
      mus,
      flvs,
      Cpoyaddress,
      sourceload,
      clipboardText,
      downs,
      liveData, //总览数据
      isShowliveData, // 是否显示直播数据
      isShowUrl,
      onChangeRoute,
      filterAcitionGroup,
      onChangeQuickSet,
      showVisible,
      showModal,
      showEmbed,
      openEmbed,
      showAttestation,
      openShowAttestation,
      saveQualificat,
      qualifications,
      liveInfo,
    };
  },
});
