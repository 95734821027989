import http from '../../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 创建分类
  create_sort(data: unknown) {
    return http.post('/api/v2/portal/create_sort', data);
  }
  // 获取分类列表
  get_sort_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/get_sort_list', data);
  };
  // 分类重命名
  edit_sort(data: unknown) {
    return http.post('/api/v2/portal/edit_sort', data);
  }
  // 删除分类
  del_portal_sort = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/del_portal_sort', data);
  };
  // 移至分类
  move_sort = (data: unknown) => {
    return http.post('/api/v2/portal/move_sort', data);
  };

  /**
   * 门户直播分类
   */
  // 获取直播分类直播列表
  get_portal_live_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/get_portal_live_list', data);
  };
  // 添加直播列表
  add_portal_live(data: unknown) {
    return http.post('/api/v2/portal/add_portal_live', data);
  }
  // 删除直播
  del_portal_live = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/portal/del_portal_live', data);
  };
  // 修改直播名称
  set_studio_title(data: unknown) {
    return http.post('/api/v2/portal/set_studio_title', data);
  }

  // 文章
  // 获取文章列表
  getPortalArticleList(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_portal_article_list', data);
  }
  // 删除门户文章
  getDelPortalArticle(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/del_portal_article', data);
  }
  // 创建文章
  getCreatePortalArticle(data: unknown) {
    return http.post('/api/v2/portal/create_portal_article', data);
  }
  // 获取文章信息
  getPortalArticleInfo(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_portal_article_info', data);
  }
  // 编辑文章内容
  getEditPortalArticle(data: unknown) {
    return http.post('/api/v2/portal/edit_portal_article', data);
  }
  // 获取分类列表
  getSortList(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_sort_list', data);
  }

  //图片
  // 获取文件列表
  getFileList(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_file_list', data);
  }
  // 编辑文件名称
  getEditFileName(data: unknown) {
    return http.post('/api/v2/portal/edit_file_name', data);
  }
  // 删除门户文件
  getDelPortalFile(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/del_portal_file', data);
  }
  // 获取单个文件信息
  getFileInfo(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_file_info', data);
  }

  //门户
  // 门户列表
  getPortalList(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_portal_list', data);
  }
  // 删除门户
  getDelPortal(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/del_portal', data);
  }
  // 设置门户显示开关
  getSetPortalShow(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/set_portal_show', data);
  }
  // 创建门户
  getCreatePortal(data: unknown) {
    return http.post('/api/v2/portal/create_portal', data);
  }
  // 获取门户
  getPortalInfo(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_portal_info', data);
  }
  // 编辑门户
  editPortal(data: unknown) {
    return http.post('/api/v2/portal/edit_portal', data);
  }
  // 获取门户推广信息
  getPortalExtensionInfo(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_portal_extension_info', data);
  }
  // 设置门户推广
  getsetPortalShare(data: unknown) {
    return http.post('/api/v2/portal/set_portal_share', data);
  }
  // 获取推广二维码
  getPromotionQrCode(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_promotion_qr_code', data);
  }
  // 获取站内链接列表
  getSitePageLinks(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_site_page_links', data);
  }
  // 门户回收站列表
  getRecycleBin(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/recycle_bin', data);
  }
  // 还原门户
  getReductionPortal(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/reduction_portal', data);
  }
  // 彻底删除门户
  getRemoveCompletely(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/remove_completely', data);
  }
  // 全部恢复
  getRestoreAll(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/restore_all', data);
  }

  /**
   * 文档分类
   */
  // 获取上传文件token
  get_upload_token(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/get_upload_token', data);
  }
  // 添加门户文件
  add_portal_file(data: unknown) {
    return http.post('/api/v2/portal/add_portal_file', data);
  }
  // 获取文件占比
  getPortalFileResources(data: AxiosRequestConfig) {
    return http.get('/api/v2/portal/portal_file_resources', data);
  }
  // 上传图片
  uploadImages(data: unknown) {
    return http.post('/api/v1/common/upload_images', data);
  }
  // 上传文档
  uploadPortalFile(data: unknown) {
    return http.post('/api/v2/portal/upload_portal_file', data);
  }
}
export default new Api();
