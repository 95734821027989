
import CDelModal from '@/components/CDelModal.vue';
import CUpload from '@/components/CUpload.vue';
import { defineComponent, ref, unref, computed, reactive } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import { Modal, Pagination, Table } from 'ant-design-vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import http from '@/api/home';
import { message, Input } from 'ant-design-vue';
import { getToken } from '@/plugins/cookie';
type Key = ColumnProps['key'];
interface DataType {
  name: string;
  time: string;
  id: string;
  mobile: string;
  note: string;
  pass: string;
}

// S-上传照片
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
// E-上传照片
export default defineComponent({
  components: {
    CDelModal,
    CUpload,
    aModal: Modal,
    aTable: Table,
    aPagination: Pagination,
    aInputSearch: Input.Search,
    SearchOutlined,
  },
  setup() {
    let token = getToken();

    // S-创建子账号
    const visible = ref<boolean>(false);
    const visibletal = ref<boolean>(false);
    const showLoading = ref<boolean>(false);
    const handleOk = () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        visible.value = false;
      }, 2000);
    };
    const showModal = () => {
      visible.value = true;
      imageUrlel.value = '';
      provinceId.value = '';
      account.value = avatar.value = caption.value = pawd.value = '';
    };
    const handleCancel = () => {
      visible.value = false;
    };

    // S-删除弹框
    const modal2Visible = ref<boolean>(false);
    const openDelModat = () => {
      modal2Visible.value = !modal2Visible.value;
    };
    // E-删除弹框

    const selectedRowKeys = ref<Key[]>([]);
    selectedRowKeys.value = [];
    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys;
      checked_all.value = selectedRowKeys.value.length == datafol.value.length ? true : false;
    };
    const checked_all = ref(false);
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
      };
    });

    //取消按钮
    const modelCanceto = () => {
      springframe.value = false;
    };

    // 全选
    const current = ref(0);
    const setSelectAll = () => {
      let data: any = [];
      datafol.value.map((res) => {
        data.push(res.id);
        // console.log(res.id);
      });
      if (checked_all.value) {
        onSelectChange([...data]);
      } else {
        onSelectChange([]);
      }
    };
    // S-上传照片
    const fileList = ref([]);
    const loading = ref<boolean>(false);
    const imageUrl = ref<string>('');
    const imageUrlel = ref<string>('');
    const provinceId = ref();
    const handleChange = (info: FileInfo) => {
      if (info.file?.response?.code === 200) {
        imageUrl.value = info.file?.response?.data.url;
        compile.value.image_avatar_id = info.file?.response?.data.id;
      }
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      } else loading.value = false;
      if (info.file.status === 'error') {
        loading.value = false;
        message.error('上传失败');
      }
    };
    const handleChangetl = (info: FileInfo) => {
      if (info.file?.response?.code === 200) {
        imageUrlel.value = info.file?.response?.data.url;
        provinceId.value = info.file?.response?.data.id;
      }
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      } else loading.value = false;
      if (info.file.status === 'error') {
        loading.value = false;
        message.error('上传失败');
      }
    };
    const imageDel = (info: any, keywords: number) => {
      if (keywords === 1) {
        imageUrlel.value = '';
        provinceId.value = '';
      } else if (keywords === 2) {
        imageUrl.value = '';
        compile.value.image_avatar_id = '';
      }
    };
    // E-上传照片

    const columns = [
      {
        title: '头像',
        dataIndex: 'avatar',
        align: 'center',
        width: '10%',
        slots: {
          customRender: 'avatar',
        },
      },
      {
        title: '账号',
        dataIndex: 'mobile',
        align: 'center',
        width: '15%',
      },
      {
        title: '创建时间',
        align: 'center',
        dataIndex: 'created_at',
        width: '20%',
      },
      {
        title: '姓名',
        dataIndex: 'name',
        align: 'center',
        width: '15%',
        slots: {
          customRender: 'name',
        },
      },
      {
        title: '备注',
        align: 'center',
        width: '25%',
        dataIndex: 'note',
      },
      {
        title: '操作',
        dataIndex: 'img',
        align: 'center',
        width: '15%',
        slots: {
          customRender: 'img',
        },
      },
    ];

    const springframe = ref<boolean>(false);
    const SuperFast = () => {
      springframe.value = false;
      //  if(springframe.value)
    };

    // 编辑弹框
    const compile = ref({
      name: '',
      note: '',
      mobile: '',
      image_avatar_id: '',
      avatar: '',
      id: '',
      pass: '',
    });
    const condition = (res: any) => {
      compile.value = res;
      imageUrl.value = compile.value.avatar;
      springframe.value = true;
    };

    //获取子账号管理
    const datafol = ref<DataType[]>([]);
    // const managementData = () => {
    //   const data = {
    //     studio_id: 10001458,
    //   };
    //   http.getMemberChildList({ params: data }).then((res) => {
    //     const resData = res.data.data;
    //     datafol.value = resData.data;
    //   });
    // };
    // managementData();
    let obj = ref({
      everySelect: '',
    });
    // 分页
    const pageConfigure = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    // 子账号列表
    const selectOption = () => {
      // compile.value = res;
      let data = {
        keywords: obj.value.everySelect,
        type: '',
        page: pageConfigure.current,
        page_num: pageConfigure.pageSize,
      };
      showLoading.value = true;
      http
        .getadminList({ params: data })
        .then((res) => {
          pageConfigure.current = res.data.data.current_page;
          pageConfigure.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          const resData = res.data.data;
          datafol.value = resData.data;
          showLoading.value = false;
        })
        .catch(() => {
          showLoading.value = false;
        });
      obj.value.everySelect = '';
    };
    selectOption();

    // 确定更新账号
    const modelCancel = () => {
      let data = {
        id: compile.value.id,
        name: compile.value.name,
        note: compile.value.note,
        mobile: compile.value.mobile,
        password: compile.value.pass,
        image_avatar_id: compile.value.image_avatar_id,
      };
      http
        .getupdate(data)
        .then((res) => {
          res.data.code == 200 ? (springframe.value = false) : '';
          selectOption();
        })
        .catch((err) => message.error(err.data.error));
    };

    // 子账号删除
    const openDelModal = () => {
      modal2Visible.value = true;
    };

    // 删除确定按钮
    const removeLiveData = () => {
      let params = {
        ids: selectedRowKeys.value.join(','),
      };
      // return
      http
        .getdelete(params)
        .then((res) => {
          selectOption();
          message.success(res.data.message);
          modal2Visible.value = false;
          checked_all.value = false;
          selectedRowKeys.value = [];
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };

    // 客户管理员创建下级账号
    const account = ref();
    const avatar = ref();
    const caption = ref();
    const pawd = ref();
    const handleCancellt = () => {
      let data = {
        image_avatar_id: provinceId.value,
        mobile: account.value,
        name: avatar.value,
        note: caption.value,
        password: pawd.value,
        type: 0,
      };
      http
        .createSubAccount(data)
        .then((res) => {
          res.data.code == 200 ? (visible.value = false) : '';
          selectOption();
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };
    // 分页器点击事件
    const showSize = () => {
      selectOption();
    };
    return {
      showLoading,
      loading,
      visible,
      showModal,
      visibletal,
      handleOk,
      handleCancel,
      modelCancel,
      cacherDate: [],
      search: '',
      choice: ref(false),
      choice_all: ref(false),
      datafol,
      columns,
      rowSelection,
      selectedRowKeys,
      modal2Visible,
      current,
      checked_all,
      setSelectAll,
      value: ref<string[]>([]),
      fileList,
      imageUrl,
      imageUrlel,
      handleChange,
      handleChangetl,
      openDelModat,
      openDelModal,
      SuperFast,
      springframe,
      condition,
      compile,
      modelCanceto,
      selectOption,
      handleCancellt,
      removeLiveData,
      account,
      avatar,
      caption,
      pawd,
      token,
      provinceId,
      pageConfigure,
      showSize,
      obj,
      imageDel,
    };
  },
});
