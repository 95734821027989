import { ActionTree, MutationTree, StoreOptions } from 'vuex';
import axios from '@/api/media';
import { BaseSearchFace } from '@/types/base';
import { cacheLive, SearchCacheLive } from './types';

class State {
  searchForm: BaseSearchFace = {};
  liveList: cacheLive[] = [];
}

class Live implements StoreOptions<State> {
  namespaced = true;
  state = new State();

  mutations: MutationTree<State> = {
    // search and
    updateSearchForm(state, data) {
      state.searchForm = data;
    },
    // list
    updateLiveList(state, data: cacheLive[]) {
      state.liveList = data.map((v) => v);
    },
  };

  actions: ActionTree<State, unknown> = {
    // 获取直播列表
    getRecordVideoList({ commit }, data) {
      const searchData = new SearchCacheLive(data);
      searchData.setDate(data.liveDate ? data.liveDate : '');
      return new Promise((resolve) => {
        axios
          .getRecordVideoList({ params: searchData })
          .then((res) => {
            commit('updateLiveList', res.data.data.data || []);
            resolve(res.data.data);
          })
          .catch((err) => {
            resolve(err.data);
          });
      });
    },
  };
}
export default new Live();
