export function numberToCurrency(value: string | number): string {
  let num = 0;
  if (typeof value !== 'number') {
    num = parseFloat(value);
  } else {
    num = value;
  }

  if (!num) return '0.00';
  // 将数值截取，保留两位小数
  num = parseFloat(num.toFixed(2));
  // 获取整数部分
  const intPart = Math.trunc(num);
  //-0处理
  const negative = String(value).search('-') !== -1 && intPart == 0 ? '-' : '';
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  // 预定义小数部分
  let floatPart = '.00';
  // 将数值截取为小数部分和整数部分
  const valueArray = num.toString().split('.');
  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString(); // 取得小数部分
    return `${negative}${intPartFormat}.${floatPart}`;
  }
  return negative + intPartFormat + floatPart;
}
