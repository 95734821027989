
import { computed, defineComponent, inject, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { message, Pagination, Modal, Row } from 'ant-design-vue';
import { emptyData } from '@/assets';
import AccountInfo from './components/AccountInfo.vue';
import LiveChars from './components/LiveChar.vue';
import ConcurrentChars from './components/ConcurrentChar.vue';
import watchChars from './components/watchChar.vue';
import MediaChars from './components/MediaChar.vue';
import LiveItemSquare from '@/components/liveItem/LiveItemSquare.vue';
import LiveItemBar from '@/components/liveItem/LiveItemBar.vue';
import AppLink from '@/components/AppLink.vue';
import http from '@/api/home';
import { AccountInfoClass, ClientSize, Concurrent, HomeInfo, LiveChartType, StoreType, Watch } from './types';
import CLoading from '@/components/CLoading.vue';
import { getCertificationStatus, getCertificationView } from '@/plugins/cookie';
import CModal from '@/components/CModal.vue';
import { useRouter } from 'vue-router';
import { useHomeStore } from '@/pinia/home';
import OfferSider from './components/OfferSide.vue';
// import GuidePage from '@/views/control/GuidePage.vue'; // 连麦上线弹框引导图
export default defineComponent({
  components: {
    AccountInfo,
    ConcurrentChars,
    watchChars,
    LiveChars,
    MediaChars,
    LiveItemSquare,
    LiveItemBar,
    [Pagination.name]: Pagination,
    AppLink,
    CLoading,
    CModal,
    OfferSider,
    aRow: Row,
    // GuidePage,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    getCertificationStatus();
    const isVisible = ref<boolean>(false);
    const homeStore = useHomeStore();

    // 去认证
    const toCertify = () => {
      if (getCertificationView() == 0 && getCertificationStatus() == 0) {
        message.error('您的上级账号未认证，无法新建直播！');
      } else if (getCertificationStatus() == 0 && homeStore.role_id !== 1237 && homeStore.role_id !== 100) {
        isVisible.value = true;
        return;
      } else if (getCertificationStatus() == 2 || homeStore.role_id === 1237 || homeStore.role_id === 100) {
        // openShowNorm();
        router.push({ path: '/newlive' });
      } else {
        router.push({ path: '/personal/set/certification' });
      }
      // getCertificationStatus() != 2 ? (isVisible.value = true) : router.push({ path: '/newlive' });
    };
    const isopenDelModal = () => {
      isVisible.value = !isVisible.value;
    };
    const cutoverView = ref(true); // 视图切换按钮
    const getCutoverIcon = computed(() => {
      return cutoverView.value ? 'list' : 'block';
    });
    const getCutoverName = computed(() => {
      return cutoverView.value ? '列表视图' : '缩略视图';
    });
    const switchView = () => {
      cutoverView.value = !cutoverView.value;
    };
    const ninePMTRole = computed(() => homeStore.funcData?.ninetyPromotion);
    const OfferSideRole = computed(() => homeStore.funcData?.offerSide);
    const ResourcePurchRole = computed(() => homeStore.funcData?.ResourcePurchase);

    const homeInfo = reactive<HomeInfo>({
      liveInfo: {
        all_number: 0,
        living: 0,
        wait_live: 0,
      },
      userInfo: {
        admin_id: 0,
        role_id: 0,
        end_at: '--:--:--',
        version: '---',
        money: '0.00',
        level_up: 0,
        certification_status: -1,
        certification_type: 0,
        certification_view: 0,
      },
      resource: {
        config_admin: {
          concurrent_num: 0,
          store_size: 0,
          toll_mode: 0,
        },
        end_at: '',
        role_id: 0,
        version: '',
      },
    });

    /**
     * 豆腐块获取数量计算
     * 三级菜单使用自定义宽度 且 最小宽对应当前组件样式宽度
     */
    const baseSize = inject('ClientSize') as ClientSize;
    const getItemCount = () => {
      const wrapWidth = baseSize.width - baseSize.menuSize - 71;
      let itemWidth = 0;
      let baseNum = 3;
      const gap = 16; // 间距
      let lastItemWidth = 0;
      let lastBaseNum = 0;
      do {
        lastItemWidth = itemWidth;
        lastBaseNum = baseNum;
        const countNum = (((wrapWidth - (baseNum - 1) * gap) / baseNum) * 100).toString();
        itemWidth = parseFloat((parseInt(countNum) / 100).toFixed(2));
        baseNum = baseNum + 1;
      } while (itemWidth >= baseSize.minWidth);
      const rowCount = lastBaseNum - 1;
      const itemHeight = parseFloat((((lastItemWidth - 2) * 5625) / 10000 + 80).toFixed(2));
      let columnCount = Math.ceil((baseSize.height - 200) / itemHeight);
      let resCount = rowCount * columnCount;
      while (resCount < 9) {
        columnCount += 1;
        resCount = rowCount * columnCount;
      }
      return resCount;
    };
    const searchCount = getItemCount();

    const resource = ref({});
    const showLoading = ref(false);
    const baseInfoRef = ref();
    const tollMode = ref<number | null>(null);
    // const getHomeHeight = ref(false);
    const getLiveList = () => {
      showLoading.value = false;
      http.getHomeDataList().then((res) => {
        const resData = res.data.data;
        homeInfo.liveInfo = resData.live_info;
        const accountInfo = new AccountInfoClass(resData.resource);
        accountInfo.setMoney(resData.money.total_money);
        homeInfo.userInfo = accountInfo;
        resource.value = resData.resource;
        setMediaChars(resData.resource.resource_balance.store);
        resData.resource.config_admin.toll_mode == 0
          ? setConcurrentCharData(resData.resource.resource_balance.concurrent)
          : resData.resource.config_admin.toll_mode == 1
          ? setWatchData(resData.resource.resource_balance.watch)
          : setWatchData(resData.resource.resource_balance.data);
        tollMode.value = resData.resource.config_admin.toll_mode;
        // getHomeHeight.value = true;
      });
      http
        .getRecentLive({
          params: {
            page_num: searchCount,
          },
        })
        .then((res) => {
          store.commit('live/updateLiveList', res.data.data.studio_list);
          const { title, watch_number } = res.data.data.histogram_arr;
          getLiveChars(title, watch_number);
          showLoading.value = true;
          baseInfoRef.value.getuserGetInformation();
        });
    };

    getLiveList();
    // 获取直播信息图表
    const liveCharData = reactive<LiveChartType[]>([]);
    const getLiveChars = (titleArr: Array<any> = [], watchArr: Array<any> = []) => {
      if (titleArr.length > 0 && watchArr.length > 0) {
        titleArr.forEach((e, i) => {
          if (i <= 10) {
            liveCharData[i] = { type: e, value: watchArr[i] };
          }
        });
      }
    };
    // 获取媒体库图表
    const mediaCharData = reactive<StoreType>({
      vid: 0,
      doc: 0,
      pic: 0,
      used: 0,
      unused: 0,
      total: 0,
      status: 0,
    });
    const setMediaChars = (v: StoreType) => {
      mediaCharData.vid = v.vid;
      mediaCharData.doc = v.doc;
      mediaCharData.pic = v.pic;
      mediaCharData.used = v.used;
      mediaCharData.unused = v.unused;
      mediaCharData.total = v.total;
      mediaCharData.status = v.status;
    };
    const calssArr = ref(['normal', 'remind', 'warning']);
    const concurrentCharData = reactive<Concurrent>({
      used_count: 0,
      total: 0,
      unused: 0,
      used_en: 0,
      used: 0,
      status: 0,
    });
    const setConcurrentCharData = (data: Concurrent) => {
      concurrentCharData.used_count = data.used_count;
      concurrentCharData.total = data.total;
      concurrentCharData.unused = data.unused;
      concurrentCharData.used_en = data.used_en;
      concurrentCharData.used = data.used;
      concurrentCharData.status = data.status;
    };

    const watchData = reactive<Watch>({
      total: 0,
      unused: 0,
      used: 0,
      unused_true: 0,
      status: 0,
    });
    const setWatchData = (data: Watch) => {
      watchData.total = data.total;
      watchData.unused = data.unused;
      watchData.unused_true = data.unused_true;
      watchData.used = data.used;
      watchData.status = data.status;
    };

    const copyShowLoading = (data: boolean) => {
      showLoading.value = data;
    };
    // 右侧展开收起按钮
    const showInfo = ref<number>(1);
    const unfoldClick = () => {
      if (showInfo.value == 1) {
        showInfo.value = 0;
      } else {
        showInfo.value = 1;
      }
    };
    const onCloseLotter = () => {
      showInfo.value = 0;
    };
    // 子页面传值
    const getShowStatus = (data: any) => {
      showInfo.value = data;
    };

    // let dataList = document.getElementById('hotId')
    // console.log(dataList,67676)

    // const dataList = () => {

    // };

    //获取近期直播div高度
    // const listRef = ref();
    // const newHeight = ref<number>();
    // watch(
    //   () => getHomeHeight.value,
    //   () => {
    //     if (getHomeHeight.value) {
    //       newHeight.value = listRef.value.offsetHeight;
    //     }
    //   }
    // );

    return {
      cutoverView,
      ninePMTRole,
      OfferSideRole,
      ResourcePurchRole,
      getCutoverIcon,
      getCutoverName,
      switchView,
      emptyData,
      homeInfo,
      liveList: computed(() => store.state.live.liveList),
      liveCharData,
      mediaCharData,
      concurrentCharData,
      showLoading,
      calssArr,
      toCertify,
      isVisible,
      isopenDelModal,
      getCertificationStatus,
      watchData,
      tollMode,
      copyShowLoading,
      homeStore,
      unfoldClick,
      onCloseLotter,
      getShowStatus,
      showInfo,
      baseInfoRef,
      // listRef,
      // newHeight,
    };
  },
});
