
import * as echarts from 'echarts';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const china = require('@/assets/json/china.json');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const world = require('@/assets/json/world.json');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const chinaCity = require('@/assets/json/chinaCity.json');
import { defineComponent, onMounted, reactive, ref, createVNode, computed, watch } from 'vue';
import { Tabs, Table, Pagination, Modal, message, Progress, Select, DatePicker, Tree, notification } from 'ant-design-vue';
import http from '@/api/direct';
import axios from '@/api';
import type { RefererCharts } from './broadCast';
import { getCurrentInstance } from 'vue';
import { getToken, getHelperStatus } from '@/plugins/cookie';
import { ExclamationCircleOutlined, DownloadOutlined, SwapOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { RangePickerIcon, emptyDataSec } from '@/assets';
import { dateFormats } from '@/utils/format/date';
import moment from 'moment';
import { useHomeStore } from '@/pinia/home';
import { onExport } from '@/components/CExport'; //导出
import { exportPDF } from '@/utils/exportPdf';
import https from '@/api/exportList';
import { useDebounce } from '@/hooks/useDebounce'; //防抖

//地图
const helper = getHelperStatus(); //直播助手标识

//S-表格
const columns = [
  {
    title: '用户名',
    dataIndex: 'name',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '用户ID',
    dataIndex: 'uid',
    key: '1',
    align: 'center',
  },
  {
    title: '所在国家',
    dataIndex: 'country',
    key: '2',
    align: 'center',
  },
  {
    title: '位置',
    dataIndex: 'addr',
    key: '3',
    align: 'center',
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: '4',
    ellipsis: true,
    align: 'center',
  },
  {
    title: '在线时长',
    dataIndex: 'visit_duration',
    key: '5',
    align: 'center',
  },
  {
    title: '观看时长',
    dataIndex: 'watch_duration',
    key: '6',
    align: 'center',
  },
  {
    title: '首次访问时间',
    dataIndex: 'visited_at',
    key: '7',
    align: 'center',
  },
  {
    title: '最后在线时间',
    dataIndex: 'leaved_at',
    key: '8',
    align: 'center',
  },
  {
    title: '来源访问',
    dataIndex: 'referer',
    key: '9',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '设备访问',
    dataIndex: 'device',
    align: 'center',
  },
];

interface DataItem {
  key: number;
  name: string;
  age: number;
  uid: string;
  address: string;
  ip: string;
  visit_duration: string;
  watch_duration: string;
  visited_at: string;
  leaved_at: string;
  referer: string;
  device: string;
}

const dataest = ref<DataItem[]>([]);

const { RangePicker } = DatePicker;
//E-表格

//S-表格--2
const columnsl = [
  {
    title: '用户名',
    dataIndex: 'name',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '用户ID',
    dataIndex: 'uid',
    key: '1',
    align: 'center',
  },
  {
    title: '位置',
    dataIndex: 'address',
    key: '2',
    align: 'center',
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: '3',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '在线总时长',
    dataIndex: 'visit_duration',
    key: '4',
    align: 'center',
  },
  {
    title: '观看总时长',
    dataIndex: 'watch_duration',
    key: '5',
    align: 'center',
  },
  {
    title: '访问次数',
    dataIndex: 'visit_times',
    key: '6',
    align: 'center',
  },
  {
    title: '首次访问时间',
    dataIndex: 'visited_at',
    key: '7',
    align: 'center',
  },
  {
    title: '最后在线时间',
    dataIndex: 'leaved_at',
    key: '8',
    align: 'center',
  },
  {
    title: '来源访问',
    dataIndex: 'referrer',
    key: '9',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '设备访问',
    dataIndex: 'device',
    align: 'center',
  },
];
// 受邀人表格
const shareColumns = [
  {
    title: '昵称',
    dataIndex: 'nickname',
    align: 'center',
    ellipsis: true,
  },
  {
    title: 'ID',
    dataIndex: 'uid',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '头像',
    dataIndex: 'avatar_url',
    align: 'center',
    slots: { customRender: 'img' },
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
    ellipsis: true,
  },
];

const treeData = [
  {
    title: '直播数据',
    key: '0',
    children: [
      {
        title: '资源概况',
        key: '0-0',
      },
      {
        title: '数据统计',
        key: '0-1',
      },
    ],
  },
  {
    title: '营销互动',
    key: '1',
    children: [
      {
        title: '互动打赏',
        key: '1-0',
      },
      {
        title: '红包雨',
        key: '1-1',
      },
    ],
  },
  {
    title: '授权设置',
    key: '2',
    children: [
      {
        title: '付费观看',
        key: '2-0',
      },
      {
        title: '手机白名单',
        key: '2-1',
      },
    ],
  },
];

interface DataIteml {
  key: number;
  name: string;
  uid: string;
  address: string;
  ip: string;
  visit_duration: string;
  watch_duration: string;
  visited_at: string;
  leaved_at: string;
  referer: string;
  device: string;
  visit_times: number;
}

// 流水汇总
const char = ref<DataIteml[]>([]);
//E-表格--2
export default defineComponent({
  components: {
    aTabs: Tabs,
    aTabPane: Tabs.TabPane,
    aTable: Table,
    aPagination: Pagination,
    aModal: Modal,
    aProgress: Progress,
    aSelect: Select,
    aRangePicker: RangePicker,
    aTree: Tree,
    DownloadOutlined,
    SwapOutlined,
  },
  emits: ['click:cutVersion'],
  setup(props, context) {
    const route = useRoute();
    const liveInfo = route.params.studioId; //438-439是获取studio_id的方法
    const showLoading = ref<boolean>(false);
    const listKey = ref(1);
    const homeStore = useHomeStore();
    const showCutVersion = computed(() => homeStore.funcData?.showCutVersion); //是否显示切换按钮
    const cutBack = () => {
      context.emit('click:cutVersion');
    };
    // 分享榜
    const Sharelisted = reactive([
      {
        name: '分享榜 ',
        ranking: '排名 ',
        nickname: '邀请人昵称',
        number: '邀请数量',
        proportion: '占比',
        operation: '操作',
      },
    ]);
    // 贡献榜
    const Sharelist = reactive([
      {
        name: '贡献榜 ',
        ranking: '排名 ',
        audience: '观众名',
        totalshares: '贡献金额',
        proportion: '占比',
      },
    ]);

    interface service {
      name: string;
      value: string;
      user_id: number;
      user_name: string;
      price: string;
      nickname: string;
      like_num: number;
      recommended_num: number;
      proportion: number;
      referral_code: string;
    }
    // 设备占比
    const share = ref<service[]>([]);
    // 贡献榜
    const devote = ref<service[]>([]);
    // 分享榜
    const listDevote = ref<service[]>([]);

    const payment = ref([
      {
        label: '  汇总  ',
        value: 0,
      },
      {
        label: '  流水  ',
        value: 1,
      },
    ]);

    // s地图图表
    const Viewingvolume = reactive([
      {
        name: '观看量排名 ',
        ranking: '排名 ',
        country_title: '地域',
        province_title: '省区',
        city_title: '城市',
        totalshares: '观看量',
        proportion: '占比',
      },
    ]);

    const mapType = ref(1);

    const clickMap = (type: number) => {
      mapType.value = type;
      if (type === 1) {
        echarts.init(document.getElementById(map.value) as HTMLElement).dispose();
        share.value = sort_view_rank.value;
        getMap();
      } else if (type === 2) {
        echarts.init(document.getElementById(map.value) as HTMLElement).dispose();
        share.value = sort_city_rank.value;
        getCitydMap();
      } else {
        echarts.init(document.getElementById(map.value) as HTMLElement).dispose();
        share.value = sort_country_rank.value;
        getWorldMap();
      }
    };

    const map = ref(`map${Date.now() + Math.random()}`);
    let myMapChart: any;
    const getMap = () => {
      echarts.registerMap('china', china);
      myMapChart = echarts.init(document.getElementById(map.value) as HTMLElement);
      myMapChart.setOption({
        tooltip: {
          show: true,
          formatter: function (params: { value: string; name: string }) {
            if (params.value) {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + params.value + '个&nbsp;&nbsp;';
            } else {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;0个&nbsp;&nbsp;';
            }
          },
          x: '550',
          y: '50',
          feature: {
            saveAsImage: {},
          },
        },
        visualMap: {
          min: share.value[share.value.length - 1] && share.value.length > 1 ? share.value[share.value.length - 1].value : 0,
          max: share.value[0] ? share.value[0].value : 0,
          calculable: true,
          itemWidth: 21,
          itemHeight: 130,
          left: '0',
          bottom: '40',
          text: ['高', '低'],
          textStyle: {
            color: '#999999',
            fontSize: 13,
          },
          inRange: {
            color: ['#C7F3F5', '#00ccb0'],
          },
          outOfRange: {
            show: false,
          },
        },
        geo: {
          map: 'china',
          show: false,
          emphasis: {
            label: {
              show: false,
            },
          },
          itemStyle: {
            show: false,
          },
        },

        series: [
          {
            type: 'map',
            map: 'china',
            aspectScale: 0.75,
            showLegendSymbol: false, // 存在legend时显示
            zoom: 1,
            roam: true, //放大缩小
            layoutCenter: ['50%', '50%'],
            layoutSize: '100%',
            label: {
              show: true,
              formatter: function (para: any) {
                if (para.value != 0 && para.value) {
                  return '{name|' + para.name + '}';
                } else {
                  return '';
                }
              },
              rich: {
                cnNum: {
                  fontSize: 11,
                  color: '#E9E9E9',
                  align: 'center',
                  show: true,
                },
                name: {
                  fontSize: 11,
                  color: '#ff4d4f',
                  align: 'center',
                  lineHeight: 60,
                  show: true,
                },
              },
              color: '#000',
            },
            itemStyle: {
              areaColor: '#E9E9E9', //地图背景颜色
              borderColor: '#FFF',
              borderWidth: 1,
              //  show: true
            },
            emphasis: {
              itemStyle: {
                //对应的鼠标悬浮效果
                areaColor: '#00ccb0',
                show: true,
              },
              label: {
                //对应的鼠标悬浮效果
                show: true,
                textstyle: {
                  color: '#00ccb0',
                },
              },
            },

            data: share.value,
          },
        ],
      });
    };

    const getWorldMap = () => {
      echarts.registerMap('china', world);
      myMapChart = echarts.init(document.getElementById(map.value) as HTMLElement);
      myMapChart.setOption({
        tooltip: {
          show: true,
          formatter: function (params: { value: string; name: string }) {
            if (params.value) {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + params.value + '个&nbsp;&nbsp;';
            } else {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;0个&nbsp;&nbsp;';
            }
          },
          x: '550',
          y: '50',
          feature: {
            saveAsImage: {},
          },
        },
        visualMap: {
          min: share.value[share.value.length - 1] && share.value.length > 1 ? share.value[share.value.length - 1].value : 0,
          max: share.value[0] ? share.value[0].value : 0,
          calculable: true,
          itemWidth: 21,
          itemHeight: 130,
          left: '0',
          bottom: '40',
          text: ['高', '低'],
          textStyle: {
            color: '#999999',
            fontSize: 13,
          },
          inRange: {
            color: ['#C7F3F5', '#00ccb0'],
          },
          outOfRange: {
            show: false,
          },
        },
        series: [
          {
            type: 'map',
            mapType: 'world',
            map: 'china',
            aspectScale: 0.75,
            showLegendSymbol: false, // 存在legend时显示
            zoom: 1,
            roam: true, //放大缩小
            layoutCenter: ['50%', '50%'],
            layoutSize: '100%',
            label: {
              show: true,
              formatter: function (para: any) {
                if (para.value != 0 && para.value) {
                  return '{name|' + para.name + '}';
                } else {
                  return '';
                }
              },
              rich: {
                cnNum: {
                  fontSize: 11,
                  color: '#E9E9E9',
                  align: 'center',
                  show: true,
                },
                name: {
                  fontSize: 11,
                  color: '#ff4d4f',
                  align: 'center',
                  lineHeight: 60,
                  show: true,
                },
              },
              color: '#000',
            },
            itemStyle: {
              areaColor: '#E9E9E9', //地图背景颜色
              borderColor: '#FFF',
              borderWidth: 1,
              //  show: true
            },
            emphasis: {
              itemStyle: {
                //对应的鼠标悬浮效果
                areaColor: '#00ccb0',
                show: true,
              },
              label: {
                //对应的鼠标悬浮效果
                show: true,
                textstyle: {
                  color: '#00ccb0',
                },
              },
            },

            data: share.value,
          },
        ],
      });
    };

    const getCitydMap = () => {
      echarts.registerMap('china', chinaCity);
      myMapChart = echarts.init(document.getElementById(map.value) as HTMLElement);
      myMapChart.setOption({
        tooltip: {
          show: true,
          formatter: function (params: { value: string; name: string }) {
            if (params.value) {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + params.value + '个&nbsp;&nbsp;';
            } else {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;0个&nbsp;&nbsp;';
            }
          },
          x: '550',
          y: '50',
          feature: {
            saveAsImage: {},
          },
        },
        visualMap: {
          min: share.value[share.value.length - 1] && share.value.length > 1 ? share.value[share.value.length - 1].value : 0,
          max: share.value[0] ? share.value[0].value : 0,
          calculable: true,
          itemWidth: 21,
          itemHeight: 130,
          left: '0',
          bottom: '40',
          text: ['高', '低'],
          textStyle: {
            color: '#999999',
            fontSize: 13,
          },
          inRange: {
            color: ['#C7F3F5', '#00ccb0'],
          },
          outOfRange: {
            show: false,
          },
        },
        series: [
          {
            type: 'map',
            map: 'china',
            aspectScale: 0.75,
            showLegendSymbol: false, // 存在legend时显示
            zoom: 1,
            roam: true, //放大缩小
            layoutCenter: ['50%', '60%'],
            layoutSize: '100%',
            label: {
              show: true,
              formatter: function (para: any) {
                if (para.value != 0 && para.value) {
                  return '{name|' + para.name + '}';
                } else {
                  return '';
                }
              },
              rich: {
                cnNum: {
                  fontSize: 11,
                  color: '#E9E9E9',
                  align: 'center',
                  show: true,
                },
                name: {
                  fontSize: 11,
                  color: '#ff4d4f',
                  align: 'center',
                  lineHeight: 60,
                  show: true,
                },
              },
              color: '#000',
            },
            itemStyle: {
              areaColor: '#E9E9E9', //地图背景颜色
              borderColor: '#FFF',
              borderWidth: 1,
              //  show: true
            },
            emphasis: {
              itemStyle: {
                //对应的鼠标悬浮效果
                areaColor: '#00ccb0',
                show: true,
              },
              label: {
                //对应的鼠标悬浮效果
                show: true,
                textstyle: {
                  color: '#00ccb0',
                },
              },
            },
            data: share.value,
          },
        ],
      });
    };
    // e地图图表

    // 根据数据随机图表颜色
    function getRandomColor(colorKey: string, index: number) {
      const colors = {
        accessColors: ['#5654F5', '#FFBE20', ' #00ccb0', ' #FF3E3E', '#1890FF', '#FF5D22'],
        broswerColors: ['#5654F5', ' #00ccb0', '#FFBE20', '#1890FF', '#5DDECF', ' #FF3E3E'],
      };
      const newColorArr = colors[colorKey];
      return index > newColorArr.length - 1 ? newColorArr[newColorArr.length - 1] : newColorArr[index];
    }

    const pulltime = ref([
      {
        id: '0',
        num: '0',
        text: '分钟',
        name: '推流时长',
        color: '#00ccb0',
      },
      {
        id: '1',
        num: '0',
        text: '次',
        name: '观看量（PV）',
        color: '#FA5555',
      },
      {
        id: '2',
        num: '0',
        text: '人',
        name: '观众人数（UV）',
        color: '#FAAD14',
      },
      {
        id: '3',
        num: '0',
        text: '人',
        name: '最高并发',
        color: '#00ccb0',
      },
      {
        id: '4',
        num: '0',
        text: '人',
        name: '注册用户',
        color: '#00ccb0',
      },
      {
        id: '5',
        num: '0',
        text: '分钟',
        name: '平均观看时长',
        color: '#FAAD14',
      },
    ]);

    const surveyList = ref([
      {
        key: 0,
        title: '聊天评论',
        num: '117',
        text: '条',
        icon: require('@/assets/svg/dataCensus_comment.svg'),
      },
      {
        key: 1,
        title: '互动投票',
        num: '117',
        text: '次',
        icon: require('@/assets/svg/dataCensus_vote.svg'),
      },
      {
        key: 2,
        title: '礼物打赏',
        num: '117',
        text: '人',
        icon: require('@/assets/svg/dataCensus_reward.svg'),
      },
      // {
      //   key: 3,
      //   title: '在线问答',
      //   num: '117',
      //   text: '人',
      //   icon: require('@/assets/svg/dataCensus_qanda.svg'),
      // },
      {
        key: 4,
        title: '直播收益',
        num: '117',
        text: '元',
        icon: require('@/assets/svg/dataCensus_profit.svg'),
      },
    ]);

    // charts
    const insert = ref(`insert${Date.now() + Math.random()}`); // 观看路径
    const main = ref(`main${Date.now() + Math.random()}`); // 访问来源
    const numberMap = ref(`numberMap${Date.now() + Math.random()}`); // 在线人数
    const equipment = ref(`equipment${Date.now() + Math.random()}`); // 设备占比

    // 设备占比图表
    const equipmentData = ref<RefererCharts[]>([]);
    let equipmentCharts: echarts.ECharts;
    const referEquipment = (sourceEquip: RefererCharts[]) => {
      equipmentCharts.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          bottom: 0,
          icon: 'circle',
          // 图例间距
          itemGap: 20,
          type: 'scroll',
        },
        // toolbox: {
        //   show: true,
        //   // 下载按钮图标
        //   feature: {
        //     saveAsImage: {
        //       show: true,
        //       title: '保存',
        //     },
        //   },
        // },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '20',
            //     fontWeight: 'bold',
            //   },
            // },
            labelLine: {
              show: true,
            },
            data: sourceEquip,
          },
        ],
      });
    };

    // 访问来源图表
    const refererData = ref<RefererCharts[]>([]);
    let refererCharts: echarts.ECharts;
    const referChartsOption = (sourceData: RefererCharts[]) =>
      refererCharts.setOption({
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        //修改图例
        legend: {
          type: 'scroll',
          icon: 'circle',
          bottom: 0,
          itemGap: 20,
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            center: ['50%', '40%'],
            avoidLabelOverlap: false,
            //添加指示线指示文字
            labelLine: {
              show: true,
              length: 15,
              lineStyle: {
                color: '#999999',
              },
            },
            data: sourceData,
          },
        ],
      });

    // 观看路径图表
    const viewPath = ref<RefererCharts[]>([]);
    let viewPathChart: echarts.ECharts;
    const referViewPath = (sourcePath: RefererCharts[]) => {
      viewPathChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          icon: 'circle',
          // 图例间距
          itemGap: 20,
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            center: ['50%', '40%'],
            labelLine: {
              title: '',
              show: true,
              length: 15, // 改变标示线的长度
              lineStyle: {
                color: '#999999', // 改变标示线的颜色
              },
            },
            data: sourcePath,
          },
        ],
      });
    };

    // 用户概况图表
    const profile = ref(`profile${Date.now() + Math.random()}`); //用户概况
    const profileData = ref<RefererCharts[]>([]);
    let profileCharts: echarts.ECharts;
    const profileChartsOption = (sourceData: RefererCharts[]) => {
      profileCharts.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          icon: 'circle',
          // 图例间距
          itemGap: 20,
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            center: ['50%', '40%'],
            labelLine: {
              title: '',
              show: true,
              length: 15, // 改变标示线的长度
              lineStyle: {
                color: '#999999', // 改变标示线的颜色
              },
            },
            data: sourceData,
          },
        ],
      });
    };

    const shareModal = ref(false);
    const userId = ref();
    const referralCode = ref();
    const openDetail = (id: number, referral_code: string) => {
      shareModal.value = !shareModal.value;
      userId.value = id;
      referralCode.value = referral_code;
      shareModalData.value = [];
      getInvitedUsers(1);
    };
    const sharePageConfig = reactive({
      current: 1, // 当前页
      pageSize: 20, // 每页条数
      totalSize: 0, // 总页数
      lastPage: 1, //最后一页数据
      noMore: false, //是否有更多数据
    });
    const shareModalData = ref<any>([]);
    const getInvitedUsers = (current: number) => {
      const data = { studio_id: liveInfo, uid: userId.value, referral_code: referralCode.value, page: current, page_num: 10 };
      http.get_invited_users({ params: data }).then((res) => {
        shareModalData.value.push(...res.data.data.data);
        sharePageConfig.totalSize = res.data.data.total;
        sharePageConfig.lastPage = res.data.data.last_page;
      });
    };
    //滚动加载数据
    const getChildList = () => {
      if (sharePageConfig.current < sharePageConfig.lastPage) {
        sharePageConfig.current++;
        sharePageConfig.noMore = false;
        getInvitedUsers(sharePageConfig.current);
      } else {
        sharePageConfig.noMore = true;
      }
    };

    const exportModal = ref(false);
    const openExpotModal = () => {
      exportModal.value = !exportModal.value;
    };

    const expandedKeys = ref<string[]>(['0', '1', '2']);
    const selectedKeys = ref<string[]>([]);
    const checkedKeys = ref<string[]>([]);

    // 在线人数图表
    const hisOnlineChartData = reactive<any>({
      dates: [],
      pvNums: [],
      uvNums: [],
      andNums: [],
    });

    let historyOnlineCharts: echarts.ECharts;
    const historyOnlineChartsOption = (sourceData: any) => {
      historyOnlineCharts.setOption({
        legend: {
          right: 80,
          icon: 'circle',
          selectedMode: true,
        },
        tooltip: {
          trigger: 'axis',
          position: function (pt: any) {
            return [pt[0], '10%'];
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: sourceData.dates,
        },
        yAxis: {
          type: 'value',
          minInterval: 1, // 最小步长
          min: 0, // 起始
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: { type: 'dashed' },
          },
        },
        dataZoom: [
          //缩放
          {
            type: 'inside',
            // start: 90,
            // end: 100,
            // maxSpan:2,
          },
          {
            start: 0,
            end: 10,
          },
        ],
        series: [
          {
            name: 'PV',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgba(248, 147, 55, 1)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(248, 147, 55, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(248, 147, 55,0)',
                },
              ]),
            },
            data: sourceData.pvNums,
          },
          {
            name: 'UV',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgba(0, 204, 176, 1)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0, 204, 176, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(0, 204, 176, 0)',
                },
              ]),
            },
            data: sourceData.uvNums,
          },
          {
            name: '并发',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgba(86, 151, 242, 1)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(86, 151, 242, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(86, 151, 242, 0)',
                },
              ]),
            },
            data: sourceData.andNums,
          },
        ],
        grid: {
          left: 80,
          right: 80,
        },
      });
      historyOnlineCharts.on('legendselectchanged', function (params: any) {
        const option: any = historyOnlineCharts.getOption();
        const select_value = Object.values(params.selected);
        if (select_value.every((val) => !val)) {
          option.legend[0].selected[params.name] = true;
        }
        historyOnlineCharts.setOption(option);
      });
    };

    // 头部 数据
    const liveType = ref(1);
    const clickLiveTag = (type: number) => {
      liveType.value = type;
      updataTime();
      if (liveType.value === 1) {
        selectValue.value = 0;
      } else {
        selectValue.value = options.value.length > 1 ? options.value[1].options[0].value : options.value[0].value;
      }
      selChange();
    };

    const selectValue = ref<any>();
    const options = ref<any>([{ value: 0, label: '全部' }]);
    const pickerValue = ref<any>([]);

    const overviewTime = ref();
    const resource_overview_time = () => {
      http.resource_overview_time({ params: { studio_id: liveInfo } }).then((res) => {
        overviewTime.value = res.data.data;
        updataTime();
        // selectValue.value = options.value[1]?.options[0]?.value || 0;
        selectValue.value = 0;
        selChange();
      });
    };
    resource_overview_time();

    // 获取直播时下拉选择数据
    const getSeleTimeLive = () => {
      if (overviewTime.value.live.push.length > 0) {
        overviewTime.value.live.push.forEach((e: any, index: number) => {
          options.value.push({
            label: '推流时间段' + `${index + 1}`,
            options: [{ value: index + 1, label: `${e.start_at + '\n' + e.end_at}` }],
          });
        });
      }
      if (overviewTime.value.live.pull.length > 0) {
        overviewTime.value.live.pull.forEach((e: any, index: number) => {
          options.value.push({
            label: '拉流时间段' + `${index + 1}`,
            options: [{ value: options.value.length, label: `${e.start_at + '\n' + e.end_at}` }],
          });
        });
      }
    };
    // 获取回放时下拉选择数据
    const getSeleTimeblack = () => {
      if (overviewTime.value.video.length > 0) {
        overviewTime.value.video.forEach((e: any, index: number) => {
          options.value.push({
            label: '回放时间段' + `${index + 1}`,
            options: [{ value: options.value.length, label: `${e.start_at + '\n' + e.end_at}` }],
          });
        });
      }
    };
    const updataTime = () => {
      switch (liveType.value) {
        case 1:
          options.value = [{ value: 0, label: '全部' }];
          getSeleTimeLive();
          getSeleTimeblack();
          if (options.value.length === 0) options.value = [];
          break;
        case 2:
          options.value = [{ value: 0, label: '全部' }];
          getSeleTimeLive();
          if (options.value.length === 0) options.value = [];
          break;
        case 3:
          options.value = [{ value: 0, label: '全部' }];
          getSeleTimeblack();
          if (options.value.length === 0) options.value = [];
          break;
      }
    };

    const start_at = ref<any>('');
    const end_at = ref<any>('');
    const timeChange = () => {
      selectValue.value = undefined;
      start_at.value = dateFormats(pickerValue.value[0].toString()).substring(0, 16);
      end_at.value = dateFormats(pickerValue.value[1].toString()).substring(0, 16);
      updataTimeData();
    };
    const openChange = (open: boolean) => {
      if (!open) timeChange();
    };
    const onClickClear = () => {
      pageConfigPv.current = pageConfigUv.current = 1;
      pageConfigPv.pageSize = pageConfigUv.pageSize = 10;
      if (pickerValue.value.length === 0) {
        [start_at.value, end_at.value] = [undefined, undefined];
        updataTimeData();
      }
    };
    const selChange = () => {
      pickerValue.value = undefined;
      pageConfigPv.current = pageConfigUv.current = 1;
      pageConfigPv.pageSize = pageConfigUv.pageSize = 10;
      if (selectValue.value === 0) {
        [start_at.value, end_at.value] = [undefined, undefined];
      } else {
        const time = options.value[selectValue.value].options[0].label;
        const arr = time.split('\n');
        [start_at.value, end_at.value] = arr;
      }
      updataTimeData();
    };

    onMounted(() => {
      // s设备占比图表
      window.onresize = function () {
        historyOnlineCharts.resize();
        viewPathChart.resize();
        refererCharts.resize();
        equipmentCharts.resize();
        myMapChart.resize();
        profileCharts.resize();
      };
      // e设备占比图表
    });

    window.onresize = function () {
      myMapChart.resize();
    };

    const sort_country_rank = ref(); //国家
    const sort_view_rank = ref(); //省份
    const sort_city_rank = ref(); //城市

    // 在线观众时间分布接口
    const get_resource_overview = () => {
      http
        .get_resource_overview({ params: { studio_id: liveInfo, start_at: start_at.value, end_at: end_at.value } })
        .then((res) => {
          const pvData = res.data.data.pv || [];
          const dateArr: string[] = []; // X轴数据
          Object.keys(pvData).forEach((res) => {
            dateArr.push(pvData[res]['created_at']);
          });
          const uvData = res.data.data.uv || [];
          const andData = res.data.data.concurrent || [];
          const numArrPv: number[] = [];
          const numArrUv: number[] = [];
          const numArrAnd: number[] = [];
          Object.keys(pvData).forEach((res) => {
            numArrPv.push(pvData[res]['number']);
          });
          Object.keys(uvData).forEach((res) => {
            numArrUv.push(uvData[res]['number']);
          });
          Object.keys(andData).forEach((res) => {
            numArrAnd.push(andData[res]['number']);
          });
          hisOnlineChartData.dates = dateArr;
          hisOnlineChartData.pvNums = numArrPv;
          hisOnlineChartData.uvNums = numArrUv;
          hisOnlineChartData.andNums = numArrAnd;
          // 在线人数图表
          const chartDo: HTMLElement = document.getElementById(numberMap.value) as HTMLElement;
          historyOnlineCharts = echarts.init(chartDo);
          historyOnlineChartsOption(hisOnlineChartData);
        })
        .catch((err) => {
          message.destroy();
          message.error(err.data.error);
        });
    };

    const activeKey = ref(1);
    // 分页器 pv  uv
    const pageConfigPv = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    const pageConfigUv = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });

    // 分页器点击事件
    const showSize = () => {
      showLoading.value = true;
      const data = {
        studio_id: liveInfo,
        page: activeKey.value ? pageConfigPv.current : pageConfigUv.current,
        page_num: activeKey.value ? pageConfigPv.pageSize : pageConfigUv.pageSize,
        start_at: start_at.value || undefined,
        end_at: end_at.value || undefined,
      };
      http
        .getAccessRunnWaterByTime({ params: data })
        .then((res) => {
          if (activeKey.value) {
            pageConfigPv.current = Number(res.data.data.pv_data.current_page);
            pageConfigPv.totalSize = res.data.data.pv_data.total;
            dataest.value = [...res.data.data.pv_data.data];
          } else {
            pageConfigUv.current = Number(res.data.data.uv_data.current_page);
            pageConfigUv.totalSize = res.data.data.uv_data.total;
            char.value = [...res.data.data.uv_data.data];
          }
          showLoading.value = false;
        })
        .finally(() => (showLoading.value = false));
    };

    // (筛选版)数据中心观看流水 接口
    const getAccessRunnWaterByTime = () => {
      const data = {
        studio_id: liveInfo,
        page: pageConfigPv.current,
        page_num: pageConfigPv.pageSize,
        start_at: start_at.value || undefined,
        end_at: end_at.value || undefined,
      };
      http
        .getAccessRunnWaterByTime({ params: data })
        .then((res) => {
          pulltime.value[1].num = res.data.data.watch_number; //观看量（PV）
          pulltime.value[2].num = res.data.data.watch_attendance; //观众人数（UV）
          pulltime.value[5].num = res.data.data.average_viewing_time; //平均观看时长
          sort_country_rank.value = res.data.data.sort_country_rank; //国家观看量排行
          share.value = res.data.data.sort_view_rank; // 默认显示省份
          sort_view_rank.value = res.data.data.sort_view_rank; //省份观看量排行
          sort_city_rank.value = res.data.data.sort_city_rank; //城市观看量排行
          getMap();

          const sourceEquipment = res.data.data.equipment; //设备占比
          let resEquipment: any = [];
          sourceEquipment.forEach((e: any, index: number) => {
            resEquipment.push({
              name: e.name,
              value: e.value,
              itemStyle: { color: getRandomColor('accessColors', index) },
            });
          });
          equipmentData.value = resEquipment;
          const chartEquip: HTMLElement = document.getElementById(equipment.value) as HTMLElement;
          equipmentCharts = echarts.init(chartEquip);
          referEquipment(equipmentData.value);

          const scourceReferer = res.data.data.referrer; //访问来源
          let redReferrer: any = [];
          scourceReferer.forEach((e: any, index: number) => {
            redReferrer.push({
              name: e.name,
              itemStyle: { color: getRandomColor('accessColors', index) },
              value: e.value,
            });
          });
          refererData.value = redReferrer;
          const chartDom: HTMLElement = document.getElementById(main.value) as HTMLElement;
          refererCharts = echarts.init(chartDom);
          referChartsOption(refererData.value);

          const sourceBros = res.data.data.bros_wer_rank; //观看途径
          let resBros: any = [];
          sourceBros.forEach((i: any, index: number) => {
            resBros.push({
              name: i.name,
              value: i.value,
              itemStyle: { color: getRandomColor('broswerColors', index) },
            });
          });
          viewPath.value = resBros;
          const chartViewPath: HTMLElement = document.getElementById(insert.value) as HTMLElement;
          viewPathChart = echarts.init(chartViewPath);
          referViewPath(viewPath.value);

          const profileDatas = res.data.data.user_profile; // 用户概况
          let resProfile: any = [];
          profileDatas.forEach((e: any, index: number) => {
            resProfile.push({
              name: e.name,
              value: e.value,
              itemStyle: { color: getRandomColor('broswerColors', index) },
            });
          });
          profileData.value = resProfile;
          const profileDataDom: HTMLElement = document.getElementById(profile.value) as HTMLElement;
          profileCharts = echarts.init(profileDataDom);
          profileChartsOption(profileData.value);

          pageConfigPv.current = Number(res.data.data.pv_data.current_page);
          pageConfigPv.totalSize = res.data.data.pv_data.total;
          pageConfigUv.current = Number(res.data.data.uv_data.current_page);
          pageConfigUv.totalSize = res.data.data.uv_data.total;
          dataest.value = [...res.data.data.pv_data.data]; //观看流水 1
          char.value = [...res.data.data.uv_data.data]; //流水汇总 0
        })
        .catch((err) => {
          message.destroy();
          message.error(err.data.error);
        });
    };

    // (筛选版)数据中心
    const getAccessListDataByTime = () => {
      const data = { studio_id: liveInfo, start_at: start_at.value || undefined, end_at: end_at.value || undefined };
      http
        .getAccessListDataByTime({ params: data })
        .then((res) => {
          pulltime.value[0].num = res.data.data.live_time; //推流时长
          pulltime.value[3].num = res.data.data.highest_concurrent; //最高并发
          pulltime.value[4].num = res.data.data.registered_users; //注册用户
          devote.value = res.data.data.contribution_rank; //贡献榜
          listDevote.value = res.data.data.share_rank; //分享榜
        })
        .catch((err) => {
          message.destroy();
          message.error(err.data.error);
        });
    };

    // (筛选版)互动概览
    const getInteractionOverviewByTime = () => {
      const data = { studio_id: liveInfo, start_at: start_at.value || undefined, end_at: end_at.value || undefined };
      http
        .getInteractionOverviewByTime({ params: data })
        .then((res) => {
          surveyList.value[0].num = res.data.data.chat_number; //聊天评论数
          surveyList.value[1].num = res.data.data.voted_number; //投票人数
          surveyList.value[2].num = res.data.data.reward_number; //打赏人数
          surveyList.value[3].num = res.data.data.live_earnings; //直播收益
        })
        .catch((err) => {
          message.destroy();
          message.error(err.data.error);
        });
    };

    // 更新接口数据
    const updataTimeData = () => {
      get_resource_overview();
      getAccessRunnWaterByTime();
      getAccessListDataByTime();
      getInteractionOverviewByTime();
    };

    // 导出接口
    const { proxy }: any = getCurrentInstance();
    const token = getToken();
    const corn = ref(1);
    const messageTip = ref();
    const exportData = useDebounce((type: number) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        export: 1,
        is_cron_download: corn.value,
      };
      https
        .get_access_running({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error;
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };
    // const exportDataShare = (type: number) => {
    //   let url = proxy.$exportNewDataShare + '?token=' + token + '&studio_id=' + liveInfo + '&export=1' + '&type=' + type;
    //   onExport(url);
    // };
     const exportDataShare = useDebounce((type: number) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        is_cron_download: corn.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });

    const delVisible = ref(false);
    const showDelModal = () => {
      delVisible.value = !delVisible.value;
    };
    const mobile = ref<string>('');
    const getMobile = () => {
      axios.user.getMemberInfo().then((res) => {
        mobile.value = res.data.data.mobile ? res.data.data.mobile : '';
      });
    };
    getMobile();
    const code = ref<string>('');
    const count = ref(60);
    const timer = ref<any>(0);
    const showCode = ref(false);
    const getCode = () => {
      if (!timer.value) {
        showCode.value = true;
        count.value = 60;
        timer.value = setInterval(() => {
          if (count.value > 0 && count.value <= 60) {
            count.value--;
          } else {
            clearInterval(timer.value);
            showCode.value = false;
            timer.value = 0;
          }
        }, 1000);
      }
      axios.login
        .getSendCaptcha({ type: -1, mobile: mobile.value })
        .then(() => {
          console.log();
        })
        .catch((err) => {
          // message.error(err.data.error);
        });
    };

    const handleOk = () => {
      if (code.value === '') {
        message.info('验证码不能为空！');
        return false;
      } else {
        axios.pagedirect
          .downloadEmptyData({ params: { studio_id: liveInfo, mobile: mobile.value, code: code.value } })
          .then((res) => {
            //数据统计里 清空数据前的下载
            const net = window.open(res.data.data);
            net?.addEventListener('beforeunload', () => {
              Modal.confirm({
                title: () => '此操作会清空直播间数据，是否继续？',
                icon: () => createVNode(ExclamationCircleOutlined),
                centered: true,
                onOk() {
                  axios.pagedirect
                    .delData({ params: { studio_id: liveInfo } })
                    .then((res) => {
                      message.success(res.data.message);
                      showDelModal();
                      updataTimeData();
                      code.value = '';
                    })
                    .catch((err) => {
                      message.error(err.data.error);
                    });
                },
                onCancel() {
                  console.log('Cancel');
                },
                class: 'test',
              });
            });
          })
          .catch((err) => {
            message.error(err.data.error);
          });
      }
    };

    const toPdf = () => {
      // window.print();
      const ele: HTMLElement | null = document.getElementById('content');
      const title = '新版直播数据-' + liveInfo;
      exportPDF(ele, title);
    };
    return {
      RangePickerIcon,
      showLoading,
      Sharelist, //分享榜
      Sharelisted,
      share,
      devote, //贡献榜
      listDevote, //分享榜
      activeKey,
      dataest,
      columns,
      columnsl,
      char,
      Viewingvolume,
      map,
      equipment,
      payment,
      pageConfigPv,
      pageConfigUv,
      showSize,
      insert,
      main,
      numberMap,
      pulltime, // 数据统计
      delVisible,
      showDelModal,
      mobile,
      code,
      getCode,
      count,
      showCode,
      handleOk,
      helper,
      clickMap,
      mapType,
      listKey,
      surveyList,
      profile,
      clickLiveTag,
      liveType,
      selectValue,
      options,
      pickerValue,
      shareModal,
      openDetail,
      shareColumns,
      shareModalData,
      exportModal,
      openExpotModal,
      treeData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      getChildList,
      emptyDataSec,
      timeChange,
      selChange,
      moment,
      showCutVersion,
      cutBack,
      openChange,
      onClickClear,
      exportData,
      exportDataShare,
      toPdf,
      start_at,
    };
  },
});
