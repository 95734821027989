import { AxiosRequestConfig } from 'axios';
import http from '../plugins/http';
class Api {
  // 客管登陆接口
  getLogin = (data: unknown) => {
    return http.post('/api/v1/admin/login', data);
  };
  // 助手登录
  loginHelper = (data: unknown) => {
    return http.post('/api/v1/live/login_helper', data);
  };
  //   发送短信验证码
  getSendCaptcha = (data: unknown) => {
    return http.post('/api/v1/common/send_captcha', data);
  };
  //   客户管理员忘记密码
  getForgetPassword = (data: unknown) => {
    return http.post('/api/v1/admin/forget_password', data);
  };
  //获取首页轮播图
  getPageBanner = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/manager/get_login_page_banner_info', data);
  };
  //后台用户访问记录
  adminUserVisit = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/admin_user_visit', data);
  };
  // 下级账号登录
  adminSubAccountLogin = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/admin_sub_account_login', data);
  };
  //下级账号登录信息
  getSubAccountInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/login/get_sub_account_info', data);
  };
  //子账号忘记密码
  adminSubForgotPassword = (data: unknown) => {
    return http.post('/api/v1/admin/admin_sub_forgot_password', data);
  };
  // 助手账号登录
  getHelperLoginUrl = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/helper_login_url', data);
  };
  // 助手账号登录
  getHelperLoginInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/login_live_helper', data);
  };
  // 助手账号信息
  getInfoLiveHelper = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/info_live_helper', data);
  };
  // 阿里云智能验证
  aliLoginVerification = (data: unknown) => {
    return http.post('/api/v1/admin/ali_login_verification', data);
  };
  // 第三方审核登陆--获取详情
  getThirdLogin(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_other_examine', data);
  }
  // 第三方审核--登陆
  thirdPartyLogin(data: unknown) {
    return http.post('/api/v1/live/other_examine_login', data);
  }
  // 获取扫码登陆二维码
  getLoginQrCode() {
    return http.get('/api/v1/admin/get_login_qr_code');
  }
}
export default new Api();
