import http from '@/api/robot';
import { ICommentT, ICommentT1, RootModal } from './type';
import { message } from 'ant-design-vue';
import { reactive, ref } from 'vue';
import { IColumns, ICreateD } from './type';

export const columns1: IColumns[] = [
  {
    title: '内容',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 180,
    align: 'center',
    slots: { customRender: 'operation' },
  },
];

export const columns2: IColumns[] = [
  {
    title: '头像',
    dataIndex: 'avatar_url',
    key: 'avatar_url',
    width: 50,
    slots: { customRender: 'avatal' },
  },
  {
    title: '内容',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 180,
    align: 'center',
    slots: { customRender: 'operation' },
  },
];

// 评论库  0添加类别  1修改类别  2添加评论 3修改评论  身份库 4添加类别  5修改类别 6添加身份 7修改身份
export function getCreateModalTitle(key: number): string {
  let title = '';
  if (key === 1 || key === 3 || key === 5 || key === 7) {
    title = '修改';
  } else {
    title = '添加';
  }
  return title;
}

export function getcreateModalData(key: number, item: any) {
  let data: ICreateD = {
    avatal: '',
    avatalId: 0,
    content: '',
  };
  if (key === 1 || key === 3 || key === 5) {
    data.content = item.name || item.content;
  } else if (key === 7) {
    data.content = item.name;
    data.avatal = item.avatar_url;
    data.avatalId = item.avatar_id;
  } else {
    data = {
      avatal: '',
      avatalId: 0,
      content: '',
    };
  }
  return data;
}

export const createModal = reactive<RootModal>({
  title: '添加',
  visible: false,
  loading: false,
});

export const typeList = ref<ICommentT[]>([]);

export const getTypeCommentList = () => {
  http.get_comment_type_list().then((res) => {
    typeList.value = res.data.data.map((e: ICommentT1) => new ICommentT(e));
  });
};
export const getTypeIdentityList = () => {
  http.get_robot_identity_list().then((res) => {
    typeList.value = res.data.data.map((e: ICommentT1) => new ICommentT(e));
  });
};

export const headerListKey = ref(0);

export const dataSource = ref([]);
export const worksList = ref([]);
// /分页
export const pageConfig = reactive({
  current: 1, // 当前页
  pageSize: 10, // 每页条数
  totalSize: 0, // 总页数
  options: ['10', '24', '36', '48', '60'], // 选项
  size: 'small', // 尺寸
  showJumper: false, // 是否显示跳页
  showSize: false, // 是否显示条数切换
});

// // 获取评论内容列表
export const getCommentList = () => {
  const data = { type_id: headerListKey.value, page: pageConfig.current, page_num: pageConfig.pageSize };
  http.get_comment_list({ params: data }).then((res) => {
    dataSource.value = res.data.data.data;
    worksList.value = dataSource.value;
    pageConfig.totalSize = res.data.data.total;
    pageConfig.current = res.data.data.current_page;
  });
};

// // 获取身份库论内容列表
export const getCommentRobotList = () => {
  const data = { identity_id: headerListKey.value, page: pageConfig.current, page_num: pageConfig.pageSize };
  http.get_comment_robot_list({ params: data }).then((res) => {
    dataSource.value = res.data.data.data;
    worksList.value = dataSource.value;
    pageConfig.totalSize = res.data.data.total;
    pageConfig.current = res.data.data.current_page;
  });
};

export function clickUpdataList(content: string, typeId: number, contentId?: number): any {
  // 评论库  0添加类别
  const creataTypeComment = async () => {
    try {
      const res = await http.add_comment_type({ type_name: content });
      message.success(res.data.message);
      createModal.visible = false;
      getTypeCommentList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 评论库  1修改类别
  const editTypeComment = async () => {
    try {
      const res = await http.edit_comment_type({ type_name: content, id: typeId });
      message.success(res.data.message);
      createModal.visible = false;
      getTypeCommentList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 2添加评论
  const creataCommentData = async () => {
    try {
      const res = await http.add_comment_content({ content: content, type_id: typeId });
      message.success(res.data.message);
      createModal.visible = false;
      getCommentList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 3修改评论
  const editCommentData = async () => {
    try {
      const res = await http.edit_comment_content({ content: content, type_id: typeId, id: contentId });
      message.success(res.data.message);
      createModal.visible = false;
      getCommentList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 身份库 4添加类别
  const addRobotIdentity = async () => {
    try {
      const res = await http.add_robot_identity({ identity_name: content });
      message.success(res.data.message);
      createModal.visible = false;
      getTypeIdentityList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 身份库 5修改类别
  const editRobotIdentityLibrary = async () => {
    try {
      const res = await http.edit_robot_identity_library({ identity_name: content, id: typeId });
      message.success(res.data.message);
      createModal.visible = false;
      getTypeIdentityList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  // 6添加身份
  const addUserCommentRobot = async (url: string, urlId: number) => {
    try {
      const res = await http.add_user_comment_robot({ name: content, identity_id: typeId, avatar_url: url, avatar_id: urlId });
      message.success(res.data.message);
      createModal.visible = false;
      getCommentRobotList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  //  7修改身份
  const editUserReviewRobot = async (url: string, urlId: number) => {
    try {
      const res = await http.edit_user_review_robot({
        name: content,
        identity_id: typeId,
        id: contentId,
        avatar_url: url,
        avatar_id: urlId,
      });
      message.success(res.data.message);
      createModal.visible = false;
      getCommentRobotList();
    } catch (err: any) {
      message.error(err.data.error);
      createModal.visible = true;
    }
  };
  return {
    creataTypeComment,
    editTypeComment,
    creataCommentData,
    editCommentData,
    addRobotIdentity,
    editRobotIdentityLibrary,
    addUserCommentRobot,
    editUserReviewRobot,
  };
}

export const delModal = reactive<RootModal>({
  title: '删除',
  visible: false,
  loading: false,
});

const getTypeCommentListDel = () => {
  http.get_comment_type_list().then((res) => {
    typeList.value = res.data.data.map((e: ICommentT1) => new ICommentT(e));
    headerListKey.value = typeList.value[0].id;
    getCommentList();
  });
};
const getTypeIdentityListDel = () => {
  http.get_robot_identity_list().then((res) => {
    typeList.value = res.data.data.map((e: ICommentT1) => new ICommentT(e));
    headerListKey.value = typeList.value[0].id;
    getCommentRobotList();
  });
};

export function clickCloseList(typeId: number, contentId: number): any {
  // 删除评论类型
  const delCommentType = async () => {
    try {
      const res = await http.del_comment_type({ params: { id: typeId } });
      message.success(res.data.message);
      delModal.visible = false;
      getTypeCommentListDel();
    } catch (err: any) {
      message.error(err.data.error);
      delModal.visible = true;
    }
  };
  // 删除评论内容
  const delCommentContent = async () => {
    try {
      const res = await http.del_comment_content({ params: { type_id: typeId, id: contentId } });
      message.success(res.data.message);
      delModal.visible = false;
      getCommentList();
    } catch (err: any) {
      message.error(err.data.error);
      delModal.visible = true;
    }
  };
  //  删除身份类型
  const delRobotIdentityLibrary = async () => {
    try {
      const res = await http.del_robot_identity_library({ params: { id: typeId } });
      message.success(res.data.message);
      delModal.visible = false;
      getTypeIdentityListDel();
    } catch (err: any) {
      message.error(err.data.error);
      delModal.visible = true;
    }
  };
  //  删除身份内容
  const delCommentRobot = async () => {
    try {
      const res = await http.del_comment_robot({ params: { identity_id: typeId, id: contentId } });
      message.success(res.data.message);
      delModal.visible = false;
      getCommentRobotList();
    } catch (err: any) {
      message.error(err.data.error);
      delModal.visible = true;
    }
  };
  return {
    delCommentType,
    delCommentContent,
    delRobotIdentityLibrary,
    delCommentRobot,
  };
}
