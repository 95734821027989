// 广告栏设置api
import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取直播间广告内容
  get_ad_content = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_ad_content', data);
  };
  // 修改直播间广告内容
  update_or_insert_studio_ad = (data: unknown) => {
    return http.post('/api/v1/live/update_or_insert_studio_ad', data);
  };
  // 广告栏点击数据
  getAdClickList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_ad_click_list', data);
  };
}

export default new Api();
