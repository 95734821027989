
import Crumb from '@/components/Crumb.vue';
import { computed, defineComponent, ref } from 'vue';
import { message, Radio, Popover } from 'ant-design-vue';
import { InputNumber } from 'ant-design-vue';
import router from '@/router';
import http from '@/api/pagedirect';
import { useStore } from 'vuex';
import { UserOutlined, QuestionCircleFilled } from '@ant-design/icons-vue';
import CLoading from '@/components/CLoading.vue';

export default defineComponent({
  components: {
    Crumb,
    aInputNumber: InputNumber,
    aRadioGroup: Radio.Group,
    aPopover: Popover,
    QuestionCircleFilled,
    UserOutlined,
    CLoading,
  },
  setup() {
    const store = useStore();
    const liveInfo = router.currentRoute.value.params.studioId;
    const checked = ref<boolean>(false);
    const valued = ref(1);
    const valuek = ref(1);
    const opened = ref(1);
    const final_people = ref(0);
    const min_number = ref(0);
    const max_number = ref(0);
    const defaultNumber = ref(0);
    const concurrency = ref(0);
    const max = ref(0);
    const img_player_url = ref('');
    const viewImgPlayerUrl = computed(() => {
      if (img_player_url.value === '') {
        return store.state.home.customData.video_url;
      }
      return img_player_url.value;
    });

    const showLoading = ref(false);

    const allNum = ref('');
    const pv_number = ref('');
    const default_number = ref('');
    const false_number = ref('');
    const modal2Visible = ref<boolean>(false);
    const setModal1Visible = (visible: boolean) => {
      modal2Visible.value = visible;
    };
    // 获取直播间放大人数
    const falsePeople = () => {
      showLoading.value = true;
      let falsePeople = {
        studio_id: liveInfo,
      };
      http
        .getFalsePeople({ params: falsePeople })
        .then((res) => {
          const that = res.data.data.body;
          const hard = res.data.data.header;
          valuek.value = that.status;
          opened.value = that.people_status;
          valued.value = that.display_mode ? that.display_mode : opened.value == 1 ? 4 : 1;
          defaultNumber.value = that.default;
          concurrency.value = that.concurrency;
          max.value = that.max;
          img_player_url.value = hard.img_player_url;
          allNum.value = that.all;
          pv_number.value = that.pv_number;
          default_number.value = that.default;
          false_number.value = that.false;
          min_number.value = that.false_min ? that.false_min : 0;
          max_number.value = that.false_max ? that.false_max : 0;
          showLoading.value = false;
        })
        .catch((res) => {
          message.error(res.data.error);
          showLoading.value = false;
        });
    };
    falsePeople();
    // 人数显示模式
    const numSwitchChange = () => {
      valued.value == 3 ? (valuek.value = 0) : valued.value == 4 ? (opened.value = valuek.value = 1) : (valuek.value = 1);
      if (valued.value != 4) opened.value = 0;
    };
    // 人数开关
    const switchChange = () => {
      if (opened.value == 1) {
        valued.value = 4;
        valuek.value = 1;
      }
      if (opened.value == 0) {
        valued.value = 1;
      }
      save();
    };
    // 添加OR修改直播间放大人数
    const save = () => {
      let orInsert = {
        studio_id: liveInfo,
        status: valuek.value, //虚拟人数显示
        people_status: opened.value, //功能开关
        final_people: final_people.value, //最终人数
        false_min: min_number.value, //观看人数放大
        false_max: max_number.value, //观看人数放大
        display_mode: valued.value == 4 ? 1 : valued.value, //显示模式
        default: defaultNumber.value, //默认基础人数
        concurrency: concurrency.value, //观看人数上限
        max: max.value, //虚拟人数上限
      };
      http
        .getUpdateOrInsertFalsePeople(orInsert)
        .then((res) => {
          const resData = res.data;
          message.destroy();
          message.success(resData.message);
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };

    const onEmpty = () => {
      http
        .delData({ params: { studio_id: liveInfo } })
        .then((res) => {
          message.success(res.data.message);
        })
        .catch((err) => {
          message.error(err.data.error);
        });
    };
    // const changeNum=()=>{
    //   if( defaultNumber.value||min_number.value||max_number.value||concurrency.value||max.value||final_people.value<0){
    //      defaultNumber.value = 0;
    //   }
    // }
    // 拿定制KV图片
    // const texts = useStore();
    // const alips = computed(() => texts.state.home.customData.video_url);
    // console.log(alips,'444444');
    return {
      checked,
      modal2Visible,
      setModal1Visible,
      valued, //显示模式
      valuek, //虚拟人数显示
      numSwitchChange,
      switchChange,
      save, //保存
      onEmpty,
      opened, //功能开关
      final_people, //最终人数
      min_number, //观看人数放大
      max_number, //观看人数放大
      defaultNumber, //默认基础人数
      concurrency, //观看人数上限
      max, //虚拟人数上限
      img_player_url, //kv
      viewImgPlayerUrl, //kv 视图
      allNum, // 观看显示人数
      pv_number, // 真实人数
      default_number, // 默认人数
      false_number, // // 放大人数
      showLoading,
      //  changeNum,
      // alips, //kv
    };
  },
});
