import { BaseSearchClass } from '@/types/base';
export class ResourceBill extends BaseSearchClass {
  start_time?: string;
  end_time?: string;
  quest_id?: number;
  addr?: string;
  studio_id?: number;
  answer_time?: number;
  quest_item_title?: string;
  user_key?: number;
  // 设置日期
  setDates(liveDate: Date[]): void {
    if (liveDate && liveDate.length > 0) {
      this.start_time = liveDate[0].toString();
      this.end_time = liveDate[1].toString();
    }
  }
  constructor(data: ResourceBill) {
    super(data);
    this.quest_id = data.quest_id;
    this.addr = data.addr;
    this.studio_id = data.studio_id;
    this.answer_time = data.answer_time;
    this.quest_item_title = data.quest_item_title;
    this.user_key = data.user_key;
  }
}
