import { EmotionList, LightGiftContent, RedEnvelopeContent } from './livechat';

// const isJSON = function (str: string) {
//   if (
//     /^[\],:{}\s]*$/.test(
//       str
//         .replace(/\\["\\/bfnrtu]/g, '@')
//         .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
//         .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
//     )
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const formatEmoji = (content: any, chatType = 2) => {
  let formatContent = content;
  if (typeof formatContent !== 'string') {
    return formatContent;
  }
  if (chatType === 2) {
    formatContent = content.replace(/>/g, '&gt;').replace(/</g, '&lt;');
  }
  for (const q in EmotionList) {
    const cont = new RegExp(EmotionList[q], 'g');
    formatContent = formatContent.replace(
      cont,
      `<img class="emoji" style="width: 20px;height:20px;margin-right:2px;" src="http://static.lighos.com/emoji/${
        Number(q) + 1
      }.png" align="bottom" alt="${EmotionList[q]}">`
    );
  }
  return formatContent;
};
export class Authority {
  avatar: number;
  avatar_url: string;
  device: string;
  device_type: number;
  id: number;
  ip: string;
  level: number;
  monitor_id: string;
  name: string;
  studio_id: number;
  uid: number;
  visited_at: number;
  constructor(data: any) {
    this.avatar = data.avatar;
    this.avatar_url = data.avatar_url;
    this.device = data.device;
    this.device_type = data.device_type;
    this.id = data.id;
    this.ip = data.ip;
    this.level = data.level;
    this.monitor_id = data.monitor_id;
    this.name = data.name;
    this.studio_id = parseInt(data.studio_id);
    this.uid = data.uid;
    this.visited_at = data.visited_at;
  }
}
export interface MultiStream {
  id: number; //id
  image_player_cover_url: string; //封面
  language_id: number;
  language_image_url: string; //标题 icon
  live_url_status: number; // 0未开始推流 1推流中 2推流结束
  mode: number;
  title: string; //标题
  stream_type: number; //地址类型
  uv_live: number; //并发量
  pv_live: number; //观看量
  push_url: string; //推流地址
  pull_url: string; //拉流
  supervision_status: number; //监看状态
  play_url: string[]; //播流地址
  push_type: number; //低延状态
}
export interface ResponseConsole {
  id: number;
  WeChat_url: string; //?* 暂时没用
  chat_type: number; // 聊天类型： 轻聊 标准
  stream_status: string;
  vr_play_status: number; // 是否为VR直播间  0:2D,1:2D、3D,2:仅3D
  vr_play_switch: number; // 是否仅3D播放
  limit: number; //* ?
  mode: number; // 直播模式
  multi_stream?: MultiStream[]; // 多流
  monitor_id: string; // 监控室id 关联 webSocket
  play_url: string[]; // 直播链接地址
  player_status: number; // 播放器状态
  push_type: number; // 推流类型
  push_url: string[]; // 推流地址
  vr_push_url: string[]; // VR推流地址
  qr_code_url: string; // 二维码观看地址
  rehearsal_switch: number; // 彩排模式
  rehearsal_password: string; // 彩排模式
  start_at: string; // 开播时间
  status: number; // 直播间状态
  studio_pid: number; // 直播间父级id 为 0 则没有
  title: string; // 直播间标题
  live_url_status: number; // 直播链接状态-关联是否显示播放器封面
  image_player_cover_id: number; // 控台视图-封面图片 id
  image_player_cover_url: string; // 控台视图-封面图片 url
  at_present_time: string; // 控台视图-更新时间
  uv_live: number; // 控台视图-在线人数(并发)
  pv_live: number; // 控台视图-观看量
  display_member: number; // 在线人数-观看页显示的控制开关
  display_chat: number; // 互动聊天-观看页显示的控制开关
  barrage_review_status: number; // 互动聊天设置-审核弹幕
  barrage_status: number; // 互动聊天设置-显示弹幕
  comment_num_status: number; // 互动聊天设置-显示评论数
  group_status: number; // 互动聊天设置-观众聊天权限开关
  notity_status: number; // 互动聊天设置-观众进入直播间提示
  private_status: number; // 互动聊天设置-观众私聊权限开关
  pic_status: number; // 互动聊天设置-发送图片权限开关
  addr_switch: number; // 互动聊天设置-观众IP属地开关
  anonymous_status: number; // 互动聊天设置-匿名聊天
  review_status: number; // 互动聊天设置-发送内容审核权限开关
  language_image_url?: string;
  gift_display_status: number; // 控台显示礼物信息
  vr_play_url: string[]; // 3d直播链接地址
}

/**
 *
 */
export class ConsoleClass {
  id: number;

  weChaturl: string;

  streamStatus: string[] | boolean;

  chatType: number; // 聊天类型： 轻聊 标准

  vrPlayStatus: number; // 区分 0 2D、1 3D

  vrPlaySwitch: boolean; // 是否仅3D播放

  limit: number;

  mode: number; // 直播模式

  multiStream: MultiStream[];

  monitorId: string; // 监控室id 关联 webSocket

  playUrl: string[]; // 直播链接地址

  playerSwitch: boolean; // 控台视图-播放器观看页开关

  pushType: boolean; // 推流类型

  liveUrlStatus: number; // 1直播中 0反之

  pushUrl: string[]; // 推流地址

  vrPushUrl: string[]; // VR推流地址

  qrCodeUrl: string; // 二维码观看地址

  rehearsalSwitch: boolean; // 彩排模式

  rehearsalPwd: string; // 彩排模式-密码

  startTime: string; // 开播时间

  status: number; // 直播间状态

  studioPid: number;

  title: string; // 直播间标题

  liveUrlSwitch: number; // 直播链接状态-关联是否显示播放器封面 0 1 2

  imagePlayerCoverId: number; // 控台视图-封面图片 id

  imagePlayerCoverUrl: string; // 控台视图-封面图片 url

  updateTime: string; // 控台视图-更新时间

  uvCount: number; // 控台视图-在线人数(并发)

  pvCount: number; // 控台视图-观看量

  memberVisible: boolean; // 在线人数-观看页显示的控制开关

  chatVisible: boolean; // 互动聊天-观看页显示的控制开关

  barrageReview: boolean; // 互动聊天设置-审核弹幕开关

  barrageVisible: boolean; // 互动聊天设置-显示弹幕开关

  commentNumVisible: boolean; // 互动聊天设置-显示评论数开关

  groupStatus: boolean; // 互动聊天设置-观众聊天权限开关

  notityStatus: boolean; // 互动聊天设置-观众进入直播间提示开关

  privateStatus: boolean; // 互动聊天设置-观众私聊权限开关

  picStatus: boolean; // 互动聊天设置-发送图片权限开关

  addressStatus: boolean; // 互动聊天设置-观众IP属地开关

  anonymousStatus: boolean; // 互动聊天设置-匿名聊天

  reviewStatus: boolean; // 互动聊天设置-发送内容审核权限开关

  languageImageUrl?: string;

  gift_display_status: number;

  vrPlayUrl: string[]; // 3d直播链接地址
  constructor(data: ResponseConsole) {
    this.id = data.id;
    this.weChaturl = data.WeChat_url;
    this.vrPlayStatus = data.vr_play_status;
    this.streamStatus = data.stream_status?.split(',');
    this.chatType = data.chat_type;
    this.vrPlaySwitch = !!data.vr_play_switch;
    this.limit = data.limit;
    this.mode = data.mode;
    this.multiStream = data.multi_stream ? data.multi_stream : [];
    this.monitorId = data.monitor_id;
    this.playUrl = data.play_url;
    this.playerSwitch = data.player_status ? true : false;
    this.pushType = data.push_type ? true : false;
    this.liveUrlStatus = data.live_url_status;
    this.pushUrl = data.push_url;
    this.vrPushUrl = data.vr_push_url;
    this.qrCodeUrl = data.qr_code_url;
    this.rehearsalSwitch = data.rehearsal_switch ? true : false;
    this.rehearsalPwd = data.rehearsal_password;
    this.startTime = data.start_at;
    this.status = data.status;
    this.studioPid = data.studio_pid;
    this.title = data.title;
    this.liveUrlSwitch = data.live_url_status;
    this.imagePlayerCoverId = data.image_player_cover_id;
    this.imagePlayerCoverUrl = data.image_player_cover_url;
    this.updateTime = data.at_present_time;
    this.uvCount = data.uv_live;
    this.pvCount = data.pv_live;
    this.memberVisible = data.display_member ? true : false;
    this.chatVisible = data.display_chat ? true : false;
    this.barrageReview = data.barrage_review_status ? true : false;
    this.barrageVisible = data.barrage_status ? true : false;
    this.chatVisible = data.display_chat ? true : false;
    this.chatVisible = data.display_chat ? true : false;
    this.commentNumVisible = data.comment_num_status ? true : false;
    this.groupStatus = data.group_status ? true : false;
    this.notityStatus = data.notity_status ? true : false;
    this.notityStatus = data.notity_status ? true : false;
    this.privateStatus = data.private_status ? true : false;
    this.picStatus = data.pic_status ? true : false;
    this.picStatus = data.pic_status ? true : false;
    this.addressStatus = data.addr_switch ? true : false;
    this.anonymousStatus = data.anonymous_status ? true : false;
    this.reviewStatus = data.review_status ? true : false;
    this.languageImageUrl = data.language_image_url;
    this.gift_display_status = data.gift_display_status;
    this.vrPlayUrl = data.vr_play_url;
  }
}

/**
 * 公共聊天评论信息对象
 */
export class MsgComment {
  comment_avatar_url: string; // 用户头像

  comment_content: string; // 评论内容

  comment_created_at: string; // 评论创建时间

  comment_id?: number; // 评论ID

  group_msg_id?: string; // 父级消息ID

  comment_msg_id: string; // 评论消息标识

  comment_msg_type: string; // 消息类型

  comment_nickname: string; // 评论人的ID

  comment_status: number; // 消息状态

  comment_uid: number; // 用户id

  type?: string; // 消息发送类型

  level?: number; // 用户等级标识
  constructor(data: any) {
    this.comment_avatar_url = data.avatar_url;
    this.comment_content = formatEmoji(data.content);
    this.comment_created_at = data.created_at;
    this.group_msg_id = data.group_msg_id;
    this.comment_msg_id = data.msg_id;
    this.comment_msg_type = data.msg_type;
    this.comment_nickname = data.nickname;
    this.comment_status = data.status;
    this.comment_uid = data.uid;
    this.type = data.type;
    this.level = data.level;
  }
}

/**
 * 观众私聊的消息内容
 *
 */
export class ChatRecord {
  id?: number; // ID

  content: string; // 私聊内容

  created_at: string; // 创建时间

  msg_id: string; // 消息ID

  msg_type: string; // 类型

  updated_at?: string; // 私聊窗口最后更新时间

  send_status?: number; // 消息发送状态

  to_uid?: number; // 私聊对象的用户id

  avatar: string; // 用户头像id

  avatar_url: string; // 用户头像

  uid: number; // 用户ID

  nickname: string; // 用户昵称

  chat_list_id?: number; // 消息列表ID

  constructor(data: any) {
    this.id = data.id;
    this.content = formatEmoji(data.content);
    this.created_at = data.msg_created_at;
    this.msg_id = data.msg_id;
    this.msg_type = data.msg_type;
    this.updated_at = data.msg_updated_at;
    this.send_status = data.send_status;
    this.to_uid = data.to_uid;
    this.avatar = data.user_avatar;
    this.avatar_url = data.user_avatar_url;
    this.uid = data.user_id;
    this.nickname = data.user_nickname;
    this.chat_list_id = data.chat_list_id;
  }
}

/**
 * socket 公共聊天对象
 */
export class SocketGMsg {
  alias: string; // 别名代号

  avatar: string; // 头像id

  avatar_url: string; // 头像url

  comment: MsgComment[];

  content: string | LightGiftContent | RedEnvelopeContent; // 内容

  content_url: string;

  group?: number; //* ？

  level: number; // 用户类型标示 1 官方 0 普通

  like: number; // 点赞标识

  like_num: number; // 点赞数

  msg_id: string; // 消息ID

  msg_type: string; // 类型

  nickname: string; // 昵称

  status: number; // 审核状态 （内容隐藏）

  time: string; // 详细时间

  type?: string; // socket消息的类型

  uid: number; //  用户id
  constructor(data: SocketGMsg) {
    this.alias = data.alias ?? '';
    this.avatar = data.avatar;
    this.avatar_url = data.avatar_url;
    this.comment = data.comment ?? [];
    this.content = data.content;
    this.content_url = data.content_url ?? '';
    this.group = data.group;
    this.level = data.level;
    this.like = data.like ?? 0;
    this.like_num = data.like_num ?? 0;
    this.msg_id = data.msg_id ?? '';
    this.msg_type = data.msg_type;
    this.nickname = data.nickname;
    this.status = data.status;
    this.time = data.time;
    this.type = data.type;
    this.uid = data.uid;
  }
}

export class GroupChat extends SocketGMsg {
  id?: number;
  // alias: string; // 别名代号
  // avatar: string; // 头像id
  // avatar_url: string; // 头像url
  // comment: MsgComment[];
  // content: string; // 内容
  created_at: string; // 发送时间
  display_order?: string; //* ？
  gender?: number; // 性别
  // group: number; //* ？
  // level: number; // 用户类型标示 1 官方 0 普通
  // like: number; // 点赞标识
  // like_num: number; // 点赞数
  // msg_id: string; // 消息ID
  // msg_type: string; // 类型
  // nickname: string; // 昵称
  // status: number; // 审核状态
  // time: string; // 详细时间
  top_status?: number; // 置顶状态
  // uid: string; //  用户id
  updated_at?: string; // 更新时间
  image_content?: string; // 对应消息中的图片 content
  image_content_url?: string; // 对应消息中的图片 content_url
  to_nickname?: string; // 私聊对象的昵称
  to_status?: number; //私聊对象状态
  to_uid?: number; // 私聊对象id
  chat_list_id?: number;
  chat_record?: ChatRecord[];
  to_avatar?: string; //私聊对象头像id
  to_avatar_url?: string; //私聊对象头像url
  address: string;
  constructor(data: any);
  constructor(data: any, type: string);
  constructor(data?: any, type?: string) {
    super(data);
    this.created_at = data.created_at;
    this.address = data.addr;
    switch (type) {
      case 'privateChat': //公共私聊
        // this.status = 1;
        this.status = data.status;
        this.created_at = data.created_at;
        this.msg_id = data.msg_id ?? data.chat_list_id;
        this.chat_list_id = data.chat_list_id;
        if (data.chat_record && data.chat_record.length > 0) {
          this.chat_record = [new ChatRecord(data.chat_record[0])];
          this.chat_record[0].content = formatEmoji(data.chat_record[0].content);
        } else {
          this.chat_record = [];
        }
        this.to_nickname = data.to_nickname;
        this.to_status = data.to_status;
        this.to_uid = data.to_uid;
        this.to_avatar = data.to_avatar;
        this.to_avatar_url = data.to_avatar_url;
        break;
      case 'baseList': //基本聊天
        this.top_status = Number(data.top_status);
        if (data.comment.length > 0) {
          this.comment = data.comment;
          this.comment[0].comment_content = formatEmoji(data.comment[0].comment_content);
        }
        if (data.msg_type === 'gift' || data.msg_type === 'redpack') {
          this.content = formatEmoji(JSON.parse(data.content));
        } else if (data.msg_type === 'img') {
          this.content = formatEmoji(data.content);
          this.image_content = data.content;
          this.image_content_url = data.image_content_url;
        } else {
          this.content = formatEmoji(data.content);
        }
        break;
      default:
        this.created_at = data.time;
        this.top_status = Number(data.top_status);
        if (data.comment.length > 0) {
          this.comment = data.comment;
          this.comment[0].comment_content = formatEmoji(data.comment[0].comment_content);
        }
        if (data.msg_type === 'gift') {
          this.content = formatEmoji(JSON.parse(data.content));
        } else {
          this.content = formatEmoji(data.content);
        }
        this.image_content = data.content;
        this.image_content_url = data.content_url;
        break;
    }
  }
}

// 私聊列表对象
export interface PrivateChat {
  id: number;
  alias: string; // 别名代号
  created_at: string; // 发送时间
  display_order?: string; //* ？
  identity: number; // 身份
  new_record: string; // 内容
  news: number; // 未读状态
  status: number; //用户发言状态 0解除 2禁言
  to_avatar: string; // 头像id
  to_avatar_url: string; // 头像url
  to_level: number; // 用户类型标示 1 官方 0 普通
  to_nickname: string; // 昵称
  to_uid: number; //  用户id
  updated_at: string; // 更新时间
}
export interface Page {
  current: number; // 当前页
  pageSize: number; // 每页数量
  totalSize: number; // 总数量
}

export class SocketPMsg {
  alias: string; // 别名代号

  avatar: string; // 头像id

  avatar_url: string; // 头像url

  chat_list_id: number; // 私聊列表id

  content: string; // 内容

  group?: number; //* ？

  level: number; // 用户类型标示 1 官方 0 普通

  msg_id: string; // 消息ID

  msg_type: string; // 类型

  nickname: string; // 昵称

  send_status: number; // 发送状态 与 消息审核相关

  studio_id: number; // 直播间ID

  to_avatar: number;

  to_avatar_url: string;

  to_nickname: string;

  to_uid: number;

  time: string; // 详细时间

  type?: string;

  image_content?: string;

  image_content_url?: string;

  uid: number; //  用户id
  constructor(data: SocketPMsg) {
    this.alias = data.alias;
    this.avatar = data.avatar;
    this.avatar_url = data.avatar_url;
    this.chat_list_id = data.chat_list_id;
    this.content = data.content;
    this.level = data.level;
    this.msg_id = data.msg_id;
    this.msg_type = data.msg_type;
    this.nickname = data.nickname;
    this.send_status = data.send_status;
    this.studio_id = data.studio_id;
    this.to_avatar = data.to_avatar;
    this.to_avatar_url = data.to_avatar_url;
    this.to_nickname = data.to_nickname;
    this.to_uid = data.to_uid;
    this.time = data.time;
    this.type = data.type;
    this.uid = data.uid;
  }
}
// 私聊对话信息对象
export class PrivateChatView extends SocketPMsg {
  id: number;
  // avatar: string; // 头像id
  // avatar_url: string; // 头像url
  barrage_status: number; //*？ 聊天信息状态
  // chat_list_id: number; // 私聊列表ID
  // content: string; // 信息内容
  created_at: string; // 时间
  deleted_at: number; // 删除时间
  ip: string; // ip地址
  // msg_id: string; // websocek 消息id
  // msg_type: string; // 消息类型
  // nickname: string; // 昵称
  // send_status: number; // 发送状态 与 消息审核相关
  status: number; // 状态
  // studio_id: number; // 直播间ID
  to_content: string | null; //* ？
  to_status: number; //* ？
  // uid: number; // 用户id
  updated_at: number; // 更新时间
  constructor(data: any) {
    super(data);
    this.id = data.id;
    this.barrage_status = data.barrage_status;
    this.deleted_at = data.deleted_at;
    this.ip = data.ip;
    this.status = data.status;
    this.to_content = data.to_content;
    this.to_status = data.to_status;
    this.updated_at = data.updated_at;
    this.content = formatEmoji(data.content);
    this.created_at = data.time;
  }
}
/***
 * @description 当前私聊对象
 */
export class PrivateMsgObj {
  alias: string;

  to_avatar: string;

  to_level: number;

  to_nickname: string;

  to_uid: number;

  constructor(data: GroupChat) {
    this.alias = data.alias;
    this.to_avatar = data.avatar;
    this.to_level = data.level;
    this.to_nickname = data.nickname;
    this.to_uid = data.uid;
  }
}

export interface MemberData {
  avatar_url: string;
  /** 终端类型 */
  device_type: number;
  /** 身份类型 */
  level: number;
  visited_at: string;
  name: string;
  uid: number;
  /** 禁言状态 */
  mute: number;
  alias: string;
  avatar: string;
  sub_data: any[];
}

export class MemberClass {
  avatar_url: string;

  /** 终端类型 */
  device_type: number;

  /** 身份类型 */
  level: number;

  visited_at: string;

  name: string;

  uid: number;

  /** 禁言状态 */
  mute: number;

  alias: string;

  avatar: string;

  sub_data: any[];

  isPc?: number;

  isMobile?: number;

  isIpad?: number;

  constructor(data: MemberData) {
    this.avatar_url = data.avatar_url;
    this.device_type = data.device_type;
    this.level = data.level;
    this.visited_at = data.visited_at;
    this.name = data.name;
    this.uid = data.uid;
    this.mute = data.mute;
    this.alias = data.alias;
    this.avatar = data.avatar;
    this.sub_data = data.sub_data;
    //  1 电脑   2 手机  3 ipad
    data.sub_data.forEach((item) => {
      if (item.device_type === 1) {
        this.isPc = 1;
      } else if (item.device_type === 2) {
        this.isMobile = 1;
      } else {
        this.isIpad = 1;
      }
    });
  }
}

export class MemberDataObj {
  alias: string;

  to_avatar: string;

  to_level: number;

  to_nickname: string;

  to_uid: number;
  constructor(data: MemberData) {
    this.alias = data.alias;
    this.to_avatar = data.avatar;
    this.to_level = data.level;
    this.to_nickname = data.name;
    this.to_uid = data.uid;
  }
}

/**
 * 机器人对象
 * @lock 锁定 1｜0
 */
export interface RobotInfo {
  id: number;
  name: string;
  avatar_id: number;
  avatar_url: string;
  lock: number;
  random_area: boolean;
  INT_random_area: boolean;
}

// ws 通知新加数据
export class MemberUpdata {
  alias: string;
  avatar_url: string;
  /** 终端类型 */
  device_type: number;
  /** 身份类型 */
  level: number;
  visited_at: string;
  name: string;
  uid: number;
  avatar: string;
  mute: number;
  isPc?: number;
  isMobile?: number;
  isIpad?: number;
  join: number;
  role_type: number;
  sub_data: any[];
  constructor(data: any) {
    this.isPc = data.device_type === 1 ? 1 : 0;
    this.isMobile = data.device_type === 2 ? 1 : 0;
    this.isIpad = data.device_type === 3 ? 1 : 0;
    this.avatar_url = data.avatar_url;
    this.device_type = data.device_type;
    this.level = data.level;
    this.visited_at = data.time;
    this.name = data.name;
    this.uid = data.uid;
    this.avatar = data.avatar;
    this.mute = 0;
    this.alias = data.alias;
    this.join = data.join;
    this.role_type = data.role_type;
    this.sub_data = [{ avatar_url: data.avatar_url, device_type: data.device_type, level: data.level, visited_at: data.time }];
  }
}

export class subClass {
  alias: string;
  avatar: string;
  device_type: number;
  level: number;
  name: string;
  uid: string;
  visited_at: string;
  isPc?: number;
  isMobile?: number;
  isIpad?: number;
  constructor(data: any) {
    this.alias = data.alias;
    this.avatar = data.avatar;
    this.device_type = data.device_type;
    this.level = data.level;
    this.visited_at = data.time;
    this.name = data.name;
    this.uid = data.uid;
    this.isPc = data.device_type === 1 ? 1 : 0;
    this.isMobile = data.device_type === 2 ? 1 : 0;
    this.isIpad = data.device_type === 3 ? 1 : 0;
  }
}
