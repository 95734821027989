import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取 网页直播
  getWebLive = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_web_link', data);
  };
  // 获取 拉流直播
  getPullLive = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_pull_flow_live', data);
  };
  // 拉流直播 开始 结束 直播
  editPullLive = (data: unknown) => {
    return http.post('/api/v1/live/edit_pull_live', data);
  };
  // 获取拉流地址历史记录
  getPullHistory(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/live_pull_stream_list', data);
  }
  // 获取 文档直播 ppt列表
  getDocumentList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_document_list', data);
  };
  // 文档直播 观看页显示
  setWanchDisplay = (data: unknown) => {
    return http.post('/api/v1/live/set_watch_display', data);
  };
  // 文档直播 获取 上传文档 文档列表
  getMediaList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/media/get_media_file_list', data);
  };
  // 文档直播 确定按钮 添加文档到页面
  moveMediaFile = (data: unknown) => {
    return http.post('/api/v1/live/move_media_document', data);
  };
  // 使用该文档
  useDocment = (data: unknown) => {
    return http.post('/api/v1/live/use_live_document', data);
  };
  // 文档翻页
  setDocmentPage = (data: unknown) => {
    return http.post('/api/v1/live/set_document_page_index', data);
  };
  //  删除文档
  delDocument = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/live/del_live_document', data);
  };
  // 弹出
  setEject = (data: unknown) => {
    return http.post('/api/v1/live/set_display_forced_eject', data);
  };
  // 设置菜单显示
  setMenu = (data: unknown) => {
    return http.post('/api/v1/live/set_document_menu', data);
  };
  // 允许观众翻页
  setTurnPage = (data: unknown) => {
    return http.post('/api/v1/live/set_audience_turn_page', data);
  };
  // 允许观众下载
  setDownload = (data: unknown) => {
    return http.post('/api/v1/live/set_allow_download', data);
  };
  // 主屏显示
  setMianScreen = (data: unknown) => {
    return http.post('/api/v1/live/set_display_force_home_screen', data);
  };
  // 锁定视图
  setLockView = (data: unknown) => {
    return http.post('/api/v1/live/set_document_switch_view', data);
  };
  // 文档翻页延时设置
  setPageDelay = (data: unknown) => {
    return http.post('/api/v1/live/set_page_delay', data);
  };
  // 互动直播状态
  getInteractionStatus = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_web_case_status', data);
  };
  // 获取互动直播信息
  getInteraction = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_studio_web_case_info', data);
  };
  // 开启互动直播
  setInteractionStatus = (data: unknown) => {
    return http.post('/api/v1/live/set_live_studio_web_case', data);
  };
  // 修改主持人密码
  setHostPassword(data: unknown) {
    return http.post('/api/v1/live/set_live_studio_host_password', data);
  }
  // 修改嘉宾密码
  setGuestPassword(data: unknown) {
    return http.post('/api/v1/live/set_live_studio_guest_password', data);
  }
  // 修改参会者密码
  setHelpPassword(data: unknown) {
    return http.post('/api/v1/live/set_live_studio_helper_password', data);
  }
  // 低延迟开关
  setLowLatency(data: unknown) {
    return http.post('/api/v1/live/set_low_latency', data);
  }
  // 转推 获取配置
  describeProjects(data: unknown) {
    return http.post('/api/v1/live_cme/describe_projects', data);
  }
  // 转推 创建转推
  createStream(data: unknown) {
    return http.post('/api/v1/live_cme/create_stream_connect', data);
  }
  // 转推 添加输出源
  addOutput(data: unknown) {
    return http.post('/api/v1/live_cme/handle_stream_connect_project_add_output', data);
  }
  // 删除输出源
  delOutput(data: unknown) {
    return http.post('/api/v1/live_cme/handle_stream_connect_project_delete_output', data);
  }
  // 一键清空
  delProject(data: unknown) {
    return http.post('/api/v1/live_cme/delete_project', data);
  }
  // 开始、结束转推
  conductWork(data: unknown) {
    return http.post('/api/v1/live_cme/handle_stream_connect_project_start_or_stop', data);
  }
  // 转推 修改
  updataOutput(data: unknown) {
    return http.post('/api/v1/live_cme/handle_stream_connect_project_modify_output', data);
  }
  // 获取互动直播会议模式
  getWastPattern(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_web_wast_pattern', data);
  }
  //设置互动直播会议模式
  setWastPattern(data: unknown) {
    return http.post('/api/v1/live/web_wast_pattern', data);
  }
  // 仅支持3D推流开关
  setVrPlaySwitch(data: unknown) {
    return http.post('/api/v1/live/set_vr_play_status', data);
  }
  // 允许观众连麦开关
  setVoiceSwitch = (data: unknown) => {
    return http.post('/api/v2/voice_interaction/set_voice_interaction_switch', data);
  };
  // 获取连麦开关信息
  getVoiceSwitch(data: AxiosRequestConfig) {
    return http.get('/api/v2/voice_interaction/get_voice_interaction_info', data);
  }
}

export default new Api();
