export const columns = [
  {
    title: '活动名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '签到二维码',
    dataIndex: 'qr_code',
    key: 'qr_code',
    align: 'center',
    slots: { customRender: 'code' },
  },
  {
    title: '活动链接',
    dataIndex: 'activity_link',
    key: 'activity_link',
    align: 'center',
    slots: { customRender: 'href' },
  },
  {
    title: '功能开关',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    slots: { customRender: 'status' },
  },
  {
    title: '配置H5',
    dataIndex: 'configure',
    key: 'configure',
    align: 'center',
    slots: { customRender: 'configure' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    align: 'center',
    slots: { customRender: 'operation' },
  },
  {
    title: '数据统计',
    dataIndex: 'census',
    key: 'census',
    align: 'center',
    slots: { customRender: 'census' },
  },
];

export const columnSign = [
  {
    title: '用户头像',
    dataIndex: 'avatar_url',
    key: 'avatar_url',
    align: 'center',
    slots: { customRender: 'avatar_url' },
  },
  {
    title: '用户名称',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
    width: '50%',
  },
  {
    title: '签到时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
  },
];

export interface IList {
  id: number;
  name: string;
  qr_code: string;
  status: boolean;
  activity_link: string;
  activity_code: string;
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
