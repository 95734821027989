
import { defineComponent, ref } from 'vue';
import { Input, Button, message } from 'ant-design-vue';
import http from '@/api/home';

export default defineComponent({
  components: {
    aInput: Input,
    aButton: Button,
  },
  setup() {
    const obj = ref({
      appid: '',
      appsecret: '',
    });
    const get_code = () => {
      http.get_wechat_subscribe().then((res) => {
        obj.value = res.data.data;
      });
    };
    get_code();
    const save = () => {
      const parms = { appid: obj.value.appid, appsecret: obj.value.appsecret };
      http.save_wechat_subscribe(parms).then(() => {
        message.success('保存成功！');
      });
    };
    return {
      obj,
      save,
    };
  },
});
