import { regular } from './regular';
export const modeType = [
  {
    type: 2,
    name: '企业认证',
    content: '免费直播10场，提供专业技术指导',
    icon: 'companyHigh',
    checked: true,
  },
  {
    type: 1,
    name: '个人认证',
    content: '免费直播5场',
    icon: 'personalHighlight',
    checked: false,
  },
];

export const cityData = {
  IT科技: ['互联网电子商务', 'IT软件与服务', 'IT硬件与设备', '电子技术', '通讯与运营商', '网络游戏'],
  金融业: ['银行', '证券｜基金｜理财｜信托', '保险'],
  餐饮: ['餐饮'],
  酒店旅游: ['酒店', '旅游'],
  运输与仓储: ['快递', '物流', '仓储'],
  教育: ['培训', '院校'],
  政府与公共事业: ['学术科研', '交管', '博物馆', '政府｜公共事业｜非营利机构'],
  医疗护理: ['医药医疗', '护理美容', '保健与卫生'],
  交通工具: ['汽车相关', '摩托车相关', '火车相关', '飞机相关'],
  房地产: ['房地产｜建筑', '物业'],
  消费品: ['消费品'],
  商业服务: ['法律', '广告｜会展', '中介服务', '检测认证', '会计｜审计'],
  文体娱乐: ['文化｜传媒', '体育', '娱乐休闲'],
  印刷: ['打印｜印刷'],
  其它: ['其它'],
};

export const provinceData = [
  'IT科技',
  '金融业',
  '餐饮',
  '酒店旅游',
  '运输与仓储',
  '教育',
  '政府与公共事业',
  '医疗护理',
  '交通工具',
  '房地产',
  '消费品',
  '商业服务',
  '文体娱乐',
  '印刷',
  '其它',
];
/**
 * 上传图片
 */
export interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

/**
 * Form数据类型
 */
export interface FormState {
  name: string;
  id_number: string | number;
  contact_person?: string | undefined;
  mobile: string | number;
  address: string;
  industry: string;
  certificate_front: string;
  certificate_rear: string;
  certification_type: number | any;
  code: number | any;
  status: any;
}

/**
 * 表单验证
 */
export const rules = {
  name: [{ required: true, message: '请输入正确信息', trigger: 'blur' }],
  id_number: [{ required: true, pattern: regular.idNumber, message: regular.idNumberMsg, trigger: 'blur' }],
  contact_person: [{ required: true, message: '请输入联系人名称', trigger: 'blur' }],
  mobile: [{ required: true, pattern: regular.mobile, message: regular.mobileMsg, trigger: 'blur' }],
  address: [{ required: true, message: '请输入您的地址', trigger: 'blur' }],
  industry: [{ required: true, message: '请输入您的行业', trigger: 'change' }],
};

export const uploadImg = [
  {
    type: 0,
    change_type: 'enterpriseImg',
    icon: 'Complayupload',
    data: { type: 2, side: 'face' },
    tips: '点击上传营业执照',
    img_name: 'frontImg',
  },
  {
    type: 1,
    change_type: 'identityUp',
    icon: 'idDown',
    data: { type: 1, side: 'face' },
    tips: '点击上传身份证人像面',
    img_name: 'frontImg',
  },
  {
    type: 2,
    change_type: 'identitydown',
    icon: 'idUp',
    data: { type: 1, side: 'back' },
    tips: '点击上传身份证国徽面',
    img_name: 'rearImg',
  },
];

export const formItem = [
  {
    type: 0,
    label: '姓名',
    label2: '企业名称',
    name: 'name',
    placeholder: '请输入姓名',
    placeholder2: '请输入企业名称',
    value: 'name',
  },
  {
    type: 0,
    label: '身份证号',
    label2: '统一信用代码',
    name: 'id_number',
    placeholder: '请输入身份证号',
    placeholder2: '请输入统一信用代码',
    value: 'id_number',
  },
  {
    type: 0,
    label: '常驻地址',
    label2: '经营地址',
    name: 'address',
    placeholder: '请输入您的地址',
    placeholder2: '请输入您的经营地址',
    value: 'address',
  },
  {
    type: 2,
    label: '联系人',
    name: 'contact_person',
    placeholder: '请输入联系人名称',
    value: 'contact_person',
  },
  {
    type: 1,
    label: '手机号码',
    name: 'mobile',
    placeholder: '请输入手机号码',
    value: 'mobile',
  },
];
