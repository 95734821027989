import type { App } from 'vue';
import {
  Button,
  Input,
  Steps,
  Form,
  Row,
  Radio,
  DatePicker,
  Col,
  Checkbox,
  Switch,
  Select,
  Empty,
  Skeleton,
} from 'ant-design-vue';

import { ElInfiniteScroll } from 'element-plus';

const ant = {
  install(Vue: App<Element>): void {
    Vue.component(Button.name, Button);
    Vue.component(Input.name, Input);
    Vue.component(Input.Password.name, Input.Password);
    Vue.component(Steps.name, Steps);
    Vue.component(Form.name, Form);
    Vue.component(Row.name, Row);
    Vue.component(Radio.name, Radio);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(Col.name, Col);
    Vue.component(Checkbox.name, Checkbox);
    Vue.component(Switch.name, Switch);
    Vue.use(Select);
    Vue.component(Empty.name, Empty);
    Vue.component(Skeleton.name, Skeleton);
    Vue.use(ElInfiniteScroll);
  },
};
export default ant;
