import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  // VUE_APP_MOCK = process.env.VUE_APP_MOCK === '1';

  // 获取五福活动列表
  getFiveBlessing = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/list', data);
  };

  // 添加五福活动
  addfiveBlessing(data: unknown) {
    return http.post('/api/v1/five_blessing/create', data);
  }

  // 编辑五福活动
  editfiveBlessing(data: unknown) {
    return http.post('/api/v1/five_blessing/edit', data);
  }

  // 五福开奖
  openfiveBlessing(data: unknown) {
    return http.post('/api/v1/five_blessing/open', data);
  }

  // 五福重置
  resetfiveBlessing(data: unknown) {
    return http.post('/api/v1/five_blessing/reset', data);
  }

  // 删除五福活动列表
  delfiveBlessing(data: unknown) {
    return http.post('/api/v1/five_blessing/delete', data);
  }

  // 获取五福详情
  getfiveBlessingView = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/view', data);
  };

  // 获取福卡报告
  getLuckyCardRecord = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/user_card', data);
  };

  // 获取活动中奖报告
  getLuckyRecord = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/open_result', data);
  };

  // 获取福卡列表
  getLuckyCardList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/card_list', data);
  };

  // 添加福卡
  addLuckyCard = (data: unknown) => {
    return http.post('/api/v1/five_blessing/card_create', data);
  };

  // 编辑福卡
  editLuckyCard = (data: unknown) => {
    return http.post('/api/v1/five_blessing/card_edit', data);
  };

  // 删除福卡
  delLuckyCard = (data: unknown) => {
    return http.post('/api/v1/five_blessing/card_delete', data);
  };

  // 福卡弹出
  popupLuckyCard = (data: unknown) => {
    return http.post('/api/v1/five_blessing/card_popup', data);
  };

  // 获取福卡详情
  getLuckyCardView = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/five_blessing/card_view', data);
  };
}

export default new Api();
