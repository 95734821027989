import { AxiosRequestConfig } from 'axios';
import http from '../plugins/http';
// import MockPath from '@/mock/mock_api';
// import { AxiosRequestConfig } from 'axios';

// const mockPath = new MockPath();
class Api {
  // VUE_APP_MOCK = process.env.VUE_APP_MOCK === '1';
  // 后台用户登录成功加入群组
  adminJoin(data: unknown) {
    return http.post('/api/v1/common/admin_join', data);
  }
  // 小铃铛数据 待沟通 没有swagger 文档记录
  broadcastMsg(data?: AxiosRequestConfig) {
    return http.get('/api/v1/home/broadcast_message', data);
  }

  // 消息中心
  getAllMsg = (data?: AxiosRequestConfig) => {
    return http.get('/api/v1/console/get_all_broadcast_messages', data);
  };

  // 获取广播信息详情
  getAllMsgInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/console/get_broadcast_messages_info', data);
  };

  // 设置全部广播消息为已读
  setAllMsgread = () => {
    return http.get('/api/v1/console/set_broadcast_messages_all_read');
  };

  // 获取 近期直播列表 and 直播间图表
  getRecentLive = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/home/recent_live', data);
  };

  // 获取获取主页信息
  getHomeDataList = () => {
    return http.get('/api/v1/home/get_home_data_list');
  };

  //获取微信公众号关注
  getPublicContent = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_public_content', data);
  };

  //添加微信公众号关注
  addWeChatPublic = (data: unknown) => {
    return http.post('/api/v1/live/add_we_chat_public', data);
  };

  // 添加微信公众号图片
  getCustomized = () => {
    return http.get('/api/v1/live/get_default_customized');
  };

  //获取直播倒计时详情
  getStudioCountdownContent = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_studio_countdown_content', data);
  };

  //添加直播倒计时详情
  addStudioCountdown = (data: unknown) => {
    return http.post('/api/v1/live/add_studio_countdown', data);
  };

  //保存定制配置信息
  getCustomizedUpload = (data: unknown) => {
    return http.post('/api/v1/live/customized_upload', data);
  };

  // 获取定制配置
  getDefaultCustomized = () => {
    return http.get('/api/v1/live/get_default_customized');
  };
  //获取微信定制
  getConfig = () => {
    return http.get('/api/v1/admin/get_config');
  };

  // 添加修改定制配置
  getSaveConfig = (data: unknown) => {
    return http.post('/api/v1/admin/save_config', data);
  };
  // 获取微信支付定制配置
  getPayCustomized = () => {
    return http.get('/api/v1/live/get_pay_customized');
  };
  // 添加修改微信支付定制配置
  getWxPayCustomized = (data: unknown) => {
    return http.post('/api/v1/live/wx_pay_customized', data);
  };
  // 微信支付证书上传
  getWxPayCertificate = (data: unknown) => {
    return http.post('/api/v1/live/wx_pay_certificate', data);
  };
  // 上传证书密钥
  getWxPaymentKeyUpload = (data: unknown) => {
    return http.post('/api/v1/live/wx_payment_key_upload', data);
  };

  // 客管注册接口
  getRegister = (data: unknown) => {
    return http.post('/api/v1/admin/register', data);
  };

  // 获取验证码
  sendCaptcha = (data: unknown) => {
    return http.post('/api/v1/common/send_captcha', data);
  };

  // 子账号更新
  getupdate = (data: unknown) => {
    return http.post(' /api/v1/admin/update', data);
  };

  // 子账号列表
  getadminList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/list', data);
  };
  // 获取子账号的详细信息列表
  getChildDetailList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_child_detail_list', data);
  };
  // 获取子账号的管理直播间列表
  getChildLiveRoomList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_child_live_room_list', data);
  };
  // 删除子账号管理的直播间
  delLiveStudioChildStudio = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/live/del_live_studio_child_studio', data);
  };

  //获取子账号管理
  // getMemberChildList = (data: AxiosRequestConfig) => {
  //   return http.get('/api/v1/admin/list', data);
  // };
  // 子账号删除
  getdelete = (data: unknown) => {
    return http.post(' /api/v1/admin/delete', data);
  };
  // 客户管理员创建下级账号
  createSubAccount = (data: unknown) => {
    return http.post('/api/v1/admin/create_sub_account', data);
  };

  // 获取控制台收支明细
  getConsoleReceiveList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/get_console_receive_list', data);
  };

  // 获取功能权限
  getFuncPermission = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/func_permission', data);
  };
  //后台用户访问记录
  adminUserVisit = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/admin_user_visit', data);
  };
  //获取用户认证状态
  getAuthenticationStatus = () => {
    return http.get('/api/v1/common/get_authentication_status');
  };

  //检测余额情况
  checkBalanceStatus() {
    return http.get('/api/v1/console/check_balance_status');
  }

  // 版本状态
  getVersionStatus() {
    return http.get('/api/v1/common/version_status');
  }
  // 续用试用版
  setVersionStatus() {
    return http.post('/api/v1/common/continue_free_version');
  }

  //获取平台资讯内容
  userGetInformation() {
    return http.get('/api/v1/common/user_get_information');
  }

  // 用户名是否存在
  name_is_exist(data: unknown) {
    return http.post('/api/v1/admin/name_is_exist', data);
  }
  // 后台新注册接口  http://http://www.bonweylive.com/api/v1/admin/new_register
  new_register(data: unknown) {
    return http.post('/api/v1/admin/new_register', data);
  }
  // 获取公众号信息
  get_wechat_subscribe() {
    return http.get('/api/v2/account/get_wechat_subscribe');
  }
  // 保存公众号信息
  save_wechat_subscribe(data: unknown) {
    return http.post('/api/v2/account/save_wechat_subscribe', data);
  }
}
export default new Api();
