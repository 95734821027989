
import { defineComponent, ref } from 'vue';
import Crumb from '@/components/Crumb.vue';
import CDelModal from '@/components/CDelModal.vue';
import { message } from 'ant-design-vue';
import { InputNumber, Popover } from 'ant-design-vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';
import http from '@/api/Interactive';
import router from '@/router';
import giftReward from './GiftReward.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { getToken } from '@/plugins/cookie';
import CUpload from '@/components/CUpload.vue';
import CLoading from '@/components/CLoading.vue';
import { dragDrop } from '@/utils/format/dragDrop';

const draggable = VueDraggableNext;
export default defineComponent({
  components: {
    Crumb,
    CUpload,
    CDelModal,
    aInputNumber: InputNumber,
    aPopover: Popover,
    // CloseOutlined,
    // aTextarea: Input.TextArea,
    GiftReward: giftReward,
    draggable: VueDraggableNext,
    // aUpload: Upload,
    // PlusOutlined,
    // LoadingOutlined,
    QuestionCircleFilled,
    CLoading,
  },
  props: {},

  setup() {
    const showLoading = ref<boolean>(false);
    const fileList = ref();
    let token = getToken();
    const liveInfo = router.currentRoute.value.params.studioId;
    const select = ref(1);
    const features = ref('');
    const imageSize = ref('');
    const secLimitGift = ref('');
    const matics = ref('');
    const createGift = ref(true);
    const loading = ref<boolean>(false);
    // const image_url = ref('');
    const logo_id = ref<string>('');
    interface lives {
      id: number;
      image_url: string;
      loading?: boolean;
      title: string;
      image_id: any;
      amount: string;
      index?: any;
    }
    // let obj=ref({
    //   title:'',
    // })
    // 关闭按钮
    const deletes = ref<boolean>(false);
    const splicindex = ref();
    const closeClick = (index: any) => {
      splicindex.value = index;
      deletes.value = true;
    };
    // del  close
    const openDelModal = () => {
      deletes.value = false;
    };

    const close = () => {
      live.value.splice(splicindex.value, 1);
      deletes.value = false;
    };
    // 重置按钮
    const reset = () => {
      let giftItem = [
        {
          id: 1,
          image_id: 5460,
          title: '点赞',
          amount: '0.00',
          image_url: 'http://images.innocomn.com/dedc0202007311554367026.png',
        },
        {
          id: 2,
          image_id: 5461,
          title: '比心',
          amount: '2.00',
          image_url: 'http://images.innocomn.com/9f9e4202007311554445166.png',
        },
        {
          id: 3,
          image_id: 5462,
          title: '鼓掌',
          amount: '10.00',
          image_url: 'http://images.innocomn.com/9d45c202007311554461798.png',
        },
        {
          id: 4,
          image_id: 5463,
          title: '亲亲',
          amount: '200.00',
          image_url: 'http://images.innocomn.com/2741a202007311554498521.png',
        },
        {
          id: 5,
          image_id: 5464,
          title: '棒棒糖',
          amount: '5.00',
          image_url: 'http://images.innocomn.com/c1fdc202007311554527558.png',
        },
        {
          id: 6,
          image_id: 5465,
          title: '皇冠',
          amount: '100.00',
          image_url: 'http://images.innocomn.com/1c149202007311554557367.png',
        },
        {
          id: 7,
          image_id: 5466,
          title: '火箭',
          amount: '199.00',
          image_url: 'http://images.innocomn.com/8ee62202007311554581140.png',
        },
        {
          id: 8,
          image_id: 5467,
          title: '跑车',
          amount: '998.00',
          image_url: 'http://images.innocomn.com/b370e202007311555022833.png',
        },
      ];
      live.value = giftItem;
      imageSize.value = restSize.value;
      secLimitGift.value = restLimit.value;
    };
    //添加按钮
    const addOption = () => {
      let obj = {
        id: 0,
        amount: '',
        image_id: 0,
        image_url: '',
        title: '',
      };
      live.value.push(obj);
    };
    // 一键免费
    const freeCharge = () => {
      live.value.forEach((item) => (item.amount = '0.00'));
    };
    // 拖拽
    const dragOptions = {
      animation: 0,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
    // 中部广告 intermediate_ad_list
    interface intermediateList {
      bg_color: string;
      content: string;
      id?: number;
      link_url: string;
      type: string;
    }
    //中部广告 数据 数组
    const intermediate_ad = ref<intermediateList[]>([
      {
        bg_color: '#00ccb0',
        content: '文字',
        link_url: 'http://',
        type: '2',
      },
    ]);
    // 图片上传
    interface FileItem {
      uid: string;
      name?: string;
      status?: string;
      response?: any;
      url?: string;
      type?: string;
      size: number;
      originFileObj: never;
    }
    interface FileInfo {
      file: FileItem;
      fileList: FileItem[];
    }
    // 图片点击事件
    const handleChange = (info: FileInfo, index: number) => {
      if (info.file?.response?.code === 200) {
        live.value[index].image_id = JSON.parse(JSON.stringify(info.file.response)).data.id;
        live.value[index].image_url = JSON.parse(JSON.stringify(info.file.response)).data.url;
      }

      if (info.file.status === 'uploading') {
        live.value[index].loading = true;
        return;
      } else live.value[index].loading = false;
    };
    const imageDel = (info: FileInfo, index: number) => {
      live.value[index].image_id = '';
      live.value[index].image_url = '';
    };
    const live = ref<lives[]>([]);
    // 获取直播间打赏配置
    const restSize = ref('40'); //重置初始值
    const restLimit = ref('0'); //重置初始值
    const studioReward = () => {
      let studio = {
        studio_id: liveInfo,
      };
      showLoading.value = true;
      http
        .getStudioReward({ params: studio })
        .then((res) => {
          live.value = [];
          live.value = [...res.data.data.body.reward_list];
          const degree = res.data.data.body;
          imageSize.value = degree.image_size;
          secLimitGift.value = degree.sec_limit_gift;
          matics.value = degree.status;
          showLoading.value = false;
        })
        .catch((err) => {
          showLoading.value = false;
          message.error(err.data.error);
        });
    };
    studioReward();
    // 添加OR修改直播间打赏礼物配置
    const updateOrInsertReward = () => {
      // 145-153是将数组中的内容过滤斜杠和空格
      let arr = JSON.stringify(live.value);
      let arr2 = JSON.parse(arr);
      var re = new RegExp('"', 'g');
      var fxg = new RegExp('\\\\', 'g');
      // console.log(arr2, 12321321);
      for (let item = 0; item < arr2.length; item++) {
        arr2[item].title = arr2[item].title.replace(fxg, '\\\\');
        arr2[item].title = arr2[item].title.replace(re, '\\"');
      }
      let update = {
        content: JSON.stringify(arr2),
        features: '打赏',
        image_size: imageSize.value,
        sec_limit_gift: secLimitGift.value,
        status: matics.value,
        studio_id: liveInfo,
      };
      http
        .getUpdateOrInsertReward(update)
        .then((res) => {
          // console.log(res.data);
          const resData = res.data;
          message.destroy();
          message.success(resData.message);
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };

    const getCreateStatus = (data: any) => {
      createGift.value = data;
      studioReward();
    };

    return {
      showLoading,
      select,
      live,
      // obj,
      updateOrInsertReward,
      features,
      imageSize, //礼物尺寸
      secLimitGift, //发送频率
      matics, //导航开关
      close, //关闭
      reset, //重置
      addOption, //添加
      createGift,
      getCreateStatus,
      freeCharge, //一键免费
      draggable,
      dragOptions,
      intermediate_ad,
      loading,
      token,
      fileList,
      handleChange,
      // image_url,
      logo_id,
      deletes,
      closeClick,
      openDelModal,
      imageDel,
      dragDrop,
    };
  },
});
