import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  get_unit_status() {
    return http.get('/api/v1/live/get_unit_status');
  }
  set_unit_status(data: unknown) {
    return http.post('/api/v1/live/set_unit_status', data);
  }
}
export default new Api();
