
import Crumb from '@/components/Crumb.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { Space, DatePicker, Radio, Col, Statistic, message, Switch } from 'ant-design-vue';
import { RangePickerIcon } from '@/assets';
import http from '@/api/home';
import router from '@/router';
import { useStore } from 'vuex';
import moment from 'moment';
export default defineComponent({
  components: {
    aSpace: Space,
    aDatePicker: DatePicker,
    aRadio: Radio,
    aRadioGroup: Radio.Group,
    Crumb,
    aCol: Col,
    aStatisticCountdown: Statistic.Countdown,
    aSwitch: Switch,
  },
  setup() {
    const disabled = ref(false);
    const mediaRef = ref();
    const liveInfo = router.currentRoute.value.params.studioId;

    // 计算直播倒计时
    const deadline = ref();
    const onChange = () => {
      // 获取当前时间
      const date = new Date();
      const now = date.getTime();

      // 设置截止时间
      const endDate = new Date(time.value); // 需要倒计时的日期
      const end = endDate.getTime();

      // 时间差
      const leftTime = end - now;
      deadline.value = Date.now() + leftTime;
    };
    // 拿定制KV图片
    const texts = useStore();
    const alips = computed(() => texts.state.home.customData.video_url);

    const select = ref(1);
    //获取直播倒计时详情
    const words = ref('距直播开始');
    const time: any = ref('');
    let open = ref('0');
    const opened = ref<any>(false);
    const writing = ref('Broadcast starts in');
    const languageType = ref(1);
    const langTypeCode = computed(() => (languageType.value == 1 ? 'D 天 H 时 m 分 s 秒' : 'DD : HH : mm : ss'));
    const chart = ref('');
    watch(alips, () => {
      chart.value = alips.value as string;
    });
    const create_at = ref('');
    const degreeData = () => {
      disabled.value = true;
      const data = {
        studio_id: liveInfo,
      };
      http
        .getStudioCountdownContent({ params: data })
        .then((res) => {
          const that = res.data.data.header;
          const hard = res.data.data.body;
          time.value = moment(that.start_at, 'YYYY-MM-DD HH:mm:ss');
          words.value = hard.content ? hard.content : '距直播开始';
          hard.status == 0 ? (opened.value = true) : (opened.value = false);
          writing.value = hard.content_en;
          open.value = hard.position ? hard.position.toString() : '0';
          chart.value = that.img_player_url;
          create_at.value = hard.created_at;
          languageType.value = hard.language_type ? hard.language_type : 1;
          // 获取当前时间
          const date = new Date();
          const now = date.getTime();
          // 设置截止时间
          const end = new Date(that.start_at.replace(/-/g, '/')).getTime();
          // 时间差
          const leftTime = end - now;
          deadline.value = Date.now() + leftTime;
        })
        .finally(() => (disabled.value = false));
    };
    degreeData();

    //添加直播倒计时详情
    const stay = () => {
      if (words.value !== '' && time.value !== '') {
        const beginData = () => {
          const data = {
            studio_id: liveInfo,
            content: words.value,
            start_at: moment(time.value).format('YYYY-MM-DD HH:mm:ss'),
            status: opened.value == true ? 0 : 1,
            content_en: writing.value,
            position: open.value,
          };
          http
            .addStudioCountdown(data)
            .then((res) => {
              const resData = res.data;
              message.destroy();
              message.success(resData.message);
            })
            .catch((res) => {
              message.error(res.data.error);
            });
        };
        beginData();
      } else {
        if (words.value == '') {
          message.info('文字不能为空');
          return;
        }
        if (time.value) {
          message.info('开播时间不能为空');
          return;
        }
      }
    };
    return {
      onChange,
      open,
      opened,
      RangePickerIcon,
      words,
      langTypeCode,
      writing,
      time,
      stay,
      deadline,
      chart,
      select,
      mediaRef,
      create_at, //默认时间
      alips, //默认KV
      languageType,
      disabled,
    };
  },
});
