
import { computed, defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import { Alert, Input, Button, Upload } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
// import ClipboardJS from 'clipboard';
import http from '@/api/home';
import { getToken } from '@/plugins/cookie';
import { useDebounce } from '@/hooks/useDebounce';
import ClipboardJS from 'clipboard';
import { useClipboard } from '@vueuse/core';

export default defineComponent({
  components: {
    aAlert: Alert,
    aInput: Input,
    aButton: Button,
    aUpload: Upload,
    UploadOutlined,

    // aTextarea: Input.TextArea,
  },
  props: {},
  setup() {
    const value = ref<string>('');
    const value1 = ref<string>('');
    const fileList = ref([]);
    const certificatele = ref([]);

    let obj = ref({
      realm: '',
      apiId: '',
      apiSecret: '',
      messagePlate: '',
      payId: '',
      payKey: '',
      paymchid: '',
      payname: '',
      payDir: '',
      paysDir: '',
      file: '',
      certificatele: '',
      pay_certificate_key: '',
    });

    // 获取微信定制
    const whitelList = ref();
    const config = () => {
      http.getConfig().then((res) => {
        const resData = res.data.data;
        obj.value.realm = resData.domain_name;
        obj.value.apiId = resData.wx_api_appId;
        obj.value.apiSecret = resData.wx_api_appSecret;
        obj.value.messagePlate = resData.wx_template_id;
        whitelList.value = [...resData.ip_white_list].join('\n');
      });
    };
    const source = whitelList.value;
    const { copy } = useClipboard({ source });
    // 获取微信支付定制配置
    const payDir = ref('');
    const paysDir = ref('');
    const file = ref('');
    const paycustomized = () => {
      http.getPayCustomized().then((res) => {
        const resData = res.data.data;
        if (res.data.code == 200) {
          if (resData.pay_certificate != '') {
            ishow.value = true;
          }
          if (resData.pay_partner_key != '') {
            shows.value = true;
          }
        }

        obj.value.payId = resData.pay_app_id;
        obj.value.payKey = resData.pay_partner_key;
        obj.value.paymchid = resData.pay_mch_id;
        obj.value.payname = resData.pay_mch_name;
        obj.value.pay_certificate_key = resData.pay_certificate_key;
        payDir.value = resData.http_pay_dir;
        paysDir.value = resData.https_pay_dir;
        file.value = resData.pay_certificate;
      });
    };
    paycustomized();
    config();

    // 复制
    const Copylist = (btns: any) => {
      let ClipboardJSObj = new ClipboardJS(btns);
      // // let ClipboardJSObj2 = new ClipboardJS('.text_btns');
      ClipboardJSObj.on('success', function (e) {
        message.destroy();
        message.info('复制成功');
        e.clearSelection();
      });
      ClipboardJSObj.on('error', function () {
        message.destroy();
        message.info('复制失败');
      });
    };

    // 添加修改定制配置
    const saveconfig = () => {
      let parms = {
        domain_name: obj.value.realm,
        wx_api_appId: obj.value.apiId,
        wx_api_appSecret: obj.value.apiSecret,
        wx_template_id: obj.value.messagePlate,
      };
      console.log(parms);
      http.getSaveConfig(parms).then((date) => {
        // const resData = date.data;
        // message.success(resData.message);
        console.log(date.data);
      });
    };

    // 添加修改微信支付定制配置
    const wxpaycustomized = () => {
      let pays = {
        pay_app_id: obj.value.payId,
        pay_partner_key: obj.value.payKey,
        pay_mch_id: obj.value.paymchid,
        pay_mch_name: obj.value.payname,
      };
      http.getWxPayCustomized(pays).then((res) => {
        // const resData = res.data;
        // message.success(resData.message);
        console.log(res.data);
      });
    };
    // 正则表达式验证页面
    // const pattern = () => {
    //   const realms = /^(http(s)?:)/;
    //   const Pagepattern = [
    //     {
    //       name: obj.value.apiId,
    //       message: 'apiId不能为空',
    //     },
    //     {
    //       name: obj.value.apiSecret,
    //       message: 'apiSecret不能为空',
    //     },
    //     {
    //       name: obj.value.payId,
    //       message: 'apayId不能为空',
    //     },
    //     {
    //       name: obj.value.payKey,
    //       message: 'payKey不能为空',
    //     },
    //     {
    //       name: obj.value.paymchid,
    //       message: 'paymchid不能为空',
    //     },
    //     {
    //       name: obj.value.payname,
    //       message: 'payname不能为空',
    //     },
    //   ];

    //   if (!realms.test(obj.value.realm as string)) {
    //     message.info('您输入的域名不合法');
    //     return false;
    //   } else {
    //     Pagepattern.some((res) => {
    //       if (!res.name) {
    //         message.info(res.message);
    //         return true;
    //       }
    //     });
    //     return true;p
    //   }
    // };

    // 保存
    const keep = useDebounce(() => {
      // console.log(obj.value);
      // let patterns = pattern();
      // if (patterns) {p
      wxpaycustomized();
      saveconfig();
      message.success('保存成功');
      // }
    });
    //
    // 微信支付证书上传
    let token = getToken();
    //  let certificate = {
    //     pay_certificate: obj.value.file,
    //   };
    const wxpaycertificate = (info: any) => {
      // let value: any = JSON.parse(JSON.stringify(fileList.value))[0].response;
      // if (value.code === 200) {
      //   ishow.value = true;
      //   message.destroy();

      //   message.success('上传成功');
      // } else if (value.code === 100) {
      //   ishow.value = false;
      //   message.destroy();

      //   message.success('文件已存在');
      // }
      message.destroy();
      if (info.file?.response?.code === 200) {
        if (info.file.response?.data.code === 100) {
          ishow.value = false;
          message.error(info.file.response.data.error);
        } else {
          ishow.value = true;
          message.success(info.file.response.message);
        }
      }
    };

    // 上传证书密钥
    const wxPaymentKeyUpload = (info: any) => {
      // let value: any = JSON.parse(JSON.stringify(obj.value.certificatele));
      // if (!value[0].response) {
      //   return;
      // }
      // if (value[0].response.code === 200) {
      //   shows.value = true;
      //   message.destroy();
      //   message.success('上传成功');
      // } else if (value.code === 100) {
      //   shows.value = false;
      //   message.destroy();
      //   message.success('文件已存在');
      // }
      message.destroy();
      if (info.file?.response?.code === 200) {
        if (info.file.response?.data.code === 100) {
          shows.value = false;
          message.error(info.file.response.data.error);
        } else {
          shows.value = true;
          message.success(info.file.response.message);
        }
      }
    };
    const ishow = ref(false);
    const shows = ref(false);
    const show = () => {
      // ishow.value = !ishow.value;
    };
    const domain = computed(() => (obj.value.realm ? obj.value.realm : 'live.qingtv.com'));
    return {
      Alert,
      value,
      value1,
      fileList,
      obj,
      certificatele,
      headers: {
        authorization: 'authorization-text',
      },
      Copylist,

      keep,
      domain,
      wxpaycertificate,
      token,
      whitelList,
      payDir,
      paysDir,
      show,
      ishow,
      shows,
      wxPaymentKeyUpload,
      copy,
    };
  },
});
