/**
 * @topShow 导航栏显示
 * @logoUrl LOGO图片
 * @videoCv 播放器封面
 * @themeColor 主题颜色
 * @pcBgColor PC页面背景
 * @pcBgUrl PC背景图
 *
 *  @textColor1 导航栏时间颜色
 * @textColor2 导航栏昵称颜色
 * @tabBgColor tab背景色
 * @msgBgColor 聊天室背景
 * @sendBgColor 发送输入框背景色
 * @menuBgColor 自定义菜单栏背景色
 * @tabTitleColor tab标题颜色
 * @topBbColor 导航栏背景颜色
 * @msgColor 信息颜色
 *@mobiled_bg 移动端聊天背景色
 *@mobiled_meau_bg 移动端菜单背景色
 *
 */
export interface ModalData {
  topShow: boolean;
  logoUrl: string;
  videoCv: string;
  themeColor: string;
  pcBgColor: string;
  pcBgUrl: string;
  titleColor: string;
  topBbColor: string;
  textColor1: string;
  textColor2: string;
  tabBgColor: string;
  pc_botton_bg: string;
  msgBgColor: string;
  sendBgColor: string;
  menuBgColor: string;
  tabTitleColor: string;
  msgColor: string;
  sendColor: string;
  type: string;
  mobile_bg: string;
  mobile_meau_bg: string;
  mobile_meau_color: string;
  mobile_botton_bg?: string;
  mobile_send_bg: string;
  pc_top_bgimg?: string;
  pc_tab_bgimg?: string;
  pc_msg_bgimg?: string;
  pc_meau_bgimg?: string;
  mobile_tab_bgimg?: string;
  mobile_msg_bgimg?: string;
  opacity_pc_0?: number;
  opacity_pc_1?: number;
  opacity_pc_2?: number;
  opacity_pc_3?: number;
  opacity_mobile_0?: number;
  opacity_mobile_1?: number;
  image_id?: any;
  color_ke?: string;
  allKey?: number;
  styleList?: any;
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export interface StyleList {
  key: number;
  title: string;
  type_name: string;
  bg_color: string;
  opacity: number;
  image_url: string;
  fileList: any[];
  loading: boolean;
  showUrl: boolean;
}

export class PcStyleSet {
  type_name: string;
  bg_color: string;
  opacity: number;
  image_url: string;
  constructor(data: StyleList) {
    this.type_name = data.type_name;
    this.bg_color = data.bg_color;
    this.opacity = data.opacity;
    this.image_url = data.image_url;
  }
}

export interface PcTemplate {
  id: number;
  name: string;
  style_info: PcStyleSet[];
  type: number;
  preview_image: string;
  bg_color: string;
  dark_mode: number;
  image_bg_url: string;
  theme_color: string;
  image_bg_id: number;
  showInput: boolean;
}
