import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取云导播列表
  get_describe_caster_channels(data: unknown) {
    return http.post('/api/v1/common/describe_casters', data);
  }
  // 删除
  delete_caster(data: unknown) {
    return http.post('/api/v1/common/delete_caster', data);
  }
  // 创建
  create_caster(data: unknown) {
    return http.post('/api/v1/common/create_caster', data);
  }
  // 获取导播台配置
  describe_caster_config(data: unknown) {
    return http.post('/api/v1/common/describe_caster_config', data);
  }
  describe_caster_stream_url(data: unknown) {
    return http.post('/api/v1/common/describe_caster_stream_url', data);
  }
  get_play_info(data: unknown) {
    return http.post('/api/v1/common/get_play_info', data);
  }
  // 复制导播台
  copy_caster(data: unknown) {
    return http.post('/api/v1/common/copy_caster', data);
  }
  // 获取云拆条列表
  search_editing_project(data: unknown) {
    return http.post('/api/v1/common/search_editing_project', data);
  }
  //创建云拆条
  create_editing_project(data: unknown) {
    return http.post('/api/v1/common/create_editing_project', data);
  }
  // 删除云拆条
  delete_editing_projects(data: unknown) {
    return http.post('/api/v1/common/delete_editing_projects', data);
  }
  //  取消预约
  editing_project(data: unknown) {
    return http.post('/api/v1/common/update_editing_project', data);
  }
  // 获取单个直播剪辑工程
  get_editing_project(data: unknown) {
    return http.post('/api/v1/common/get_editing_project', data);
  }
  get_editing_project_materials(data: unknown) {
    return http.post('/api/v1/common/get_editing_project_materials', data);
  }

  // 签到上墙
  // 获取活动列表
  get_activity_list(data: AxiosRequestConfig) {
    return http.get('/api/v1/console/get_activity_list', data);
  }
  // 添加活动
  create_activity(data: unknown) {
    return http.post('/api/v1/console/create_activity', data);
  }
  // 获取活动信息
  get_activity_info(data: AxiosRequestConfig) {
    return http.get('/api/v1/console/get_activity_info', data);
  }
  // 修改活动信息
  edit_activity(data: unknown) {
    return http.post('/api/v1/console/edit_activity', data);
  }
  // 删除活动信息
  del_activity_info(data: AxiosRequestConfig) {
    return http.get('/api/v1/console/del_activity_info', data);
  }
  // 观看页获取用户签到列表
  get_front_user_sign_list(data: AxiosRequestConfig) {
    return http.get('/api/v1/console/get_user_sign_in_list', data);
  }
  // 设置活动开关
  set_activity_status(data: unknown) {
    return http.post('/api/v1/console/set_activity_status', data);
  }
  // 配置活动页面
  set_activity_page(data: unknown) {
    return http.post('/api/v1/console/set_activity_page', data);
  }
  // 复制活动信息
  copy_activity_info(data: unknown) {
    return http.post('/api/v1/console/copy_activity_info', data);
  }
}

export default new Api();
