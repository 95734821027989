
import { defineComponent, ref, reactive } from 'vue';
import { Tabs, Table, Pagination, DatePicker } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import moment from 'moment';
import { RangePickerIcon } from '@/assets';
import http from '@/api/account';
import { ResourceBill } from './ResourceBill';
const columns = [
  {
    title: '流水编号',
    dataIndex: 'id',
    width: 180,
    align: 'center',
    key: '0',
  },
  {
    title: '服务类型',
    dataIndex: 'service_type_title',
    key: '1',
    align: 'center',
    width: 200,
    ellipsis: true,
  },
  {
    title: '金额',
    dataIndex: 'price',
    key: '2',
    align: 'center',
    width: 200,
    slots: {
      customRender: 'price',
    },
  },
  {
    title: '生成时间',
    width: 200,
    dataIndex: 'created_at',
    key: '3',
    align: 'center',
  },
  {
    dataIndex: 'remark',
    key: '4',
    ellipsis: true,
    align: 'left',
    slots: {
      title: 'cTipsTitle',
    },
  },
];
const showLoading = ref<boolean>(false);
const Postes = ref<DataItem[]>([]);
interface DataItem {
  key: number;
  id: number;
  service_type_title: string;
  price: string;
  created_at: string;
  remark: string;
}
// for (let i = 0; i < 8; i++) {
//   Postes.push({
//     key: i,
//     id: 10718,
//     service_type_title: '收入',
//     price: '0.01',
//     created_at: '2021-09-14 15:22:59',
//     remark:
//       '频道名称:《新后台数据测试专用直播新后台数据测试专用直播新后台数据测试专用直播新后台数据测试专用直播》, 礼物购买:《比心》 X 1',
//   });
// }
export default defineComponent({
  components: {
    [RangePicker.name]: RangePicker,
    aTabs: Tabs,
    aTabPane: Tabs.TabPane,
    aTable: Table,
    aPagination: Pagination,
  },
  props: {},
  setup() {
    //   分页
    const pageConfigure = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    // 获取第三方账单列表
    const queryForms = ref<Date[]>([]);
    const ThirdPartyFeeBillList = () => {
      let third: any = {
        page: pageConfigure.current,
        page_num: pageConfigure.pageSize,
      };
      showLoading.value = true;
      const postThird = new ResourceBill(third);
      postThird.setDate(queryForms.value as any);
      http
        .getThirdPartyFeeBillList({ params: postThird })
        .then((res) => {
          pageConfigure.current = res.data.data.current_page;
          //    pageConfigure.totalSize = res.data.data.total;
          pageConfigure.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          Postes.value = [...res.data.data.data];
          showLoading.value = false;
        })
        .catch(() => {
          showLoading.value = false;
        });
    };
    ThirdPartyFeeBillList();
    const trigger = () => {
      ThirdPartyFeeBillList();
    };
    const showSize = () => {
      ThirdPartyFeeBillList();
    };
    const empty = () => {
      queryForms.value = [];
      ThirdPartyFeeBillList();
    };
    return {
      showLoading,
      moment,
      RangePickerIcon,
      Postes,
      columns,
      pageConfigure,
      queryForms,
      trigger,
      showSize,
      empty,
    };
  },
});
