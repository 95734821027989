import { useConsoleStore } from '@/pinia/console';

const consoleStore = useConsoleStore();

/**
 *  显示隐藏消息通知
 * @description 为实现直播间多开的情况下数据同步
 **/
export const socketSetMsgShow = (socketData: any) => {
  const index = consoleStore.lightGroupMsg.list.findIndex((e) => e.msg_id === socketData.message_id);
  if (index === -1) return;
  consoleStore.lightGroupMsg.list[index].status = socketData.status;
};

/**
 *  置顶消息通知
 * @description 为实现直播间多开的情况下数据同步
 **/
export const socketSetMsgTop = function (socketData: any) {
  const index = consoleStore.lightGroupMsg.list.findIndex((e) => e.msg_id === socketData.message_id);
  if (index === -1) return;
  consoleStore.lightGroupMsg.list[index].top_status = parseInt(socketData.top_status);
  if (socketData.top_status) {
    consoleStore.lightGroupMsg.topList.unshift(consoleStore.lightGroupMsg.list[index]);
  } else {
    consoleStore.lightGroupMsg.topList = consoleStore.lightGroupMsg.topList.filter((e) => e.msg_id !== socketData.message_id);
  }
};
/**
 * 删除消息通知
 * @description 为实现直播间多开的情况下数据同步
 **/
export const socketDelMsg = (socketData: any) => {
  const index = consoleStore.lightGroupMsg.list.findIndex((e) => e.msg_id === socketData.message_id);
  if (index === -1) return;
  if (consoleStore.lightGroupMsg.list[index].top_status === 1) {
    consoleStore.lightGroupMsg.topList = consoleStore.lightGroupMsg.topList.filter((e) => e.msg_id !== socketData.message_id);
  }
  consoleStore.lightGroupMsg.list.splice(index, 1);
};

/**
 *  清空聊天记录通知
 * @description 为实现直播间多开的情况下数据同步
 **/
export const socketClearMsg = () => {
  consoleStore.resetList();
};
