/**
 * @description {WebSocket typescipt class 封装}
 * */

import EventBus from '@/components/EventBus';

// websocket 状态接口
interface IwsStatus {
  msg: string;
  status: number;
}

class Socket {
  public url: string; //websocket 链接地址
  private isLock: boolean; //防止多次重连
  private timer: any; // 重连 延时函数
  private sTimer: any; // ♥跳 延时函数
  private sinTimer: any; // ♥跳
  public timeOut?: number; // ♥跳间隔时长
  public ws: WebSocket | undefined; // websocket实例
  constructor(url: string, timeOut = 10000) {
    this.url = url;
    this.isLock = false;
    this.timeOut = timeOut;
    this.__init__();
  }

  // 初始化函数
  private __init__(): void {
    const url = this.url;
    if (url == undefined || url == '') {
      throw new Error('websocket连接地址不能为空');
    } else {
      this.wsInit();
    }
  }

  // websocket 初始化
  public wsInit(): void {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = () => {
      this.heartCheck();
    };

    this.ws.addEventListener('message', () => {
      this.heartCheck();
    });

    this.ws.onerror = () => {
      this.reconnect();
    };

    this.ws.onclose = (e) => {
      this.reconnect();
    };
    this.ws.onmessage = (msg: any) => {
      const data = JSON.parse(msg.data);
      switch (data.type) {
        case 'init': // 加入
          EventBus.emit('init', data);
          break;
        case 'pc_ping': // 服务器心跳
          break;
        case 'send_to_admin': // 自定义广播通知
          EventBus.emit('broadcast');
          break;
        case 'all_broadcast': // 广播通知
          EventBus.emit('broadcast');
          break;
        case 'chat_number': // 在线人数
          EventBus.emit('updateChatNumber', data);
          break;
        case 'online_number': //更新图表数据
          EventBus.emit('online_number', data);
          break;
        case 'member_update': // 在线成员的加入或离开
          EventBus.emit('memberUpdate', data);
          break;
        case 'send_group': // 群发消息
          EventBus.emit('groupMsg', data);
          break;
        case 'send_light_group': // 轻聊版群聊
          EventBus.emit('sendLightGroup', data);
          return;
        case 'send_comment': //评论
          EventBus.emit('commentMsg', data);
          break;
        case 'send_private': // 私聊消息
          EventBus.emit('privateMsg', data);
          break;
        case 'status': // 推流状态
          EventBus.emit('liveStatus', data);
          break;
        case 'console_usage_documentation': //文档使用
          EventBus.emit('consoleUsageDocumentation', data);
          break;
        case 'vote_update': // 投票更新
          EventBus.emit('updateVote', data);
          break;
        case 'rtasr': // 实时字幕
          EventBus.emit('rtasr', data);
          break;
        case 'cme_status': // 转推状态
          EventBus.emit('turnPushStatus', data);
          break;
        case 'pull_stream_status': // 拉流状态
          EventBus.emit('pullStatus', data);
          break;
        case 'del_chat_information': // 删除聊天消息
          EventBus.emit('delConsoleMsg', data);
          break;
        case 'set_hide_chat_information': // 显示聊天消息
          EventBus.emit('setHideConsoleMsg', data);
          break;
        case 'set_top_chat_information': // 置顶聊天消息
          EventBus.emit('setTopConsoleMsg', data);
          break;
        case 'delete_chat': // 清空聊天消息
          EventBus.emit('clearMsg', data);
          break;
        case 'scan_qr_info': // APP端扫码后 PC收到通知
          EventBus.emit('qrCodeLoginInfo', data);
          break;
        case 'scan_user_info': // APP端确认登陆
          EventBus.emit('qrCodeLoginOk', data);
          break;
        case 'scan_login_rt': // APP端取消登陆
          EventBus.emit('qrCodeLoginCancel', data);
          break;
        case 'download_status': // 下载中心数量
          EventBus.emit('downNum', data);
          break;
        case 'voice_interaction_switch': // 允许连麦开关
          EventBus.emit('interactionStatus', data);
          break;
        default:
          break;
      }
    };
  }

  public closeWebsocket(): void {
    this.ws?.close();
  }

  // 心跳
  private heartCheck(): void {
    this.sTimer && clearTimeout(this.sTimer);
    this.sinTimer && clearInterval(this.sinTimer);
    this.sTimer = setTimeout(() => {
      // 发送♥跳
      (this.ws as WebSocket).send(JSON.stringify({ type: 'pc_ping' }));
      this.sinTimer = setTimeout(() => {
        if ((this.ws as WebSocket).readyState != 1) {
          (this.ws as WebSocket).close();
        }
      }, this.timeOut);
    }, this.timeOut);
  }

  // 重连
  private reconnect(): void {
    if (this.isLock) {
      return;
    }
    this.isLock = true;
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.wsInit();
      this.isLock = false;
    }, 4000);
  }

  // 返回websocket 实例 {promise}
  public getInstance(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      if (this.ws) {
        resolve(this.ws);
      } else {
        reject('出错啦！！！');
      }
    });
  }

  // 返回websocket 实例 {promise}
  public send(formData: any): void {
    if (this.ws) {
      this.ws.send(JSON.stringify(formData));
    }
  }

  // 返回websocket的状态
  public wsStatus(): IwsStatus {
    // CONNECTING：值为0，表示正在连接。
    // OPEN：值为1，表示连接成功，可以通信了。
    // CLOSING：值为2，表示连接正在关闭。
    // CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
    const status = this.ws?.readyState;
    let reStatus = {
      msg: '',
      status: 3,
    };
    switch (status) {
      case 0:
        reStatus = {
          msg: 'connecting',
          status: 0,
        };
        break;
      case 1:
        reStatus = {
          msg: 'open',
          status: 1,
        };
        break;
      case 2:
        reStatus = {
          msg: 'closing',
          status: 2,
        };
        break;
      case 3:
        reStatus = {
          msg: 'closed',
          status: 3,
        };
        break;
      default:
        return reStatus;
    }

    return reStatus;
  }
}

export default Socket;
