import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 主题美化
  getTheme(data: unknown) {
    return http.post('/api/v1/live/theme', data);
  }
  // 直播间详情
  getviewDetails(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/view', data);
  }
  // 开播倒计时接口
  // 1.获取直播倒计时详情
  // getStudioCountdownContent(data: AxiosRequestConfig){
  //     return http.get('/api/v1/live/get_studio_countdown_content',data)
  // }
  // 人数设置
  // 获取直播间放大人数
  getFalsePeople(data: AxiosRequestConfig) {
    return http.get('  /api/v1/live/get_false_people', data);
  }
  // 添加OR修改直播间放大人数
  getUpdateOrInsertFalsePeople(data: unknown) {
    return http.post('/api/v1/live/update_or_insert_false_people', data);
  }
  // 多频道
  // 导航开关
  getStreamThemeHeaderStatus(data: unknown) {
    return http.post(' /api/v1/live/stream_theme_header_status', data);
  }
  // logo设置
  getStreamThemePlayerLogo(data: unknown) {
    return http.post('/api/v1/live/stream_theme_player_logo', data);
  }
  // 直播封面
  getStreamThemePlayerCover(data: unknown) {
    return http.post(' /api/v1/live/stream_theme_player_cover', data);
  }
  // 背景设置
  getStreamThemeImageBg(data: unknown) {
    return http.post(' /api/v1/live/stream_theme_image_bg', data);
  }
  // 频道配色
  getStreamThemeColor(data: unknown) {
    return http.post(' /api/v1/live/stream_theme_color', data);
  }
  // 背景色
  getStreamThemeBgColor(data: unknown) {
    return http.post(' /api/v1/live/stream_theme_bg_color', data);
  }

  // 下载访问数据
  downloadEmptyData(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/download_empty_data', data);
  }
  // 清空访问数据
  delData(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/empty_access_data', data);
  }
  //  全局主体设置
  updataStreamTheme(data: unknown) {
    return http.post('/api/v1/live/stream_global_theme', data);
  }
  // 获取自定义样式设置
  getDecorate(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_decorate_info', data);
  }
  // 保存
  setPcDecorate(data: unknown) {
    return http.post('/api/v1/live/set_advanced_decorate', data);
  }
  // 存储为模板库
  saveToTemplate(data: unknown) {
    return http.post('/api/v1/live/save_to_template', data);
  }
  // 获取模板库数据pc
  getTemplateList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_template_library_list', data);
  }
  // 删除模板
  delTemplate(data: unknown) {
    return http.post('/api/v1/live/del_template', data);
  }
  // 修改模板库名字
  setTemplateName(data: unknown) {
    return http.post('/api/v1/live/set_template_name', data);
  }
}
export default new Api();
