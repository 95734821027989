
import { defineComponent } from '@vue/runtime-core';
import { VueDraggableNext } from 'vue-draggable-next';
import { message, Upload } from 'ant-design-vue';
import { ref } from 'vue';
// import { PlusOutlined } from '@ant-design/icons-vue';
import http from '@/api/live';
import { dragDrop } from '@/utils/format/dragDrop';

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
    [Upload.name]: Upload,
  },
  setup() {
    const languageList = ref();
    let options: any = ref([]);
    const multiList = ref([
      {
        alias: '' || undefined,
        selectImg: '',
        nameSelect: '',
        language: undefined,
        language_id: null,
        studio_id: 0,
        studio_name: '',
        studio_index: null,
        input: undefined,
        select: undefined,
        selectLive: 0,
      },
    ]);
    // 获取接口数据
    const getList = () => {
      http
        .getLanguageList()
        .then((res) => {
          languageList.value = res.data.data.data;
        })
        .catch(() => {
          message.destroy();
        });
      http
        .getLanguageStudioList()
        .then((res) => {
          const resData = res.data.data;
          options.value = [...resData];
        })
        .catch(() => {
          message.destroy();
        });
    };
    getList();

    // 拖拽
    const getMulti = (e: any) => {
      console.log(multiList.value, e);
    };

    const getStrLength = (item: any, str: any) => {
      var cArr = str.match(/[^\x00-\xff]/gi);
      if (str.length + (cArr == null ? 0 : cArr.length) > 16) {
        message.error('菜单名称中文不能大于8个字符 ，英文不能大于16个字符！');
        if (cArr == null) {
          item.title_sub = str.substr(0, 16);
        } else {
          item.title_sub = str.substr(0, 8);
        }
      }
    };

    const getSelectLive = (num: number, index: number) => {
      if (num == 1) {
        multiList.value[index].selectLive = 1;
        // multiList.value[index].input = undefined;
      } else {
        const studio_index = multiList.value[index].studio_index;
        if (studio_index !== null) {
          options.value[studio_index].chat_type = 2;
        }
        multiList.value[index].selectLive = 0;
        // multiList.value[index].studio_index = null;
        // multiList.value[index].studio_id = 0;
        // multiList.value[index].select = undefined;
      }
    };
    // 选择频道
    const getChannel = (e: any, index: number) => {
      multiList.value[index].selectImg = languageList.value[e].image_url;
      multiList.value[index].alias = languageList.value[e].ch_name;
      multiList.value[index].language_id = languageList.value[e].id;
    };
    // 选择直播间
    const getSelect = (index: number, resIndex: any) => {
      // let studio_index = multiList.value[index].studio_index;
      // if (studio_index !== null) {
      //   options.value[studio_index].chat_type = 2;
      // }
      // options.value[resIndex].chat_type = 1;
      multiList.value[index].studio_index = resIndex;
      multiList.value[index].studio_id = options.value[resIndex].id;
      multiList.value[index].studio_name = options.value[resIndex].title;
      // multiList.value[index].input = undefined;
    };
    // 选择直播间获取焦点
    // const getFocus = (index: number) => {
    //   const studio_index = multiList.value[index].studio_index;
    //   if (studio_index !== null) {
    //     options.value[studio_index].chat_type = 2;
    //   }
    // };
    // 选择直播间失去焦点
    // const getBlur = (index: number) => {
    //   const studio_index = multiList.value[index].studio_index;
    //   if (studio_index !== null) {
    //     options.value[studio_index].chat_type = 1;
    //   }
    // };

    // 添加
    const toAdd = () => {
      multiList.value.push({
        alias: '' || undefined,
        selectImg: '',
        nameSelect: '',
        language: undefined,
        language_id: null,
        studio_id: 0,
        studio_name: '',
        studio_index: null,
        input: undefined,
        select: undefined,
        selectLive: 0,
      });
    };
    // 删除
    const toDelete = (index: number) => {
      multiList.value.splice(index, 1);
    };

    return {
      multiList,
      getMulti,
      options,
      getChannel,
      toAdd,
      languageList,
      toDelete,
      getSelect,
      // getFocus,
      // getBlur,
      getSelectLive,
      getStrLength,
      dragDrop,
    };
  },
});
