import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取直播间信息接口
  getLiveInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_live_info', data);
  }
  // 直播信息接口
  getAccessListData(data: AxiosRequestConfig) {
    return http.get(' /api/v1/common/get_access_list_data', data);
  }
  // 在线观众时间分布接口
  getHistoryOnline(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/history_online', data);
  }
  //    观看流水接口
  getAccessRunningWater(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_access_running_water', data);
  }
  // 批量导出Excel
  getExcelRegionalDistribution(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/excel_regional_distribution', data);
  }
  // 获取受邀人详情
  get_invited_users(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_invited_users', data);
  }
  // 获取互动概况
  get_interaction_overview(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_interaction_overview', data);
  }
  // 获取资源概况时间段
  resource_overview_time(data: AxiosRequestConfig) {
    return http.get('/api/v2/live/resource_overview_time_slot', data);
  }
  // 获取直播信息
  get_resource_overview(data: AxiosRequestConfig) {
    return http.get('/api/v2/live/resource_overview', data);
  }
  // (筛选版)数据中心观看流水
  getAccessRunnWaterByTime(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_access_running_water_by_time', data);
  }
  // (筛选版)数据中心
  getAccessListDataByTime(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_access_list_data_by_time', data);
  }
  // (筛选版)互动概览
  getInteractionOverviewByTime(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_interaction_overview_by_time', data);
  }
}
export default new Api();
