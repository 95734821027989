import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  //数据详情
  QuestItemsInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quest_items_info', data);
  }
  //数据分析中查询有几题
  ItemsCount(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/items_count', data);
  }
  //数据分析/live/items_analysis
  ItemsAnalysis(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/items_analysis', data);
  }
  // 附件题删除
  questDetailEncDel(data: unknown) {
    return http.post('/api/v1/live/quest_detail_enc_del', data);
  }

  // 数据总览
  // 折线图数据
  questOverview(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quest_overview', data);
  }
}
export default new Api();
