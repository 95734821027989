import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 设置水印
  getSetWatermark = (data: unknown) => {
    return http.post('/api/v1/advanced_settings/set_watermark', data);
  };
  //   获取列表
  getWatermarkInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/advanced_settings/get_watermark_info', data);
  };
  //   获取水印位置列表
  getLocationList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/advanced_settings/get_location_list', data);
  };
  // 上传图片
  upImages(data: unknown) {
    return http.post('/api/v1/common/upload_images', data);
  }
}
export default new Api();
