import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 导出地址
  excel_regional_distribution = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/excel_regional_distribution', data);
  };
  //机器人使用记录导出
  robot_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/robot_list', data);
  };
   // 活动预约 微信预约导出地址
  we_chat_reserve_user = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/we_chat_reserve_user', data);
  };
  // 拉流直播历史记录  导出地址
  live_pull_stream_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/live_pull_stream_list', data);
  };
  //获取自定义菜单信息 and 导出功能 {export = 3} | 导出功能
  get_menus = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_menu', data);
  };
  // 授权观看手机白名单导出名单
  whitelist_export = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/live/whitelist_export', data);
  };
  // 打点统计导出接口
  export_dotting_data = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/export_dotting_data', data);
  };
  //新数据中心导出
  get_access_running = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/get_access_running_water_by_time', data);
  };
  //新数据中心分享榜贡献榜导出
  get_access_list = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/get_access_list_data_by_time', data);
  };

  quest_overview = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/quest_overview', data);
  };
  quest_items_info = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/quest_items_info', data);
  };
  quest_export = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/quest_export', data);
  };
  quest_enc_export = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/quest_enc_export', data);
  };

}
export default new Api();