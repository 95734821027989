
import { defineComponent } from 'vue';
export default defineComponent({
  setup() {
    const Rem = () => {
      let docEl = document.documentElement,
        oSize = docEl.clientWidth / 7.5;
      if (oSize > 100) {
        oSize = 100; //  限制rem值   640 / 6.4 =100
      }
      docEl.style.fontSize = oSize + 'px';
    };
    window.addEventListener('resize', Rem, false);
    Rem();
    // window.daovoice('init', {
    //   app_id: '04cefad5',
    //   email: 'daovoice@example.com', // 替换成当前用户的邮件
    //   user_id: 'NO_89757', // 选填: 替换成当前用户的唯一标识
    //   signed_up: 1449821660, // 选填: // 替换当前用户的注册时间，格式为 Unix 时间戳
    //   name: '道客船长', // 选填: 替换当前用户的真名或者昵称
    // });
    // window.daovoice('update');
    // const daovoice = () => {
    //   window.daovoice('show');
    // };
    return {
      Rem,
      // daovoice,
    };
  },
});
