import { ActionTree, MutationTree, StoreOptions } from 'vuex';
import axios from '@/api/live';
import { BaseSearchFace } from '@/types/base';
import { RootLive, SearchLiveClass } from './types';

class State {
  searchForm: BaseSearchFace = {};
  liveList: RootLive[] = [];
}

class Live implements StoreOptions<State> {
  namespaced = true;
  state = new State();

  mutations: MutationTree<State> = {
    // search and
    updateSearchForm(state, data) {
      state.searchForm = data;
    },
    // list
    updateLiveList(state, data: RootLive[]) {
      state.liveList = data.map((v) => {
        if (v.watch_number || v.watch_number === 0) {
          v.number = v.watch_number;
        }
        if (!v.start_at && v.time !== '') {
          v.start_at = v.time as string;
        }
        if (!v.qr_code_url && v.watch_url !== '') {
          v.qr_code_url = v.watch_url as string;
        }
        return v;
      });
    },
  };

  actions: ActionTree<State, unknown> = {
    // 获取直播列表
    getLiveList({ commit }, data) {
      const searchData = new SearchLiveClass(data);
      searchData.setDate(data.liveDate);
      return new Promise((resolve) => {
        axios.getLiveList({ params: searchData }).then((res) => {
          commit('updateLiveList', res.data.data.data || []);
          resolve(res.data.data);
        });
      });
    },
    // 删除直播列表
    delLiveData(_: unknown, ids: string) {
      const data = {
        ids,
        deleted_at: 1,
      };
      return new Promise((resolve, reject) => {
        axios
          .delLiveData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  };
}
export default new Live();
