import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // vod上传媒体库视频
  createUploadVideo = (data: unknown) => {
    return http.post('/api/v1/media/create_upload_video', data);
  };
  // 刷新视频上传凭证
  refreshUploadVideo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/refresh_upload_video', data);
  };
  // 获取上传地址和凭证
  createUploadImage = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/create_upload_image', data);
  };
  //保存回放
  inset_video_replay = (data: unknown) => {
    return http.post('/api/v1/live/inset_video_replay', data);
  };
  //获取媒体库视频列表
  getVideoList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/demand/get_demand_video_list', data);
  };
  //查看预告回放视频
  get_studio_notice_replay_video = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_studio_notice_replay_video', data);
  };
  //保存直播预告
  set_live_studio_notice = (data: unknown) => {
    return http.post('/api/v1/live/set_live_studio_notice', data);
  };
  // 视频预告是否循环播放
  cycleSwitch(data: unknown) {
    return http.post('/api/v1/live/cycle_switch', data);
  }

  //////////////多频道引导页设置
  //查询直播间信息
  stream_style_show = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/stream_style_show', data);
  };
  //设置
  stream_style_set = (data: unknown) => {
    return http.post('/api/v1/live/stream_style_set', data);
  };

  /**
   * 认证
   */
  //用户提交认证
  user_authentication = (data: unknown) => {
    return http.post('/api/v1/common/user_authentication', data);
  };
  //认证时获取用户注册信息
  get_register_info = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/get_register_info', data);
  };
  //用户获取认证资料
  user_get_authentication_info = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/common/user_get_authentication_info', data);
  };
}
export default new Api();
