import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取直播间竞答红包选择题库列表
  getQuestionList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_live_studio_contest_select_question_list', data);
  }
  //   控制台发送竞答红包
  setContestPack(data: unknown) {
    return http.post('/api/v1/live/set_live_studio_contest_red_pack', data);
  }
  // 微信扫码支付
  weChatPay(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/we_chat_scan_pay', data);
  }
  //  微信扫码支付 新接口
  newWeChatScanPay(data: unknown) {
    return http.post('/api/v1/wechat/partner_scan', data);
  }
  // 控制台--生成红包订单
  generateRedPacked(data: unknown) {
    return http.post('/api/v1/wechat/generate_red_packed_order', data);
  }
  // 轮询订单
  pollingOrder(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/polling_order', data);
  }

  // 微信支付模式
  wechat_pay_mode(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/wechat_pay_mode', data);
  }
}
export default new Api();
