
import Crumb from '@/components/Crumb.vue';
import { defineComponent, ref, reactive, toRefs } from 'vue';
import { Switch, message, Popover } from 'ant-design-vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';
import http from '@/api/home';
import { getToken } from '@/plugins/cookie';
import router from '@/router';
import { useUpload } from '@/components/CUload';
import CUpload from '@/components/CUpload.vue';
import CLoading from '@/components/CLoading.vue';

export default defineComponent({
  components: {
    aSwitch: Switch,
    aPopover: Popover,
    CUpload,
    Crumb,
    CLoading,
    QuestionCircleFilled,
  },

  setup() {
    const liveInfo = router.currentRoute.value.params.studioId;
    let token = getToken();
    // S-上传照片
    const imageUrl = ref<string>('');
    const logo_id = ref('');
    const { fileList, loading, handleChange, imageDel } = useUpload(imageUrl, logo_id);
    // E-上传照片

    // S-开关
    const state = reactive({
      checked1: true,
    });

    //获取微信公众号关注
    const chart = ref('');
    const official = ref('官方公众号');
    const englishName = ref('Official account');
    const chinabutton = ref('');
    const englishbutton = ref('');
    const englishdecribe = ref('Scan QR code to follow');
    const languageType = ref('');
    // const btn_text = ref('');
    const item = ref('关注后可收到最新动态');
    const qrcode = ref('');
    let Singlechoice = ref(1);
    const matics = ref(1);

    const showLoading = ref(false);
    const accountData = () => {
      const data = {
        studio_id: liveInfo,
      };
      showLoading.value = true;
      http
        .getPublicContent({ params: data })
        .then((res) => {
          const degree = res.data.data.body;
          const that = res.data.data.header;
          official.value = degree.name;
          item.value = degree.content;
          imageUrl.value = degree.logo_url;
          matics.value = degree.status;
          Singlechoice.value = degree.pop;
          logo_id.value = degree.logo_id;
          chinabutton.value = degree.btn_text;
          englishName.value = degree.name_en;
          englishbutton.value = degree.btn_text_en;
          englishdecribe.value = degree.content_en;
          chart.value = that.img_player_url;
          select.value = degree.language_type ? degree.language_type : 1;
        })
        .finally(() => (showLoading.value = false));
    };
    accountData();

    //添加微信公众号关注
    const save = () => {
      if (official.value !== '' && item.value !== '') {
        const preserData = () => {
          const data = {
            studio_id: liveInfo,
            name: official.value,
            content: item.value,
            pop: Singlechoice.value,
            status: matics.value,
            logo_id: logo_id.value,
            btn_text: chinabutton.value,
            name_en: englishName.value,
            btn_text_en: englishbutton.value,
            content_en: englishdecribe.value,
          };
          http
            .addWeChatPublic(data)
            .then((res) => {
              const resData = res.data;
              message.destroy();
              message.success(resData.message);
            })
            .catch((err) => {
              message.error(err.data.error);
            });
        };
        preserData();
      } else {
        if (official.value == '') {
          message.info('名称不能为空');
          return;
        }
        // alert('不能为空');
      }
    };
    //开关添加公众号
    // const tealg = () => {
    //   if (official.value !== '' && item.value !== '') {
    //     const preserData = () => {
    //       const data = {
    //         studio_id: 10001458,
    //         name: official.value,
    //         content: item.value,
    //         pop: Singlechoice.value,
    //         status: matics.value,
    //       };
    //       http.addWeChatPublic(data).then((res) => {
    //         const resData = res.data;
    //         message.success(resData.message);
    //       });
    //     };
    //     preserData();
    //   } else {
    //     alert('不能为空');
    //   }
    // };
    //获取公众号图片

    // const BackgroundData = () => {
    //   // const data = {
    //   //   // studio_id: route.params.id,
    //   //   studio_id: 10001458,
    //   // };
    //   http.getCustomized().then((res) => {
    //     const resData = res.data.data;
    //     chart.value = resData.video_url;
    //     console.log(resData,'000000000');

    //   });

    // };

    // BackgroundData();
    // 中英文切换
    const select = ref(1);

    return {
      fileList,
      loading,
      imageUrl,
      handleChange,
      ...toRefs(state),
      Singlechoice,
      official,
      chinabutton, //中文按钮
      englishName, //英文名称
      englishbutton, //英文按钮
      englishdecribe, //英文描述
      item,
      qrcode,
      save,
      disBtn: false, // 防止多次点击保存
      chart,
      matics,
      // tealg,
      transfer: false, // 功能开关
      token,
      logo_id,
      select,
      imageDel,
      languageType,
      showLoading,
    };
  },
});
