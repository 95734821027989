/**
 * 根据 roleId 获取等级图表 .svg
 * 当排除在数组外的 roleId 全部为定制版
 * @param roleId
 * @returns string
 */
export function getRoleIcon(roleId?: number): string {
  if (!roleId) return '';
  const basePath = 'https://images.innocomn.com/';
  const suffix = '.svg';
  let url = '';
  const roleArr = [100, 101, 200, 300, 1237, 1238, 1239, 1240];
  const roleArr2 = [201, 301];
  if (roleArr.includes(roleId)) {
    url = basePath + `version_${roleId.toString()}` + suffix;
  } else if (roleArr2.includes(roleId)) {
    url = basePath + `version_${(roleId - 1).toString()}` + suffix;
  } else {
    url = basePath + 'version_cust' + suffix;
  }
  console.log(url);
  return url;
}

/**
 * 新版计费的版本图标
 * 根据 roleId 获取等级图标 .png
 * @param roleId
 * @returns string
 */
export function getNewVerRoleIcon(roleId?: number): string {
  if (!roleId) return '';
  const basePath = 'https://images.innocomn.com/';
  const suffix = '.png';
  let url = '';
  const roleArr = { 98: 'T0', 102: 'T1', 202: 'T2', 302: 'T3', 303: 'T4', 304: 'TF' };
  const roleArr2 = { 97: 'C0', 103: 'C1', 203: 'C2', 305: 'C3', 306: 'C4', 307: 'CF' };
  if (Object.keys(roleArr).includes(roleId.toString())) {
    url = basePath + roleArr[roleId] + suffix;
  } else if (Object.keys(roleArr2).includes(roleId.toString())) {
    url = basePath + roleArr2[roleId] + suffix;
  }
  return url;
}

/**
 * 动态加载资源
 * @param src 
 * @returns 
 */
export function loadJs(src:string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.type = "text/javascript";
    script.src = src;
    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    }
    script.onerror = () => {
      reject();
    }
  })
}
