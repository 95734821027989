import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取敏感词
  getSensitiveWords(data: AxiosRequestConfig) {
    return http.get('/api/v1/account/get_sensitive_words_list', data);
  }
  //设置敏感词
  setSensitiveWords(data: unknown) {
    return http.post('/api/v1/account/set_sensitive_words', data);
  }
  //删除敏感词
  delSensitiveWords(data: unknown) {
    return http.post('/api/v1/account/del_sensitive_words', data);
  }
  //获取公共词库列表
  getDefaultList() {
    return http.get('/api/v1/account/get_thesaurus_list');
  }
  //保存用户词库
  setDefaultList(data: unknown) {
    return http.post('/api/v1/account/set_thesaurus', data);
  }
  //获取用户词库
  getUserList() {
    return http.get('/api/v1/account/get_user_thesaurus_list');
  }
  // 设置敏感词状态
  setWordsState(data: unknown) {
    return http.post('/api/v1/account/set_sensitive_words_status', data);
  }
  // 删除用户词库
  delWordsState(data: AxiosRequestConfig) {
    return http.get('/api/v1/account/del_user_thesaurus', data);
  }
}

export default new Api();
