import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取下级账号直播间列表
  getSubAccountLiveList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_sub_account_live_list', data);
  };
  // 删除下级账号内的直播间
  getDelSubAccountLiveStudio(data: AxiosRequestConfig) {
    return http.delete('/api/v1/admin/del_sub_account_live_studio', data);
  }
  //添加下级子账号
  addAdminSubAccount = (data: unknown) => {
    return http.post('/api/v1/admin/add_admin_sub_account', data);
  };
  //获取下级登录页配置
  getSubAllocatingLoginPage = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_sub_allocating_login_page', data);
  };
  //修改下级账号登录配置
  setAllocatingLoginPage = (data: unknown) => {
    return http.post('/api/v1/admin/set_sub_allocating_login_page', data);
  };
  //添加下级账号登录配置
  addSubAllocatingLoginPage = (data: unknown) => {
    return http.post('/api/v1/admin/add_sub_allocating_login_page', data);
  };
  //获取下级账号列表
  getSubAllocatingList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_sub_allocating_list', data);
  };
  //删除客管下级账号
  delAdminSubAccount = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/admin/del_admin_sub_account', data);
  };
  //一键复制
  getSubAccountUri = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_sub_account_uri', data);
  };
  //还原下级账号
  setAccountRestoration = (data: unknown) => {
    return http.post('/api/v1/admin/set_account_restoration', data);
  };
  //修改客管下级账号
  setAdminSubAccount = (data: unknown) => {
    return http.post('/api/v1/admin/set_admin_sub_account', data);
  };
  //子账号分配资源
  adminSubAllocatingResources = (data: unknown) => {
    return http.post('/api/v1/admin/admin_sub_allocating_resources', data);
  };
  //获取回收下级账号列表
  getSubRecycleBinList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_sub_recycle_bin_list', data);
  };
  //彻底删除下级账号
  thoroughDeleteSubAdmin = (data: AxiosRequestConfig) => {
    return http.delete('/api/v1/admin/thorough_delete_sub_admin', data);
  };
  // 主账号资源概览
  allAccountInfo = () => {
    return http.get('/api/v2/platform_bill/all_account_info');
  };
  // 子账号资源概览
  getSubordinateAccountResources = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/admin/get_subordinate_account_resources', data);
  };
}
export default new Api();
