
import CDelModal from '@/components/CDelModal.vue';
import { Tabs, Input, Table, Modal, Pagination, message, Image, notification } from 'ant-design-vue';
import { defineAsyncComponent, defineComponent, reactive, ref, toRefs } from 'vue';
const aTabs = Tabs;
const aTabPane = Tabs.TabPane;
const aModal = Modal;
import { UploadOutlined, ExclamationCircleFilled } from '@ant-design/icons-vue';
import { ColumnProps } from 'ant-design-vue/lib/table/interface';
import http from '@/api/questionnnaire';
import { documentPreview } from '@/assets';
import router from '@/router';
import { getToken } from '@/plugins/cookie';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import https from '@/api/exportList';
import { useDebounce } from '@/hooks/useDebounce'; //防抖

const studioId = router.currentRoute.value.params.studioId;
const token = getToken();
const AnalysisEchars = defineAsyncComponent(() => import('./AnalysisEchars.vue'));
type Key = ColumnProps['key'];

interface EnclosureType {
  key: Key;
  avatar_url: string;
  name: string;
  uid: string;
  file_url: string;
  up_time: string;
  id: number;
  record_id: number;
}
interface TextType {
  key: Key;
  avatar_url: string;
  record_id: number;
  content: string;
  uid: string;
  name: string;
}

export default defineComponent({
  components: {
    CDelModal,
    aTabs,
    aTabPane,
    aModal,
    aImage: Image,
    UploadOutlined,
    ExclamationCircleFilled,
    [Input.Search.name]: Input.Search,
    [Table.name]: Table,
    [Pagination.name]: Pagination,
    message,
  },
  setup() {
    const showLoading = ref<boolean>(false);
    const questId = router.currentRoute.value.query.id;
    // 操作表格标题
    const enclosureColumns = [
      {
        title: '编号',
        dataIndex: 'record_id',
        align: 'center',
        width: '10%',
      },
      {
        title: '头像',
        dataIndex: 'avatar_url',
        align: 'center',
        slots: {
          customRender: 'avatar_url',
        },
        width: '8%',
      },
      {
        title: '用户ID',
        dataIndex: 'uid',
        align: 'center',
        width: '12%',
      },

      {
        title: '上传时间',
        dataIndex: 'up_time',
        align: 'center',
        width: '20%',
      },
      {
        title: '附件',
        dataIndex: 'file_url',
        align: 'center',
        width: '20%',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        slots: {
          customRender: 'operation',
        },
        width: '100px',
      },
    ];
    // 文本表格标题
    const textColumns = [
      {
        title: '编号',
        dataIndex: 'record_id',
        align: 'center',
        width: '10%',
      },
      {
        title: '用户头像',
        dataIndex: 'avatar_url',
        align: 'center',
        slots: {
          customRender: 'avatar_url',
        },
        width: '10%',
      },
      {
        title: '用户名',
        dataIndex: 'name',
        align: 'center',
        width: '16%',
      },
      {
        title: '用户ID',
        dataIndex: 'uid',
        align: 'center',
        width: '16%',
      },
      {
        title: '文本答案',
        dataIndex: 'content',
        align: 'center',
        width: '54%',
        ellipsis: true,
      },
    ];
    //搜索关键字
    const keyword = ref();
    //数据分析数组
    const item_config = ref({
      title: '',
      remark: '',
      id: null,
      type: null,
      fill_prop: '',
      fill_count: null,
      structure: {
        header: [],
        record_details_list: {
          data: [],
        },
      },
    });
    //题目显示
    const tabList = ref();
    //查询题目接口
    const ItemsCount = () => {
      let count = {
        studio_id: studioId,
        quest_id: questId,
      };
      http
        .ItemsCount({ params: count })
        .then((res) => {
          if (res.data.code == 200) {
            tabList.value = res.data.data.count;
            items_analysis();
          }
        })
        .catch(() => {
          message.destroy();
        });
    };
    ItemsCount();
    const activeKey = ref(1);
    //切换题目事件
    const itemClick = () => {
      keyword.value = '';
      items_analysis();
    };
    //文本表格数组
    const textData = ref<TextType[]>([]);
    // 操作表格数据
    const enclosureData = ref<EnclosureType[]>([]);
    const showCharts = ref(false);
    //数据分析接口
    const items_analysis = () => {
      let analysis = {
        studio_id: studioId,
        quest_id: questId,
        item: activeKey.value,
        keyword: keyword.value,
      };
      showCharts.value = false;
      showLoading.value = true;
      http
        .ItemsAnalysis({ params: analysis })
        .then((res) => {
          if (res.data.code == 200) {
            item_config.value = res.data.data;
            if (item_config.value.type == 3 || item_config.value.type == 4)
              textData.value = item_config.value.structure.record_details_list.data;
            if (item_config.value.type == 6) enclosureData.value = item_config.value.structure.record_details_list.data;
            if (
              item_config.value.type == 0 ||
              item_config.value.type == 1 ||
              item_config.value.type == 2 ||
              item_config.value.type == 5
            )
              showCharts.value = true;
            showLoading.value = false;
          }
        })
        .catch(() => {
          showLoading.value = false;
        });
    };
    // 表格选择事件
    const state = reactive<{
      selectedRowKeys: Key[];
    }>({
      selectedRowKeys: [], // Check here to configure the default column
    });
    const onSelectChange = (selectedRowKeys: Key[]) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    // 分页器
    const pagination = ref({
      defaultPageSize: 10,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['10', '20', '30', '40'],
      total: 0,
      // onShowSizeChange // 改变每页数量时更新显示
    });

    // 附件下载
    const downLoad = (file_url: string) => {
      window.location.href = file_url;
    };

    const deletesInfo = ref({
      show_modal: ref(false),
      del_type: 0,
      del_id: 0,
    });

    const openDelModal = () => {
      deletesInfo.value.show_modal = false;
    };

    // 附件题删除
    const questDelClick = (status: number, id: any) => {
      deletesInfo.value.show_modal = true;
      deletesInfo.value.del_type = status;
      deletesInfo.value.del_id = id;
    };

    const questDetailEncDel = () => {
      let params = {
        items_id: item_config.value.id,
        id: deletesInfo.value.del_id,
        all: deletesInfo.value.del_type,
      };
      http.questDetailEncDel(params).then((res) => {
        if (res.data.code == 200) {
          message.success(res.data.message);
          deletesInfo.value.show_modal = false;
          items_analysis();
        }
      });
    };

    // 附件导出
    const questEncExport = useDebounce(() => {
      const data = {
        studio_id: studioId,
        is_cron_download: corn.value,
        item_id: item_config.value.id,
      };
      https
        .quest_enc_export({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });

    const fileInfo = ref({
      audio_url: '',
      video_modal: ref(false),
      audio_modal: ref(false),
      isAudio: ref(false),
      isVideo: ref(false),
    });
    let play_data = {
      video_url: '',
    };

    // 查看附件
    const viewFile = (item: any) => {
      if (
        item.ext == 'doc' ||
        item.ext == 'docx' ||
        item.ext == 'ppt' ||
        item.ext == 'pptx' ||
        item.ext == 'xls' ||
        item.ext == 'xlsx'
      )
        window.open('http://view.officeapps.live.com/op/view.aspx?src=' + item.file_url);
      if (item.ext == 'mov' || item.ext == 'wmv' || item.ext == 'mp4') {
        fileInfo.value.isVideo = false;
        play_data.video_url = item.file_url;
        fileInfo.value.video_modal = true;
        fileInfo.value.isAudio = false;
        fileInfo.value.audio_url = '';
      }
      if (item.ext == 'mov' || item.ext == 'mp4') {
        fileInfo.value.audio_modal = true;
        fileInfo.value.isVideo = true;
        fileInfo.value.isAudio = false;
        playStatus.value = true;
        setTimeout(() => {
          MPplayer(play_data); //mp4方法
        });
      }
      if (item.ext == 'mp3') {
        fileInfo.value.audio_modal = true;
        fileInfo.value.isAudio = true;
        fileInfo.value.isVideo = false;
        fileInfo.value.audio_url = item.file_url;
      }
    };
    //m3u8格式
    // const media = ref();
    const videoRef = ref();
    const videoPlayer = ref();
    const playStatus = ref<boolean>(false);
    //mp4格式
    const MPplayer = (play_data: any) => {
      videoPlayer.value = videojs(videoRef.value, {
        playbackRates: [0.5, 0.75, 1, 1.5, 2], //播放速度
        language: 'zh-CN',
        notSupportedMessage: '此视频暂时无法播放,请稍后再试',
        controls: true,
        techOrder: ['html5'], //html5模式
        fluid: true, //流式布局,适应父元素大小
        aspectRatio: '16:9', //流式布局时，自适应大小的比例
        preload: true, //预加载
        autoplay: true, //是否自动播放
        loop: true,
      });
      videoPlayer.value.src({
        src: play_data.video_url,
      });
    };
    const videocancel = () => {
      playStatus.value = false;
      fileInfo.value.audio_modal = false;
      videoPlayer.value.dispose();
    };

    // 导出所有题目分析
    const corn = ref(1);
    const messageTip = ref();
    const quesExport = useDebounce(() => {
      const data = {
        studio_id: studioId,
        is_cron_download: corn.value,
        quest_id: questId,
      };
      https
        .quest_export({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };

    return {
      showLoading,
      showCharts, // 图表显示隐藏
      questId, // 问卷ID
      documentPreview, // 预览图标
      downLoad, // 附件下载方法
      questDelClick, // 附件删除点击
      questDetailEncDel, // 附件删除接口
      deletesInfo, // 附件删除字段
      questEncExport, // 附件导出
      viewFile, // 查看附件
      quesExport, // 导出所有题目分析
      fileInfo,
      AnalysisEchars,
      activeKey,
      tabList,
      search: '',
      onSelectChange,
      pagination,
      enclosureColumns,
      enclosureData,
      textData,
      ...toRefs(state),
      textColumns,
      itemClick,
      item_config,
      keyword,
      items_analysis,
      videocancel,
      videoRef,
      playStatus,
      play_data,
      openDelModal,
    };
  },
});
