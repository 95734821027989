
import * as echarts from 'echarts';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const china = require('@/assets/json/china.json');
import Crumb from '@/components/Crumb.vue';
import { defineComponent, onMounted, reactive, ref, nextTick, createVNode, computed } from 'vue';
import { Tabs, Table, Pagination, Modal, message, notification } from 'ant-design-vue';
import http from '@/api/direct';
import axios from '@/api';
import type { RefererCharts } from './broadCast';
import { getCurrentInstance } from 'vue';
import { getToken, getHelperStatus } from '@/plugins/cookie';
import { ExclamationCircleOutlined, DownloadOutlined, SwapOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useHomeStore } from '@/pinia/home';
import { onExport } from '@/components/CExport'; //导出
import https from '@/api/exportList';
import { useDebounce } from '@/hooks/useDebounce'; //防抖

//地图
const helper = getHelperStatus(); //直播助手标识

//S-表格
const columns = [
  {
    title: '用户名',
    width: 150,
    dataIndex: 'name',
    fixed: 'left',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '用户ID',
    dataIndex: 'uid',
    key: '1',
    width: 150,
    align: 'center',
  },
  {
    title: '所在城市',
    dataIndex: 'addr',
    key: '2',
    width: 150,
    align: 'center',
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: '3',
    width: 150,
    align: 'center',
  },
  {
    title: '在线时长',
    dataIndex: 'visit_duration',
    key: '4',
    width: 150,
    align: 'center',
  },
  {
    title: '观看时长',
    dataIndex: 'watch_duration',
    key: '5',
    width: 150,
    align: 'center',
  },
  {
    title: '首次访问时间',
    dataIndex: 'visited_at',
    key: '6',
    width: 150,
    align: 'center',
  },
  {
    title: '最后在线时间',
    dataIndex: 'leaved_at',
    key: '7',
    width: 150,
    align: 'center',
  },
  {
    title: '来源访问',
    dataIndex: 'referer',
    key: '8',
    width: 150,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '设备访问',
    width: 150,
    dataIndex: 'device',
    fixed: 'right',
    align: 'center',
  },
];

interface DataItem {
  key: number;
  name: string;
  age: number;
  uid: string;
  address: string;
  ip: string;
  visit_duration: string;
  watch_duration: string;
  visited_at: string;
  leaved_at: string;
  referer: string;
  device: string;
}

const dataest = ref<DataItem[]>([]);

//E-表格

//S-表格--2
const columnsl = [
  {
    title: '用户名',
    width: 150,
    dataIndex: 'name',
    fixed: 'left',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '用户ID',
    dataIndex: 'uid',
    key: '1',
    width: 150,
    align: 'center',
  },
  {
    title: '  地址',
    dataIndex: 'address',
    key: '2',
    width: 150,
    align: 'center',
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: '3',
    width: 150,
    align: 'center',
  },
  {
    title: '在线总时长',
    dataIndex: 'visit_duration',
    key: '4',
    width: 150,
    align: 'center',
  },
  {
    title: '观看总时长',
    dataIndex: 'watch_duration',
    key: '5',
    width: 150,
    align: 'center',
  },
  {
    title: '访问次数',
    dataIndex: 'visit_times',
    key: '6',
    width: 150,
    align: 'center',
  },
  {
    title: '首次访问时间',
    dataIndex: 'visited_at',
    key: '7',
    width: 150,
    align: 'center',
  },
  {
    title: '最后在线时间',
    dataIndex: 'leaved_at',
    key: '8',
    width: 150,
    align: 'center',
  },
  {
    title: '来源访问',
    dataIndex: 'referrer',
    key: '9',
    width: 150,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '设备访问',
    width: 150,
    dataIndex: 'device',
    fixed: 'right',
    align: 'center',
  },
];

interface DataIteml {
  key: number;
  name: string;
  uid: string;
  address: string;
  ip: string;
  visit_duration: string;
  watch_duration: string;
  visited_at: string;
  leaved_at: string;
  referer: string;
  device: string;
  visit_times: number;
}
// 流水汇总
const char = ref<DataIteml[]>([]);
//E-表格--2
export default defineComponent({
  components: {
    aTabs: Tabs,
    aTabPane: Tabs.TabPane,
    aTable: Table,
    aPagination: Pagination,
    aModal: Modal,
    DownloadOutlined,
    SwapOutlined,
  },
  emits: ['click:cutVersion'],
  setup(props, context) {
    const route = useRoute();
    const liveInfo = route.params.studioId; //438-439是获取studio_id的方法
    const showLoading = ref<boolean>(false);
    const homeStore = useHomeStore();
    const showCutVersion = computed(() => homeStore.funcData?.showCutVersion); //是否显示切换按钮
    const cutBack = () => {
      context.emit('click:cutVersion');
    };
    // 分享榜
    const Sharelisted = reactive([
      {
        name: '分享榜 ',
        ranking: '排名 ',
        audience: '观众名',
        totalshares: '分享总数',
      },
    ]);
    // 贡献榜
    const Sharelist = reactive([
      {
        name: '贡献榜 ',
        ranking: '排名 ',
        audience: '观众名',
        totalshares: '贡献金额',
      },
    ]);
    // 人气榜
    const Sharelisting = reactive([
      {
        name: '人气榜 ',
        ranking: '排名 ',
        audience: '人气用户',
        totalshares: '人气总数',
      },
    ]);

    interface service {
      name: string;
      value: string;
      user_id: number;
      user_name: string;
      price: string;
      nickname: string;
      like_num: number;
      recommended_num: number;
    }
    // 设备占比
    const share = ref<service[]>([]);
    // 贡献榜
    const devote = ref<service[]>([]);
    //人气榜
    const shareDevote = ref<service[]>([]);
    // 分享榜
    const listDevote = ref<service[]>([]);

    const payment = ref([
      {
        label: '  汇总  ',
        value: 0,
      },
      {
        label: '  流水  ',
        value: 1,
      },
    ]);
    // 分页器
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    // 地域分布导出
    const { proxy }: any = getCurrentInstance();
    const token = getToken();
    const corn = ref(1);
    const messageTip = ref();
    const derivation = useDebounce((type: any) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        is_cron_download: corn.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };
    const derivelList = useDebounce((type: any) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        is_cron_download: corn.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 观看流水，流水汇总导出
    const leadOut = useDebounce((type: any) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        is_cron_download: corn.value,
        group: activeKey.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });

    // s地图图表
    const Viewingvolume = reactive([
      {
        name: '观看量排名 ',
        ranking: '排名 ',
        province: '省份',
        totalshares: '观看量',
      },
    ]);
    const map = ref(`map${Date.now() + Math.random()}`);
    let myMapChart: any;
    const getMap = () => {
      echarts.registerMap('china', china);
      myMapChart = echarts.init(document.getElementById(map.value) as HTMLElement);
      // const outname = [
      //   '吉林',
      //   '辽宁',
      //   '广东',
      //   '黑龙江',
      //   '内蒙古',
      //   '安徽',
      //   '河南',
      //   '云南',
      //   '新疆',
      //   '海南',
      //   '河北',
      //   '台湾',
      //   '青海',
      //   '西藏',
      //   '江苏',
      // ];
      // const outvalue = [12, 6, 8];
      // const outdata = [];
      // for (let i = 0; i < outname.length; i++) {
      //   outdata.push({
      //     name: outname[i],
      //     value: outvalue[i],
      //   });
      // }
      myMapChart.setOption({
        tooltip: {
          show: true,
          formatter: function (params: { value: string; name: string }) {
            if (params.value) {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + params.value + '个&nbsp;&nbsp;';
            } else {
              return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;0个&nbsp;&nbsp;';
            }
          },
          x: '550',
          y: '50',
          feature: {
            saveAsImage: {},
          },
        },
        visualMap: {
          min: share.value[share.value.length - 1] && share.value.length > 1 ? share.value[share.value.length - 1].value : 0,
          max: share.value[0] ? share.value[0].value : 0,
          calculable: true,
          itemWidth: 21,
          itemHeight: 130,
          left: '0',
          bottom: '40',
          text: ['高', '低'],
          // calculable: true,
          textStyle: {
            color: '#999999',
            fontSize: 13,
          },
          inRange: {
            color: ['#C7F3F5', '#00ccb0'],
          },
          outOfRange: {
            show: false,
          },
        },

        geo: {
          map: 'china',
          show: false,
          emphasis: {
            label: {
              show: false,
            },
          },
          itemStyle: {
            show: false,
          },
        },

        series: [
          {
            type: 'map',
            map: 'china',
            aspectScale: 0.75,
            showLegendSymbol: false, // 存在legend时显示
            zoom: 1,
            roam: false, //放大缩小
            // center: [104.114129, 37.550339],
            label: {
              show: true,
              formatter: function (para: any) {
                if (para.value != 0 && para.value) {
                  return '{name|' + para.name + '}';
                } else {
                  return '';
                }
              },
              rich: {
                cnNum: {
                  fontSize: 11,
                  color: '#E9E9E9',
                  align: 'center',
                  show: true,
                },
                name: {
                  fontSize: 11,
                  color: '#ff4d4f',
                  align: 'center',
                  lineHeight: 60,
                  show: true,
                },
              },
              //formatter: '{b}',
              color: '#000',
            },
            itemStyle: {
              areaColor: '#E9E9E9', //地图背景颜色
              borderColor: '#FFF',
              borderWidth: 1,
              //  show: true
            },
            emphasis: {
              itemStyle: {
                //对应的鼠标悬浮效果
                areaColor: '#00ccb0',
                show: true,
              },
              label: {
                //对应的鼠标悬浮效果
                show: true,
                textstyle: {
                  color: '#00ccb0',
                },
              },
            },

            data: share.value,
          },
        ],
      });
    };
    // e地图图表

    // 根据数据随机图表颜色
    function getRandomColor(colorKey: string, index: number) {
      const colors = {
        accessColors: ['#5654F5', '#FFBE20', ' #00ccb0', ' #FF3E3E', '#1890FF', '#FF5D22'],
        broswerColors: ['#5654F5', ' #00ccb0', '#FFBE20', '#1890FF', '#5DDECF', ' #FF3E3E'],
      };
      const newColorArr = colors[colorKey];
      return index > newColorArr.length - 1 ? newColorArr[newColorArr.length - 1] : newColorArr[index];
    }

    const Pulltime = ref([
      {
        id: '0',
        num: '0',
        text: '分钟',
        name: '推流时长',
        color: '#00ccb0',
      },
      {
        id: '1',
        num: '0',
        text: '次',
        name: '观看量（PV）',
        color: '#FA5555',
      },
      {
        id: '2',
        num: '0',
        text: '人',
        name: '观众人数（UV）',
        color: '#FAAD14',
      },
      {
        id: '3',
        num: '0',
        text: '人',
        name: '最高并发',
        color: '#00ccb0',
      },
      {
        id: '4',
        num: '0',
        text: '人',
        name: '注册用户',
        color: '#00ccb0',
      },
      {
        id: '5',
        num: '0',
        text: '元',
        name: '直播收益',
        color: '#FAAD14',
      },
    ]);

    // charts

    const insert = ref(`insert${Date.now() + Math.random()}`); // 观看路径
    const main = ref(`main${Date.now() + Math.random()}`); // 访问来源
    const numberMap = ref(`numberMap${Date.now() + Math.random()}`); // 在线人数
    const equipment = ref(`equipment${Date.now() + Math.random()}`); // 设备占比

    // 设备占比图表
    const equipmentData = ref<RefererCharts[]>([]);
    let equipmentCharts: echarts.ECharts;
    const referEquipment = (sourceEquip: RefererCharts[]) => {
      equipmentCharts.setOption({
        tooltip: {
          trigger: 'item',
        },
        legend: {
          // show: true, //隐藏名称
          // margin: [70, 0, 0, 0],
          icon: 'circle',
          left: 'center',
          bottom: 'bottom',
        },
        toolbox: {
          show: true,
          // 下载按钮图标
          feature: {
            saveAsImage: {
              show: true,
              title: '保存',
              // emphasis: {
              //   iconStyle: {
              //     textPadding: [0, 0, 0, 0],
              //   },
              // },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: true,
            },
            data: sourceEquip,
          },
        ],
      });
    };

    // 访问来源图表
    const refererData = ref<RefererCharts[]>([]);
    let refererCharts: echarts.ECharts;
    const referChartsOption = (sourceData: RefererCharts[]) =>
      refererCharts.setOption({
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        //修改图例
        legend: {
          type: 'scroll',
          // orient: 'vertical',
          icon: 'circle',
          left: 'center',
          bottom: 'bottom',
        },
        toolbox: {
          show: true,
          // 下载按钮图标
          feature: {
            saveAsImage: {
              show: true,
              title: '保存',
              // emphasis: {
              //   iconStyle: {
              //     textPadding: [0, 0, 0, 0],
              //   },
              // },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'outside',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '14',
                fontWeight: 'bold',
              },
            },
            //添加指示线指示文字
            labelLine: {
              show: false,
              length: 15,
              length2: 15,
              lineStyle: {
                color: '#333',
              },
            },
            data: sourceData,
          },
        ],
      });

    // 观看路径图表
    const viewPath = ref<RefererCharts[]>([]);
    let viewPathChart: echarts.ECharts;
    const referViewPath = (sourcePath: RefererCharts[]) => {
      viewPathChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        legend: {
          type: 'scroll',
          left: 'center',
          bottom: 'bottom',
          icon: 'circle',
          // 图例间距
          itemGap: 10,
        },
        toolbox: {
          show: true,
          // 下载按钮图标
          feature: {
            saveAsImage: {
              show: true,
              title: '保存',
              // emphasis: {
              //   iconStyle: {
              //     textPadding: [0, 30, 0, 0],
              //   },
              // },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            labelLine: {
              title: '',
              show: true,
              length: 15, // 改变标示线的长度
              lineStyle: {
                color: '#000', // 改变标示线的颜色
              },
            },
            data: sourcePath,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      });
    };

    // 在线人数图表
    const hisOnlineChartData = reactive<any>({
      dates: [],
      nums: [],
    });

    let historyOnlineCharts: echarts.ECharts;
    const historyOnlineChartsOption = (sourceData: any) => {
      historyOnlineCharts.setOption({
        tooltip: {
          trigger: 'axis',
          position: function (pt: any) {
            return [pt[0], '10%'];
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: sourceData.dates,
        },
        yAxis: {
          type: 'value',
          minInterval: 1, // 最小步长
          min: 0, // 起始
          boundaryGap: false,
          splitLine: {
            show: false,
          },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 90,
            end: 100,
            // maxSpan:2,
          },
          {
            start: 0,
            end: 10,
          },
        ],
        series: [
          {
            name: '在线人数',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgba(0, 204, 176, 1)',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0, 204, 176, 1)',
                },
                {
                  offset: 1,
                  color: 'rgba(0, 204, 176, 0)',
                },
              ]),
            },
            data: sourceData.nums,
          },
        ],
        grid: {
          left: 80,
          right: 80,
        },
      });
    };

    onMounted(() => {
      // s设备占比图表
      window.onresize = function () {
        historyOnlineCharts.resize();
        viewPathChart.resize();
        refererCharts.resize();
        equipmentCharts.resize();
        myMapChart.resize();
      };
      // e设备占比图表
    });

    window.onresize = function () {
      myMapChart.resize();
    };

    const studioInfo = () => {
      let liInfo = {
        studio_id: liveInfo,
      };
      http.getLiveInfo({ params: liInfo }).then((res) => {
        Pulltime.value[2].num = res.data.data.uv;
      });
    };
    studioInfo();

    const accessListData = () => {
      let access = {
        studio_id: liveInfo,
      };
      http.getAccessListData({ params: access }).then((res) => {
        // 数据统计
        Pulltime.value[0].num = res.data.data.live_time;
        Pulltime.value[1].num = res.data.data.watch_number;
        Pulltime.value[2].num = res.data.data.watch_attendance;
        Pulltime.value[3].num = res.data.data.highest_concurrent;
        Pulltime.value[4].num = res.data.data.registered_users;
        Pulltime.value[5].num = res.data.data.live_earnings;

        // 观看路径图表 与 访问来源图表 数据格式化
        const scourceReferer = res.data.data.referer;
        scourceReferer.forEach((e: any, index: number) => {
          refererData.value.push({
            name: e.name,
            itemStyle: { color: getRandomColor('accessColors', index) },
            value: e.value,
          });
        });
        const sourceBros = res.data.data.bros_wer_rank;
        sourceBros.forEach((i: any, index: number) => {
          viewPath.value.push({
            name: i.name,
            value: i.value,
            itemStyle: { color: getRandomColor('broswerColors', index) },
          });
        });
        // 观看路径图表
        const chartViewPath: HTMLElement = document.getElementById(insert.value) as HTMLElement;
        viewPathChart = echarts.init(chartViewPath);
        referViewPath(viewPath.value);

        // 访问来源图表
        const chartDom: HTMLElement = document.getElementById(main.value) as HTMLElement;
        refererCharts = echarts.init(chartDom);
        referChartsOption(refererData.value);

        share.value = res.data.data.sort_view_rank;
        getMap();

        // 设备占比图表
        // 674-682行是图表设备占比图表的数据
        const sourceEquipment = res.data.data.equipment;
        sourceEquipment.forEach((e: any, index: number) => {
          equipmentData.value.push({
            name: e.name,
            value: e.value,
            itemStyle: { color: getRandomColor('accessColors', index) },
          });
        });
        const chartEquip: HTMLElement = document.getElementById(equipment.value) as HTMLElement;
        equipmentCharts = echarts.init(chartEquip);
        referEquipment(equipmentData.value);

        devote.value = res.data.data.contribution_rank;
        shareDevote.value = res.data.data.thumb_up_rank;
        listDevote.value = res.data.data.share_rank;
      });
    };
    accessListData();

    // 在线观众时间分布接口
    const historyOnline = () => {
      const history = {
        type: '',
        studio_id: liveInfo,
      };
      http.getHistoryOnline({ params: history }).then((res) => {
        const resData = res.data.data;
        const dateArr: string[] = [];
        const numArr: number[] = [];
        Object.keys(resData).forEach((res) => {
          dateArr.push(resData[res]['created_at']);
          numArr.push(resData[res]['number']);
        });
        hisOnlineChartData.dates = dateArr;
        hisOnlineChartData.nums = numArr;
        // 在线人数图表
        const chartDo: HTMLElement = document.getElementById(numberMap.value) as HTMLElement;
        historyOnlineCharts = echarts.init(chartDo);
        historyOnlineChartsOption(hisOnlineChartData);
      });
    };
    historyOnline();

    const activeKey = ref(1);
    // 观看流水接口
    const accessRunningWater = () => {
      let runWater = {
        studio_id: liveInfo,
        type: activeKey.value,
        page: pageConfig.current,
        page_num: pageConfig.pageSize,
      };
      showLoading.value = true;
      http
        .getAccessRunningWater({ params: runWater })
        .then((res) => {
          pageConfig.current = Number(res.data.data.current_page);
          // 判断页面无数据页码显示第一页，使用三元运算符判断
          pageConfig.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          dataest.value = [...res.data.data.data];
          char.value = [...res.data.data.data];
          showLoading.value = false;
        })
        .catch((err) => {
          showLoading.value = false;
        });
    };
    accessRunningWater();
    // 分页器点击事件
    const showSize = () => {
      accessRunningWater();
    };

    const bills = () => {
      pageConfig.current = 1;
      accessRunningWater();
    };

    const delVisible = ref(false);
    const showDelModal = () => {
      delVisible.value = !delVisible.value;
    };
    const mobile = ref<string>('');
    const getMobile = () => {
      axios.user.getMemberInfo().then((res) => {
        mobile.value = res.data.data.mobile ? res.data.data.mobile : '';
      });
    };
    getMobile();
    const code = ref<string>('');
    const count = ref(60);
    const timer = ref<any>(0);
    const showCode = ref(false);
    const getCode = () => {
      if (!timer.value) {
        showCode.value = true;
        count.value = 60;
        timer.value = setInterval(() => {
          if (count.value > 0 && count.value <= 60) {
            count.value--;
          } else {
            clearInterval(timer.value);
            showCode.value = false;
            timer.value = 0;
          }
        }, 1000);
      }
      axios.login
        .getSendCaptcha({ type: -1, mobile: mobile.value })
        .then(() => {
          console.log();
        })
        .catch((err) => {
          // message.error(err.data.error);
        });
    };

    const handleOk = () => {
      if (code.value === '') {
        message.info('验证码不能为空！');
        return false;
      } else {
        axios.pagedirect
          .downloadEmptyData({ params: { studio_id: liveInfo, mobile: mobile.value, code: code.value } })
          .then((res) => {
            //数据统计里 清空数据前的下载
            const net = window.open(res.data.data);
            net?.addEventListener('beforeunload', () => {
              Modal.confirm({
                title: () => '此操作会清空直播间数据，是否继续？',
                icon: () => createVNode(ExclamationCircleOutlined),
                centered: true,
                onOk() {
                  axios.pagedirect
                    .delData({ params: { studio_id: liveInfo } })
                    .then((res) => {
                      message.success(res.data.message);
                      showDelModal();
                      accessRunningWater();
                      historyOnline();
                      nextTick(() => {
                        accessListData();
                      });
                      code.value = '';
                    })
                    .catch((err) => {
                      message.error(err.data.error);
                    });
                },
                onCancel() {
                  console.log('Cancel');
                },
                class: 'test',
              });
            });
          })
          .catch((err) => {
            message.error(err.data.error);
          });
      }
    };
    return {
      showLoading,
      Sharelist, //分享榜
      Sharelisted,
      Sharelisting,
      share,
      devote, //贡献榜
      shareDevote, //人气榜
      listDevote, //分享榜
      activeKey,
      dataest,
      columns,
      columnsl,
      char,
      Viewingvolume,
      map,
      equipment,
      payment,
      pageConfig,
      showSize,
      bills,
      derivation, //地域分布导出
      derivelList,
      leadOut, // 观看流水，流水汇总导出
      insert,
      main,
      numberMap,
      Pulltime, // 数据统计
      delVisible,
      showDelModal,
      mobile,
      code,
      getCode,
      count,
      showCode,
      handleOk,
      helper,
      showCutVersion,
      cutBack,
    };
  },
});
