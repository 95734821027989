import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取直播间打卡配置信息
  getSingInInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_sing_in_info', data);
  }
  //   获取直播间打卡配置信息
  getSetSingInInfo(data: unknown) {
    return http.post(' /api/v1/live/set_sing_in_info', data);
  }
  //   获取直播间打卡记录
  getSingInInList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_sing_in_list', data);
  }

  // 获取直播间弹窗广告列表
  getStudioAdList(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/get_studio_ad_list', data);
  }
  // 获取直播间弹窗广告信息
  getSdTimingInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_studio_ad_timing_info', data);
  }
  // 设置直播间弹窗弹出
  getSetStudioAdElastic(data: unknown) {
    return http.post('/api/v1/live/set_studio_ad_elastic', data);
  }

  // 设置直播间弹窗广告定时任务
  getSetStudioAdTiming(data: unknown) {
    return http.post(' /api/v1/live/set_studio_ad_timing', data);
  }
  // 获取直播间放大人数
  getFalsePeople(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/get_false_people', data);
  }

  // 添加OR修改直播间放大人数
  getupdateOrInsertFalsePeople(data: unknown) {
    return http.post(' /api/v1/live/update_or_insert_false_people', data);
  }

  // 获取投票列表
  getVoteList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/vote_list', data);
  };
  // 投票主题开关
  setVoteSwitch(data: unknown) {
    return http.post('/api/v1/live/vote_switch', data);
  }
  // 弹出
  votePopup(data: unknown) {
    return http.post('/api/v1/live/vote_popup', data);
  }
  // 机器人使用记录
  robotList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/robot_list', data);
  }
  upImages(data: unknown) {
    return http.post('/api/v1/common/upload_images', data);
  }
}
export default new Api();
