/**
 * @type 券类型   1代金券  2优惠券   3折扣券   4增值券  5、自定义活动券
 * @status 状态   1未使用   2已使用   0已失效
 */
export class IList {
  id: number;
  type: number;
  background: string;
  status: number;
  pirce: number;
  title: string;
  describe: string;
  cardId: number;
  constructor(data: ICopData) {
    this.id = data.id;
    this.type = data.card_type === 0 ? 5 : data.card_type;
    this.background = '';
    this.status = data.status === 1 ? 2 : data.status === 2 ? 0 : 1;
    this.pirce = data.quota;
    this.title = data.card_name;
    this.describe = data.describe;
    this.cardId = data.card_id;
  }
}

interface ICopData {
  admin_id: number;
  card_id: number;
  card_name: string;
  card_type: number;
  describe: string;
  end: '永久有效';
  get_type: number;
  id: number;
  quota: number;
  status: number; // 0.未使用       1. 已使用       2. 已过期
  value: string;
}
