import { defineStore } from 'pinia';
import {
  Authority,
  ConsoleClass,
  GroupChat,
  MsgComment,
  Page,
  PrivateChat,
  PrivateChatView,
  PrivateMsgObj,
  ResponseConsole,
  MemberData,
  RobotInfo,
  MemberClass,
} from './types/console';
import axios from '@/api';
import { EmotionList, LightGMsg } from './types/livechat';
import { objectPick } from '@vueuse/core';

export const formatEmoji = (content: string): string => {
  let formatContent = content.replace(/>/g, '&gt;').replace(/</g, '&lt;');
  for (const q in EmotionList) {
    const cont = new RegExp(EmotionList[q], 'g');
    formatContent = formatContent.replace(
      cont,
      `<img class="emoji" style="width: 20px;height:20px;margin-right:2px;" src="http://static.lighos.com/emoji/${
        Number(q) + 1
      }.png" align="bottom" alt="${EmotionList[q]}">`
    );
  }
  return formatContent;
};
interface ConsoleState {
  consoleInfo: ConsoleClass; // 直播间信息
  authority: Authority;
  msgRenderCount: number; // 100 毫秒渲染条数
  aiSubTitleRenderCount: number; // 100 字幕逐字渲染频率
  chatType: number; // 聊天类型 公共 1 与 私聊 0
  // 公聊列表-公聊版
  groupMsg: {
    virtualList: GroupChat[];
    virtualCommentList: MsgComment[];
    list: GroupChat[];
    pageConfig: Page;
    topCount: number; // 公聊置顶数量
  };
  // 私聊列表
  privateMsg: {
    newMsgCount: number;
    list: PrivateChat[];
    virtualList: PrivateChatView[];
    pageConfig: Page;
  };
  // 当前私聊对象
  currentDialog: {
    user: PrivateMsgObj | undefined;
    pmsgItem: PrivateChat;
    list: PrivateChatView[];
    pageConfig: Page;
  };
  // 公聊列表-轻聊版
  lightGroupMsg: {
    virtualList: LightGMsg[];
    provisionalList: LightGMsg[]; // 暂存的同步列表数据
    topList: LightGMsg[];
    list: LightGMsg[];
    pageConfig: Page;
  };
  groupListScrollStatus: boolean; // 公共列表接收消息权限 - 当滚动时不加载到列表中配合列表渲染一起使用
  // 成员列表
  memberList: MemberClass[];
  memberCount: {
    allCount: number;
    padCount: number;
    mobileCount: number;
    pcCount: number;
  };
  chatMarkVisible: boolean; // 公聊列表右键遮罩展示状态
  //机器人信息
  robotInfo: RobotInfo;
  robotVisible: boolean;

  toUid: number;
}
// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const useConsoleStore = defineStore('console', {
  // state: 返回对象的函数
  state: (): ConsoleState => ({
    groupListScrollStatus: false,
    msgRenderCount: 100,
    aiSubTitleRenderCount: 0,
    chatType: 1,
    lightGroupMsg: {
      provisionalList: [],
      topList: [],
      virtualList: [],
      list: [],
      pageConfig: {
        current: 1,
        pageSize: 20,
        totalSize: 0,
      },
    },
    memberList: [],
    memberCount: {
      allCount: 0,
      padCount: 0,
      mobileCount: 0,
      pcCount: 0,
    },
    groupMsg: {
      virtualList: [],
      virtualCommentList: [],
      list: [],
      pageConfig: {
        current: 1,
        pageSize: 20,
        totalSize: 0,
      },
      topCount: 0,
    },
    privateMsg: {
      newMsgCount: 0,
      virtualList: [],
      list: [],
      pageConfig: {
        current: 1,
        pageSize: 20,
        totalSize: 0,
      },
    },
    currentDialog: {
      user: {
        alias: '',
        to_avatar: '',
        to_level: 0,
        to_nickname: '',
        to_uid: 0,
      },
      pmsgItem: {
        id: 0,
        alias: '',
        created_at: '',
        display_order: '',
        identity: 0,
        new_record: '',
        news: 0,
        status: 0,
        to_avatar: '',
        to_avatar_url: '',
        to_level: 0,
        to_nickname: '',
        to_uid: 0,
        updated_at: '',
      },
      list: [],
      pageConfig: {
        current: 1,
        pageSize: 20,
        totalSize: 0,
      },
    },
    chatMarkVisible: false,
    authority: {
      avatar: 0,
      avatar_url: '',
      device: '',
      device_type: 0,
      id: 0,
      ip: '',
      level: 0,
      monitor_id: '',
      name: '',
      studio_id: 0,
      uid: 0,
      visited_at: 0,
    },
    robotInfo: {
      avatar_id: 0,
      avatar_url: '',
      id: 0,
      lock: 0,
      name: '',
      random_area: false,
      INT_random_area: false,
    },
    robotVisible: false,
    consoleInfo: {
      id: 0,

      streamStatus: [],

      weChaturl: '',

      vrPlayStatus: 0, // 区分2D、3D

      vrPlaySwitch: false, // 是否仅支持3D

      chatType: 0, // 聊天类型： 轻聊 标准

      limit: 0,

      mode: 0, // 直播模式

      multiStream: [], // 多流

      monitorId: '', // 监控室id 关联 webSocket

      playUrl: [], // 直播链接地址

      playerSwitch: false, // 控台视图-播放器观看页开关

      pushType: false, // 推流类型

      liveUrlStatus: 0,

      pushUrl: [], // 推流地址

      vrPushUrl: [], // vr推流地址

      qrCodeUrl: '', // 二维码观看地址

      startTime: '', // 开播时间

      status: 0, // 直播间状态

      studioPid: 0, // 直播间父级id

      title: '', // 直播间标题

      liveUrlSwitch: 0, //* 暂时不用 旧直播观看页显示

      rehearsalSwitch: false, // 控台视图-彩排模式开关

      rehearsalPwd: '', // 控台视图-彩排模式密码

      imagePlayerCoverId: 0, // 控台视图-封面图片 id

      imagePlayerCoverUrl: '', // 控台视图-封面图片 url

      updateTime: '', // 控台视图-更新时间

      uvCount: 0, // 控台视图-在线人数(并发)

      pvCount: 0, // 控台视图-观看量

      memberVisible: false, // 在线人数-观看页显示的控制开关

      chatVisible: false, // 互动聊天-观看页显示的控制开关

      barrageReview: false, // 互动聊天设置-审核弹幕开关

      barrageVisible: false, // 互动聊天设置-显示弹幕开关

      commentNumVisible: false, // 互动聊天设置-显示评论数开关

      groupStatus: false, // 互动聊天设置-观众聊天权限开关

      notityStatus: false, // 互动聊天设置-观众进入直播间提示开关

      privateStatus: false, // 互动聊天设置-观众私聊权限开关

      picStatus: false, // 互动聊天设置-发送图片权限开关

      addressStatus: false, // 互动聊天设置-观众IP属地开关

      anonymousStatus: false, // 互动聊天设置-匿名聊天

      reviewStatus: false, // 互动聊天设置-发送内容审核权限开关

      languageImageUrl: '',

      gift_display_status: 0,

      vrPlayUrl: [], // 3d直播链接地址
    },
    toUid: 0,
  }),
  getters: {
    lightGroupList(state) {
      return state.lightGroupMsg.topList.concat(state.lightGroupMsg.list.filter((e) => e.top_status === 0));
    },
    groupProvisionalList(state) {
      return state.lightGroupMsg.provisionalList;
    },
  },
  // optional actions
  actions: {
    /**
     * 获取直播控台信息
     */
    getConsoleInfo(studioId: number) {
      return new Promise((resolve, reject) => {
        axios.liveChat
          .getLiveConsoleInfo({
            params: {
              studio_id: studioId,
            },
          })
          .then((res) => {
            const resData: ResponseConsole = res.data.data;
            this.consoleInfo = new ConsoleClass(resData);
            resolve(res);
            return axios.liveChat.getConfigLimit();
          })
          .then((res) => {
            this.msgRenderCount = res.data.data.chat_limit_pop;
            this.aiSubTitleRenderCount = res.data.subtitle_pc_delay;
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取公共信息列表
     * @returns
     */
    getGroupMsg(studioId: number, page?: number) {
      axios.liveChat
        .groupChatList({
          params: {
            studio_id: studioId,
            page: page ?? this.groupMsg.pageConfig.current,
            page_num: this.groupMsg.pageConfig.pageSize,
          },
        })
        .then((res) => {
          const resData = res.data.data;
          const topArr: GroupChat[] = [];
          const filData: GroupChat[] = [];
          resData.data.forEach((e: any) => {
            if (e.chat_list_id) {
              filData.push(new GroupChat(e, 'privateChat'));
              return false;
            }
            const item = new GroupChat(e, 'baseList');
            // e.content = formatEmoji(e.content);
            // e.comment = e.comment.map((o: MsgComment) => {
            //   o.comment_content = formatEmoji(o.comment_content);
            //   return o;
            // });
            if (e.top_status) {
              topArr.push(item);
            } else {
              filData.push(item);
            }
          });
          this.groupMsg.list.splice(this.groupMsg.topCount, 0, ...topArr);
          this.groupMsg.topCount = topArr.length;
          this.groupMsg.list = this.groupMsg.list.concat(filData);
          this.groupMsg.pageConfig.current = resData.current_page;
          this.groupMsg.pageConfig.totalSize = resData.total;
        })
        .catch(() => {
          // message.error(err.data.error);
        });
    },
    /**
     * 获取私聊信息列表
     *
     */
    getPrivateMsg(studioId: number, dialogId?: number) {
      axios.liveChat
        .privateChatlist({
          params: {
            studio_id: studioId,
            page: 1,
            page_num: this.privateMsg.pageConfig.pageSize,
          },
        })
        .then((res: any) => {
          const resData = res.data.data;
          this.privateMsg.list = resData.data.map((e: PrivateChat) => {
            e.new_record = formatEmoji(e.new_record);
            return e;
          });
          // 处理第一次私聊没有列表id
          if (dialogId) {
            const index = this.privateMsg.list.findIndex((e) => e.id === dialogId);
            this.currentDialog.pmsgItem = this.privateMsg.list[index];
            this.currentDialog.user = undefined;
          } else if (!this.currentDialog.pmsgItem.id && this.privateMsg.list.length > 0) {
            this.currentDialog.pmsgItem = this.privateMsg.list[0];
          }
          const selectedId = dialogId ? dialogId : this.currentDialog.pmsgItem.id;
          this.privateMsg.pageConfig.current = resData.current_page;
          this.privateMsg.pageConfig.totalSize = resData.total;
          let nowDialogIndex = 0;
          this.privateMsg.newMsgCount = resData.data.reduce(function (acc: number, cur: PrivateChat, idx: number) {
            if (cur.news && cur.id !== selectedId) {
              acc += cur.news;
            } else if (cur.news && cur.id === selectedId) {
              nowDialogIndex = idx;
            }
            return acc;
          }, 0);
          this.privateMsg.list[nowDialogIndex].news = 0;
        })
        .catch(() => {
          // message.error(err.data.error);
        });
    },
    /**
     * 更新私聊信息列表
     **/
    updatePrivateMsg(studioId: number, page?: number) {
      axios.liveChat
        .privateChatlist({
          params: {
            studio_id: studioId,
            page: page,
            page_num: this.privateMsg.pageConfig.pageSize,
          },
        })
        .then((res: any) => {
          const resData = res.data.data;
          const formatData = resData.data.map((e: PrivateChat) => {
            e.new_record = formatEmoji(e.new_record);
            return e;
          });
          this.privateMsg.list += formatData;
          const selectedId = this.currentDialog.pmsgItem.id;
          this.privateMsg.pageConfig.current = resData.current_page;
          this.privateMsg.pageConfig.totalSize = resData.total;
          this.privateMsg.newMsgCount = resData.data.reduce(function (acc: number, cur: PrivateChat) {
            if (cur.news && cur.id !== selectedId) {
              acc += cur.news;
            }
            return acc;
          }, 0);
        })
        .catch(() => {
          // message.error(err.data.error);
        });
    },
    // resetPrivateMsg() {
    //   this.privateMsg.newMsgCount = 0;
    //   this.privateMsg.pageConfig = {
    //     current: 1,
    //     pageSize: this.privateMsg.pageConfig.pageSize,
    //     totalSize: 0,
    //   };
    // },
    resetDialogMsg() {
      this.currentDialog.pmsgItem = {
        id: 0,
        alias: '',
        created_at: '',
        display_order: '',
        identity: 0,
        new_record: '',
        news: 0,
        status: 0,
        to_avatar: '',
        to_avatar_url: '',
        to_level: 0,
        to_nickname: '',
        to_uid: 0,
        updated_at: '',
      };
      this.currentDialog.list = [];
      this.currentDialog.pageConfig = {
        current: 1,
        pageSize: this.privateMsg.pageConfig.pageSize,
        totalSize: 0,
      };
    },
    /**
     * 获取私聊详细记录
     * @param studioId 直播间id
     * @param data 私聊列表项
     * @returns
     */
    getDialogMsg(studioId: number, data: PrivateChat) {
      if (this.currentDialog.user) {
        this.currentDialog.user = undefined;
      }
      this.resetDialogMsg();
      this.currentDialog.pmsgItem = data;
      axios.liveChat
        .privateView({
          params: {
            chat_list_id: this.currentDialog.pmsgItem.id,
            identity: this.currentDialog.pmsgItem.identity,
            studio_id: studioId,
            page: this.currentDialog.pageConfig.current,
            page_num: this.currentDialog.pageConfig.pageSize,
          },
        })
        .then((res: any) => {
          const resData = res.data.data;
          const formatData = resData.data.map((e: PrivateChatView) => {
            e.content = formatEmoji(e.content);
            return e;
          });
          this.currentDialog.list = formatData;
          this.currentDialog.pageConfig = {
            current: resData.current_page,
            pageSize: resData.per_page,
            totalSize: resData.total,
          };
        })
        .catch(() => {
          // message.error(err.data.error);
        });
    },

    toDialogMsg(studioId: number, toUid: number) {
      this.toUid = toUid;
      this.chatType = 0;
      this.resetDialogMsg();
      axios.liveChat
        .privateView({
          params: {
            to_uid: toUid,
            studio_id: this.consoleInfo.id,
          },
        })
        .then((res: any) => {
          const resData = res.data.data;
          const formatData = resData.data.map((e: PrivateChatView) => {
            e.content = formatEmoji(e.content);
            return e;
          });
          this.currentDialog.list = formatData;
          this.currentDialog.pageConfig = {
            current: resData.current_page,
            pageSize: resData.per_page,
            totalSize: resData.total,
          };
        })
        .catch(() => {
          // message.error(err.data.error);
        });
    },

    updataDialogMsg(studioId: number, page?: number) {
      axios.liveChat
        .privateView({
          params: {
            chat_list_id: this.currentDialog.pmsgItem.id,
            identity: this.currentDialog.pmsgItem.identity,
            page: page,
            page_num: this.currentDialog.pageConfig.pageSize,
            to_uid: this.toUid,
            studio_id: this.consoleInfo.id,
          },
        })
        .then((res: any) => {
          const resData = res.data.data;
          const formatData = resData.data.map((e: PrivateChatView) => {
            e.content = formatEmoji(e.content);
            return e;
          });
          this.currentDialog.list = this.currentDialog.list.concat(formatData);
          this.currentDialog.pageConfig = {
            current: resData.current_page,
            pageSize: resData.per_page,
            totalSize: resData.total,
          };
        })
        .catch((err: any) => {
          // message.error(err.data.error);
        });
    },

    /**
     * @description 获取公共信息列表-轻聊版
     * @returns
     */
    getLightGroupMsg(studioId: number, page?: number): Promise<unknown> {
      return new Promise((resolve, reject) => {
        Promise.all([
          axios.liveChat.lightGroupChatList({
            params: {
              studio_id: studioId,
              page: page ?? this.groupMsg.pageConfig.current,
              page_num: this.groupMsg.pageConfig.pageSize,
            },
          }),
          axios.liveChat.lightTopChatList({
            params: {
              studio_id: studioId,
            },
          }),
        ])
          .then(([res1, res2]) => {
            const resData = res1.data.data;
            const newData = resData.data.map((e: any) => new LightGMsg(e));
            this.lightGroupMsg.list = this.lightGroupMsg.list.concat(newData);
            this.lightGroupMsg.pageConfig.current = Number(resData.current_page);
            this.lightGroupMsg.pageConfig.totalSize = resData.total;

            const resData2 = res2.data.data;
            if (resData2.length) this.lightGroupMsg.topList = resData2.map((e: any) => new LightGMsg(e));
            resolve({});
          })
          .catch((err) => {
            console.error(err?.data?.error);
            reject(err);
          });
      });
    },
    /**
     * 获取在线成员列表
     */
    getMemberList(studioId: number) {
      return new Promise((resolve) => {
        axios.liveInformation
          .getMemberList({
            params: { studio_id: studioId, monitor_id: this.consoleInfo.monitorId },
          })
          .then((res) => {
            const resData = res.data.data;
            // this.memberList = resData.data;
            this.memberList = resData.data.map((e: MemberData) => new MemberClass(e));
            //  1 电脑   2 手机  3 ipad
            this.memberCount.allCount = resData.count;
            this.memberCount.padCount = resData.ipad_count;
            this.memberCount.pcCount = resData.pc_count;
            this.memberCount.mobileCount = resData.mobile_count;
            resolve(res);
          })
          .catch(() => {
            // message.error(err.data.error);
          });
      });
    },
    /**
     * 获取最新的消息
     */
    getNewGroupList() {
      this.groupListScrollStatus = false;
      this.lightGroupMsg.list = this.lightGroupMsg.provisionalList.concat(this.lightGroupMsg.list);
      this.lightGroupMsg.provisionalList = [];
    },

    resetList() {
      this.groupMsg.list = [];
      this.groupMsg.virtualList = [];
      this.groupMsg.pageConfig = {
        current: 1,
        pageSize: this.groupMsg.pageConfig.pageSize,
        totalSize: 0,
      };
      this.groupMsg.topCount = 0;
      this.privateMsg.list = [];
      this.privateMsg.pageConfig = {
        current: 1,
        pageSize: this.privateMsg.pageConfig.pageSize,
        totalSize: 0,
      };
      this.groupListScrollStatus = false;
      this.lightGroupMsg.provisionalList = [];
      this.lightGroupMsg.topList = [];
      this.lightGroupMsg.list = [];
      this.lightGroupMsg.virtualList = [];
      this.lightGroupMsg.pageConfig = {
        current: 1,
        pageSize: this.lightGroupMsg.pageConfig.pageSize,
        totalSize: 0,
      };
    },
  },
});
