
import { computed, defineComponent, reactive, ref } from 'vue';
import SingHeader from '@/components/SingHeader.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { message, Input } from 'ant-design-vue';
import http from '@/api/login';
import Rotation from '@/components/Rotation.vue';
import CAwsc from '@/components/CAwsc.vue';
const store = useStore();
const customData = computed(() => store?.state.home.customData);
interface Ilogin {
  username: number | string;
  password: string;
  passwords: string;
  code: string;
}
export default defineComponent({
  components: {
    aInputPassword: Input.Password,
    SingHeader,
    Rotation,
    CAwsc,
  },
  setup() {
    const userName = ref<string>('');
    const router = useRouter();
    const store = useStore();
    const formState = reactive({
      layout: 'horizontal',
    });
    const formData: Ilogin = reactive({
      username: '',
      password: '',
      passwords: '',
      code: '',
    });
    const handlSubmit = async () => {
      const form = {
        mobile: formData.username as number,
        password: formData.password,
        code: formData.code,
        passwords: formData.passwords,
      };
      await store.dispatch('user/login', form);
    };
    const isShowform = ref(true);
    const formItemLayout = reactive({
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    });
    const buttonItemLayout = reactive({
      wrapperCol: {
        span: 14,
        offset: 4,
      },
    });
    const open = () => {
      router.push({ name: 'Login' });
    };

    let isShowcode = ref(true);
    let timer: any = ref(0);
    const TIME_COUNT = 60;
    let count = ref(TIME_COUNT);
    const getCode = () => {
      if (formData.username != '') {
        if (!timer.value) {
          count.value = TIME_COUNT;
          isShowcode.value = false;
          timer.value = setInterval(() => {
            if (count.value > 0 && count.value <= TIME_COUNT) {
              count.value--;
            } else {
              isShowcode.value = true;
              clearInterval(timer.value);
              timer.value = 0;
            }
          }, 1000);
        }
      } else {
        message.info('请输入手机号');
        return;
      }
      let send = {
        type: 0,
        mobile: formData.username,
      };
      http.getSendCaptcha(send).then(() => {
        // console.log(res.data);
      });
    };
    const dragStatus = ref(1);
    const getDragData = (data: any) => {
      aliVerification(data);
    };
    const aliVerification = (data: any) => {
      let params = {
        session_id: data.sessionId,
        sig: data.sig,
        token: data.token,
        scene: 1,
      };
      http
        .aliLoginVerification(params)
        .then(() => {
          dragStatus.value = 0;
        })
        .catch((res) => {
          dragStatus.value = 2;
          (window as any).ic.reset();
          message.error(res.data.error);
        });
    };
    //  客户管理员忘记密码
    const modifyShark = ref<boolean>(false);
    const modify = () => {
      if (modifyShark.value == true) {
        return;
      }
      if (formData.username == '') {
        message.info('手机号不能为空');
        return;
      }
      if (formData.code == '') {
        message.info('验证码不能为空');
        return;
      }
      if (formData.password == '' || formData.passwords == '') {
        message.info('密码不能为空');
        return;
      }
      if (dragStatus.value) {
        message.info('请先点击验证！');
        return;
      }
      let modife = {
        mobile: formData.username,
        new_password: formData.password,
        code: formData.code,
        rwd_password: formData.passwords,
      };
      http
        .getForgetPassword(modife)
        .then((res) => {
          modifyShark.value = false;
          // const resData = res.data;
          // message.success('修改成功');
          message.success(res.data.message);
          if (res.data.code === 200) {
            open();
          }
        })
        .catch((err) => {
          message.error(err.data.error);
          modifyShark.value = false;
        });
    };

    return {
      customData,
      isShowform,
      formState,
      formItemLayout,
      buttonItemLayout,
      formData,
      handlSubmit,
      userName,
      checked: ref(false),
      open,
      getCode,
      isShowcode,
      count,
      modify,
      getDragData,
    };
  },
});
