/**
 * 注册观看
 */
export interface SignupWObj {
  account_field: string;
  bg_color: string;
  bg_img: number;
  bg_img_url: string;
  pc_bg_img: number;
  pc_bg_img_url: string;
  created_at: string;
  form: string;
  head_img: number;
  head_img_url: string;
  id: number;
  intro: string;
  login_btn_text_en: string;
  login_btn_text_zh: string;
  multi_type: number;
  nickname_field: string;
  register_btn_text_en: string;
  register_btn_text_zh: string;
  status: number;
  studio_id: number;
  theme_color: string;
  title: string;
  type: number;
  updated_at: number;
  examine_status?: number;
}
export interface Option {
  disabled?: boolean;
  value: string;
  label: string;
  title?: string;
}
export interface FormObj {
  id: number;
  isInternational: boolean;
  isLogin: boolean;
  isUser_name: boolean;
  isVerify: boolean;
  isMerge: boolean;
  isMultipleChoice: string;
  option: Partial<Partial<Option>[]>;
  options: Option[];
  options2: Option[];
  preview_radio: string;
  require: boolean;
  select: string;
  select2: string;
  tips: string;
  title: string;
  type: string;
  value: string;
  verify_code: string;
}

export class SignupWClass {
  account_field: string;

  bg_img?: number;

  form: string;

  intro: string;

  nickname_field: string;

  pc_bg_img?: number;

  login_btn_text_en: string;

  login_btn_text_zh: string;

  register_btn_text_en: string;

  register_btn_text_zh: string;

  status: boolean;

  studio_id: number;

  title: string;

  type: boolean;

  head_img?: number;

  pc_bg_img_url: string;

  bg_img_url: string;

  head_img_url: string;

  formArr: FormObj[];

  merge_fields?: string;

  examine_status?: boolean;

  constructor(data: SignupWObj) {
    this.account_field = data.account_field;
    this.bg_img = data.bg_img;
    this.form = data.form;
    this.intro = data.intro;
    this.nickname_field = data.nickname_field;
    this.pc_bg_img = data.pc_bg_img;
    this.status = Boolean(data.status);
    this.studio_id = data.studio_id;
    this.title = data.title;
    this.type = data.type === 1 ? true : false;
    this.head_img = data.head_img;
    this.pc_bg_img_url = data.pc_bg_img_url;
    this.bg_img_url = data.bg_img_url;
    this.head_img_url = data.head_img_url;
    this.login_btn_text_en = data.login_btn_text_en;
    this.login_btn_text_zh = data.login_btn_text_zh;
    this.register_btn_text_en = data.register_btn_text_en;
    this.register_btn_text_zh = data.register_btn_text_zh;
    this.formArr = data.form
      ? (JSON.parse(data.form) as FormObj[]).map((e, index) => {
          e.id = index;
          return e;
        })
      : [];
    this.examine_status = data.examine_status ? true : false;
  }
}

function formatJson(FormObj: any) {
  const str = JSON.stringify(FormObj) as string;
  const re = new RegExp('\\\\', 'g');
  return str.replace(re, '\\\\');
}

export class SignupWFromClass {
  account_field: string;

  bg_img?: number;

  form: string;

  intro: string;

  nickname_field: string;

  pc_bg_img?: number;

  status: number;

  studio_id: number;

  title: string;

  type: string;

  head_img?: number;

  login_btn_text_en: string;

  login_btn_text_zh: string;

  register_btn_text_en: string;

  register_btn_text_zh: string;

  merge_fields?: string;

  examine_status?: number;

  constructor(data: SignupWClass) {
    this.account_field = data.account_field;
    this.bg_img = data.bg_img;
    this.form = formatJson(data.formArr);
    this.intro = data.intro;
    this.nickname_field = data.nickname_field;
    this.pc_bg_img = data.pc_bg_img;
    this.head_img = data.head_img;
    this.status = data.status ? 1 : 0;
    this.studio_id = data.studio_id;
    this.title = data.title;
    this.type = data.type ? '1' : '2';
    this.login_btn_text_en = data.login_btn_text_en;
    this.login_btn_text_zh = data.login_btn_text_zh;
    this.register_btn_text_en = data.register_btn_text_en;
    this.register_btn_text_zh = data.register_btn_text_zh;
    this.merge_fields = data.merge_fields;
    this.examine_status = data.examine_status ? 1 : 0;
  }
}

export interface SwitchForm {
  isLogin: boolean;
  isUserName: boolean;
  isMerge: boolean;
}

export interface ColumnObj {
  title: string;
  dataIndex: string;
  width?: string;
  align: string;
  ellipsis?: boolean;
  slots?: any;
}

export interface ModeData {
  title: string;
  name: string;
  content: string;
}
