import { BaseSearchClass } from '@/types/base';
import { Component } from 'vue';

export class ResourceBill extends BaseSearchClass {
  start_at?: string;
  end_at?: string;
  studio_id?: number;
  service_type?: string;
  pay_status?: number;
  type?: number;
  fund_flow?: number;
  keywords?: string;
  computer_type?: string;
  // 设置日期
  setDate(liveDate: Date[]): void {
    if (liveDate && liveDate.length > 0) {
      this.start_at = liveDate[0].toString();
      this.end_at = liveDate[1].toString();
    }
  }

  constructor(data: ResourceBill) {
    super(data);
    this.studio_id = data.studio_id;
    this.service_type = data.service_type;
    this.pay_status = data.pay_status ?? 0;
    this.type = data.type;
    this.fund_flow = data.fund_flow;
    this.keywords = data.keywords;
    this.computer_type = data.computer_type;
  }
}

export interface MenuType {
  name: string;
  title: string;
  path: string;
  component: Component;
}
