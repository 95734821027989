
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const customData = computed(() => store.state.home.customData);
    return {
      customData: customData.value,
    };
  },
  name: 'Video',
  props: ['childclo', 'title'],
});
