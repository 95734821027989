export interface RootModal {
  title: string;
  visible: boolean;
}

/**
 * 更多设置
 * @answer_mode 答题方式
 * @answer_count 答题数
 * @answer_operation  答题操作
 */
export interface MoreFormType {
  answer_mode: number;
  answer_count?: number;
  answer_operation: number;
}

/**
 * 答题统计
 * @id 序号
 * @avatar_url 用户头像
 * @name 用户昵称
 * @uid 用户id
 * @addr 地理位置
 * @time 答题时间
 * @referrer 来源访问
 * @device 设备
 */
export interface CensusForm {
  id: number;
  avatar_url: string;
  name: string;
  uid: number;
  addr: string;
  time: string;
  referrer: string;
  device: string;
}

export interface Imodal {
  title: string;
  visible: boolean;
  loading: boolean;
}

export interface Options {
  label: string;
  value: number | '';
}

/**
 * @select 题目类型 单选 多选
 * @keywords 搜索
 * @check 全选
 */
export interface FormType {
  select: number | undefined;
  keywords: string;
  check: boolean;
}

export class AnswerType {
  answer: string;
  answerTrue: boolean;
  constructor(data: AnswerTurnClass) {
    this.answer = data.answer;
    this.answerTrue = Boolean(data.answerTrue);
  }
}
export class AnswerTurnClass {
  answer: string;
  answerTrue: number;
  constructor(data: AnswerType) {
    this.answer = data.answer;
    this.answerTrue = Number(data.answerTrue);
  }
}

/**
 * @title 题目
 * @answer 答案
 * @answerTrue 正确答案
 * @check 选中状态
 * @type 单选或多选
 * @num 答题次数
 * @mustRight 是否必对
 * @message 是否开启正确提示
 * @showEditor 是否显示编辑部分
 * @showdetails 是否显示题目信息
 */
export interface DataType {
  id?: number;
  title: string;
  answerArr: AnswerType[];
  check: boolean;
  type: number;
  num: number;
  mustRight: boolean;
  message: boolean;
  showEditor: boolean;
  showdetails: boolean;
}

export interface DetailsForm {
  title: string;
  answerArr: AnswerTurnClass[];
}

// 白名单
export interface WhiteObj {
  id: number;
  mobile: string;
  created_at: string;
}

/**
 * @id id
 * @order_number 序号
 * @title 题目名称
 * @answer_type 题型  1单选  2多选
 * @count 答题次数
 * @time 修改时间
 * @option 选项
 */
interface ResList {
  id: number;
  order_number: number;
  title: string;
  answer_type: number;
  count: number;
  time: string;
  option: any;
}
export class AnswerListClass {
  id: number;
  serialNumber: number;
  title: string;
  type: string;
  num: number;
  time: string;
  option: any;
  constructor(data: ResList) {
    this.id = data.id;
    this.serialNumber = data.order_number;
    this.title = data.title;
    this.type = data.answer_type === 1 ? '单选' : '多选';
    this.num = data.count;
    this.time = data.time;
    this.option = JSON.parse(data.option);
  }
}
