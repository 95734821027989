import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取抽奖列表
  getDrawList(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_list', data);
  }
  // 编辑 查看抽奖配置
  getDrawWiew(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_view', data);
  }
  // 获取 抽奖参与人数 本地观众内定 人数
  getMemberNum(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/member_number', data);
  }
  //新建抽奖 编辑抽奖 开关
  createDraw(data: unknown) {
    return http.post('/api/v1/draw/draw_create', data);
  }
  //   手动开奖
  openDraw(data: unknown) {
    return http.post('/api/v1/draw/draw_open', data);
  }
  //   删除抽奖
  delDraw(data: unknown) {
    return http.post('/api/v1/draw/draw_delete', data);
  }
  //   获取抽奖人员名单
  getMemberList(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/member_list', data);
  }
  //  删除名单
  delMemberList(data: unknown) {
    return http.post('/api/v1/draw/member_delete', data);
  }
  //   内定
  drawFixed(data: unknown) {
    return http.post('/api/v1/draw/draw_fixed', data);
  }
  // 添加名单
  createMember(data: unknown) {
    return http.post('/api/v1/draw/member_create', data);
  }
  // 重置
  drawReset(data: unknown) {
    return http.post('/api/v1/draw/reset', data);
  }
  // 中奖名单
  getWinList(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_history_view', data);
  }
  // 获取活动抽奖列表
  getDrawActive(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_list_new', data);
  }
  // 活动抽奖开关
  drawSwitchNew(data: unknown) {
    return http.post('/api/v1/draw/draw_switch_new', data);
  }
  // 奖品展示
  drawReadyNew(data: unknown) {
    return http.post('/api/v1/draw/draw_ready_new', data);
  }
  // 开始抽奖
  drawStartNew(data: unknown) {
    return http.post('/api/v1/draw/draw_start_new', data);
  }
  // 结束抽奖
  drawEndNew(data: unknown) {
    return http.post('/api/v1/draw/draw_end_new', data);
  }
  // 删除抽奖
  drawDeleNew(data: unknown) {
    return http.post('/api/v1/draw/draw_delete_new', data);
  }
  // 活动抽奖详情
  drawViewNew(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_view_new', data);
  }
  // 保存新建活动抽奖
  drawCreateNew(data: unknown) {
    return http.post('/api/v1/draw/draw_create_new', data);
  }
  // 保存修改
  drawEditNew(data: unknown) {
    return http.post('/api/v1/draw/draw_edit_new', data);
  }
  // 获取活动抽奖中奖名单
  drawResultNew(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/draw_result_new', data);
  }
  // 删除活动抽奖中奖名单
  delDrawWin(data: unknown) {
    return http.post('/api/v1/draw/draw_win_delete', data);
  }

  /**
   *  活动抽奖新接口
   */
  // 获取参与人数
  getMemberNumNew(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/member_number_new', data);
  }
  //  获取抽奖人员名单
  getMemberListNew(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/member_list_new', data);
  }
  //  删除名单
  delMemberListNew(data: unknown) {
    return http.post('/api/v1/draw/member_delete_new', data);
  }
  // 添加名单
  createMemberNew(data: unknown) {
    return http.post('/api/v1/draw/member_create_new', data);
  }
  //   内定
  drawFixedNew(data: unknown) {
    return http.post('/api/v1/draw/draw_fixed_new', data);
  }

  // 抽奖名单一键删除
  delAllList(data: unknown) {
    return http.post('/api/v1/draw/member_delete_all', data);
  }
  setRegistrationCopy(data: unknown) {
    return http.post('/api/v1/draw/set_registration_copy', data);
  }
  getConfigInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/draw/get_registration_config_info', data);
  }
}
export default new Api();
