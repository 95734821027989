import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 账单获取所有直播间列表
  getLiveStudioList = (data: AxiosRequestConfig) => {
    return http.get(' /api/v1/bill/get_live_studio_list', data);
  };
  //   获取服务类型列表
  getServiceTypeList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/get_service_type_list', data);
  };

  // 获取资源包后计费账单列表
  getResourcePackageFeeBill = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/get_resource_package_fee_bill', data);
  };
  //  预计费账单
  getEstimatedFeeBillList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/estimated_fee_bill_list', data);
  };
  // 获取控制台收支明细
  getConsoleReceiveList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/get_console_receive_list', data);
  };
  // 获取第三方账单列表
  getThirdPartyFeeBillList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/bill/get_third_party_fee_bill_list', data);
  };
  // 账户中心-账户充值
  generateAccountRechargeOrder(data: unknown) {
    return http.post('/api/v1/account/generate_account_recharge_order', data);
  }
  // 微信扫码支付
  weChatScanPay(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/we_chat_scan_pay', data);
  }
  // 余额充值回调接口
  balanceRechargeCallback(data: AxiosRequestConfig) {
    return http.get('/api/v1/bill/balance_recharge_callback', data);
  }
  // 获取剩余余额
  getTheRemainingBalance() {
    return http.get('/api/v1/bill/get_the_remaining_balance');
  }
  // 版本权限
  getVsersionList(data?: AxiosRequestConfig) {
    return http.get('/api/v1/common/role_auth_list', data);
  }
  // 版本购买升级(除余额支付外生成订单号)
  versionPurchaseUpgrade(data: unknown) {
    return http.post('/api/v1/bill/version_purchase_upgrade', data);
  }
  // 轮询版本升级订单
  pollingOrder(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/polling_order', data);
  }
  // 版本购买回调
  versionPurchaseCallback(data: AxiosRequestConfig) {
    return http.get('/api/v1/bill/version_purchase_callback', data);
  }
  // 创建资源包购买订单
  payPackageCreateOrder(data: unknown) {
    return http.post('/api/v1/common/new_package_create_order', data);
  }
  // 付费后资源分配
  packageBalanceChange(data: unknown) {
    return http.post('/api/v1/common/package_balance_change', data);
  }
  // 轮询资源包购买订单 轮询回调
  packagePollingOrder(data: AxiosRequestConfig) {
    return http.get('/api/v1/wechat/package_polling_order', data);
  }

  // 下拉框筛选
  optionSearch() {
    return http.get('/api/v2/platform_bill/option_search');
  }
  // 可用券列表
  getCardCouponList(data: AxiosRequestConfig) {
    return http.get('/api/v1/card_coupon/card_coupon_list', data);
  }
  //领取活动卡券 qingtv-api.com/api/v1/common/use_card
  use_card(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/use_card', data);
  }
}

export default new Api();
