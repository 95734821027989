
import { defineComponent, ref } from 'vue';
import { Carousel } from 'ant-design-vue';
import http from '@/api/login';
export default defineComponent({
  components: {
    [Carousel.name]: Carousel,
  },
  setup() {
    const imgList = ref([
      {
        id: '',
        banner_url: 'https://images.innocomn.com/ed9d0202203011321255959.jpg',
        link_url: '',
      },
    ]);
    const getPageBanner = () => {
      http.getPageBanner({}).then((res) => {
        imgList.value = res.data.data;
      });
    };
    // getPageBanner();
    return {
      imgList,
    };
  },
});
