import type { App } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue'; // svg component

const req = require.context('../assets/svgIcons', false, /\.svg$/);
const requireAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);
requireAll(req);

export default {
  install: (app: App<Element>): void => {
    app.component('SvgIcon', SvgIcon);
  },
};
