import { ref } from 'vue';
import http from '@/api/conentCenter/communal';
import { message } from 'ant-design-vue';
import * as qiniu from 'qiniu-js';
interface FileItem {
  uid: string;
  name?: any;
  status?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
  response?: string | any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

interface updInfo {
  percentage: number;
  name: string;
  isShow: boolean;
  size: any;
  format: any;
  response?: any;
  url?: any;
}

export const useUpload = (params: any) => {
  const fileList: any = ref([]);
  const loading = ref<boolean>(false);
  const viewFileList = ref<updInfo[]>([]);
  const fileData = ref();
  const beforeUpload = (file: FileItem) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    const isLt500M = file.size / 1024 / 1024 < 500;
    if (!isLt2M && params.type == 1) {
      message.error('上传图片大小必须小于 2MB!');
    }
    if (!isLt500M && params.type == 2) {
      message.error('上传文件大小必须小于 500MB!');
    }
    return params.type == 1 ? isLt2M : isLt500M;
  };
  const handleChange = async (info: any) => {
    fileList.value.push(info);
    const res: any = new Map();
    fileList.value = fileList.value.filter((a: any) => !res.has(a.file.name) && res.set(a.file.name, 1));
    for (let i = 0; i < fileList.value.length; i++) {
      const obj: any = {
        name: fileList.value[i].file.name,
        size: fileList.value[i].file.size,
        url: '',
        format: fileList.value[i].file.name.split('.')[1],
        percentage: 0,
        isShow: false,
      };
      viewFileList.value.push(obj);
    }
    await dragUploadFile();
  };
  const dragUploadFile = async () => {
    try {
      const res: any = new Map();
      viewFileList.value = viewFileList.value.filter((a) => !res.has(a.name) && res.set(a.name, 1));
      for (let i = 0; i < fileList.value.length; i++) {
        loading.value = true;
        const formData = new FormData();
        formData.append(params.type == 1 ? 'images' : 'file', fileList.value[i].file);
        fileData.value = fileList.value[i].file;
        const res = ref();
        if (viewFileList.value[i].percentage != 100) {
          if (params.type == 1) {
            res.value = await http.uploadImages(formData);
          } else {
            res.value = await http.uploadPortalFile(formData).catch(() => {
              // message.error('文档上传异常');
            });
          }
          viewFileList.value[i].percentage = res.value.data.code == 200 ? 100 : 0;
          viewFileList.value[i].url = params.type == 1 ? res.value.data.data.url : res.value.data.data;
          viewFileList.value[i].isShow = res.value.data.code == 200 ? true : false;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const clearFile = () => {
    fileList.value = [];
  };

  return {
    loading,
    fileList,
    viewFileList,
    beforeUpload,
    handleChange,
    clearFile,
  };
};
