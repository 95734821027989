import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  getWhiteList(data: AxiosRequestConfig) {
    return http.get('/api/v2/live/mobile_whitelist', data);
  }
  addWhiteList(data: unknown) {
    return http.post('/api/v2/live/add_mobile_whitelist', data);
  }
  delWhiteList(data: unknown) {
    return http.post('/api/v2/live/whitelist_del', data);
  }
  clearWhiteList(data: unknown) {
    return http.post('/api/v2/live/whitelist_clear', data);
  }
}

export default new Api();
