export class SignupClass {
  checks?: boolean;
  direct?: boolean;
  lived?: boolean;
  Data?: boolean;

  constructor(data: SignupClass) {
    this.checks = Boolean(data.checks);
    this.direct = Boolean(data.direct);
    this.lived = Boolean(data.lived);
    this.Data = Boolean(data.Data);
  }
}

// 点赞页面
export interface imageList {
  name: string;
  img_list: string[];
  loading?: boolean;
}

interface DataType {
  status: number;
  index: number;
  image_list: any;
  real_num: number;
  false_switch: number;
  false_final: number;
  false_min: number;
  false_max: number;
  count: number; //总数
  false_num: number; //虚拟点赞数
}

export class DataTypeClass {
  status: boolean;
  index: number;
  image_list: imageList[];
  false_switch: number;
  false_min: number;
  false_max: number;
  false_final: number; //虚拟点赞基数
  real_num: number; //真实点赞数
  count: number; //总数
  false_num: number; //虚拟点赞数

  constructor(data: DataType) {
    this.status = data.status ? true : false;
    this.index = data.index;
    this.real_num = data.real_num;
    this.image_list = JSON.parse(data.image_list);
    this.false_switch = data.false_switch;
    this.false_final = data.false_final;
    this.false_min = data.false_min;
    this.false_max = data.false_max;
    this.count = data.count;
    this.false_num = data.false_num;
  }
}

//- 上传图片
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
