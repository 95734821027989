// 弹幕上屏
import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 直播间全屏弹幕
  setStudioFullScreenBarrage = (data: unknown) => {
    return http.post('/api/v1/live/set_studio_full_screen_barrage', data);
  };
  // 获取直播间全屏弹幕设置
  getStudioFullScreenBarrage = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_studio_full_screen_barrage', data);
  };
}

export default new Api();
