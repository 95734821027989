import moment, { Moment } from 'moment';

export interface Logttery {
  id: number;
  title: string;
  startTime: string; //开奖倒计时
  end_at: string; // 开奖时间
  draw_no: string; //抽奖单号
  number_win: number; //中奖人数
  open: number;
  pic: number; //图片
  pic_url: string; //图片链接
  status: number; // 0关 1开 2已开奖 3已开奖且关闭
  user_join: number; //观众参与
}

export interface Applicant {
  fixed: number; //内定名单 报名人数
  local: number; // 本地名单 报名人数
  user: number; // 观众参与 报名人数
}

export interface ViewForm {
  auto_draw: number; // 自动开奖 0关 1开
  draw_no: string; //抽奖单号
  end_at: string;
  filter: number; //过滤中奖观众
  fixed: number; //内定中将
  id: number;
  local_join: number; // 本地名单
  number_win: number; //中奖人数
  pic: number;
  pic_url: string; //图片链接
  sign_join: number; //打卡参与
  status: number; // 0关 1开 2已开奖 3已开奖且关闭
  studio_id?: number;
  title: string;
  user_join: number; // 观众参与
  win_history: number; //允许查看中奖历史
}

export class ViewFormClass {
  id: number;
  title: string;
  localJoin: boolean;
  autoDraw: boolean; // 自动开奖 0关 1开
  drawNo: string; //抽奖单号
  endAt: Moment | null;
  filter: boolean; //过滤中奖观众
  fixed: boolean; //内定中将
  numberWin: number; //中奖人数
  pic: number;
  picUrl: string;
  signJoin: boolean; //打卡参与
  status: number;
  userJoin: boolean; // 观众参与
  winHistory: boolean; //允许查看中奖历史
  constructor(data: ViewForm) {
    this.id = data.id;
    this.title = data.title;
    this.localJoin = data.local_join ? true : false;
    this.autoDraw = data.auto_draw ? true : false;
    this.drawNo = data.draw_no;
    this.endAt = data.end_at ? moment(data.end_at) : null;
    this.filter = data.filter ? true : false;
    this.fixed = data.fixed ? true : false;
    this.numberWin = data.number_win;
    this.pic = data.pic;
    this.picUrl = data.pic_url;
    this.signJoin = data.sign_join ? true : false;
    this.status = data.status;
    this.userJoin = data.user_join ? true : false;
    this.winHistory = data.win_history ? true : false;
  }
}

export interface MemberList {
  fixed: number;
  id: number;
  mobile: string;
  realname: string;
}

export interface WinType {
  id: number;
  mobile: string;
  realname: string;
  title: string;
}

// 上传头像
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
//某个奖品
interface AwardList {
  config_draw_id: number; //奖项id
  id: number;
  pic: number;
  pic_url: string;
  title: string;
  number: number;
}
// 某个奖项
interface PrizeList {
  id: number;
  new_id: number; //年会活动id
  reward: AwardList[];
  title: string;
}
//活动抽奖列表
export interface ActionList {
  draw_id: number;
  id: number;
  number: number; //中将名额
  pic_url: number; //图片
  reward_id: number; //奖品id
  start_status: number; //开奖状态 0准备 1开始 2结束
  status: number; //状态
  title: string; // 标题
  data: PrizeList[];
  image: any; //自己定义的存图片
  selectAwards: any; //自己定义的绑定选择器
  selectPrize: any; //自己定义的绑定选择器
  statuSw: boolean;
  selectAwardId: any; //存id
  selectPrizeId: any; //存id
  selectAwardNum: number;
}
// 奖品名称块
interface TweDa {
  config_draw_id?: number; //奖项id
  id?: number;
  pic: number;
  pic_url?: string; //图片地址
  title: string; //标题
  loading?: false;
  number?: number; //中奖名额
}
// 奖项名称块
export interface OneDa {
  id?: number;
  reward: TweDa[];
  title: string;
}
export interface AddView {
  bg: number;
  bg_pc: number;
  bg_pc_url: string;
  bg_url: string;
  data: OneDa[];
  filter: number;
  fixed: number;
  id: number;
  list: number;
  local_join: number;
  sign_join: number;
  start_status: number;
  title: string;
  user_join: number;
  win_history: number;
  weight_status: number;
  weight: any[];
}
export class AddViewClass {
  bg: number; //背景id
  bg_url: string; // 背景图片
  bg_pc: number; // pc背景
  bg_pc_url: string; // pc背景图片
  data: OneDa[];
  filter: boolean; //过滤中过奖的
  fixed: boolean; // 内定开关
  id: number;
  list: number; //中奖名单 本轮0  全部1
  local_join: boolean; //本地参与
  sign_join: boolean; // 打卡参与
  start_status: number;
  title: string; // 标题
  user_join: boolean; // 用户参与
  win_history: number; //开奖历史
  weight_status: boolean;
  weight: any[];
  constructor(data: AddView) {
    this.bg = data.bg;
    this.bg_pc = data.bg_pc;
    this.bg_pc_url = data.bg_pc_url;
    this.bg_url = data.bg_url;
    this.data = data.data;
    this.filter = data.filter ? true : false;
    this.fixed = data.fixed ? true : false;
    this.id = data.id;
    this.list = data.list;
    this.local_join = data.local_join ? true : false;
    this.sign_join = data.sign_join ? true : false;
    this.start_status = data.start_status;
    this.title = data.title;
    this.user_join = data.user_join ? true : false;
    this.win_history = data.win_history;
    this.weight_status = data.weight_status ? true : false;
    this.weight = data.weight;
  }
}

export interface Event {
  created_at: number;
  draw_title: string;
  id: number;
  mobile: string;
  realname: string;
  reward_title: string;
}

export interface SelList {
  id: number;
  title: string;
}
