
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
const elLocale = zhCn;
import { emptyDataSec } from '@/assets';

import { Layout, Menu, ConfigProvider, message, Button } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
import http from '@/api';
import { computed, defineComponent, nextTick, onErrorCaptured, onMounted, onUnmounted, provide, ref, watch, h } from 'vue';
import WebHeader from './base/Header.vue';
import HelperHeader from './base/HelperHeader.vue';
import { notification } from 'ant-design-vue';
const { Sider, Content } = Layout;
const { Item, SubMenu } = Menu;
// import { homeMenu, liveMenu } from '@/router';
import { useStore } from 'vuex';

import AppLink from '@/components/AppLink.vue';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  RouteLocationNormalized,
  RouteRecordRaw,
  useRoute,
  useRouter,
} from 'vue-router';
import { getFuncRole, getHelperStatus, getHelperUrl, getOriginMenuStatus, getUserCache, removeFuncRole } from '@/plugins/cookie';
import { LiveInfo } from '@/store/modules/live/types';
import CModal from '@/components/CModal.vue';
import { setCertificationStatus, getCertificationStatus, getExamineStatus, getExamine } from '@/plugins/cookie';
import { useHomeStore } from '@/pinia/home';
import EventBus from '@/components/EventBus';
import Socket from '@/utils/socket';
import { useSocketStore } from '@/pinia/socket';
import { clearPending } from '@/plugins/http';
import { useLiveStore } from '@/pinia/live';
import { gatewayMenu } from '@/router/gatewayMenu';
import { liveMenu } from '@/router/liveMenu';
import { mediaMenuHelp } from '@/router/role';
import { loadJs } from '@/utils/common';

export type Iheader = {
  title: string;
  path: string;
  name: string;
  isActive: boolean;
};
export interface Ibasedata {
  header: Iheader;
}

export default defineComponent({
  name: 'Home',
  components: {
    aLayout: Layout,
    aLayoutSider: Sider,
    aMenu: Menu,
    aConfigProvider: ConfigProvider,
    aMenuItem: Item,
    aSubMenu: SubMenu,
    aLayoutContent: Content,
    WebHeader,
    HelperHeader,
    AppLink,
    elConfigProvider: ElConfigProvider,
    CModal,
  },
  setup() {
    const downText = ref();
    // 接收ws通知
    const downNumClick = (data: any) => {
      downDot.value = data.message;
      if (downDot.value.status === 1) {
        downText.value = '导出完成，请前往下载中心查看';
        openNotificationWithIcon('success');
      } else {
        downText.value = '下载失败';
        openNotificationWithIcon('error');
      }
    };
    EventBus.on('downNum', downNumClick);

    // 导出成功时发出通知
    const router = useRouter();
    const helperData = ref();
    const studioId = router.currentRoute.value.params.studioId;
    const openNotificationWithIcon = (type: string) => {
      const key = `open${Date.now()}`;
      notification[type]({
        description: downText.value,
        message: downDot.value.file_title,
        duration: 30,
        btn: h(
          Button,
          {
            type: 'primary',
            size: 'small',
            onClick: () => {
              notification.close(key);
              if (helper && helperData.value == 1) {
                router.push(`/data_center/${studioId}/down_center`);
              } else {
                router.push('/live/down_center');
              }
            },
          },
          '去查看'
        ),
        key,
        onClose: close,
      });
    };

    const isExamine = getExamineStatus(); //是否是第三方审核登录
    const socketStore = useSocketStore();
    if (!socketStore.socket) {
      // socketStore.socket = new Socket('wss://live.lighos.com/wss'); // 正式
      // socketStore.socket = new Socket('wss://test-live.lighos.com/wss'); // 测试
      socketStore.socket = new Socket(process.env.VUE_APP_WSS_LINK as string);
    }
    const store = useStore();
    const homeStore = useHomeStore();

    homeStore.adminId = getUserCache() ? JSON.parse(getUserCache().toString()).id : '';
    // 初始化加入通信
    const onJoinSocket = async (data: any) => {
      homeStore.clientId = data.client_id;
      await http.home.adminJoin({
        client_id: homeStore.clientId,
        admin_id: homeStore.adminId,
      });
    };
    if (!isExamine) {
      EventBus.on('init', onJoinSocket);
    }
    // header
    const collapsed = ref(true); // 菜单展开按钮状态
    const setShowCollapsed = () => {
      collapsed.value = !collapsed.value;
      homeStore.collapsed = collapsed.value;
    };
    const logoUrl = computed(() => {
      return !collapsed.value ? customData.value.top_logo_url : customData.value.logo_url;
    });
    const logoClassName = computed(() => (!collapsed.value ? 'full_logo_align' : 'logo_align'));
    // 左侧菜单
    const route = useRoute();
    const openKeys = ref<string[]>([(route?.meta.routeParent as string) ?? '']);
    const selectedKeys = ref<string[]>([route.name as string]);
    // 根据header项过滤菜单
    const menu = ref<RouteRecordRaw[]>([]);
    const userMenu = ref<RouteRecordRaw[]>([]);

    // 查看是否有暂存权限
    const roleData = getFuncRole();
    if (roleData) {
      homeStore.$patch({
        funcData: roleData,
      });
      removeFuncRole();
    }
    // 消息超过99就显示99+
    // const msgnum = computed(() => {
    //   return downDot.value >= 99 ? '99+' : downDot.value;
    // });
    // 点击下载中心数量就消失
    // const watchNums = () => {
    //   downDot.value = ''
    // }
    // EventBus.on('watchNum', watchNums);

    const proVersion = ref();
    const downDot = ref<any>();
    const timer: any = ref(0);
    const changeStatus = () => {
      timer.value = window.setInterval(() => {
        getVersionStatus();
      }, 600000);
    };
    const getVersionStatus = () => {
      http.home.getVersionStatus().then((res) => {
        if (!res.data.data.status && route.name != 'WalletVersion' && route.name != 'WalletPay') {
          router.push('/homeMask');
        }
        // homeStore  是否显示新版本
        homeStore.isNewVersion = Boolean(res.data.data.is_2022_version);
        proVersion.value = res.data.data.version;
        // downDot.value = res.data.data.download_count;
      });
    };
    changeStatus();
    getVersionStatus();

    // 首次进入检测连接是否含隐藏菜单的状态
    getOriginMenuStatus() === '1' && (homeStore.menuVisible = false);

    // 根据路由直播间id设置直播间信息
    const stateLiveInfo = ref<Partial<LiveInfo>>({});
    const liveStore = useLiveStore();
    provide('liveInfo', stateLiveInfo);

    const helper = getHelperStatus(); //直播助手标识
    const helperUrl = helper && getHelperUrl();
    const examineUrl = isExamine && getExamine();
    const setLiveInfo = async (to: RouteLocationNormalized, from?: RouteLocationNormalized, studioId?: string) => {
      if (to.params.studioId && to.params.studioId !== from?.params.studioId) {
        if (!studioId) {
          studioId = to.params.studioId as string;
        }
        const res = await http.live.getLiveInfo({
          params: { studio_id: studioId },
        });
        if (
          res.data.data?.studio_pid === res.data.data?.id &&
          res.data.data?.mode === 5 &&
          !(to.meta?.role as string[]).includes('language')
        ) {
          router.push('/404');
          return;
        }
        if (helper) {
          if (helperUrl?.studioId != res.data.data?.studio_pid && helperUrl?.studioId != res.data.data?.id) {
            router.push('/404');
            return;
          }
        }
        if (isExamine) {
          const redirectName = [`/live_set/${examineUrl?.studioId}/signup_watch`];
          if (examineUrl?.studioId != res.data.data?.id || !redirectName.includes(to.path)) {
            router.push('/404');
            return;
          }
        }
        stateLiveInfo.value = res.data.data;
        liveStore.liveInfo = res.data.data;
      }
      if (!to.params.studioId) {
        stateLiveInfo.value = {};
        liveStore.$reset();
      }
      return stateLiveInfo.value.studio_pid;
    };
    const getHelperRole = (helper: number) => {
      if (helper) {
        const account = getUserCache() ? JSON.parse(getUserCache().toString()).account : '';
        http.login
          .getInfoLiveHelper({
            params: {
              account: account,
              studio_id: helperUrl?.studioId,
              target_studio_id: helperUrl?.targetStudioId,
            },
          })
          .then((res) => {
            helperData.value = res.data.data.data;
            store.commit('user/setHelperInfo', res.data.data);
          })
          .catch((err) => message.error(err.data.error));
      }
    };
    getHelperRole(helper);
    // 获取首页菜单

    const formatMenu = (_menuList: RouteRecordRaw[], studioId?: string) => {
      const menuArr: RouteRecordRaw[] = [];
      _menuList.forEach((element) => {
        /* 过滤指定账号 */
        const FILTERMENU = ['Personal', 'Wallet'];
        const isFilter = FILTERMENU.includes(element.name as string) || FILTERMENU.includes(element.meta?.routeParent as string);
        if (Number(homeStore.adminId) === 10002150 && isFilter) {
          return false;
        }
        /* 过滤指定账号 */
        element.path = studioId ? element.path.replace(':studioId', studioId) : element.path;
        element.path = studioId ? element.path.replace('?studioId', studioId) : element.path;
        element.path = studioId ? element.path.replace(':gatewayId', studioId) : element.path;
        element.path = studioId ? element.path.replace('?gatewayId', studioId) : element.path;
        if (studioId && stateLiveInfo.value.studio_pid === parseInt(studioId) && element.meta) {
          if (element.meta.role) {
            if (stateLiveInfo.value.mode === 4 && !(element.meta?.role as string[]).includes('stream')) {
              element.meta.hidden = true;
            } else if (stateLiveInfo.value.mode === 5 && !(element.meta?.role as string[]).includes('language')) {
              element.meta.hidden = true;
            }
          } else {
            element.meta.hidden = true;
          }
        }

        if (element.children?.length) {
          element.children = (element.children as RouteRecordRaw[]).filter((e) => {
            e.path = studioId ? e.path.replace(':studioId', studioId) : e.path;
            e.path = studioId ? e.path.replace(':gatewayId', studioId) : e.path;
            if (studioId && e.meta && helper) {
              if (
                !e.meta.role ||
                !(e.meta?.role as string[]).includes('helper') ||
                !e.meta.helperKey ||
                store.state.user.helperInfo[e.meta.helperKey as string] === 0 ||
                e.meta.only
              ) {
                return false;
              }
            }
            if (studioId && stateLiveInfo.value.studio_pid === parseInt(studioId) && e.meta) {
              if (!e.meta.role) {
                return false;
              }
              if (stateLiveInfo.value.mode === 4 && !(e.meta?.role as string[]).includes('stream')) {
                return false;
              } else if (stateLiveInfo.value.mode === 5 && !(e.meta?.role as string[]).includes('language')) {
                return false;
              }
            }
            if (e.meta?.only) {
              if (studioId && (stateLiveInfo.value.mode !== 4 || stateLiveInfo.value.studio_pid !== parseInt(studioId))) {
                return false;
              }
            }
            if (e.meta?.funcId && !homeStore.funcData[e.name as string]) return false; // 由于 vueRouter 无法删除多层级嵌套子路由故需要筛选
            if (e.meta?.hidden !== true) return true; // 处理需要隐藏的路由
          });
          if (element.children.length > 0 || element.meta?.hideMenu) menuArr.push(element);
        } else {
          if (element.meta?.hidden === void 0 || element.meta?.hidden === false) {
            element.children = undefined;
            menuArr.push(element);
          }
        }
      });
      if (!studioId) {
        userMenu.value = menuArr.filter((e) => e.meta?.userMenu === true);
        userMenu.value.forEach((item: any) => {
          if (homeStore.funcData?.MessageCenter == true) {
            userMenu.value = menuArr.filter((e) => e.meta?.userMenu === true);
            userMenu.value.forEach((item) => {
              let obj: any = item.name == 'Personal' && item.children?.filter((i) => i.meta?.userMenu === true);
              obj && userMenu.value.push(obj[0]);
            });
          }
        });
        store.commit('home/setMenu', menuArr);
      }
      return menuArr;
    };

    const routerList = router.getRoutes();
    const homeMenu = routerList.filter((e) => e?.meta?.menuType === 'home');
    // const liveMenuroute = routerList.filter((e) => e?.meta?.menuType === 'live');
    // const liveMenu = routerList.filter((e) => e?.meta?.menuType === 'live');
    // 菜单切换与生成
    const getMenu = async (to: RouteLocationNormalized, from?: RouteLocationNormalized, studioId?: string) => {
      if (to.matched[1].meta.menuType !== from?.matched[1].meta.menuType) {
        menu.value = [];
      }
      let menuList: Array<RouteRecordRaw> = [];
      if (from && to.matched[1].meta.menuType === from.matched[1].meta.menuType) {
        return true;
      }
      if (studioId) {
        await setLiveInfo(to, from, studioId);
      } else {
        await setLiveInfo(to, from);
      }

      menu.value = formatMenu(homeMenu);
      // 左侧导航栏过滤掉用户列表
      const index = menu.value.findIndex((item) => item.path === '/user/list');
      if (index >= 0) {
        menu.value.splice(index, 1);
      }
      switch (to.matched[1].meta.menuType) {
        case 'live':
          menuList = JSON.parse(JSON.stringify(liveMenu));
          if (!studioId) {
            studioId = to.params.studioId as string;
          }
          menu.value = formatMenu(menuList, studioId);
          if (helper) {
            const index = menuList.findIndex((e) => e.name === 'Console');
            const list = JSON.parse(JSON.stringify(menuList.splice(0, index)));
            const helpMenu = JSON.parse(JSON.stringify(mediaMenuHelp));
            menu.value = formatMenu(list.concat(helpMenu).concat(menuList), studioId);
          }
          break;
        case 'gateway':
          menuList = JSON.parse(JSON.stringify(gatewayMenu));
          if (!studioId) {
            studioId = to.params.gatewayId as string;
          }
          menu.value = formatMenu(menuList, studioId);
          break;
        default:
          break;
      }
    };
    provide('getmenu', getMenu);

    // userMenu.value = homeMenu.filter((e) => e.meta?.userMenu === true);
    watch(homeStore.funcData, () => {
      getMenu(route);
    });

    onBeforeRouteUpdate((to, from) => {
      if (from.name === 'WalletVersion' || from.name === 'WalletPay') {
        getVersionStatus();
      }
      getMenu(to, from);
      if (to.path === '/home') {
        openKeys.value = [''];
      } else {
        openKeys.value = [to.meta.routeParent as string];
      }
      if (to.matched.length > from.matched.length) {
        //设置动画名称
        transitionName.value = 'slide-left';
      } else {
        transitionName.value = 'slide-right';
      }
      selectedKeys.value = [to.name as string];
    });

    const onOpenChange = (key: string[]) => {
      if (!collapsed.value) {
        const latestOpenKey = key.find((key) => openKeys.value.includes(key) === false);
        openKeys.value = latestOpenKey ? [latestOpenKey] : [];
      }
    };

    // 获取用户认证状态
    const isVisible = ref<boolean>(false);
    const autoStaus = ref(false);
    const getAuthenticationStatus = async () => {
      try {
        const res = await http.home.getAuthenticationStatus();
        res.data.data.status == 0 && res.data.data.certification_view == 1 && !getCertificationStatus()
          ? (isVisible.value = true)
          : '';
        homeStore.concurrencyChartVisible = !!res.data.data.version_type;
        homeStore.role_id = res.data.data.role_id;
        setCertificationStatus(res.data.data.status);
      } catch (err: any) {
        console.error(err.data.error);
      }
      autoStaus.value = true;
    };
    const isopenDelModal = () => {
      isVisible.value = !isVisible.value;
    };

    const getUseData = () => {
      const userCache = getUserCache();
      if (userCache) {
        store.commit('user/setUserinfo', JSON.parse(userCache as string));
      }
    };

    const customData = computed(() => store.state.home.customData);
    const customFootStyle = computed(() => store.state.home.customFootStyle);
    const customLayoutStyle = computed(() => store.state.home.customLayoutStyle);
    const transitionName = ref('slide-left');
    const init = async () => {
      getUseData();
      getMenu(route);
      getAuthenticationStatus();
      var link = document.querySelector("link[rel~='icon']") as any;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      // if (!customData.value.top_logo_url || !customData.value.logo_url) {
      // if (!isExamine) {
      await store.dispatch('home/getDefaultCustomized');
      // }
      // }
      link.href = customData.value.icon_url;
    };
    init();

    onBeforeRouteLeave((to) => {
      if (to.name !== '404') {
        homeStore.$reset();
        window.clearInterval(timer.value);
      }
    });

    // 跨站消息接收对接的设置
    const getPostMsg = (e: MessageEvent<any>) => {
      if (e.origin === 'http://1.15.247.179') {
        // console.info(e.source); // 父页面window对象，全等于window.parent/window.top
        console.info(e.data); //父页面发送的消息
        router.push(e.data);
      }
    };
    // onMounted(() => {
    //   // 静听iframe 消息触发
    //   window.addEventListener('message', (e) => getPostMsg(e), false);
    // });

    onUnmounted(async () => {
      if (!isExamine) {
        EventBus.off('init', onJoinSocket);
      }
      clearPending();
      if (socketStore.socket) {
        const wss: WebSocket = await socketStore.socket.getInstance();
        wss.close();
      }
      liveStore.$reset();
      window.removeEventListener('message', (e) => getPostMsg(e));
    });

    // 捕获错误
    onErrorCaptured(() => {
      return false;
    });

    // 局部组件刷新
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide('reload', reload);

    // 余额检测
    const checkBalanceStatus = async () => {
      try {
        await http.home.checkBalanceStatus();
      } catch (err: any) {
        console.error(err.data.error);
      }
    };
    if (!isExamine) {
      checkBalanceStatus();
    }
    const checkoutUpload = async () => {
      await loadJs('https://images.innocomn.com/es6-promise.min.js');
      await loadJs('https://images.innocomn.com/aliyun-oss-sdk-6.13.0.min.js');
      await loadJs('https://images.innocomn.com/aliyun-upload-sdk-1.5.2.min.js');
      // 如要本地测试富文本请替换解除以下注释
      // await loadJs('https://static.lighos.com/tinymce/js/tinymce/tinymce.min.js');
      await loadJs('/tinymce/js/tinymce/tinymce.min.js');
      !homeStore.onVideojs && (await loadJs('https://images.innocomn.com/video.min.js'));

      // await loadJs('https://images.innocomn.com/g2plot.min.js');
      // await loadJs('https://images.innocomn.com/echarts.min.js');
    };
    onMounted(() => {
      checkoutUpload();
    });

    const loadVideojs = async () => {
      await loadJs('https://images.innocomn.com/video.min.js');
    };
    provide('loadVideojs', loadVideojs);

    return {
      openNotificationWithIcon,
      homeStore,
      emptyDataSec,
      menu,
      userMenu,
      collapsed,
      openKeys,
      selectedKeys,
      onOpenChange,
      setShowCollapsed,
      logoUrl,
      logoClassName,
      locale,
      transitionName,
      customData,
      customFootStyle,
      customLayoutStyle,
      elLocale,
      isRouterAlive,
      autoStaus,
      isVisible,
      isopenDelModal,
      helper,
      route,
      isExamine,
      proVersion,
      downDot,
      // msgnum,
    };
  },
});
