// 答题观看
import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取更多设置
  getMoreConfig(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_more_config', data);
  }
  //   保存更多设置
  answerConfigSave(data: unknown) {
    return http.post('/api/v1/live/answer_config_save', data);
  }
  // 状态更改
  answerStatus(data: unknown) {
    return http.post('/api/v1/live/answer_status', data);
  }
  //获取  题目列表
  getAnswerList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/answer_subject_list', data);
  }
  //   题目添加/编辑
  answerSubjectSave(data: unknown) {
    return http.post('/api/v1/live/answer_subject_save', data);
  }
  // 题目详情
  getAnswerInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/answer_subject_info', data);
  }
  // 答题数据
  getAnswerRecord(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/answer_data', data);
  }
  // 题目删除
  delAnswerList(data: unknown) {
    return http.post('/api/v1/live/answer_subject_delete', data);
  }
  // 题目排序
  setAnswerOrder(data: unknown) {
    return http.post('/api/v1/live/set_answer_order', data);
  }
}

export default new Api();
