import moment, { Moment } from 'moment';
import { reactive, ref } from 'vue';

export const ranges = {
  今天: [moment().startOf('day'), moment().endOf('day')],
  明天: [moment().startOf('day').subtract(-1, 'day'), moment().endOf('day').subtract(-1, 'day')],
  最近一周: [moment().startOf('day').subtract(1, 'weeks'), moment()],
  最近一月: [moment().startOf('day').subtract(1, 'months'), moment()],
  最近三月: [moment().startOf('day').subtract(3, 'months'), moment()],
};

interface Stream {
  id: number;
  image_player_cover_id: number;
  image_player_cover_url: string;
  live_url_status: number;
  title: string;
}
interface Language {
  id: number;
  image_player_cover_id: number;
  language_image_url: string;
  live_url_status: number;
  title_sub: string;
}

export interface RootLive {
  id: number;
  contain_stream_ids: string;
  created_at: string;
  image_player_cover_url: string;
  live_url_status: number;
  mode: number;
  number: number;
  push_stream_duration: number;
  qr_code_url: string;
  start_at: string;
  status: number;
  studio_status: number;
  title: string;
  watch_number?: number; // 首页观看数
  time?: string; // 首页开始时间
  watch_url?: string; // 首页观看地址
  file_class?: number;
  stream_list?: Stream[]; //多频道列表
  language_list?: Language[];
  studio_pid: number;
  sort_name: string;
  mode_title: string;
  isEdit: boolean;
}

export const groupMenu = ref([
  {
    name: '默认分类',
    value: 0,
  },
  {
    name: '分类1',
    value: 1,
  },
  {
    name: '分类2',
    value: 2,
  },
]);

interface LiveSearch {
  liveDate: Moment[];
  keywords: string;
  checked_all: boolean;
  selectArr: any;
  modalVisible: boolean;
  addLiveVisible: boolean;
}

export const pageConfig = reactive({
  current: 1, // 当前页
  pageSize: 12, // 每页条数
  totalSize: 1, // 总页数
  options: ['12', '24', '36', '48', '60'], // 选项
  size: 'small', // 尺寸
  showJumper: false, // 是否显示跳页
  showSize: false, // 是否显示条数切换
});

export const queryForm = reactive<LiveSearch>({
  keywords: '',
  liveDate: [],
  checked_all: false,
  selectArr: [],
  modalVisible: false,
  addLiveVisible: false,
});

// 添加直播弹窗
export const modalPageConfig = reactive({
  current: 1, // 当前页
  pageSize: 8, // 每页条数
  totalSize: 0, // 总页数
  options: ['8', '16', '24', '32', '40'], // 选项
  size: 'small', // 尺寸
  showJumper: false, // 是否显示跳页
  showSize: false, // 是否显示条数切换
});
