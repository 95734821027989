
import CUpload from '@/components/CUpload.vue';
import Crumb from '@/components/Crumb.vue';
import { defineComponent, ref } from 'vue';
import { Input, Switch, Radio } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import http from '@/api/livebroadcast';
// import { useStore } from 'vuex';
import { useUpload } from '@/components/CUload';
import { getToken } from '@/plugins/cookie';
import router from '@/router';
import CLoading from '@/components/CLoading.vue';

export default defineComponent({
  components: {
    CUpload,
    Crumb,
    aTextarea: Input.TextArea,
    aSwitch: Switch,
    aRadio: Radio,
    aRadioGroup: Radio.Group,
    CLoading,
  },
  setup() {
    let token = getToken();
    const liveInfo = router.currentRoute.value.params.studioId;
    const imageUrl = ref<string>('');
    const value = ref<string>('test value');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let obj = ref({
      content: '',
      id: '',
      status: '',
    });
    //  上传图片
    const logoId = ref();
    const { fileList, loading, handleChange, imageDel } = useUpload(imageUrl, logoId);

    const DownClose = () => {
      let updwechat = {
        content: obj.value.content,
        logo_id: logoId.value,
        status: obj.value.status,
        studio_id: liveInfo,
      };
      http
        .getUpdWeChatShare(updwechat)
        .then((res) => {
          message.destroy();
          message.success(res.data.message);
        })
        .catch((res) => {
          message.error(res.data.error);
        });
    };

    const showLoading = ref(false);
    // 后台获取微信分享内容
    const shareContent = () => {
      let sharecont = {
        studio_id: liveInfo,
      };
      showLoading.value = true;
      http
        .getShareContent({ params: sharecont })
        .then((res) => {
          obj.value = res.data.data;
          logoId.value = res.data.data.logo_id;
          imageUrl.value = res.data.data.logo_url;
        })
        .finally(() => (showLoading.value = false));
    };
    shareContent();

    return {
      fileList,
      loading,
      imageUrl,
      handleChange,
      //   liveItemBg,
      value,
      //   diver,
      obj,
      DownClose,
      //   success,
      token,
      logoId,
      imageDel,
      showLoading,
    };
  },
});
