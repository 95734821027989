import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 视频列表
  getListUse(data: any) {
    return http.get('/api/v2/video_live/list_use', data);
  }
  //添加视频列表
  getList(data: any) {
    return http.get('/api/v2/video_live/list', data);
  }
  // 配置视频列表
  listSetting(data: unknown) {
    return http.post('/api/v2/video_live/list_setting', data);
  }
  //开始直播
  startLive(data: unknown) {
    return http.post('/api/v2/video_live/start', data);
  }
  //结束直播
  endLive(data: unknown) {
    return http.post('/api/v2/video_live/end', data);
  }
  //循环播放
  modeLive(data: unknown) {
    return http.post('/api/v2/video_live/mode', data);
  }
  // 配置推流地址
  pushSetting(data: unknown) {
    return http.post('/api/v2/video_live/push_setting', data);
  }
  startAt(data: any) {
    return http.get('/api/v2/video_live/start_at', data);
  }
  // 删除视频
  delListLiveVideo(data: unknown) {
    return http.post('/api/v2/video_live/del_list_live_video', data);
  }
}
export default new Api();
