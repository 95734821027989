import { Key } from 'ant-design-vue/lib/_util/type';

export interface WebLive {
  host_password_status: boolean; // 主持 开关
  link: string; // 主持 开播链接
  host_password: string; // 主持 密码
  helper_password_status: boolean; // 参会者 开关
  helper_link: string; //参会者 开播链接
  helper_password: string; //参会者 密码
  exp_duration?: number; //?
  locking?: number; //?
}

export interface PullLive {
  pull_url: string; // 拉流地址
  status: number; // 状态
  duration: string | number;
}

export interface HisData {
  id: number;
  duration: string;
  pull_url: string;
  start_at: string;
}

export interface DataType {
  key: Key; // key
  computer_name: string; // 名称
  created_at: string; // 上传日期
  number: number; // 页数
}
// 上传图片
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export interface DocumentData {
  computer_name: string; //文件名
  document_id: number; // 文件id
  id: number; // id
  image_urls: any; // 图片
  page_index: number;
  status: number; // 状态 0未使用 1使用中
}
// export class PullLClass {
//   pull_url: string;

//   status: boolean;

//   constructor(data: PullLive) {
//     this.pull_url = data.pull_url;
//     this.status = data.status === 1 ? true : false;
//   }
// }
interface Receive {
  receive_status: number; //领取状态 1领取 0未领取
  role_type: number; // 0定制版 1非定制版
}
export interface Interaction {
  guest_link: string; // 嘉宾
  guest_password: string; //嘉宾 密码
  guest_password_status: number; // 嘉宾 状态
  helper_link: string; //参会者
  helper_password: string; // 参会者 密码
  helper_password_status: number; // 参会者 状态
  host_link: string; //主持 链接
  host_password: string; //主持 密码
  host_password_status: number; // 主持 状态
  id: number; // id
  locking: number; // 锁定
  low_latency: number; // 低延时
  studio_id: number; // 直播间id
  receive: Receive;
}

export class InteractionClass {
  hostPasswordStatus: boolean; // 主持 状态

  hostLink: string; //主持 链接

  hostPassword: string; //主持 密码

  helperPasswordStatus: boolean; // 参会者 状态

  helperLink: string; //参会者

  helperPassword: string; // 参会者 密码

  guestPasswordStatus: boolean; // 嘉宾 状态

  guestLink: string; // 嘉宾

  guestPassword: string; //嘉宾 密码

  constructor(data: Interaction) {
    this.hostPasswordStatus = data.host_password_status ? true : false;
    this.helperPasswordStatus = data.helper_password_status ? true : false;
    this.guestPasswordStatus = data.guest_password_status ? true : false;
    this.hostLink = data.host_link;
    this.hostPassword = data.host_password;
    this.helperLink = data.helper_link;
    this.helperPassword = data.helper_password;
    this.guestLink = data.guest_link;
    this.guestPassword = data.guest_password;
  }
}

export interface MulPush {
  name: string;
  push_url: string;
  id?: string;
  duration?: number;
}

export interface DelModal {
  singleDel: boolean;
  wholeDel: boolean;
}
