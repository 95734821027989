import axios from 'axios';
import { getToken } from '@/plugins/cookie';
const token = getToken();

const imagesHandler: any = (blobInfo: any, success: any, failure: any) => {
  const formData = new FormData();
  formData.append('images', blobInfo.blob(), blobInfo.filename());
  formData.append('token', token as any);
  if (formData) {
    axios.post('/api/v1/common/upload_images', formData).then((res) => {
      if (res.data.code == 200) {
        const url = res.data.data.url;
        success(url);
      } else {
        failure('');
      }
    });
  }
};
const init = {
  menubar: false,
  plugins: 'image paste code link',
  images_file_types: 'jpg,jpeg,png,gif',
  statusbar: false, // 隐藏底部状态栏
  automatic_uploads: true,
  images_upload_handler: imagesHandler,
  language: 'zh_CN',
  toolbar:
    'code undo redo image forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify \
          outdent indent ormatselect | fontsizeselect | formatselect | fontselect | link',
  height: 400,
  convert_urls: false,
  fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
  font_formats:
    '宋体=SimSun; 新宋体=NSimSun; 仿宋=FangSong; 黑体=SimHei; 微软雅黑=Microsoft Yahei; 微软正黑体=Microsoft JhengHei; 楷体=KaiTi;',
  // image_dimensions: false,
  content_style: 'img{width:100%;height:auto;}img.no-fill{width:auto;max-width:100%;}',
  image_class_list: [
    { title: '全屏显示', value: '' },
    { title: '原始尺寸', value: 'no-fill' },
  ],
  default_link_target: '_blank',
};

const apikey = 'pfpbmgdaarsqslaf0o1topep7suv94f85e7teevqht7js15a';

export { init, apikey };
