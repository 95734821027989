import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  // 虚拟直播场景列表
  getSceneList(data: AxiosRequestConfig) {
    return http.get('/api/v2/fictitious/fictitious_scene_list', data);
  }
  // 获取用户信息
  getTokenInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/administrator/get_token', data);
  };
}

export default new Api();
