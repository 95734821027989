
import CDelModal from '@/components/CDelModal.vue';
import { message, Modal } from 'ant-design-vue';
import { defineComponent, ref, unref, computed, reactive, h } from 'vue';
import { DatePicker, Input, Pagination, Upload, Spin, Popover } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import { UploadOutlined, ExclamationCircleFilled } from '@ant-design/icons-vue';
import { RangePickerIcon } from '@/assets';
import moment from 'moment';
import { Table } from 'ant-design-vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import http from '@/api/media';
import { getToken } from '@/plugins/cookie';
type Key = ColumnProps['key'];
import { documentPpt, documentPdf } from '@/assets';
import { documentPreview } from '@/assets';
import { documentDelete } from '@/assets';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { ResourceBill } from '../wallet/bill/ResourceBill';
import SvgIcon from '../../components/SvgIcon.vue';

// S 上传文档
interface FileItem {
  originFileObj: Blob;
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
const showLoading = ref<boolean>(false);
// E 上传文档
const datafol = ref<DataType[]>([]);
interface DataType {
  // key: Key;
  // name: string;
  // address: string;
  // time: string;
  id: number;
  computer_name: string;
  computer_type: string;
  status: string;
  created_at: string;
  img: string;
}
const columns = [
  {
    title: '文档名称',
    dataIndex: 'computer_name',
    width: '203px',
    align: 'left',
    slots: {
      customRender: 'computer_name',
    },
    ellipsis: true,
  },
  {
    title: '文件类型',
    dataIndex: 'computer_type',
    align: 'center',
    width: '20%',
    slots: {
      customRender: 'computer_type',
    },
  },
  {
    title: '进度',
    dataIndex: 'status',
    align: 'center',
    width: '23%',
    slots: {
      customRender: 'status',
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    align: 'center',
    width: '23%',
  },
  {
    title: '操作',
    dataIndex: 'img',
    align: 'center',
    width: '23%',
    slots: {
      customRender: 'img',
    },
  },
];

export default defineComponent({
  components: {
    CDelModal,
    [RangePicker.name]: RangePicker,
    [Input.Search.name]: Input.Search,
    UploadOutlined,
    [Pagination.name]: Pagination,
    aModal: Modal,
    aTable: Table,
    aUpload: Upload,
    aSpin: Spin,
    aPopover: Popover,
    SvgIcon,
    ExclamationCircleFilled,
  },
  setup() {
    let token = getToken();
    const loading = ref<boolean>(false);
    // const setModal1Visible = (visible: boolean) => {
    //   modal2Visible.value = visible;
    // };
    //确认删除
    const showModal = () => {
      modal2Visible.value = true;
    };

    const handleOk = () => {
      // console.log(e);
      modal2Visible.value = false;
    };
    const selectedRowKeys = ref<Key[]>([]);
    selectedRowKeys.value = [];
    const onSelectChange = (changableRowKeys: Key[]) => {
      selectedRowKeys.value = changableRowKeys;
      checked_all.value = selectedRowKeys.value.length == datafol.value.length ? true : false;
    };
    const checked_all = ref(false);
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
      };
    });
    // 全选
    const current = ref(0);
    const setSelectAll = () => {
      if (checked_all.value) {
        let data: any = [];
        datafol.value.map((res) => {
          data.push(res.id);
        });
        onSelectChange([...data]);
      } else {
        onSelectChange([]);
      }
    };

    function getBase64(img: Blob, callback: (base64Url: string) => void) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result as string));
      reader.readAsDataURL(img);
    }

    // 取消删除按钮
    const modal2Visible = ref<boolean>(false);
    const openDelModat = () => {
      modal2Visible.value = !modal2Visible.value;
    };

    // 分页
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总页数
      options: ['10', '20', '30', '40', '50'], // 选项
    });

    let obj = ref({
      search: '',
      id: '',
    });
    // 获取媒体库列表;
    // const Document = () => {
    //   console.log(11111111111111111)
    //   let data = {
    //     page: pageConfig.current,
    //     page_num: pageConfig.pageSize,
    //     keywords: obj.value.search,
    //     computer_type: '',
    //     state_time: '',
    //   };
    //   http
    //     .getMediaFileList({
    //       params: data,
    //     })
    //     .then((res) => {
    //       pageConfig.current = res.data.data.current_page;
    //       pageConfig.totalSize = res.data.data.total;
    //       const resData = res.data.data;
    //       datafol.value = resData.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // };
    // Document();
    const queryForms = ref<Date[]>([]);
    // 防抖
    const shake = ref<boolean>(false);
    const mediaFileList = () => {
      let media: any = {
        keywords: obj.value.search,
        computer_type: '',
        // state_time: '',
        // end_time: '',
        page: pageConfig.current,
        page_num: pageConfig.pageSize,
      };
      const postMedia = new ResourceBill(media);
      // console.log('------', queryForms.value)
      postMedia.setDate(queryForms.value as any);
      if (shake.value) {
        return;
      }
      shake.value = true;
      showLoading.value = true;
      http
        .getMediaFileList({ params: postMedia })
        .then((res) => {
          shake.value = false;
          pageConfig.current = res.data.data.current_page;
          pageConfig.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          const resData = res.data.data;
          datafol.value = resData.data;
          showLoading.value = false;
        })
        .catch((err) => {
          showLoading.value = false;
        });
    };
    mediaFileList();

    // 分页点击事件
    const showSize = () => {
      mediaFileList();
    };
    // 文档库删除
    const openDelModal = () => {
      // id重复，清空数组
      let data: any = [];
      datafol.value.map((res) => {
        data.push(res.id);
      });
      modal2Visible.value = true;
    };
    const removeLiveData = () => {
      // 删除确定按钮
      modal2Visible.value = false;
      const params = {
        id: selectedRowKeys.value.join(','),
      };
      http.delMediaFile({ params: params }).then(() => {
        mediaFileList();
        selectedRowKeys.value = [];
        checked_all.value = false;
        message.success('删除成功！');
      });
    };

    // S上传文档
    // 上传文件至服务器
    const fileList = ref([]);
    const isUploading = ref(false);
    const handleChange = (info: FileInfo) => {
      isUploading.value = true;
      let value: any = fileList.value[fileList.value.length - 1];
      if (value.response?.code == 200) {
        message.success(value.response.message);
        isUploading.value = false;
        mediaFileList();
      } else if (value.response?.code) {
        isUploading.value = false;
        message.error(value.response?.error);
      }
    };
    const trigger = () => {
      mediaFileList();
    };
    // E上传文档
    // 预览
    const msgInfoRef = ref();
    const previews = ref<boolean>(false);
    const handPreview = () => {
      previews.value = false;
      // let array: any[] = [];
      // array.forEach((index) => {
      //   imageUrls.value.splice(index, 1);
      // })
      // let array = Array<any>();
      // Object.keys(imageUrls.value).forEach((e) => {
      //   if (imageUrls.value[e] === true) {
      //     array.push(e);
      //   }
      // });
      // return array.join(',');
    };
    const datas = ref();
    const imageUrls = ref();
    const previewsImg = (record: any) => {
      datas.value = record.computer_name;
      imageUrls.value = record.image_urls;
      previews.value = true;
    };
    const delDocument = (record: any) => {
      // 获取id
      selectedRowKeys.value = [];
      selectedRowKeys.value.push(record.id);
      modal2Visible.value = true;
    };
    // 文件上传中....
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    return {
      showLoading,
      obj,
      pageConfig,
      modal2Visible,
      // setModal1Visible,
      // choice: ref(false),
      // choice_all: ref(false),
      datafol,
      columns,
      rowSelection,
      selectedRowKeys,
      current,
      checked_all,
      setSelectAll,
      RangePickerIcon,
      loading,
      showModal,
      handleOk,
      // isSelected,
      openDelModat,
      removeLiveData,
      openDelModal,
      fileList,
      headers: {
        authorization: 'authorization-text',
      },
      handleChange,
      token,
      showSize,
      trigger,
      queryForms,
      documentPpt,
      documentPdf,
      documentPreview,
      documentDelete,
      handPreview,
      previewsImg,
      delDocument,
      previews,
      datas,
      imageUrls,
      msgInfoRef,
      indicator,
      isUploading,
      mediaFileList,
      moment,
    };
  },
});
