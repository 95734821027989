import store from '@/store';
import { RouteRecordRaw } from 'vue-router';
/**
 * 直播菜单
 * @descriptionmeta 包含相关关键字作用：
 *  父组件
 * @title - 标题
 * @icon - 菜单图标
 * @menuType - 菜单分类 首页菜单与进入直播间后的菜单区分
 * @userMenu {boolean} 是否在右上角菜单显示
 *
 * 子组件
 * @routeParent - 子选项的父级菜单标识
 * @hidden {boolean} 是否在左侧菜单显示
 * @customMode {boolean} 自定义mode层
 * @applink {boolean} 是否是站内路由
 * @role {boolean} 是否有权限
 * @only {boolean} 只能在对应role显示
 * @helperKey {string} 关键字对应助手接口返回关键字
 */
export const liveMenu: Array<RouteRecordRaw> = [
  {
    path: '/live_set/:studioId',
    name: 'liveSet',
    component: () => import('../views/liveSet/index.vue'),
    meta: {
      title: '基础设置',
      icon: 'menu_live_settings',
      menuType: 'live',
    },
    children: [
      {
        path: '/live_set/:studioId/broad_cast_control',
        name: 'BroadCastControl',
        component: () => import('../views/dataCenter/BroadCastControl.vue'),
        meta: {
          title: '直播总览',
          icon: '',
          routeParent: 'liveSet',
          role: ['stream', 'language', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/edit_live',
        name: 'EditLive',
        component: () => import('../views/newLive/newlive.vue'),
        meta: {
          title: '频道编辑',
          routeParent: 'liveSet',
          role: ['stream', 'language', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/count',
        name: 'CountDown',
        component: () => import('../views/compile/CountDown.vue'),
        meta: {
          title: '开播倒计时',
          icon: '',
          routeParent: 'liveSet',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/notice',
        name: 'Notice',
        component: () => import('../views/liveSet/Notice.vue'),
        meta: {
          title: '视频预告',
          routeParent: 'liveSet',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/numberSetting',
        name: 'NumberSetting',
        component: () => import('../views/liveSet/NumberSetting.vue'),
        meta: {
          title: '人数设置',
          routeParent: 'liveSet',
          role: ['language', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/liveassistant',
        name: 'LiveAssistant',
        component: () => import('../views/liveSet/LiveAssistant.vue'),
        meta: {
          title: '直播助手',
          routeParent: 'liveSet',
          role: ['language', 'stream'],
        },
      },
      {
        path: '/live_set/:studioId/signup_watch',
        name: 'SignupWatch',
        component: () => import('../views/liveSet/signupWatch/SignupWatch.vue'),
        meta: {
          title: '报名观看',
          routeParent: 'liveSet',
          role: ['stream', 'language', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/stream_boot',
        name: 'StreamBoot',
        component: () => import('../views/liveSet/StreamBoot.vue'),
        meta: {
          title: '多频道引导页',
          routeParent: 'liveSet',
          only: 'stream',
          role: ['stream', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/live_set/:studioId/ai_subtitles',
        name: 'AiSubtitles',
        component: () => import('../views/compile/AiSubtitles.vue'),
        meta: {
          title: '多语实时字幕',
          icon: '',
          routeParent: 'liveSet',
          funcId: 985,
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
    ],
  },

  {
    path: '/pages_editor/:studioId',
    name: 'PagesEditor',
    component: () => import('../views/pagesEditor/index.vue'),
    meta: {
      title: '页面配置',
      icon: 'menu_view_editor',
      menuType: 'live',
    },
    children: [
      {
        path: '/pages_editor/:studioId/page_director',
        name: 'PageDirector',
        component: () => import('../views/compile/PageDirector.vue'),
        meta: {
          title: '页面装饰',
          icon: '',
          routeParent: 'PagesEditor',
          role: ['stream', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },

      {
        path: '/pages_editor/:studioId/menu',
        name: 'CustomizeMenu',
        component: () => import('../views/pagesEditor/CustomizeMenu/CustomizeMenu.vue'),
        meta: {
          title: '自定义菜单',
          icon: '',
          routeParent: 'PagesEditor',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/pages_editor/:studioId/liveGuide',
        name: 'LiveGuide',
        component: () => import('../views/compile/LiveGuide.vue'),
        meta: {
          title: '直播引导页',
          icon: '',
          routeParent: 'PagesEditor',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/pages_editor/:studioId/antirecord',
        name: 'AntiRecordingScreen',
        component: () => import('../views/senior/antiRecordingScreen/AntiRecordingScreen.vue'),
        meta: {
          title: '防录屏设置',
          icon: '',
          routeParent: 'PagesEditor',
          funcId: 1005,
        },
      },
    ],
  },
  {
    path: '/market/:studioId',
    name: 'Market',
    component: () => import('../views/liveSet/index.vue'),
    meta: {
      title: '营销互动',
      icon: 'menu_present',
      menuType: 'live',
    },
    children: [
      {
        path: '/market/:studioId/mutually_engage',
        name: 'MutuallyEngage',
        component: () => import('../views/liveSet/InteractiveReward/MutuallyEngage.vue'),
        meta: {
          title: '互动打赏',
          icon: '',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/red_envelope_rain',
        name: 'RedEnvelopeRain',
        component: () => import('../views/liveSet/MarketingInteraction/RedEnvelopeRain.vue'),
        meta: {
          title: '红包雨',
          icon: '',
          routeParent: 'Market',
          role: ['language', 'helper'],
          helperKey: 'set', // 临时设置
          funcId: 603,
        },
      },
      {
        path: '/market/:studioId/competitive_red_envelope',
        name: 'competitive_red_envelope',
        component: () => import('../views/liveSet/MarketingInteraction/CompetitiveRedEnvelope.vue'),
        meta: {
          title: '竞答红包',
          icon: '',
          routeParent: 'Market',
          role: ['language', 'helper'],
          helperKey: 'set', // 临时设置
          funcId: 653,
        },
      },
      {
        path: '/market/:studioId/event_reservation',
        name: 'EventReservation',
        component: () => import('@/views/liveSet/EventReservation/EventReservation.vue'),
        meta: {
          title: '活动预约',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/advertising',
        name: 'Advertising',
        component: () => import('../views/liveSet/Advertising.vue'),
        meta: {
          title: '广告栏设置',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/vote',
        name: 'Vote',
        component: () => import('../views/liveSet/Vote/index.vue'),
        meta: {
          title: '互动投票',
          icon: '',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/vote/create',
        name: 'Create',
        component: () => import('../views/liveSet/Vote/Create.vue'),
        meta: {
          title: '新建投票',
          icon: '',
          hidden: true,
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/official',
        name: 'OfficialAccount',
        component: () => import('../views/liveSet/OfficialAccount.vue'),
        meta: {
          title: '公众号设置',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
          funcId: 444,
        },
      },
      {
        path: '/market/:studioId/sharing_set',
        name: 'SharingSetting',
        component: () => import('../views/liveSet/SharingSetting.vue'),
        meta: {
          title: '分享设置',
          routeParent: 'Market',
          role: ['stream', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/bullet_chat',
        name: 'bulletChat',
        component: () => import('../views/liveSet/bulletChat.vue'),
        meta: {
          title: '弹幕上墙',
          icon: '',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/market/:studioId/like_setting',
        name: 'LikeSetting',
        component: () => import('../views/liveSet/LikeSetting.vue'),
        meta: {
          title: '人气点赞',
          icon: '',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'set', // 临时设置
          funcId: 991,
        },
      },
      {
        path: '/market/:studioId/questionnairee',
        name: 'Questionnairee',
        component: () => import('../views/liveSet/QuestionnaireIn/index.vue'),
        meta: {
          title: '轻问卷',
          icon: 'questionIcon',
          routeParent: 'Market',
          role: ['helper'],
          helperKey: 'data', // 临时设置
        },
      },
    ],
  },
  {
    path: '/view_warrent/:studioId',
    name: 'viewWarrent',
    component: () => import('../views/viewWarrent/index.vue'),
    meta: {
      title: '观看授权',
      icon: 'warrent_view',
      menuType: 'live',
    },
    children: [
      {
        path: '/view_warrent/:studioId/authorization',
        name: 'Authorization',
        component: () => import('../views/viewWarrent/AuthorizedViewing/Empower.vue'),
        meta: {
          title: '授权设置',
          routeParent: 'viewWarrent',
          role: ['stream', 'language', 'helper'],
          helperKey: 'set', // 临时设置
        },
      },
    ],
  },
  {
    path: '/playback/:studioId',
    name: 'PlayBack',
    component: () => import('../views/testView.vue'),
    redirect: '/playback/:studioId/playback_settings',
    meta: {
      title: '视频回放',
      icon: 'menu_playblack',
      menuType: 'live',
    },
    children: [
      {
        path: '/playback/:studioId/playback_settings',
        name: 'playBack',
        component: () => import('../views/playback/playbackSettings.vue'),
        meta: {
          title: '回放设置',
          icon: '',
          routeParent: 'PlayBack',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/playback/:studioId/media_management',
        name: 'media',
        redirect: '/media/videos',
        meta: {
          title: '媒体库管理',
          icon: '',
          routeParent: 'PlayBack',
          role: [],
        },
      },
    ],
  },
  {
    path: '/data_center/:studioId',
    name: 'DataCenter',
    component: () => import('../views/dataCenter/index.vue'),
    meta: {
      title: '数据统计',
      icon: 'menu_data',
      menuType: 'live',
    },
    redirect: '/data_center/:studioId/live',
    children: [
      {
        path: '/data_center/:studioId/live',
        name: 'LiveData',
        component: () => import('../views/dataCenter/LiveData.vue'),
        meta: {
          title: '直播数据',
          icon: '',
          routeParent: 'DataCenter',
          role: ['language', 'helper'],
          helperKey: 'data', // 临时设置
          funcId: 1136,
        },
      },
      {
        path: '/data_center/:studioId/questionnaire',
        name: 'Questionnaire',
        component: () => import('../views/dataCenter/Questionnaire/index.vue'),
        meta: {
          title: '问卷报告',
          icon: '',
          routeParent: 'DataCenter',
          role: ['language', 'helper'],
          helperKey: 'data', // 临时设置
        },
      },
      {
        path: '/data_center/:studioId/chapter_playback',
        name: 'ChapterPlayback',
        component: () => import('../views/dataCenter/ChapterPlayback.vue'),
        meta: {
          title: '打点统计',
          icon: '',
          routeParent: 'DataCenter',
          role: ['language', 'helper'],
          helperKey: 'data', // 临时设置
          funcId: 1094,
        },
      },
      {
        path: '/data_center/:studioId/down_center',
        name: 'LiveDownCenter',
        component: () => import('../views/dataCenter/DownCenter.vue'),
        meta: {
          title: '下载中心',
          icon: '',
          routeParent: 'DataCenter',
          role: ['language', 'helper'],
          helperKey: 'data', // 临时设置
        },
      },
    ],
  },
  // {
  //   path: '/advance',
  //   name: 'Advance',
  //   component: () => import('../views/senior/index.vue'),
  //   meta: {
  //     title: '高级设置',
  //     icon: 'menu_advance',
  //     menuType: 'home',
  //     userMenu: true,
  //   },
  //   redirect: '/advance/antirecord',
  //   children: [
  //     {
  //       path: '/advance/antirecord',
  //       name: 'AntiRecordingScreen',
  //       component: () => import('../views/senior/antiRecordingScreen/AntiRecordingScreen.vue'),
  //       meta: {
  //         title: '防录屏设置',
  //         icon: '',
  //         routeParent: 'Advance',
  //         customMode: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/console/:studioId',
    name: 'Console',
    component: () => import('../views/control/Console.vue'),
    meta: {
      title: '发起直播',
      icon: 'menu_control',
      hidden: false,
      menuType: 'live',
      routeParent: 'Console',
      role: ['language'],
    },
    beforeEnter: (): void => {
      store.commit('home/customLayoutStyle', true);
    },
  },

  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('../views/test2.vue'),
  //   meta: {
  //     hidden: true,
  //     title: '测试',
  //     icon: '',
  //     routeParent: 'test',
  //   },
  // },
];
