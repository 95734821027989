import { ActionTree, MutationTree, StoreOptions } from 'vuex';
import {
  setToken,
  setUserCache,
  setSubToken,
  setCertificationView,
  setHelperStatus,
  removeHelperUrl,
  setHelperData,
  setExamineStatus,
  setAlias,
} from '@/plugins/cookie';
/**
 * @description: 用户信息
 * @id {ID}
 * @name {用户名}
 * @mobile {手机号}
 * @email {邮箱}
 * @avatar {头像}
 * @company {公司名称}
 * @identity {身份}
 * @token {token}
 */
export interface IUser {
  id: string;
  name: string;
  mobile: string;
  email: string;
  avatar: string;
  company: string;
  token: string;
  identity: string;
  alias: string;
}
interface HelpUser {
  account: string;
  avatar: string;
  chat: number;
  control: number;
  data: number;
  id: number;
  set: number;
  token: string;
}
class State {
  // list & info

  userInfo: IUser = {
    id: '',
    name: '默认用户',
    mobile: '',
    email: '',
    avatar: 'https://static.lighos.com/default/avatar.png',
    company: '',
    identity: '',
    token: '',
    alias: '',
  };
  helperInfo: HelpUser = {
    account: '',
    avatar: '',
    chat: 0,
    control: 0,
    data: 0,
    id: 0,
    set: 0,
    token: '',
  };
}

class User implements StoreOptions<State> {
  namespaced = true;

  state = new State();

  mutations: MutationTree<State> = {
    // list & info
    loginSaveUserInfo(store, userInfo: IUser) {
      store.userInfo = userInfo;
      setHelperStatus('0');
      setExamineStatus('0');
      removeHelperUrl();
      setUserCache(userInfo);
      setToken(userInfo.token);
      setCertificationView('1');
      setAlias(userInfo.alias);
    },
    subLoginSaveUserInfo(store, userInfo: IUser) {
      store.userInfo = userInfo;
      setHelperStatus('0');
      setExamineStatus('0');
      removeHelperUrl();
      setUserCache(userInfo);
      setSubToken(userInfo.token);
      setCertificationView('0');
    },
    helperSaveUserInfo(store, userInfo: HelpUser) {
      store.helperInfo = userInfo;
      setHelperStatus('1');
      setExamineStatus('0');
      setUserCache(userInfo);
      setToken(userInfo.token);
      setCertificationView('1');
    },
    examineSaveUserInfo(store, userInfo: IUser) {
      store.userInfo = userInfo;
      setHelperStatus('0');
      removeHelperUrl();
      setExamineStatus('1');
      setUserCache(userInfo);
      setToken(userInfo.token);
      setCertificationView('1');
    },
    setHelperInfo(store, data: any) {
      store.helperInfo.chat = data.chat;
      store.helperInfo.control = data.control;
      store.helperInfo.data = data.data;
      store.helperInfo.set = data.set;
      setHelperData(data);
    },
    setUserinfo(state, userInfo: IUser) {
      state.userInfo = userInfo;
    },
    resetHelper(state) {
      state.helperInfo = {
        account: '',
        avatar: '',
        chat: 0,
        control: 0,
        data: 0,
        id: 0,
        set: 0,
        token: '',
      };
    },
  };

  actions: ActionTree<State, unknown> = {};
}

export default new User();
