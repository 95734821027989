
import { defineComponent, ref } from 'vue';
import { city } from '@/assets/json/city.json';
import { Upload } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import http from '@/api/user';
import { getToken, setUserCache } from '@/plugins/cookie';
const cityArray = city;
import { createStore } from 'vuex';
import { DefaultUser } from '@/assets/defaultImg';
import user from '@/store/modules/user';
import { useDebounce } from '@/hooks/useDebounce';
import CUpload from '@/components/CUpload.vue';
import { UploadOutlined, ClearOutlined } from '@ant-design/icons-vue';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  components: {
    [Upload.name]: Upload,
    CUpload,
    UploadOutlined,
    ClearOutlined,
  },
  setup() {
    const readonly = ref(true);
    let token = getToken();
    let country = ref('China');
    // 省市二级联动
    let provinceList = ref(cityArray);
    let cityList = ref(provinceList.value[0].children);
    // 获取省份
    const getProvince = (index: number) => {
      cityList.value = provinceList.value[index].children;
      data.value.province = provinceList.value[index].value;
      data.value.city = cityList.value[0].value;
    };
    // 获取城市
    const getCity = (index: number) => {
      data.value.city = cityList.value[index].value;
    };

    let data: any = ref({
      email: '',
      name: '',
      company: '',
      mobile: '',
      avatar: '',
      image_avatar_id: ' ',
      city: undefined,
      province: undefined,
      id: null,
      country: '中国',
    });
    // 上传头像
    const fileList = ref([]);
    const uploadLogo = (info: FileInfo) => {
      if (info.file?.response?.code === 200) {
        data.value.avatar = info.file?.response?.data.url;
        data.value.image_avatar_id = info.file?.response?.data.id;
      }
      if (info.file.status === 'error') {
        message.error('上传失败');
      }
    };
    const imageDel = () => {
      data.value.avatar = '';
      data.value.image_avatar_id = '';
    };
    // 获取基本设置
    const getMemberInfo = () => {
      http.getMemberInfo().then((res) => {
        data.value = res.data.data;
        data.value.province ? '' : (data.value.province = undefined);
        data.value.city ? '' : (data.value.city = undefined);
        data.value.id ? '' : (data.value.id = null);
        data.value.country ? '' : (data.value.country = '中国');
        store.commit('increment');
      });
    };
    getMemberInfo();
    const toEdit = () => {
      readonly.value = !readonly.value;
      getMemberInfo();
    };
    // 修改基本设置
    const toUpdMember = useDebounce(() => {
      message.destroy();
      delete data.value.id;
      delete data.value.mobile;
      delete data.value.avatar;
      delete data.value.email;
      data.value.image_avatar_id ? '' : (data.value.image_avatar_id = 0);
      http.updMemberInfo(data.value).then(() => {
        store.commit('increment');
        message.success('更新成功！');
        readonly.value = true;
        getMemberInfo();
      });
    });

    const store = createStore({
      state: {
        user,
      },
      mutations: {
        increment(state) {
          state.user.state.userInfo.avatar = data.value.avatar ? data.value.avatar : DefaultUser;
          state.user.state.userInfo.name = data.value.name;
          state.user.state.userInfo.company = data.value.company;
          setUserCache(state.user.state.userInfo);
        },
      },
    });

    return {
      country,
      provinceList,
      cityList,
      getProvince,
      getCity,
      token,
      uploadLogo,
      imageDel,
      fileList,
      data,
      toUpdMember,
      DefaultUser,
      readonly,
      toEdit,
    };
  },
});
