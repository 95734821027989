
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Steps, message, Statistic, Alert } from 'ant-design-vue';
import { CheckCircleFilled, InfoCircleFilled, CloseOutlined } from '@ant-design/icons-vue';
import { mail } from '@/assets';
import http from '@/api/user';
import { useDebounce } from '@/hooks/useDebounce';

export default defineComponent({
  components: {
    [Steps.Step.name]: Steps.Step,
    [Statistic.Countdown.name]: Statistic.Countdown,
    CheckCircleFilled,
    InfoCircleFilled,
    CloseOutlined,
    aAlert: Alert,
  },
  setup() {
    const alert = '还未设置密码，请设置一个密码。';
    console.log(process.env.VUE_APP_API_LINK);
    let current = ref(0);
    let routerId = ref(1);
    const id = useRouter().currentRoute.value.query.id;
    const text = useRouter().currentRoute.value.query.text;
    routerId.value = Number(id) ? Number(id) : 0;
    const stepList = [
      {
        id: 0,
        one: '填写密码信息',
        two: '确认密码信息',
        three: '完成',
      },
      {
        id: 1,
        one: '填写手机号码',
        two: '确认手机号',
        three: '完成',
      },
      {
        id: 2,
        one: '验证身份',
        two: '修改邮箱',
        three: '完成',
      },
    ];
    // 下一步
    const toNext = useDebounce(async () => {
      let next: any = true;
      if (routerId.value == 0) {
        if (password.value.newPassword.replace(/[\u0391-\uFFE5]/g, 'aa').length > 16) {
          message.warning('新密码不能大于16位字符!');
          return;
        }
        next = await passwordVerify();
      } else if (routerId.value == 1) {
        next = await mobileVerify();
      } else {
        next = await emailVerify();
      }
      next ? current.value++ : '';
    });
    let error = ref(false);

    // 账户密码
    const password = ref({
      oldPassword: '',
      newPassword: '',
      newsPassword: '',
      code: '',
    });
    //检测密码状态
    const oldPasswordStatus = ref(false);
    const checkPassword = () => {
      http
        .checkPasswordStatus()
        .then((res) => {
          oldPasswordStatus.value = res.data.code == 200 ? false : true;
        })
        .catch(() => {
          oldPasswordStatus.value = true;
          message.destroy();
        });
    };
    routerId.value == 0 ? checkPassword() : '';
    // 点击更新验证码
    const codeImg = ref('/api/v1/member/get_image_auth_code');
    const switchCode = () => {
      codeImg.value = `/api/v1/member/get_image_auth_code?time=${new Date()}`;
    };

    const passwordLevel = ref();
    const passwordText = ref();
    // 输入密码规则
    const rules = () => {
      passwordText.value = undefined;
      if (password.value.newPassword.replace(/[\u0391-\uFFE5]/g, 'aa').length < 6) {
        passwordText.value = '密码不能小于6位字符';
        return;
      }
      if (password.value.newPassword.replace(/[\u0391-\uFFE5]/g, 'aa').length > 16) {
        passwordText.value = '密码不能大于16位字符';
        return;
      }
    };

    // 获取密码级别
    const getPasswordLevel = () => {
      password.value.newPassword = password.value.newPassword.replace(/[\u4e00-\u9fa5]/g, '');
      const characterReg = new RegExp('[/\\\\*:?"<>| %$&~`!+=-_^$#@]'); //特殊字符
      const num = password.value.newPassword.match(/[0-9]/gi);
      const eArr = password.value.newPassword.match(/[a-zA-Z]/gi);
      const cAhReg = new RegExp('[A-Z]'); //英文字符
      const chReg = new RegExp('[a-z]'); //英文字符
      const tArr = characterReg.test(password.value.newPassword);
      if (
        password.value.newPassword.replace(/[\u0391-\uFFE5]/g, 'aa').length >= 6 &&
        password.value.newPassword.replace(/[\u0391-\uFFE5]/g, 'aa').length <= 16
      ) {
        if (chReg.test(password.value.newPassword) && cAhReg.test(password.value.newPassword) && num) {
          passwordLevel.value = '强';
        } else if (
          (num && eArr === null && !tArr) ||
          (eArr && num === null && !tArr) ||
          (tArr && num === null && eArr === null)
        ) {
          passwordLevel.value = '弱';
        } else {
          passwordLevel.value = '中';
        }
      }
    };

    // 修改密码验证
    const deadline = ref();
    const passwordVerify = () => {
      return new Promise((resolve) => {
        message.destroy();
        const oldPassword = password.value.oldPassword;
        const newPassword = password.value.newPassword;
        const newsPassword = password.value.newsPassword;

        if (current.value == 0) {
          if (oldPasswordStatus.value) {
            if (newPassword.length < 6) {
              message.warning('请设置最少六位长度的密码！');
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            if (oldPassword.length < 6 || newPassword.length < 6) {
              message.warning('请设置最少六位长度的密码！');
            } else if (oldPassword == newPassword) {
              message.warning('新密码不能与旧密码一致！');
            } else {
              console.log(111);
              // 验证旧密码
              const data = {
                password: password.value.oldPassword,
                new_password: password.value.newPassword,
              };
              http
                .verifyOldPassword(data)
                .then(() => {
                  console.log(222);

                  resolve(true);
                })
                .catch((err) => {
                  resolve(false);
                  message.error(err.data.error);
                });
            }
          }
        } else if (current.value == 1) {
          if (newsPassword.length < 6) {
            message.warning('请设置最少六位长度的密码！');
          } else if (newPassword !== newsPassword) {
            message.warning('两次输入密码不一致！');
          } else if (!password.value.code) {
            message.warning('请输入验证码！');
          } else {
            // 修改帐密
            const data = {
              password: password.value.oldPassword,
              new_password: password.value.newsPassword,
              code: password.value.code,
            };
            http
              .saveNewPassword(data)
              .then(() => {
                localStorage.removeItem('lighostoken');
                deadline.value = Date.now() + 10000;
                resolve(true);
              })
              // .catch(() => {
              //   switchCode();
              //   resolve(false);
              // });
              .catch((err) => {
                switchCode();
                resolve(false);
                message.error(err.data.error);
              });
          }
        }
      });
    };
    const mobile = ref({
      countDown: 0,
      newCountDown: 0,
      oldCode: '',
      newMobile: '',
      newCode: '',
    });

    // 修改手机验证
    const mobileVerify = () => {
      return new Promise((resolve) => {
        message.destroy();
        let mobiles = mobile.value;
        if (current.value == 0) {
          if (!mobiles.oldCode) {
            message.warning('验证码不能为空！');
            resolve(false);
          } else {
            let data = {
              code: mobiles.oldCode,
            };
            http
              .checkCodeMobile(data)
              .then(() => {
                resolve(true);
              })
              .catch((err) => {
                resolve(false);
                message.error(err.data.error);
              });
          }
          // resolve(true);
        } else if (current.value == 1) {
          if (!mobiles.newMobile || !mobiles.newCode) {
            message.warning(`${mobiles.newMobile ? '验证码不能为空！' : '手机号不能为空！'}`);
          } else {
            let data = {
              new_mobile: mobiles.newMobile,
              code: mobiles.newCode,
            };
            http
              .changeMobile(data)
              .then(() => {
                localStorage.removeItem('lighostoken');
                deadline.value = Date.now() + 10000;
                message.success('修改成功！');
                resolve(true);
              })
              .catch((err) => message.error(err.data.error));
          }
        }
      });
    };

    const email = ref({
      code: '',
      email: '',
      codes: '',
      countDown: 0,
    });
    // 修改密码验证
    const emailVerify = () => {
      return new Promise((resolve) => {
        message.destroy();
        let emails = email.value;
        if (current.value == 0) {
          if (!emails.code) {
            message.warning('验证码不能为空！');
            resolve(false);
          } else {
            // 验证手机验证码
            let data = {
              code: emails.code,
            };
            http
              .checkCodeMobile(data)
              .then(() => {
                resolve(true);
              })
              .catch((err) => {
                resolve(false);
                message.error(err.data.error);
              });
          }
        } else if (current.value == 1) {
          if (send_mailbox.value) {
            // 发送邮箱验证码
            sendEmail();
            resolve(false);
          } else {
            if (!email.value.codes) {
              message.warning('验证码不能为空！');
              resolve(false);
            } else {
              // 修改邮箱
              let data = {
                email: email.value.email,
                code: Number(email.value.codes),
              };
              http
                .buildEmail(data)
                .then(() => {
                  deadline.value = Date.now() + 10000;
                  resolve(true);
                })
                .catch(() => {
                  resolve(false);
                });
            }
          }
        }
      });
    };
    // 新邮箱发送邮件
    const sendEmail = () => {
      email.value.countDown = send_mailbox.value ? 0 : Date.now() + 60000;
      if (!email.value.email) {
        message.warning('新邮箱不能为空！');
      } else {
        let data = {
          email: email.value.email,
        };
        http
          .sendEmailCode(data)
          .then(() => {
            send_mailbox.value ? (send_mailbox.value = false) : '';
          })
          .catch((err) => message.error(err.data.error));
      }
    };

    // 未收到验证码
    // const noReceived = () => {
    //   Modal.confirm({
    //     title: '未收到验证码',
    //     icon: createVNode(ExclamationCircleFilled),
    //     content: '更改手机号收不到验证短信可进行人工验证更改手机号！',
    //     onOk() {
    //       console.log('OK');
    //     },
    //     onCancel() {
    //       console.log('Cancel');
    //     },
    //     centered: true,
    //     closable: true,
    //     okText: '人工验证',
    //   });
    // };

    let send_mailbox = ref(true);
    // 完成倒计时结束
    const router = useRouter();
    const onFinish = () => {
      if (routerId.value == 2) {
        router.push('/personal/set/security');
      } else {
        location.reload();
      }
    };
    // 发送验证码
    const sendCode = () => {
      http
        .sendCodeMobile()
        .then(() => {
          message.success('发送成功！');
        })
        .catch((err) => message.error(err.data.error));
      mobile.value.countDown = Date.now() + 60000;
    };
    // 发送验证码倒计时结束
    const onFinishMobile = () => {
      if (current.value == 0) {
        mobile.value.countDown = 0;
      } else {
        mobile.value.newCountDown = 0;
      }
    };
    // 发送新手机号验证码
    const sendNewCode = () => {
      if (!mobile.value.newMobile) {
        message.warning('手机号不能为空！');
        return;
      } else {
        let data = {
          type: 0,
          mobile: mobile.value.newMobile,
        };
        http
          .sendCaptcha(data)
          .then(() => {
            message.success('发送成功！');
          })
          .catch((err) => message.error(err.data.error));
        mobile.value.newCountDown = Date.now() + 60000;
      }
    };

    const modifyTips = ref(true);

    return {
      alert,
      current,
      routerId,
      stepList,
      toNext,
      error,
      // noReceived,
      mail: mail,
      send_mailbox,
      // tempLogin,
      // getCode,
      oldPasswordStatus,
      password,
      codeImg,
      switchCode,
      deadline,
      onFinish,
      sendCode,
      mobile,
      onFinishMobile,
      sendNewCode,
      email,
      text,
      modifyTips,
      sendEmail,
      rules,
      passwordLevel,
      getPasswordLevel,
      passwordText,
    };
  },
});
