import { ActionTree, MutationTree, StoreOptions } from 'vuex';
import axios from '@/api/live';
import { getToken } from '@/plugins/cookie';
import { message } from 'ant-design-vue';
import { IdGive, InviteForm, PayForm, PinForm, Upload, UserWhitelist, WeChat, WhiteList } from './types';
// import routerid from '@/router';
// import { isArray } from '@vue/shared';
// const liveBroadcastingRoomId = routerid.currentRoute.value.params.studioId;

class State {
  //  总开关
  free = false;
  //id
  studioId = '';
  keyAuth = '';
  loading = false;
  showLoading = false;
  mode = 0;
  //密码
  liveView: PinForm = {
    title: '',
    password_setting: '',
    password_watch: false,
  };
  //支付
  payView: PayForm = {
    paySwitch: false,
    price: 0,
    text: '',
    isPhonebypass: false,
    isInvitebypass: false,
  };
  weView: WeChat = {
    wechatSwitch: false,
  };
  // 白名单
  whiteView: WhiteList = {
    mobileWhiteList: [],
    ipWhiteList: [],
    whiteSwitch: false,
    loginPoint: false, //单点登录
  };
  // 邀约
  inviteView: InviteForm = {
    viewSwitch: false,
    inviturl: '',
    qrCode: '',
  };
  //身份赋予
  idView: IdGive = {
    callback: '',
    authKey: '',
    idLoginPoint: false,
    idGiveSwitch: false,
  };
  userWhite: UserWhitelist = {
    user_whitelist_status: false,
    user_whitelist_point_status: false,
    user_whitelist_setting: '',
  };
  // mobileImg = '';
  // pcImg = '';
  Upload: Upload = {
    mobileImg: '',
    pcImg: '',
  };
  checkFree = false;
}

// 提交
function saveData(state: State) {
  const free_watch = state.free ? 0 : 1; //总开关

  const id = state.studioId; // id

  const token = getToken();

  const password_watch = state.liveView.password_watch ? 1 : 0;

  const password_setting = JSON.stringify({
    Watch_code: state.liveView.password_setting,
    title: state.liveView.title,
  });

  const pay_watch = state.payView.paySwitch ? 1 : 0; //支付

  const pay_setting = JSON.stringify({
    price: state.payView.price * 100,
    hint_text: state.payView.text,
    isPhonebypass: state.payView.isPhonebypass,
    isInvitebypass: state.payView.isInvitebypass,
  }); // 支付

  const wechat_watch = state.weView.wechatSwitch ? 1 : 0; //微信

  const whitelist_watch = state.whiteView.whiteSwitch ? 1 : 0; //白名单

  const login_point = state.whiteView.loginPoint ? 1 : 0;

  const w = state.whiteView.mobileWhiteList
    ? state.whiteView.mobileWhiteList.map((item) => {
        return item.mobile;
      })
    : '';
  const arr = Array.from(state.whiteView.ipWhiteList ? state.whiteView.ipWhiteList : '');
  const whitelist_setting = JSON.stringify({
    ip_whitelist: arr,
    mobile_whitelist: w,
  });

  const invite_watch = state.inviteView.viewSwitch ? 1 : 0; //邀约

  const invite_setting = JSON.stringify({
    invite_url: state.inviteView.inviturl,
    qrcode: state.inviteView.qrCode,
  });

  const user_whitelist_status = state.userWhite.user_whitelist_status ? 1 : 0;

  const user_whitelist_point_status = state.userWhite.user_whitelist_point_status ? 1 : 0;

  const user_whitelist_setting = JSON.stringify(state.userWhite.user_whitelist_setting) || '';
  // data.api_endowment.callback = state.idView.callback; // 身份赋予
  // data.api_endowment.auth_key = state.idView.authKey;
  // data.api_endowment.identity_point = state.idView.idLoginPoint;
  return {
    studio_id: id,
    free_watch,
    token,
    password_watch,
    password_setting,
    pay_watch,
    pay_setting,
    wechat_watch,
    whitelist_watch,
    login_point,
    whitelist_setting,
    invite_watch,
    invite_setting,
    user_whitelist_status,
    user_whitelist_point_status,
    user_whitelist_setting,
  };
}
function cutomData(state: State) {
  const id = state.studioId; // id
  const token = getToken();
  const callback = state.idView.callback;
  const identity_key = state.idView.authKey;
  const identity_point = state.idView.idLoginPoint ? 1 : 0;
  const status = state.idView.idGiveSwitch ? 1 : 0;
  return {
    studio_id: id,
    token,
    callback,
    identity_key,
    identity_point,
    status,
  };
}

class authorizView implements StoreOptions<State> {
  namespaced = true;
  state = new State();

  mutations: MutationTree<State> = {
    getImg(state, data) {
      const mobileImg = data.mobile_image_url;
      const pcImg = data.pc_image_url;
      state.Upload = {
        mobileImg,
        pcImg,
      };
    },
    upImg(state, data) {
      state.Upload = data;
    },
    getUserWhitelist(state, data) {
      const user_whitelist_status = data.user_whitelist_status === 1 ? true : false;
      const user_whitelist_point_status = data.user_whitelist_point_status === 1 ? true : false;
      const user_whitelist_setting = data.user_whitelist_setting
        ? JSON.parse(data.user_whitelist_setting)
        : {
            title: '',
            account: '',
            password: '',
            login: '',
          };
      state.userWhite = {
        user_whitelist_status,
        user_whitelist_point_status,
        user_whitelist_setting,
      };
    },
    setUserWhitelist(state, data) {
      state.userWhite = data;
      // console.log(state.liveView);
    },
    // updata密码
    updataLiveView(state, data: any) {
      const password_watch = data.password_watch === 1 ? true : false;
      const { Watch_code, title } = data.password_setting ? JSON.parse(data.password_setting) : 0;
      state.liveView = { title, password_setting: Watch_code, password_watch };
      // console.log(state.liveView);
    },
    updateViewForm(state, data: PinForm) {
      state.liveView = data;
      // console.log(state.liveView);
    },
    // updata支付
    updatePayView(state, data) {
      const paySwitch = data.pay_watch === 1 ? true : false;
      const { price, hint_text, isPhonebypass, isInvitebypass } = data.pay_setting ? JSON.parse(data.pay_setting) : '';
      state.payView = {
        price: price ? price / 100 : 0,
        paySwitch,
        text: hint_text,
        isPhonebypass: isPhonebypass === true ? true : false,
        isInvitebypass: isInvitebypass === true ? true : false,
      };
    },
    updatePayForm(state, data: PayForm) {
      state.payView = data;
      // console.log(state.payView);
    },
    // updata 微信
    updateWeView(state, data) {
      state.weView.wechatSwitch = data.wechat_watch === 1 ? true : false;
    },
    updateWeForm(state, data: WeChat) {
      state.weView = data;
    },
    // 修改白名单
    updateWhiteView(state, data) {
      const whiteSwitch = data.whitelist_watch === 1 ? true : false;
      const loginPoint = data.login_point === 1 ? true : false;
      const { mobile_whitelist, ip_whitelist } = data.whitelist_setting ? JSON.parse(data.whitelist_setting) : '';
      state.whiteView = {
        whiteSwitch: whiteSwitch,
        loginPoint,
        mobileWhiteList: mobile_whitelist?.map((e: string, i: number) => ({
          id: i,
          mobile: e,
        })),
        ipWhiteList: ip_whitelist,
      };
    },
    updateWhiteForm(state, data: WhiteList) {
      state.whiteView = data;
    },

    //修改二维码
    updateInviteView(state, data) {
      const viewSwitch = data.invite_watch === 1 ? true : false;
      const { invite_url, qrcode } = data.invite_setting ? JSON.parse(data.invite_setting) : '';
      state.inviteView = { viewSwitch, inviturl: invite_url, qrCode: qrcode };
    },
    updateInviteForm(state, data: InviteForm) {
      state.inviteView = data;
      // console.log(state.inviteView);
    },
    // 身份赋予
    updateIdView(state, data) {
      const callback = data.api_endowment.callback;
      const authKey = data.api_endowment.auth_key;
      const idLoginPoint = data.api_endowment.identity_point === 1 ? true : false;
      const idGiveSwitch = data.api_endowment.status === 1 ? true : false;
      state.idView = { callback, authKey, idLoginPoint, idGiveSwitch };
    },
    updateIdForm(state, data: IdGive) {
      state.idView = data;
      // console.log(state.idView);
    },

    //多种开关打开时
    checkPassSwitch(state) {
      if (
        state.payView.paySwitch ||
        state.weView.wechatSwitch ||
        state.whiteView.whiteSwitch ||
        state.inviteView.viewSwitch ||
        state.idView.idGiveSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkPaySwitch(state) {
      if (
        state.liveView.password_watch ||
        state.weView.wechatSwitch ||
        state.whiteView.whiteSwitch ||
        state.inviteView.viewSwitch ||
        state.idView.idGiveSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkWSwitch(state) {
      if (
        state.liveView.password_watch ||
        state.payView.paySwitch ||
        state.whiteView.whiteSwitch ||
        state.inviteView.viewSwitch ||
        state.idView.idGiveSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkWhiteSwitch(state) {
      if (
        state.liveView.password_watch ||
        state.payView.paySwitch ||
        state.weView.wechatSwitch ||
        state.inviteView.viewSwitch ||
        state.idView.idGiveSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkUserWhiteSwitch(state) {
      if (
        state.liveView.password_watch ||
        state.payView.paySwitch ||
        state.weView.wechatSwitch ||
        state.inviteView.viewSwitch ||
        state.idView.idGiveSwitch ||
        state.whiteView.whiteSwitch
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkInviteSwitch(state) {
      if (
        state.liveView.password_watch ||
        state.payView.paySwitch ||
        state.weView.wechatSwitch ||
        state.whiteView.whiteSwitch ||
        state.idView.idGiveSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    chaeckSwitch(state) {
      state.payView.paySwitch = state.weView.wechatSwitch = false; // 身份赋予  与付费、微信不能一起使用
      if (
        state.liveView.password_watch ||
        state.payView.paySwitch ||
        state.weView.wechatSwitch ||
        state.whiteView.whiteSwitch ||
        state.inviteView.viewSwitch ||
        state.userWhite.user_whitelist_status
      ) {
        message.info('您目前存在多种授权观看方式，保存即生效。');
      }
    },
    checkSave(state) {
      if (
        !state.liveView.password_watch &&
        !state.payView.paySwitch &&
        !state.weView.wechatSwitch &&
        !state.whiteView.whiteSwitch &&
        !state.inviteView.viewSwitch &&
        !state.idView.idGiveSwitch &&
        !state.userWhite.user_whitelist_status
      ) {
        state.checkFree = true;
      } else {
        state.checkFree = false;
      }
    },
    // 总开关
    getFree(state, data) {
      const freeSwitch = data.free_watch === 0 ? true : false;
      state.free = freeSwitch;
      // console.log(state.free);
    },
    // 开关联动.
    updateChildSwitch(state) {
      if (!state.free) {
        // console.log('123', state.free);
        state.free = true;
      }
      if (state.payView.paySwitch === true) {
        state.weView.wechatSwitch = true;
      }
    },
    updateSwitch(state, data) {
      state.free = data;
    },
    //id
    getId(state, data) {
      state.studioId = data.id;
      state.mode = data.mode;
    },
    updataId(state, data) {
      state.studioId = data;
    },
    getKeyAuth(state, data) {
      state.keyAuth = data.auth_key;
    },
  };

  actions: ActionTree<State, unknown> = {
    getLiveView({ commit, state }, studioId) {
      state.showLoading = true;
      const data = {
        studio_id: studioId,
      };
      axios
        .getLiveView({ params: data })
        .then((res) => {
          commit('updataLiveView', res.data.data || {});
          commit('updatePayView', res.data.data || {});
          commit('updateWeView', res.data.data || {});
          commit('updateWhiteView', res.data.data || {});
          commit('updateInviteView', res.data.data || {});
          commit('updateIdView', res.data.data || {});
          commit('getFree', res.data.data || {});
          commit('getId', res.data.data);
          commit('getUserWhitelist', res.data.data || {});
          commit('getImg', res.data.data || {});
        })
        .finally(() => (state.showLoading = false));
    },
    saveAothorize({ state }) {
      state.loading = true;
      const str = saveData(state);
      const data = {
        ...str,
      };
      return new Promise((resolve, reject) => {
        axios
          .saveAothorize(data)
          .then((res) => {
            if (res.data.code === 200) {
              // message.destroy();
              // message.success(res.data.message);
              resolve(res.data.message);
              state.loading = false;
            }
          })
          .catch((err) => {
            // message.destroy();
            reject(err.data);
            // message.error(err.data.error);
          });
      });
    },
    customAuthorization({ commit, state }) {
      const str = cutomData(state);
      const data = {
        ...str,
      };
      return new Promise((resolve, reject) => {
        axios
          .customAuthorization(data)
          .then((res) => {
            commit('getKeyAuth', res.data.data || {});
            resolve(res.data.data.auth_key);
          })
          .catch((err) => {
            reject(err.data);
          });
      });
    },
  };
}
export default new authorizView();
