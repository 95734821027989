export interface IColumns {
  title: string;
  dataIndex: string;
  key: string;
  width?: number;
  align?: string;
  slots?: any;
}

export interface ICommentT1 {
  id: number;
  type_name?: string;
  identity_name?: string;
}

export class ICommentT {
  id: number;
  name: string | any;
  constructor(data: ICommentT1) {
    this.id = data.id;
    this.name = data.type_name || data.identity_name;
  }
}

export interface ICreateD {
  avatal: string;
  avatalId: number;
  content: string;
}

export interface RootModal {
  title: string;
  visible: boolean;
  loading: boolean;
}