// 美化二维码
import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  getData(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_qr_code_style_config', data);
  }
  setCode(data: unknown) {
    return http.post('/api/v1/common/set_up_qr_code_style', data);
  }
}

export default new Api();
