<template>
  <div style="position: relative; flex-shrink: 0" :style="{ width: width + 'px', height: '22px' }">
    <!-- 小格 -->
    <div class="grid-container" :style="{ width: width + 'px', height: height + 'px', borderLeft: border }"></div>

    <!-- 数字 -->
    <div class="grid-number" :style="{ marginTop: height + 2 + 'px' }" v-if="showNumber">
      {{ time }}
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, defineProps, computed } from 'vue';

// 接收参数
const props = defineProps({
  width: Number,
  frame: Number,
  showNumber: Boolean,
});

// 小格的高度
const height = computed(() => (props.showNumber ? 9 : 7));

const border = computed(() => (props.showNumber ? '1px solid #333333' : '1px solid #B1B1B1'));

// 小格的显示时间
const second2hms = (second) => {
  const h = Math.floor(second / 3600) >= 10 ? String(Math.floor(second / 3600)) : '0' + Math.floor(second / 3600);
  const m = Math.floor((second / 60) % 60) >= 10 ? String(Math.floor((second / 60) % 60)) : '0' + Math.floor((second / 60) % 60);
  const s = Math.floor(second % 60) >= 10 ? String(Math.floor(second % 60)) : '0' + Math.floor(second % 60);

  return h === '00' ? m + ':' + s : h + ':' + m + ':' + s;
};

const frame2Time = (frame, fps) => (frame % fps === 0 ? second2hms(frame / fps) : (frame % fps) + 'f');
const time = computed(() => frame2Time(props.frame ? props.frame : 0, 30));
</script>

<style>
.grid-container {
  position: absolute;
  top: 0;
  left: 0;
}

.grid-number {
  position: absolute;
  left: 0;
  top: 7px;
  color: #979797;
  font-weight: bold;
  font-size: 12px;
  transform: scale(0.83333333);
  transform-origin: left center;
  line-height: 18px;
}
</style>
