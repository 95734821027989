import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createBlock(_component_a_result, {
    class: "result_warp",
    title: "很抱歉，暂时没有找到您访问的页面。",
    "sub-title": "Sorry, the page you visited does not exist."
  }, {
    icon: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_svg_icon, {
          class: "not_found",
          "icon-class": "notFound"
        })
      ])
    ]),
    extra: _withCtx(() => [
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.back
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.backTime) + "秒后返回首页", 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}