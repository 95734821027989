import { message } from 'ant-design-vue';
import { ref, Ref } from 'vue';
export interface UseUpload {
  fileList: Ref<any>;
  loading: Ref<boolean>;
  handleChange: (...args: any[]) => any;
  imageDel: (...args: any[]) => any;
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export function useUpload(images: Ref<string>, imageIds: Ref<any>): UseUpload {
  const fileList = ref<any>();
  const loading = ref(false);
  const handleChange = (info: FileInfo) => {
    if (info.file.status === 'error') {
      loading.value = false;
      message.error('上传失败');
    }
    if (info.file?.response?.code === 200) {
      images.value = info.file?.response?.data.url;
      imageIds.value = info.file?.response?.data.id ? info.file?.response?.data.id : 0;
    }
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    } else loading.value = false;
  };
  const imageDel = () => {
    images.value = '';
    imageIds.value = '';
  };
  return { fileList, loading, handleChange, imageDel };
}
