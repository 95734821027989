
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import MobileHeader from '../components/MobileHeader.vue';
import { defineComponent, nextTick, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message, Modal } from 'ant-design-vue';
import http from '@/api/home';
import https from '@/api/login';
import { getDisplay } from '@/plugins/cookie';
import CAwsc from '@/components/CAwsc.vue';
import { useStore } from 'vuex';
import { CheckOutlined } from '@ant-design/icons-vue';
import { useDebounce } from '@/hooks/useDebounce'; //防抖

interface Ilogin {
  name: string;
  username: number | string;
  password: string;
  company: string;
  code: string;
  mobile: string;
}
// 百度统计
var _hmt: any = _hmt || [];
(function () {
  var hm = document.createElement('script');
  hm.src = 'https://hm.baidu.com/hm.js?fd5c6566568d3f88bfee5af54cc30922';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode?.insertBefore(hm, s);
})();
export default defineComponent({
  components: {
    aModal: Modal,
    PrivacyPolicy,
    MobileHeader,
    CAwsc,
    CheckOutlined,
  },
  setup() {
    const store = useStore();
    // const defaultTopLogo = 'https://images.innocomn.com/top_logo.svg';
    const defaultTopLogo = store.state.home.black_top_logo_url;

    const userName = ref<string>('');
    const router = useRouter();
    const route = useRoute(); // 新年新版本活动
    const company = ref('');
    const formState = reactive({
      layout: 'horizontal',
    });
    const formData: Ilogin = reactive({
      name: '', //用户名
      username: '',
      password: '',
      company: '',
      code: '',
      mobile: '',
    });
    const isShowform = ref(true);
    const formItemLayout = reactive({
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    });
    const jump = () => {
      router.push('/Logins');
    };

    let isShowcode = ref(true);
    let timer: any = ref(0);
    const TIME_COUNT = 60;
    let count = ref(TIME_COUNT);
    const getCode = () => {
      var reg = /^1[0-9]{10}$/;
      if (!reg.test(formData.username as string)) {
        message.info('请输入正确有效的手机号');
        return false;
      }
      if (!timer.value) {
        count.value = TIME_COUNT;
        isShowcode.value = false;
        timer.value = setInterval(() => {
          if (count.value > 0 && count.value <= TIME_COUNT) {
            count.value--;
          } else {
            isShowcode.value = true;
            clearInterval(timer.value);
            timer.value = 0;
          }
        }, 1000);
      }
      // 获取验证码
      let data = {
        mobile: formData.username,
        type: 0,
      };
      http.sendCaptcha(data);
    };

    // 验证用户名规则
    const showYes = ref(false);
    const nameText = ref();
    const rules = () => {
      return new Promise((resolve: any) => {
        showYes.value = false;
        nameText.value = undefined;
        const characterReg = new RegExp('[/\\\\*:?"<>| %$&~`!^$#@]'); //特殊字符
        const zhReg = new RegExp('[\u4e00-\u9fa5]'); //中文字符
        const chReg = new RegExp('[a-zA-Z]'); //英文字符
        if (formData.name.replace(/[\u0391-\uFFE5]/g, 'aa').length < 6) {
          nameText.value = '用户名不能小于6位字符';
          resolve(false);
          return false;
        }
        if (formData.name.replace(/[\u0391-\uFFE5]/g, 'aa').length > 18) {
          nameText.value = '用户名不能大于18位字符';
          resolve(false);
          return false;
        }
        if (characterReg.test(formData.name)) {
          nameText.value = '用户名不能包含特殊字符';
          resolve(false);
          return false;
        }
        if (!zhReg.test(formData.name) && !chReg.test(formData.name)) {
          nameText.value = '用户名必须包含中文或字母';
          resolve(false);
          return false;
        }
        http
          .name_is_exist({ name: formData.name })
          .then((res) => {
            if (res.data.data) {
              showYes.value = true;
              resolve(true);
            }
          })
          .catch((err) => message.error(err?.data?.error));
      });
    };

    //隐私协议弹框
    const visible = ref<boolean>(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      visible.value = false;
    };
    const dragStatus = ref(1);
    const getDragData = (data: any) => {
      aliVerification(data);
    };
    const aliVerification = (data: any) => {
      let params = {
        session_id: data.sessionId,
        sig: data.sig,
        token: data.token,
        scene: 2,
      };
      https
        .aliLoginVerification(params)
        .then(() => {
          dragStatus.value = 0;
        })
        .catch((res) => {
          dragStatus.value = 2;
          (window as any).ic.reset();
          message.error(res.data.error);
        });
    };
    const checkedalt = ref(false);
    // 客管注册接口
    // 新年新版本活动
    const isNewVersion = () => {
      const versionNameArr = ['resource_purchase', 'newlive', 'versionUrchase'];
      let res = false;
      route.query.version_name && (res = versionNameArr.includes(route.query.version_name as string));
      route.query.v && (res = Boolean((route.query.v as string) === 'c'));
      return res;
    };

    const registerShark = ref<boolean>(false);
    const register = useDebounce(async () => {
      if (registerShark.value) {
        return;
      }
      // if (formData.company == '') {
      //   message.info('公司名称不能为空');
      //   return;
      // }
      if (formData.name == '') {
        message.info('用户名不能为空');
        registerShark.value = false;
        return;
      }
      if (formData.username == '') {
        message.info('手机号不能为空');
        return;
      }
      if (formData.code == '') {
        message.info('验证码不能为空');
        return;
      }
      const next = await rules();
      if (!next && !showYes.value) {
        message.destroy();
        message.error(nameText.value ? nameText.value : '用户名已存在！');
        return;
      }
      if (dragStatus.value) {
        message.info('请先滑动验证！');
        return;
      }

      let referrer = document.referrer;
      const data = {
        code: formData.code,
        name: formData.name,
        link: window.location.href,
        referrer: referrer ? referrer : window.location.host,
        mobile: formData.username,
      };
      if (checkedalt.value !== true) {
        message.info('请先勾选条款阅读并同意（隐私协议）');
        return;
      }

      http
        .new_register(data)
        .then((res) => {
          registerShark.value = false;
          message.success(res.data.message);
          jump();
        })
        .catch((err) => {
          message.error(err.data.error);
          registerShark.value = false;
        });

      // let resgist = {
      //   company: formData.company,
      //   mobile: formData.username,
      //   code: formData.code,
      //   name: '',
      //   passwords: '',
      //   referrer: referrer ? referrer : window.location.host,
      //   link: window.location.href,
      //   new_version: Number(isNewVersion()), // 新年新版本活动
      // };

      // http
      //   .getRegister(resgist)
      //   .then((res) => {
      //     registerShark.value = false;
      //     message.success(res.data.message);
      //     jump();
      //   })
      //   .catch(() => {
      //     registerShark.value = false;
      //   });
    });
    const Rem = () => {
      let docEl = document.documentElement,
        oSize = docEl.clientWidth / 7.5;
      if (oSize > 100) {
        oSize = 100; //  限制rem值   640 / 6.4 =100
      }
      docEl.style.fontSize = oSize + 'px';
    };
    window.addEventListener('resize', Rem, false);
    Rem();
    //用户访问记录
    const newTime = Date.parse(new Date().toString()) / 1000;
    const adminUserVisit = () => {
      http.adminUserVisit({ params: { entry_time: newTime } }).then(() => {
        sessionStorage.setItem('visit', 'mobile_register');
      });
    };
    const user_visit = sessionStorage.getItem('visit');
    if (user_visit == null) {
      adminUserVisit();
      window.onbeforeunload = () => {
        let data = { entry_time: newTime, exit_page: 1 };
        http.adminUserVisit({ params: data });
      };
    }
    return {
      isShowform,
      formState,
      formItemLayout,
      formData,
      userName,
      jump,
      getCode,
      isShowcode,
      count,
      visible,
      showModal,
      handleOk,
      company,
      Rem,
      register,
      checkedalt,
      getDisplay,
      defaultTopLogo,
      getDragData,
      rules,
      showYes,
      nameText,
    };
  },
});
