
import { defineComponent, ref, reactive } from 'vue';
import moment from 'moment';
import { DatePicker, Table, Pagination, message, notification } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import http from '@/api/Interactive';
import https from '@/api/exportList';

import router from '@/router';
import { ResourceBill } from '../../dataCenter/Questionnaire/components/details';
import { UploadOutlined, RollbackOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance } from 'vue';
import { getToken } from '@/plugins/cookie';
import { onExport } from '@/components/CExport'; //导出
import { useDebounce } from '@/hooks/useDebounce'; //防抖


type Key = ColumnProps['key'];
// const emit = defineEmits(['createStatus']);
const columns = [
  {
    title: '用户ID',
    dataIndex: 'user_id',
    width: '203px',
    align: 'center',
    slots: {
      customRender: 'user_id',
    },
  },
  {
    title: '用户名',
    dataIndex: 'user_name',
    align: 'center',
    width: '23%',
    slots: {
      customRender: 'user_name',
    },
  },
  {
    title: '礼物名称',
    dataIndex: 'reward_name',
    align: 'center',
    width: '23%',
    ellipsis: true,
  },
  {
    title: '时间',
    dataIndex: 'created_at',
    align: 'center',
    width: '23%',
  },
  {
    title: '数量',
    dataIndex: 'number',
    align: 'center',
    width: '23%',
  },
  {
    title: '金额(元)',
    dataIndex: 'price',
    align: 'center',
    width: '23%',
  },
];
export default defineComponent({
  emits: ['createStatus'],
  components: {
    [RangePicker.name]: RangePicker,
    aTable: Table,
    aPagination: Pagination,
    UploadOutlined,
    RollbackOutlined,
    // ExclamationCircleFilled,
    // aModal: Modal,
  },
  props: {},
  setup(props, { emit }) {
    const showLoading = ref<boolean>(false);
    const liveInfo = router.currentRoute.value.params.studioId;
    const selectedRowKeys = ref<Key[]>([]);
    const modal2Visible = ref<boolean>(false);
    interface DataType {
      id: number;
      number: number;
      created_at: string;
      reward_name: string;
      status: string;
      price: string;
      user_id: string;
      user_name: string;
    }
    const datafol = ref<DataType[]>([]);
    const choice_arr = ref('');
    // const onSelectChange = (changableRowKeys: Key[]) => {
    // 礼物打赏记录中的id
    // 在删除弹框中，想要删除表格，就得获取id，所以通过以下步骤完成：
    // （1）新命名一个变量名  const choice_arr = ref('')
    // （2）使用 choice_arr.value = changableRowKeys.join(',');
    // （3）  const keys = ref({});
    // const removeLiveData = () => {
    //   let arr: string[] = [];
    //   Object.keys(keys.value).forEach((k, j) => {
    //     keys.value[k] == true ? arr.push(Object.keys(keys.value)[j]) : '';
    //   });
    // （4）在确认删除按钮的接口中将 choice_arr.value 赋值给对应的ID
    //   choice_arr.value = changableRowKeys.join(',');
    //   selectedRowKeys.value = changableRowKeys;
    // };
    // const rowSelection = computed(() => {
    //   return {
    //     selectedRowKeys: unref(selectedRowKeys),
    //     onChange: onSelectChange,  //表格全部选择
    //   };
    // });
    // 返回
    const goBack = () => {
      emit('createStatus', true);
    };
    // 分页器
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    const showSize = () => {
      studioRewardList();
    };
    // 日历
    const queryForm = ref<Date[]>([]);
    // 获取直播间打赏记录
    const studioRewardList = () => {
      let reward: any = {
        studio_id: liveInfo,
        page: pageConfig.current,
        page_num: pageConfig.pageSize,
      };
      showLoading.value = true;
      const postQuest = new ResourceBill(reward);
      postQuest.setDates(queryForm.value as any);
      http
        .getStudioRewardList({ params: postQuest })
        .then((res) => {
          pageConfig.current = res.data.data.body.current_page;
          // 判断页面无数据页码显示第一页，使用三元运算符判断
          pageConfig.totalSize = res.data.data.body.total > 0 ? res.data.data.body.total : 10;
          const degree = res.data.data.body.data;
          datafol.value = degree;
          showLoading.value = false;
        })
        .catch(() => {
          showLoading.value = false;
        });
    };
    studioRewardList();
    const trigger = () => {
      studioRewardList();
    };
    // 导出
    const { proxy }: any = getCurrentInstance();
    const token = getToken();
    const corn = ref(1);
    const messageTip = ref();
    const sourceload = useDebounce((type: any) => {
      const data = {
        studio_id: liveInfo,
        type: type,
        is_cron_download: corn.value,
      };
      https
        .excel_regional_distribution({ params: data })
        .then((res: any) => {
          messageTip.value = res.data.message;
          openNotificationWithIcon('success');
        })
        .catch((res: any) => {
          messageTip.value = res.data.error
          openNotificationWithIcon('error');
        });
    });
    // 导出成功时发出通知
    const openNotificationWithIcon = (type: string) => {
      notification[type]({
        message: messageTip.value,
       duration: 5,
      });
    };
    const dateFormat = (time: any) => {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      // 拼接 format="YYYY-MM-DD hh:mm:ss"
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    };
    return {
      showLoading,
      moment,
      //   rowSelection,
      datafol,
      columns,
      goBack, //返回
      pageConfig, //分页
      showSize,
      liveInfo,
      queryForm, //日历
      trigger, //日历点击
      modal2Visible, //删除弹框
      //   removeLiveData,
      selectedRowKeys,
      choice_arr,
      sourceload, //导出
      dateFormat,
    };
  },
});
