/**
 * 使光标移动到最后
 * @param el DOM对象
 * @returns
 */
export const setFocus = (el: any) => {
  el.focus();
  const range = document.createRange();
  range.selectNodeContents(el);
  range.collapse(false);
  const sel = window.getSelection();
  //判断光标位置，如不需要可删除
  if ((sel as Selection).anchorOffset != 0) {
    return;
  }
  (sel as Selection).removeAllRanges();
  (sel as Selection).addRange(range);
};

export const setEnter = function (element: any): void {
  let caretOffset = 0,
    endOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  const sel = win.getSelection();
  // 选中的区域
  if (sel.rangeCount > 0) {
    const range = win.getSelection().getRangeAt(0);
    // 克隆一个选中区域
    const preCaretRange = range.cloneRange();
    // 设置选中区域的节点内容为当前节点
    preCaretRange.selectNodeContents(element);
    // 重置选中区域的结束位置
    endOffset = preCaretRange.toString().length;
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;

    caretOffset === endOffset ? insertHtml('<br><br>') : insertHtml('<br>');
  }
};

/**
 * 获取当前光标位置
 * @param element
 * @returns
 */
export const getCursortPosition = function (element: any): number {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  const sel = win.getSelection();
  // 选中的区域
  if (sel.rangeCount > 0) {
    const range = win.getSelection().getRangeAt(0);
    // 克隆一个选中区域
    const preCaretRange = range.cloneRange();
    // 设置选中区域的节点内容为当前节点
    preCaretRange.selectNodeContents(element);
    // 重置选中区域的结束位置
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  }
  return caretOffset;
};

/**
 * 光标处插入html代码，参数是String类型的html代码，例子："<p>猪头诺</p>"
 * @param html
 */
export const insertHtml = (html: string, isHtml = true): void => {
  let range: any;
  // IE9 或 非IE浏览器
  const sel = window.getSelection();
  if ((sel as Selection).getRangeAt && (sel as Selection).rangeCount) {
    range = (sel as Selection).getRangeAt(0);
    range.deleteContents();
    // Range.createContextualFragment() would be useful here but is
    // non-standard and not supported in all browsers (IE9, for one)
    const el = document.createElement('div');
    if (isHtml) {
      el.innerHTML = html;
    } else {
      el.innerText = html;
    }
    const frag = document.createDocumentFragment();
    let node, lastNode;
    while ((node = el.firstChild)) {
      lastNode = frag.appendChild(node);
    }
    range.insertNode(frag);
    // Preserve the selection
    if (lastNode) {
      range = range.cloneRange();
      range.setStartAfter(lastNode);
      range.collapse(true);
      (sel as Selection).removeAllRanges();
      (sel as Selection).addRange(range);
    }
  }
};

export const onPaste = (element: any) => {
  element.preventDefault();
  let text = (element.originalEvent || element).clipboardData.getData('text/plain');
  // 换行等处理
  text = text.replace('<br/>', '\n').replace('<br>', '\n');
  insertHtml(text, false);
};
