
import { defineComponent, ref, reactive } from 'vue';
import { Table, Pagination, DatePicker } from 'ant-design-vue';
import moment from 'moment';
import { RangePickerIcon } from '@/assets';
import http from '@/api/account';
import { ResourceBill } from './ResourceBill';
const { RangePicker } = DatePicker;
// 表
const columns = [
  {
    title: '流水编号',
    width: 80,
    dataIndex: 'order_no',
    align: 'center',
    ellipsis: true,

    key: '0',
  },
  {
    title: '服务类型',
    dataIndex: 'service_type_title',
    key: '1',
    width: 80,
    align: 'center',
    ellipsis: true,
  },
  {
    title: '金额',
    dataIndex: 'payment_amount',
    key: '2',
    width: 80,
    align: 'center',
    slots: {
      customRender: 'payment_amount',
    },
  },
  {
    title: '支付类型',
    dataIndex: 'pay_type_title',
    key: '3',
    width: 80,
    align: 'center',
    slots: {
      customRender: 'pay_type_title',
    },
  },
  {
    title: '生成时间',
    dataIndex: 'created_at',
    key: '4',
    width: 80,
    align: 'center',
  },
  {
    title: '用途',
    dataIndex: 'source',
    key: '5',
    width: 80,
    align: 'center',
  },
];
const showLoading = ref<boolean>(false);
const Postes = ref<DataItem[]>([]);
interface DataItem {
  key: number;
  order_no: number;
  service_type_title: string;
  payment_amount: string;
  pay_type_title: string;
  created_at: string;
  source: string;
}
// for (let i = 0; i < 8; i++) {
//   Postes.push({
//     key: i,
//     order_no: 199,
//     service_type_title: '支出',
//     payment_amount: '1.5547',
//     pay_type_title: '余额支付',
//     created_at: '2021-09-08 09:12:04',
//     source: '资源包扣费',
//   });
// }
// 服务类型
const pays = ref([
  {
    label: '全部',
    value: 0,
  },
  {
    label: '收入',
    value: 1,
  },
  {
    label: '支出',
    value: 2,
  },
]);
let obj = ref({
  paymentStatus: null,
});
export default defineComponent({
  components: {
    [RangePicker.name]: RangePicker,
    aTable: Table,
    aPagination: Pagination,
  },
  props: {},
  setup() {
    const mediaRef = ref();
    //   分页
    const pageConfigure = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    //   获取控制台收支明细
    const queryForms = ref<Date[]>([]);
    const consoleReceiveList = (current: number) => {
      pageConfigure.current = current;
      let receive: any = {
        fund_flow: obj.value.paymentStatus,
        // start_at: '',
        // end_at: '',
        page: current,
        page_num: pageConfigure.pageSize,
      };
      showLoading.value = true;
      const postSwitch = new ResourceBill(receive);
      postSwitch.setDate(queryForms.value as any);
      http
        .getConsoleReceiveList({ params: postSwitch })
        .then((res) => {
          pageConfigure.current = res.data.data.current_page;
          //    pageConfigure.totalSize = res.data.data.total;
          pageConfigure.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          Postes.value = [...res.data.data.data];
          showLoading.value = false;
        })
        .catch((err) => {
          showLoading.value = false;
        });
    };
    consoleReceiveList(1);
    const trigger = () => {
      consoleReceiveList(1);
    };
    // 分页器点击事件
    const showSize = () => {
      consoleReceiveList(pageConfigure.current);
    };
    // 清空
    const empty = () => {
      queryForms.value = [];
      obj.value.paymentStatus = null;
      consoleReceiveList(1);
    };
    return {
      showLoading,
      moment,
      pays,
      RangePickerIcon,
      columns,
      Postes,
      obj,
      pageConfigure,
      queryForms,
      trigger,
      showSize,
      empty,
      mediaRef,
    };
  },
});
