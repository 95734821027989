import { computed, reactive, Ref, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { SignupWClass, SwitchForm, Option, SignupWFromClass, ColumnObj, FormObj } from './type';
import http from '@/api';
import { useDebounce } from '@/hooks/useDebounce';
import { RootModal } from '@/types/base';
import { useRoute } from 'vue-router';
// 表单项
export const sourceTxtOption: Option[] = [
  {
    value: '1',
    label: '文本',
  },
  {
    value: '2',
    label: '下拉',
  },
];
export const sourceTypeOption: Option[] = [
  {
    value: '1',
    label: '姓名',
  },
  {
    value: '2',
    label: '性别',
  },
  {
    value: '3',
    label: '地区',
  },
  {
    value: '4',
    label: '手机',
  },
  {
    value: '5',
    label: '邮箱',
  },
  {
    value: '6',
    label: '单位',
  },
  {
    value: '7',
    label: '职务',
  },
  {
    value: '10',
    label: '图片',
  },
  {
    value: '8',
    label: '隐私',
    disabled: false,
  },
  {
    value: '9',
    label: '默认',
  },
];

export const disabled = ref(false);

export function useSignupWatch(signupWatch: Ref<SignupWClass>, switchForm: SwitchForm) {
  disabled.value = true;
  const getForm = useDebounce(async (studioId: string) => {
    try {
      const res = await http.live.getViewForm({ params: { studio_id: studioId } });
      if (res.data.data) {
        signupWatch.value = new SignupWClass(res.data.data);
      }
      switchForm.isUserName = signupWatch.value.formArr.some((e) => e.isUser_name);
      switchForm.isLogin = signupWatch.value.formArr.some((e) => e.isLogin);
      switchForm.isMerge = signupWatch.value.formArr.some((e) => e.isMerge);
      const arr = signupWatch.value.formArr.map((e) => {
        if (e.isMerge) return e.title;
      });
      signupWatch.value.merge_fields = arr.filter((e) => e != undefined).join();
      disabled.value = false;
    } catch (e) {
      console.error(e);
      http.live
        .getTitleImg({ params: { studio_id: studioId } })
        .then((res) => {
          signupWatch.value.title = res.data.data.title;
          signupWatch.value.head_img_url = res.data.data.head_img_url;
          signupWatch.value.head_img = res.data.data.head_img;
        })
        .catch((err) => console.error(err.data.error));
      disabled.value = false;
    }
  });

  // 修改昵称 与 登录验证
  watch(
    () => [...signupWatch.value.formArr],
    (newVal) => {
      const NickNameArr = newVal.reduce(function (acc, cur) {
        if (cur.isUser_name) {
          acc.push(cur.title);
        }
        return acc;
      }, [] as string[]);
      signupWatch.value.nickname_field = NickNameArr.join('-');
      const hasIsLogin = newVal.some((e) => {
        if (e.isLogin === true) {
          signupWatch.value.account_field = e.title;
        }
        return e.isLogin === true;
      });
      if (!hasIsLogin) {
        signupWatch.value.account_field = '';
      }
    },
    { deep: true }
  );
  const onChangenickName = () => {
    if (!switchForm.isUserName) {
      signupWatch.value.formArr = signupWatch.value.formArr.map((e) => {
        e.isUser_name = false;
        return e;
      });
    }
  };

  // 登录验证开关触发
  const onChangeLoginVerify = () => {
    if (!switchForm.isLogin) {
      signupWatch.value.formArr = signupWatch.value.formArr.map((e) => {
        e.isLogin = false;
        return e;
      });
    }
    if (switchForm.isLogin && signupWatch.value.examine_status === true) {
      signupWatch.value.status = true;
    }
  };
  // 数据合并
  const onChangeMerge = () => {
    signupWatch.value.merge_fields = '';
    if (!switchForm.isMerge) {
      signupWatch.value.formArr = signupWatch.value.formArr.map((e) => {
        e.isMerge = false;
        return e;
      });
    }
  };

  // 登录验证子项触发
  const onChangeItemIsLogin = (item: FormObj) => {
    if (item.title) {
      signupWatch.value.account_field = item.title;
    }
    signupWatch.value.formArr = signupWatch.value.formArr.map((e) => {
      e.isLogin = e.id === item.id ? true : false;
      return e;
    });
  };

  // 数据合并触发
  const onChangeItemIsMerge = () => {
    const arr = signupWatch.value.formArr.map((e) => {
      if (e.isMerge) return e.title;
    });
    signupWatch.value.merge_fields = arr.filter((e) => e != undefined).join();
  };

  const subForm = useDebounce(async (studioId: string) => {
    try {
      signupWatch.value.studio_id = parseInt(studioId);
      const formData = new SignupWFromClass(signupWatch.value);
      const res = await http.live.saveViewForm(formData);
      message.destroy();
      message.success(res.data.message);
    } catch (err: any) {
      message.destroy();
      message.error(err.data.error);
      // if (signupWatch.value.examine_status) signupWatch.value.examine_status = false;
    }
  });
  return {
    switchForm,
    signupWatch,
    getForm,
    subForm,
    onChangenickName,
    onChangeLoginVerify,
    onChangeMerge,
    onChangeItemIsLogin,
    onChangeItemIsMerge,
  };
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export function useFormData(signupWatch: Ref<SignupWClass>) {
  const onAddItem = () => {
    signupWatch.value.formArr.push({
      id: signupWatch.value.formArr.length,
      isInternational: false,
      isLogin: false,
      isUser_name: false,
      isVerify: false,
      isMerge: false,
      isMultipleChoice: '1',
      option: [],
      options: sourceTxtOption,
      options2: sourceTypeOption,
      preview_radio: '1',
      require: true,
      select: '1',
      select2: '1',
      tips: '请输入内容',
      title: '',
      type: 'text',
      value: '',
      verify_code: '',
    });
  };
  const delVisible = ref<boolean>(false);
  const delIndex = ref();
  const onDelItem = (index: number) => {
    delIndex.value = index;
    delVisible.value = true;
  };
  // 删除
  const openDelModal = () => {
    delVisible.value = !delVisible.value;
  };
  // 确定删除
  const okDelModal = () => {
    signupWatch.value.formArr.splice(delIndex.value, 1);
    openDelModal();
  };
  return { onAddItem, onDelItem, openDelModal, okDelModal, delVisible };
}

export function useLiveData() {
  const viewListObj = reactive({
    selectValue: 0,
    datetTimeValue: ref([]),
  });
  const infoModal = reactive<RootModal>({
    title: '注册观看统计',
    visible: false,
    loading: false,
    centered: true,
  });
  const pageConfig = reactive({
    current: 1, // 当前页
    pageSize: 10, // 每页条数
    totalSize: 0, // 总页数
    options: ['10', '24', '36', '48', '60'], // 选项
    size: 'small', // 尺寸
    showJumper: false, // 是否显示跳页
    showSize: false, // 是否显示条数切换
  });
  const columns = ref<ColumnObj[]>([]);
  const route = useRoute();
  const viewList = ref<[]>([]);
  const showLoading = ref<boolean>(false);
  const getViewList = useDebounce(async (studioId: string, formArr: any) => {
    const { current, pageSize } = pageConfig;
    const search = {
      page: current,
      page_num: pageSize,
      status: viewListObj.selectValue == 0 ? 0 : 1,
      studio_id: route.params.studioId,
      start_at: viewListObj.datetTimeValue[0] ? new Date(viewListObj.datetTimeValue[0]) : viewListObj.datetTimeValue[0],
      end_at: viewListObj.datetTimeValue[1] ? new Date(viewListObj.datetTimeValue[1]) : viewListObj.datetTimeValue[1],
    };
    showLoading.value = true;
    const res = await http.live.getViewListForm({ params: search });
    res ? (showLoading.value = false) : '';
    const resData = res.data.data.data;
    const resHead = JSON.parse(res.data.data.head);
    viewList.value = resData.data;
    pageConfig.current = resData.current_page;
    pageConfig.totalSize = resData.total;
    const columnArr: ColumnObj[] = [];
    const resHeadTitle = ref('');
    if(Array.isArray(formArr)){
    formArr.forEach((e: any) => {
      if (e.select2 === '8') resHeadTitle.value = e.title;
    });
  }
    resHead.forEach((item: any) => {
      const index = item.value.indexOf('_');
      const type = item.value.slice(0, index);
      if (index && type === 'image') {
        columnArr.push({
          title: item.name,
          dataIndex: item.value,
          align: 'center',
          slots: { customRender: 'image' },
        });
      } else if (item.name != resHeadTitle.value) {
        columnArr.push({
          title: item.name,
          dataIndex: item.value,
          align: 'center',
          ellipsis: true,
        });
      }
    });
    columns.value = columnArr;
  });

  const openModal = async (formArr: any, studioId?: string) => {
    if (studioId) {
      // infoModal.width = document.body.clientWidth / 2;
      getViewList(studioId, formArr);
      infoModal.visible = true;
    } else {
      infoModal.visible = false;
    }
  };
  watch(
    () => infoModal.visible,
    (newVal) => {
      if (!newVal) {
        pageConfig.current = 1;
        pageConfig.totalSize = 0;
        columns.value = [];
        viewList.value = [];
      }
    }
  );
  return {
    showLoading,
    columns,
    viewList,
    pageConfig,
    infoModal,
    openModal,
    getViewList,
    viewListObj,
  };
}

export function useUpload(signupWatch: Ref<SignupWClass>) {
  const fileList = ref([]);
  const loading = reactive({
    coverImg: false,
    headImg: false,
    headImgM: false,
  });

  const getBase64 = (img: Blob, callback: (base64Url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const clearImg = (keyword: string) => {
    switch (keyword) {
      case 'headImg':
        signupWatch.value.pc_bg_img = 0;
        signupWatch.value.pc_bg_img_url = '';
        break;
      case 'headImgM':
        signupWatch.value.bg_img = 0;
        signupWatch.value.bg_img_url = '';
        break;
      default:
        signupWatch.value.head_img = 0;
        signupWatch.value.head_img_url = '';
        break;
    }
  };

  const handleChange = (info: FileInfo, keyword: string): void => {
    if (info.file.status === 'uploading') {
      loading[keyword] = true;
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () => {
        if (info.file?.response?.code === 200) {
          // message.success(info.file?.response?.message);
          switch (keyword) {
            case 'headImg':
              signupWatch.value.pc_bg_img = parseInt(info.file?.response?.data.id);
              signupWatch.value.pc_bg_img_url = info.file?.response?.data.url;
              break;
            case 'headImgM':
              signupWatch.value.bg_img = parseInt(info.file?.response?.data.id);
              signupWatch.value.bg_img_url = info.file?.response?.data.url;
              break;
            default:
              signupWatch.value.head_img = parseInt(info.file?.response?.data.id);
              signupWatch.value.head_img_url = info.file?.response?.data.url;
              break;
          }
        } else {
          message.error(info.file?.response?.error);
        }
        loading[keyword] = false;
      });
    }
    if (info.file.status === 'error') {
      loading[keyword] = false;

      message.error('上传失败');
    }
  };
  const beforeUpload = (file: FileItem) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传 JPG 文件!');
    }
    return isJpgOrPng;
  };
  return {
    fileList,
    loading,
    handleChange,
    beforeUpload,
    clearImg,
  };
}
