<template>
  <div class="loader-container" id="myul">
    <!-- <div class="container_start"></div> -->
    <div
      class="loader-container_every"
      v-for="(item, index) in 31"
      :key="index"
      :style="{
        'animation-delay': index !== 0 && index !== 30 ? `${Math.random()}s` : '',
      }"
    ></div>
    <!-- <div class="container_end"></div> -->
  </div>
</template>
<style lang="less" scoped>
body {
  background: #00ccb0;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}
.loader-container {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  // width: 80%;
  height: 20px;
  // margin: -11px 0px 0 0;
}
// .container_start {
//   position: absolute;
//   left: -4px;
//   top: -7px;
//   display: inline-block;
//   background: #00ccb0;
//   height: 20px;
//   width: 1px;
//   margin: 2px;
// }
// .container_end {
//   position: absolute;
//   right: -4px;
//   top: -7px;
//   // left: 0;
//   display: inline-block;
//   background: #00ccb0;
//   height: 20px;
//   width: 1px;
//   margin: 2px;
// }
.loader-container_every {
  position: relative;
  display: inline-block;
  background: #00ccb0;
  height: 100%;
  width: 1px;
  margin: 1px;
  //   -webkit-animation: load 1s ease-in-out infinite;
  animation: load 3s ease-in-out infinite;
}

@-moz-keyframes load {
  0%,
  100% {
    -moz-transform: scaleY(1);
    background: #00ccb0;
  }
  16.67% {
    -moz-transform: scaleY(3);
    background: #00ccb0;
  }
  33.33% {
    -moz-transform: scaleY(1);
    background: #00ccb0;
  }
  50% {
    -moz-transform: scaleY(3);
    background: #00ccb0;
  }
  66.67% {
    -moz-transform: scaleY(1);
    background: #00ccb0;
  }
  83.34% {
    -moz-transform: scaleY(3);
    background: #00ccb0;
  }
}
@-webkit-keyframes load {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    background: #00ccb0;
  }
  16.67% {
    -webkit-transform: scaleY(3);
    background: #00ccb0;
  }
  33.33% {
    -webkit-transform: scaleY(1);
    background: #00ccb0;
  }
  50% {
    -webkit-transform: scaleY(3);
    background: #00ccb0;
  }
  66.67% {
    -webkit-transform: scaleY(1);
    background: #00ccb0;
  }
  83.34% {
    -webkit-transform: scaleY(3);
    background: #00ccb0;
  }
}
@keyframes load {
  0%,
  100% {
    transform: scaleY(1);
    background: #00ccb0;
  }
  16.67% {
    transform: scaleY(3);
    background: #00ccb0;
  }
  33.33% {
    transform: scaleY(1);
    background: #00ccb0;
  }
  50% {
    transform: scaleY(3);
    background: #00ccb0;
  }
  66.67% {
    transform: scaleY(1);
    background: #00ccb0;
  }
  83.34% {
    transform: scaleY(3);
    background: #00ccb0;
  }
}
</style>
