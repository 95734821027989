/**
 * 图文
 * @content 内容
 */
export interface TxtContent {
  content: string;
}

/**
 *  跳转链接对象
 * @content 文案内容
 * @content_url 跳转链接
 */
export interface LinkContent {
  content: string;
  content_url: string;
}

/**
 * 图文发布消息内容对象
 * @msg_id 未知
 * @img 图片链接地址
 * @content 文本内容
 * @created_at 创建时间
 */
export interface ImgTxt {
  msg_id: string;
  img: string;
  content: string;
  created_at: string;
}

/**
 * 图文发布对象
 * @content 图文消息数组
 * @host 主持人名称
 */
export interface ImgTextRelease {
  content: ImgTxt[];
  host: string;
}

/**
 * 商品对象
 * @title 标题
 * @img 图片地址
 * @link 商品链接
 * @price 当前价
 * @origin_price 原价
 * @btn_name 按钮名称
 */
export interface GoodItem {
  title: string;
  img: string;
  link: string;
  price: string;
  origin_price: string;
  btn_name: string;
}
/**
 * 外部页面对象
 * @menu_type 菜单显示
 * @expand_type 拓展显示
 * @popup 默认弹出
 * @expand_url 拓展图标地址
 * @expand_name 拓展名称
 * @height pc 页面高度
 * @content_url 页面地址
 * @callback_url 回调地址
 */
export interface ExternalContent {
  menu_type: string | boolean;
  expand_type: string | boolean;
  full_type: string | boolean;
  popup: string | boolean;
  expand_url: string;
  expand_name: string;
  height: string;
  content_url: string;
  callback_url: string;
  eject: string;
}

/**
 * 外部页面对象 --- 非接口对应对象用于格式化
 * @menu_type 菜单显示
 * @expand_type 拓展显示
 * @full_type 全屏显示
 * @popup 默认弹出
 * @expand_url 拓展图标地址
 * @expand_name 拓展名称
 * @height pc 页面高度
 * @content_url 页面地址
 * @callback_url 回调地址
 */
export interface ExternalPage {
  menu_type: boolean;
  expand_type: boolean;
  full_type: boolean;
  popup: boolean;
  expand_url: string;
  expand_name: string;
  height: string;
  content_url: string;
  callback_url: string;
  eject: number;
}

/**
 * 外部页面对象表单显示状态
 * @popup 默认弹出
 * @expand_url 拓展图标地址
 * @expand_name 拓展名称
 * @height pc 页面高度
 * @content_url 页面地址
 * @callback_url 回调地址
 */
export interface ExternalPageVisible {
  popup: boolean;
  expand_url: boolean;
  expand_name: boolean;
  height: boolean;
  control: boolean;
  content_url: boolean;
  callback_url: boolean;
}

export class ExternalPageClass {
  menu_type: boolean;

  expand_type: boolean;

  full_type: boolean;

  popup: boolean;

  expand_url: string;

  expand_name: string;

  height: string;

  content_url: string;

  callback_url: string;

  eject: number;
  constructor(data: ExternalContent) {
    this.menu_type = Boolean(data.menu_type);
    this.expand_type = Boolean(data.expand_type);
    this.full_type = Boolean(data.full_type);
    this.popup = Boolean(data.popup);
    this.expand_url = data.expand_url;
    this.expand_name = data.expand_name;
    this.height = data.height;
    this.content_url = data.content_url;
    this.callback_url = data.callback_url;
    this.eject = Number(data.eject);
  }
}
/**
 * 视频列表对象
 * @cover_url 视频封面
 * @definition 未知
 * @duration 时长
 * @id 主键
 * @isUpload 未知
 * @play_url 播放链接
 * @start_at  创建时间
 * @title 标题
 * @video_id 未知
 */
export interface VideoItem {
  cover_url: string;
  definition: string;
  duration: string;
  id: number;
  isUpload: boolean;
  play_url: string;
  start_at: string;
  title: string;
  video_id: string;
}
export interface Video extends VideoItem {
  resolution: string;
  video_url: string;
  created_at: string;
}
export interface Videoform extends VideoItem {
  isShowEdit?: boolean;
}

/**
 * 菜单内容聚合类型
 */
export type MenuContentType =
  | TxtContent
  | []
  | LinkContent
  | ImgTextRelease
  | GoodItem[]
  | string[]
  | ExternalContent
  | VideoItem[];

/**
 * 菜单项
 * @index 序号
 * @name 标题
 * @type_id 类型ID 1 聊天互动 2 图文内容
 * @isActive 是否选中
 * @datas 内容
 */
export interface CustomItem {
  index: number;
  name: string;
  type_id: string;
  isActive: boolean;
  datas: MenuContentType;
}

// export interface OriginData {
//   datas: TxtContent | [];
//   type_id: string;
// }

/**
 * 菜单项备份类
 * @type_id 类型ID
 */
export class OriginData {
  datas: MenuContentType;

  type_id: string;

  constructor(data: OriginData) {
    this.type_id = data.type_id;
    this.datas = data.datas;
  }
}

/**
 * 自定义菜单对象
 * @id 主键
 * @display_chat 显示图表
 * @display_member 显示人数
 * @studio_id 直播间ID
 * @menu 菜单内容数组
 * @menu 菜单内容备份数组
 */
export interface CustomMenu {
  id: number;
  display_chat: number;
  display_member: number;
  studio_id: number;
  menu: CustomItem[];
  origin_datas: OriginData[];
}

// 选项
export interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}
