import { log } from '@antv/g2plot/lib/utils';
import Cookies from 'js-cookie';
const TokenKey = 'lighostoken';

export function getToken(): string | boolean {
  if (getSubToken()) {
    return getSubToken();
  }
  // return Cookies.get(TokenKey) ?? false; // cookie 方式
  return localStorage.getItem(TokenKey) ?? false;
}
export function getTokenKey(): string {
  return TokenKey;
}
export function setToken(token: string): void {
  // Cookies.set(TokenKey, token, { secure: true, sameSite: 'none' }); // cookie 方式
  localStorage.setItem(TokenKey, token);
  localStorage.removeItem('subToken');
}
export function getSubToken(): string | boolean {
  return localStorage.getItem('subToken') ?? false;
}
export function setSubToken(token: string): void {
  localStorage.setItem('subToken', token);
  localStorage.removeItem(TokenKey);
}
export function removeToken(): void {
  localStorage.clear();
  // Cookies.remove(TokenKey);
  sessionStorage.removeItem('display_logo');
  sessionStorage.removeItem('loginVersionName');
  sessionStorage.removeItem('func');
  sessionStorage.removeItem('roleStatus');
 
  // sessionStorage.clear();
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setUserCache(userInfo: any): void {
  localStorage.setItem('cache_tokens', JSON.stringify(userInfo));
}
export function getUserCache(): string | boolean {
  return localStorage.getItem('cache_tokens') ?? false;
}

export function setDisplay(display: any): void {
  sessionStorage.setItem('display_logo', display);
}
export function getDisplay(): string | boolean {
  return sessionStorage.getItem('display_logo') ?? '';
}
export function setSubAlias(sub_alias: any): void {
  localStorage.setItem('sub_logo', sub_alias);
}

// 认证状态
export function setCertificationStatus(status: any): void {
  localStorage.setItem('certification_status', status);
}

export function getCertificationStatus(): any {
  return localStorage.getItem('certification_status') ?? 2;
}
/**
 * 设置账户 0.不允许查看 1.允许查看
 * @param {number} status 0 ｜ 1
 */
export function setCertificationView(status: any): void {
  sessionStorage.setItem('certification_view', status);
}

// 0.不允许查看 1.允许查看
export function getCertificationView(): string | number {
  return sessionStorage.getItem('certification_view') ?? 1;
}
export function resetCertificationView(): void {
  sessionStorage.setItem('certification_view', '1');
}
/**
 * 直播助手标识
 * @returns 1 助手账户 0 普通账户
 */
export function getHelperStatus(): number {
  return localStorage.getItem('helper') ? Number(localStorage.getItem('helper')) : 0;
}
export function setHelperData(data: any): void {
  localStorage.setItem('cache_helper', window.btoa(window.encodeURIComponent(JSON.stringify(data))));
}
export function getHelperData(): any {
  return localStorage.getItem('cache_helper')
    ? JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('cache_helper') as string)))
    : '';
}
/**
 * 设置用户状态
 * @param status
 */
export function setHelperStatus(status: string): void {
  localStorage.setItem('helper', status);
}
export function getHelperUrl(): any {
  return localStorage.getItem('helperUrl')
    ? JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('helperUrl') as string)))
    : {
        url: '',
        studioId: '',
      };
}
export function setHelperUrl(status: any): void {
  localStorage.setItem('helperUrl', window.btoa(window.encodeURIComponent(JSON.stringify(status))));
}
/**
 * 清除助手缓存的登录url
 */
export function removeHelperUrl(): void {
  localStorage.removeItem('helperUrl');
}
// 第三方审核
export function setExamineStatus(status: string): void {
  localStorage.setItem('examine', status);
}
export function getExamineStatus(): number {
  return localStorage.getItem('examine') ? Number(localStorage.getItem('examine')) : 0;
}
export function setExamine(status: any): void {
  localStorage.setItem('examineId', window.btoa(window.encodeURIComponent(JSON.stringify(status))));
}
export function getExamine(): any {
  return localStorage.getItem('examineId')
    ? JSON.parse(decodeURIComponent(window.atob(localStorage.getItem('examineId') as string)))
    : { studioId: '', url: '' };
}
export function removeExamine(): void {
  localStorage.removeItem('examineId');
}

// 版本购买就平台路由名称
export function setVersionName(name: string | any): void {
  sessionStorage.setItem('loginVersionName', name);
}
export function getVersionName(): string {
  return sessionStorage.getItem('loginVersionName') ?? '';
}
/**
 * 缓存用户权限
 */
export function setFuncRole(data: any): void {
  sessionStorage.setItem('func', window.btoa(window.encodeURIComponent(JSON.stringify(data))));
}
export function getFuncRole(): any {
  return sessionStorage.getItem('func')
    ? JSON.parse(decodeURIComponent(window.atob(sessionStorage.getItem('func') as string)))
    : '';
}
export function removeFuncRole(): any {
  sessionStorage.removeItem('func');
}

/**
 * 设置主题
 */
export function setCustomTheme(data: string): void {
  localStorage.setItem('theme', data);
}
export function getCustomTheme(): 'dark' | 'light' {
  return (localStorage.getItem('theme') as 'dark' | 'light') ?? 'dark';
}

/**
 *  登录 同意隐私协议
 *  pc 端 和手机端
 */
export function setPrivacyAgreePc(data: any): void {
  Cookies.set('privacy_pc', data, { expires: 30 });
  // Cookies.set('privacy_pc', data, { secure: true, sameSite: 'none', expires: 30 });
}
export function grtPrivacyAgreePc(): any {
  return Cookies.get('privacy_pc') ?? false;
}
export function setPrivacyAgree(data: any): void {
  Cookies.set('privacy_mobile', data, { expires: 30 });
  // Cookies.set('privacy_mobile', data, { secure: true, sameSite: 'none', expires: 30 });
}
export function grtPrivacyAgree(): any {
  return Cookies.get('privacy_mobile') ?? false;
}

/**
 * 设置 跨站隐藏菜单状态
 */
const MenuVisibleRouterKey = 'origin_menu'; //路由关键字
const MenuVisibleKey = 'origin_menu'; // 缓存中的标识
export function getMenuVisibleRouterKey(): string {
  return MenuVisibleRouterKey;
}
/**
 * @description '1' 隐藏菜单 ｜ ‘0’ 显示菜单
 * @param {string} data
 */
export function setOriginMenuStatus(data: any): void {
  localStorage.setItem(MenuVisibleKey, data);
}
/**
 * @description 获取远程菜单显示状态
 * @returns '1' 隐藏菜单 ｜ ‘0’ 显示菜单
 */
export function getOriginMenuStatus(): string {
  return localStorage.getItem(MenuVisibleKey) ?? '0';
}

// 登陆获取别名alias
export function setAlias(alias: string): void {
  localStorage.setItem('alias', alias);
}
export function getAlias(): string {
  return localStorage.getItem('alias') ?? '';
}
