/**
 *
 *
 * @export
 * @interface BaseSearchFace 基础查询接口
 * @type {string} keywords 关键字
 * @type {number} page 页码
 * @type {number} page_num 每页条数
 * @type {boolean} sortDesc 是否降序 当前未使用
 * @type {string} sortName 排序名 当前未使用
 */
export interface BaseSearchFace {
  keywords?: string;
  page?: number;
  page_num?: number;
  sortDesc?: boolean;
  sortName?: string;
}

/**
 *
 * @description 基础查询类
 * @export
 * @class BaseSearchClass
 *
 */
export class BaseSearchClass {
  keywords?: string;

  page?: number;

  page_num?: number;

  seqAsc?: boolean;

  seqColumn?: string;

  constructor(data: BaseSearchFace) {
    // base
    this.page = data.page;
    this.page_num = data.page_num;
    this.keywords = data.keywords ? data.keywords : void 0;
  }
}

export interface RootModal {
  title: string;
  visible: boolean;
  loading: boolean;
  centered?: boolean;
  width?: number;
}
