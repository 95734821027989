/**
 * 限制数字位数
 * @param str
 * @returns
 */
export function fontFormat(str: string): string {
  let newStr = '';
  // eslint-disable-next-line no-control-regex
  const cArr = str.match(/[^\x00-\xff]/gi);
  if (str.length + (cArr == null ? 0 : cArr.length) > 8) {
    if (cArr == null) {
      newStr = `${str.substr(0, 8)}...`;
    } else {
      newStr = `${str.substr(0, 4)}...`;
    }
  } else {
    newStr = str;
  }
  return newStr;
}

/**
 * 时间格式化
 * @param date
 * @param fmt
 * @returns
 */
export function dateFormat(date: Date, fmt = 'YYYY-mm-dd HH:MM'): string {
  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}

/**
 * 数字格式化 0,000.00
 * @param value
 * @returns
 */
export function numberToCurrency(value: string | number): string {
  let num = 0;
  if (typeof value !== 'number') {
    num = parseFloat(value);
  } else {
    num = value;
  }
  if (!num) return '0.00';
  // 将数值截取，保留两位小数
  num = parseFloat(num.toFixed(2));
  // 获取整数部分
  const intPart = Math.trunc(num);
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  // 预定义小数部分
  let floatPart = '.00';
  // 将数值截取为小数部分和整数部分
  const valueArray = num.toString().split('.');
  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString(); // 取得小数部分
    return `${intPartFormat}.${floatPart}`;
  }
  return intPartFormat + floatPart;
}

// 聊天消息标识
export function msgid(): string {
  const l = 6;
  const x = '0123456789qwertyuiopasdfghjklzxcvbnm';
  let tmp = '';
  const timestamp = new Date().getTime();
  for (let i = 0; i < l; i++) {
    tmp += x.charAt(Math.ceil(Math.random() * 100000000) % x.length);
  }
  return timestamp + tmp;
}
// 格式化数字前添0
export function addZero(i: number): any {
  return i < 10 ? `0${i}` : i;
}

export function isMobile(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  return /phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone/.test(
    ua
  );
}
