import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b45a6d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createBlock(_component_a_carousel, {
    autoplay: "",
    dots: false
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          (item.link_url)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: item.link_url,
                target: "_blank"
              }, [
                (item.banner_url)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: item.banner_url,
                      class: "bannerImg"
                    }, null, 8, _hoisted_2))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_1))
            : _withDirectives((_openBlock(), _createElementBlock("img", {
                key: 1,
                src: item.banner_url,
                class: "bannerImg"
              }, null, 8, _hoisted_3)), [
                [_vShow, item.banner_url]
              ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}