import { BaseSearchClass } from '@/types/base';
import { number } from 'echarts';

export interface RootMsg {
  id?: number;
  type_name: string;
  type_id: number;
  title?: string;
  message: string;
  created_at: string;
  status: number;
}

export interface DefaultCustomized {
  video_storage:number;
  copyright: string;
  copyright_link: string;
  icon: number;
  icon_url: string;
  logo_id: number;
  logo_url: string;
  theme_color: string;
  top_logo_id: number;
  top_logo_link: string;
  top_logo_url: string;
  video_id: number;
  video_url: string;
  refresh_status: number; //是否显示 手动刷新推流地址
}

export class MsgClass extends BaseSearchClass {
  status?: number;

  type?: number;

  constructor(data: MsgClass) {
    super(data);
    this.status = data.status;
    this.type = data.type;
  }
}

export interface SuMenuType {
  funcId: number;
  role: boolean;
}
