import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { FileItem, FileInfo } from './types';
export const colorList = ref(['#00ccb0', '#FFA316', '#FF5F5F', '#16B9FA', '#607DFE', '#D750FE', '#397BFF']);

export const uploadNow = () => {
  const fileList = ref([]);
  const flieLink = ref();
  const file = ref();
  const loading = ref<boolean>(false);
  const beforeUpload = (file: FileItem) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('建议大小图片大小2M以内!');
    }
    return isLt2M;
  };
  const handleChange = (info: FileInfo) => {
    if (info.file.status === 'uploading') {
      loading.value = true;
    } else {
      loading.value = false;
    }
    if (info.file?.response?.code === 200) {
      flieLink.value = info.file?.response?.data.url;
      file.value = info.file;
      loading.value = false;
    }
  };
  const handlePreview = (info: FileInfo) => {
    // console.log(1111);
  };
  return {
    file,
    fileList,
    flieLink,
    loading,
    beforeUpload,
    handleChange,
    handlePreview,
  };
};
