import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  // VUE_APP_MOCK = process.env.VUE_APP_MOCK === '1';

  // 获取列表筛选条件
  getCondition = () => {
    return http.get('/api/v2/live/condition');
  };

  // 获取直播列表
  getLiveList = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/live/live_studio_list', data);
  };
  // 获取子账号列表
  getChildList = () => {
    return http.get('/api/v1/live/get_child_list');
  };
  // 添加管理直播间的子账号
  addStudioChild = (data: unknown) => {
    return http.post('/api/v1/live/add_studio_child', data);
  };
  // 删除直播数据
  delLiveData = (data: unknown) => {
    return http.post('/api/v1/live/delete', data);
  };

  //   直播间详情
  getLiveView = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/view', data);
  };

  // 直播间信息
  getLiveInfo = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_live_info', data);
  };

  // 创建直播间
  Create = (data: unknown) => {
    return http.post('/api/v1/live/create', data);
  };

  //获取多视频直播列表
  getStreamStudioList = () => {
    return http.get('/api/v1/live/get_stream_studio_list');
  };

  //获取语言列表
  getLanguageList = () => {
    return http.get('/api/v1/common/get_language_list');
  };

  // 获取可以选择直播间列表
  getLanguageStudioList = () => {
    return http.get('/api/v1/live/get_language_studio_list');
  };

  // 编辑直播间详情
  setStudioInfo = (data: unknown) => {
    return http.post('/api/v1/live/set_studio_info', data);
  };

  // 直播间开关
  clickOffStudio = (data: unknown) => {
    return http.post('/api/v1/live/click_off_studio', data);
  };

  //  直播间付费记录
  getPayList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/live_payorder_list', data);
  };

  //  邀约观看 使用记录
  getBindList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/bind_code_data', data);
  };

  //  生成观看卷地址
  createCode = (data: unknown) => {
    return http.post('/api/v1/live/create_code', data);
  };
  //投票主题列表
  getVoteList = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/vote_list', data);
  };
  //投票主题结果
  voteResult = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/vote_result', data);
  };

  // 投票主题开关
  voteSwitch = (data: unknown) => {
    return http.post('/api/v1/live/vote_switch', data);
  };

  // 投票主题弹出
  votePopup = (data: unknown) => {
    return http.post('/api/v1/live/vote_popup', data);
  };

  // 投票主题删除
  voteDel = (data: unknown) => {
    return http.post('/api/v1/live/vote_delete', data);
  };

  // 投票主题详情
  getVoteView = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/vote_view', data);
  };

  // 投票主题新增
  createVote = (data: unknown) => {
    return http.post('/api/v1/live/vote_create', data);
  };

  // 编辑投票详情
  voteUpdate = (data: unknown) => {
    return http.post('/api/v1/live/vote_update', data);
  };

  //  保存授权配置
  saveAothorize = (data: unknown) => {
    return http.post('/api/v1/live/save_authorize', data);
  };
  customAuthorization = (data: unknown) => {
    return http.post('/api/v1/live/custom_authorization', data);
  };

  // 自定义授权
  custom = (data: unknown) => {
    return http.post('/api/v1/live/custom_authorization', data);
  };

  // 保存背景图
  setBgimg = (data: unknown) => {
    return http.post('/api/v1/live/set_background_image', data);
  };

  // 观众白名单列表
  userWhitelist = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/user_whitelist', data);
  };

  // 观众白名单添加
  userWhiteListAdd = (data: unknown) => {
    return http.post('/api/v1/live/user_whitelist_create', data);
  };

  // 观众白名单清空
  userWhiteListDel = (data: unknown) => {
    return http.post('/api/v1/live/user_whitelist_clean', data);
  };

  // 观众白名单删除
  userWhitelistDelete = (data: unknown) => {
    return http.post('/api/v1/live/user_whitelist_delete', data);
  };

  // 新版创建文件夹
  addFolderFile = (data: unknown) => {
    return http.post('/api/v2/live/new_create_file_class', data);
  };

  // 删除分类（文件夹）
  delFolderFile = (data: unknown) => {
    return http.post('/api/v2/live/delete_file_class', data);
  };

  // 重命名分类文件夹
  updFolderFile = (data: unknown) => {
    return http.post('/api/v2/live/rename_folder', data);
  };

  // 移动分类（文件夹）
  studioMovesFolder = (data: unknown) => {
    return http.post('/api/v2/live/studio_move_file_class', data);
  };

  // 获取分类列表
  getFileClassList = (data?: AxiosRequestConfig) => {
    return http.get('/api/v2/live/get_file_class_list', data);
  };

  // 创建优惠券付费订单
  adminPayCreateOrder = (data: unknown) => {
    return http.post('/api/v1/card_coupon/admin_pay_create_order', data);
  };

  // 绑定优惠券直播间
  cardCouponStudioBuild = (data: unknown) => {
    return http.post('/api/v1/card_coupon/card_coupon_studio_build', data);
  };

  // 轮询订单
  pollingOrder = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/wechat/polling_order', data);
  };

  // 微信扫码支付
  weChatScanPay = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/wechat/we_chat_scan_pay', data);
  };

  // 获取自定义菜单信息 and 导出功能
  getMenu = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/get_menu', data);
  };

  // 保存自定义菜单信息
  saveMenu = (data: unknown) => {
    return http.post('/api/v1/live/save_menu', data);
  };

  // 获取注册观看信息
  getViewForm = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/view_form', data);
  };

  // 获取注册观看模版列表
  getSWatchFromList = () => {
    return http.get('/api/v1/live/get_from_list');
  };

  // 保存注册观看信息
  saveViewForm = (data: unknown) => {
    return http.post('/api/v1/live/save_form', data);
  };

  // 注册观看列表数据
  getViewListForm = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/live/list_form', data);
  };

  // join
  getJoin = (data: unknown) => {
    return http.post('/api/v1/live/join', data);
  };

  // 首页一键复制
  clickCopy = (data: unknown) => {
    return http.post('/api/v1/live/copy_studio_info', data);
  };
  // 获取语言列表
  getCaptionLanguageList = () => {
    return http.get('/api/v1/home/get_caption_language_list');
  };
  // 获取多语字幕状态
  getMultSubtitle = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/home/get_subtitles_status', data);
  };
  // 实时多语字幕
  MultSubtitle = (data: unknown) => {
    return http.post('/api/v1/home/multilingual_subtitles', data);
  };
  // 字幕开关
  setCaptionSwitch = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/home/caption_switch', data);
  };
}

export default new Api();
