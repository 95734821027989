/**
 * @useName 参与者姓名
 * @type 执业资质类型
 * @img 上传的图片
 * @imgId 图片id
 * @loading 图片加载中
 * @imgName 图片文件名
 * @imgSize 图片大小
 * @progress 上传图片进度
 */
export interface ListType {
  useName: string;
  useType: string;
  imgageUrl: string;
  loading: boolean;
  imgageName: string;
  imgageSize: number;
  fileList: any[];
}
export class ListClass {
  useName: string;
  useType: string;
  imgageUrl: string;
  loading: boolean;
  imgageName: string;
  imgageSize: number;
  fileList: any[];
  constructor(data: ListObj) {
    this.useName = data.participant_name;
    this.useType = data.qualification_type;
    this.imgageUrl = data.qualification_image;
    this.loading = false;
    this.imgageName = data.qualification_image_name;
    this.imgageSize = data.qualification_image_size;
    this.fileList = [];
  }
}

export class ListObj {
  participant_name: string;
  qualification_type: string;
  qualification_image: string;
  qualification_image_name: string;
  qualification_image_size: number;
  constructor(data: ListType) {
    this.participant_name = data.useName;
    this.qualification_type = data.useType;
    this.qualification_image = data.imgageUrl;
    this.qualification_image_name = data.imgageName;
    this.qualification_image_size = data.imgageSize;
  }
}

export interface ObjType {
  status: boolean;
  list: ListType[];
}
