import { formatEmoji } from './console';

export const EmotionList = [
  '#微笑;',
  '#流汗;',
  '#色;',
  '#尴尬;',
  '#流泪;',
  '#害羞;',
  '#感冒;',
  '#睡;',
  '#可怜;',
  '#尬笑;',
  '#怒;',
  '#笑哭;',
  '#呲牙;',
  '#噢;',
  '#难受;',
  '#汗;',
  '#委屈;',
  '#愤怒;',
  '#调皮;',
  '#可爱;',
  '#搞怪;',
  '#晕;',
  '#哈哈;',
  '#困;',
  '#窘迫;',
  '#无奈;',
  '#开心;',
  '#酷;',
  '#高兴;',
  '#无语;',
  '#享受;',
  '#皱眉;',
  '#痛苦;',
  '#惊恐;',
  '#吐舌头;',
  '#飞吻;',
  '#嫌弃;',
  '#坏笑;',
  '#傲慢;',
  '#天使;',
  '#俏皮;',
  '#顽皮;',
  '#苦恼;',
  '#猪头;',
  '#老虎;',
  '#恐怖;',
  '#咧嘴;',
  '#亲亲;',
  '#不说;',
  '#不看;',
  '#不听;',
  '#瓢虫;',
  '#太阳;',
  '#炸弹;',
  '#外星人;',
  '#熊;',
  '#野猪;',
  '#奶牛;',
  '#咖啡;',
  '#心碎;',
  '#爱心;',
  '#点赞;',
  '#弱;',
  '#鼓掌;',
  '#挥手;',
  '#拳头;',
  '#力量;',
  '#ok;',
  '#幽灵;',
  '#花朵;',
  '#拜托;',
  '#嘴唇;',
  '#恶魔;',
  '#火箭;',
  '#便便;',
  '#火焰;',
  '#100分;',
  '#钱;',
  '#棒棒糖;',
];
export interface LightImgContent {
  id: string;
  url: string;
}

/**
 * @description 礼物内容对象
 */
export interface LightGiftContent {
  content: string;
  image_url: string;
  messageid: string;
  number: number;
  title: string;
}
/**
 * @description 红包内容对象
 * @id 主键
 * @command 封面内容
 * @type 红包类型
 * @type_name 红包类型名称
 * @contest_type 竞答红包的类型 含 '1' 现金红包 or  '2' 礼物
 */
export interface RedEnvelopeContent {
  id: string;
  command: string;
  type_name: string;
  type: number;
  contest_type?: string;
}
export class LightGMsg {
  avatar: number; // 头像id

  avatar_url: string; // 头像url

  content: LightImgContent | LightGiftContent | RedEnvelopeContent | string; // 内容

  created_at: string; // 时间

  id: number;

  msg_id: string; // 消息ID

  msg_type: number; // 消息类型

  level: number; // 用户类型标示 1 官方 0 普通

  studio_id?: number; // 直播间ID

  title: string; // 标题

  status: number; // 审核

  top_status: number; // 置顶状态

  uid: number; // 用户id

  nickname: string; // 用户名

  type?: string; // 消息发送类型

  address: string; //地址
  constructor(data: any) {
    this.avatar = Number(data.avatar_id);
    this.avatar_url = data.avatar_url;
    this.content = jsonFormatEmoji(data.content);
    this.created_at = data.created_at;
    this.id = Number(data.id);
    this.msg_id = data.message_id;
    this.msg_type = Number(data.message_type);
    this.level = Number(data.operator_type);
    this.status = Number(data.status);
    this.studio_id = Number(data.studio_id);
    this.title = data.title;
    this.top_status = Number(data.top_status);
    this.uid = Number(data.user_id);
    this.nickname = data.user_name;
    this.type = data.type;
    this.address = data.addr;
  }
}

export class LightSocketGMsg extends LightGMsg {
  constructor(data: any) {
    super(data);
    this.content = formatEmoji(data.content, 1);
  }
}

// 不可与其他消息类型复用
const jsonFormatEmoji = (content: string): any => {
  try {
    let formatContent = JSON.parse(content);
    if (typeof formatContent !== 'string') {
      return formatContent;
    }
    for (const q in EmotionList) {
      const cont = new RegExp(EmotionList[q], 'g');
      formatContent = formatContent.replace(
        cont,
        `<img class="emoji" style="width: 20px;height:20px;margin-right:2px;" src="http://static.lighos.com/emoji/${
          Number(q) + 1
        }.png" align="bottom" alt="${EmotionList[q]}">`
      );
    }
    return formatContent;
  } catch (err) {
    return content;
  }
};
