import axios from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  //获取评论内容列表
  get_comment_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_comment_list', data);
  }
  // 获取机器人评论配置
  get_robot_comment_config(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_robot_comment_config', data);
  }
  // 获取评论类型列表
  get_comment_type_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_comment_type_list', data);
  }
  // 删除评论机器人
  del_comment_content(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_comment_content', data);
  }
  // 删除评论机器人
  del_comment_robot(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/del_comment_robot', data);
  }
  // 设置机器人评论发送
  set_robot_comment_config(data: unknown) {
    return axios.post('/api/v1/console/set_robot_comment_config', data);
  }
  // 添加评论类型
  add_comment_type(data: unknown) {
    return axios.post('/api/v1/console/add_comment_type', data);
  }
  //获取身份库列表
  get_robot_identity_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_robot_identity_library_list', data);
  }
  // 添加身份库
  add_robot_identity(data: unknown) {
    return axios.post('/api/v1/console/add_robot_identity_library', data);
  }
  // 获取评论机器人列表
  get_comment_robot_list(data: AxiosRequestConfig) {
    return axios.get('/api/v1/console/get_comment_robot_list', data);
  }
  // 修改评论类型
  edit_comment_type(data: unknown) {
    return axios.post('/api/v1/console/edit_comment_type', data);
  }
  // 修改身份库
  edit_robot_identity_library(data: unknown) {
    return axios.post('/api/v1/console/edit_robot_identity_library', data);
  }
  // 添加评论内容
  add_comment_content(data: unknown) {
    return axios.post('/api/v1/console/add_comment_content', data);
  }
  // 修改评论内容
  edit_comment_content(data: unknown) {
    return axios.post('/api/v1/console/edit_comment_content', data);
  }
  // 添加用户评论机器人
  add_user_comment_robot(data: unknown) {
    return axios.post('/api/v1/console/add_user_comment_robot', data);
  }
  // 修改用户评论机器人
  edit_user_review_robot(data: unknown) {
    return axios.post('/api/v1/console/edit_user_review_robot', data);
  } 
}

export default new Api();
