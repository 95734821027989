import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  //获取管理员信息
  getMemberInfo = () => {
    return http.get('/api/v1/member/get_member_info');
  };

  // 修改管理员信息
  updMemberInfo = (data: unknown) => {
    return http.post('/api/v1/member/upd_member_info', data);
  };

  //用户信息--用户绑定信息
  buildInfo = () => {
    return http.get('/api/v1/member/build_info');
  };

  //检测密码状态
  checkPasswordStatus = () => {
    return http.get('/api/v1/common/check_password_status');
  };

  // 验证旧密码
  verifyOldPassword = (data: unknown) => {
    return http.post('/api/v1/member/verify_old_password', data);
  };

  // 修改新密码
  saveNewPassword = (data: unknown) => {
    return http.post('/api/v1/member/save_new_password', data);
  };

  // 更换密保手机--原手机发送验证码
  sendCodeMobile = () => {
    return http.post('/api/v1/member/send_code_mobile');
  };

  // 更换密保手机--验证旧手机号验证码
  checkCodeMobile = (data: unknown) => {
    return http.post('/api/v1/member/check_code_mobile', data);
  };

  // 发送短信验证码
  sendCaptcha = (data: unknown) => {
    return http.post('/api/v1/common/send_captcha', data);
  };

  // 更换密保手机--更换手机号
  changeMobile = (data: unknown) => {
    return http.post('/api/v1/member/change_mobile', data);
  };

  // 密保邮箱--发送邮箱验证码
  sendEmailCode = (data: unknown) => {
    return http.post('/api/v1/member/send_email_code', data);
  };

  // 密保邮箱--绑定邮箱
  buildEmail = (data: unknown) => {
    return http.post('/api/v1/member/build_email', data);
  };

  // 获取用户列表
  getUserList = (data: AxiosRequestConfig) => {
    return http.get('/api/v2/agent/client', data);
  };

  // 根据用户别名获取token，用于一键登录后台
  getTokenByUserAlias = (data: AxiosRequestConfig) => {
    return http.get('/api/v1/administrator/get_token', data);
  };
  
}

export default new Api();
