
import { defineComponent, ref, reactive } from 'vue';
import { Tabs, Table, Pagination, DatePicker } from 'ant-design-vue';
const { RangePicker } = DatePicker;
import moment from 'moment';
import { RangePickerIcon } from '@/assets';
import http from '@/api/account';
import { getToken } from '@/plugins/cookie';
import { ResourceBill } from './ResourceBill';
import AppLink from '@/components/AppLink.vue';
import { useRoute } from 'vue-router';
// 表1
const columns = [
  {
    title: '账单编号',
    width: 120,
    dataIndex: 'id',
    fixed: 'left',
    align: 'center',
    key: '0',
  },
  {
    dataIndex: 'studio_title',
    key: '1',
    width: 200,
    align: 'left',
    ellipsis: true,
    slots: {
      title: 'cStudioTitle',
      customRender: 'studio_title',
    },
  },
  {
    dataIndex: 'service_type_title',
    key: '2',
    width: 200,
    align: 'left',
    slots: {
      title: 'cServeTitle',
    },
  },
  {
    title: '资源消耗量',
    dataIndex: 'resource_consumption',
    key: '3',
    width: 120,
    align: 'center',
  },
  {
    title: '支付方式',
    dataIndex: 'pay_type_title',
    key: '4',
    width: 100,
    align: 'center',
  },
  {
    title: '消费',
    dataIndex: 'amount',
    key: '5',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'amount',
    },
  },
  {
    title: '现金支付',
    dataIndex: 'payment_amount',
    key: '6',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'payment_amount',
    },
  },
  {
    title: '支付时间',
    dataIndex: 'created_at',
    key: '7',
    width: 215,
    align: 'center',
  },
  {
    title: '出账时间',
    dataIndex: 'billing_at',
    key: '8',
    width: 215,
    align: 'center',
  },
  {
    title: '支付状态',
    width: 120,
    dataIndex: 'status_title',
    fixed: 'right',
    align: 'center',
    key: '9',
  },
];
const Postes = ref<DataItem[]>([]);
interface DataItem {
  key: number;
  id: number;
  studio_title: string;
  service_type_title: string;
  resource_consumption: string;
  pay_type_title: string;
  amount: string;
  payment_amount: string;
  created_at: string;
  billing_at: string;
  status_title: string;
}

// 表2
const columnsl = [
  {
    title: '账单编号',
    width: 120,
    dataIndex: 'id',
    fixed: 'left',
    align: 'center',
    key: '0',
  },
  {
    dataIndex: 'studio_title',
    key: '1',
    align: 'left',
    ellipsis: true,
    width: 200,
    slots: {
      title: 'cStudioTitle',
      customRender: 'studio_title',
    },
  },
  {
    title: '服务类型',
    dataIndex: 'type_name',
    key: '2',
    ellipsis: true,
    align: 'center',
    width: 120,
  },
  {
    dataIndex: 'remarke',
    key: '3',
    width: 120,
    ellipsis: true,
    align: 'center',
    slots: {
      title: 'cRemarkeTitle',
    },
  },
  {
    title: '消费',
    dataIndex: 'number',
    key: '4',
    align: 'center',
    width: 120,
    slots: {
      customRender: 'number',
    },
  },
  {
    title: '支付时间',
    dataIndex: 'created_at',
    key: '5',
    width: 120,
    align: 'center',
  },
  {
    key: '6',
    dataIndex: 'tips',
    align: 'left',
    width: 150,
    fixed: 'right',
    slots: {
      title: 'cTipsTitle',
    },
  },
];
const char = ref<DataIteml[]>([]);
interface DataIteml {
  key: number;
  id: string;
  age: number;
  studio_title: string;
  type_name: string;
  remarke: string;
  number: string;
  created_at: string;
  tips: string;
}

// 支付状态
const pays = ref([
  {
    label: '全部',
    value: 0,
  },
  {
    label: '支付成功',
    value: 1,
  },
  {
    label: '支付失败',
    value: 2,
  },
]);

export default defineComponent({
  components: {
    [RangePicker.name]: RangePicker,
    aTabs: Tabs,
    aTabPane: Tabs.TabPane,
    aTable: Table,
    aPagination: Pagination,
    AppLink,
  },
  props: {},
  setup() {
    const activeKey = ref('1');
    const route = useRoute();
    route.query.bill ? (activeKey.value = '2') : '';

    const mediaRef = ref();

    // 获取服务类型列表
    interface service {
      studio_id: string;
      studio_title: string;
    }
    const serviceList = ref<service[]>([]);
    let service_obj = ref({
      id: 0,
      service_title: '全部',
    });
    const serviceTypeList = () => {
      let token = getToken();
      http.getServiceTypeList({ params: token }).then((res) => {
        if (res.data.code == 200) {
          let arr = res.data.data;
          arr.unshift(service_obj.value);
          serviceList.value = arr;
        }
      });
    };
    serviceTypeList();

    // 获取下拉框筛选数据
    const Payment: any = ref([{ label: '全部', value: '' }]); //  支付方式
    const channelList = ref<service[]>([]); //频道名称
    const getOptionSearch = () => {
      http.optionSearch().then((res) => {
        const typeList = [...res.data.data.type_list];
        typeList.map((res: any) => {
          Payment.value.push({
            label: res.value,
            value: res.key,
          });
        });
        channelList.value = [...res.data.data.studio_list];
        channelList.value.unshift({ studio_id: '', studio_title: '全部' });
      });
    };
    getOptionSearch();

    // 分页
    const pageConfigure = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    let obj = ref({
      paymentStatus: null,
      payms: null,
      service: null,
      Chan: null,
      chanList: null,
    });

    const showLoading = ref<boolean>(false);
    // 获取资源包后计费账单列表
    const queryForms = ref<Date[]>([]);
    const resourcePackageFeeBill = (current: number) => {
      let resource: any = {
        studio_id: obj.value.Chan,
        service_type: obj.value.service,
        pay_status: obj.value.paymentStatus,
        page: current,
        page_num: pageConfigure.pageSize,
      };
      showLoading.value = true;
      obj.value.Chan == 0 && delete resource.studio_id;
      const postBilling = new ResourceBill(resource);
      postBilling.setDate(queryForms.value as any);
      http
        .getResourcePackageFeeBill({ params: postBilling })
        .then((res) => {
          pageConfigure.current = res.data.data.current_page;
          pageConfigure.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          Postes.value = [];
          res.data.data.data.map((res: any) => {
            Postes.value.push(res);
          });
          showLoading.value = false;
        })
        .catch(() => {
          showLoading.value = false;
        });
    };
    route.query.bill ? '' : resourcePackageFeeBill(1);
    // 分页
    const pageConfig = reactive({
      current: 1, // 当前页
      pageSize: 10, // 每页条数
      totalSize: 10, // 总数
      options: ['10', '20', '30', '40', '50'], // 选项
    });
    // 日历
    const queryForm = ref<Date[]>([]);
    // 预计费账单
    const estimatedFeeBillList = (current: number) => {
      const estim: any = {
        studio_id: obj.value.chanList,
        type: obj.value.payms,
        page: current,
        page_num: pageConfig.pageSize,
      };
      showLoading.value = true;
      obj.value.chanList == 0 && delete estim.studio_id;
      const formData = new ResourceBill(estim);
      formData.setDate(queryForm.value as any);
      delete formData.pay_status;
      http
        .getEstimatedFeeBillList({ params: formData })
        .then((res) => {
          pageConfig.current = res.data.data.current_page;
          // 判断页面无数据页码显示第一页，使用三元运算符判断
          pageConfig.totalSize = res.data.data.total > 0 ? res.data.data.total : 10;
          char.value = [...res.data.data.data];
          showLoading.value = false;
        })
        .catch(() => {
          showLoading.value = false;
        });
    };
    route.query.bill ? estimatedFeeBillList(1) : '';

    const trigger = () => {
      if (activeKey.value === '1') {
        resourcePackageFeeBill(1);
      } else {
        estimatedFeeBillList(1);
      }
    };

    const bills = () => {
      if (activeKey.value === '1') {
        resourcePackageFeeBill(1);
      } else {
        estimatedFeeBillList(1);
      }
    };
    // 分页器点击事件
    const showSize = () => {
      resourcePackageFeeBill(pageConfigure.current);
    };
    const Pager = () => {
      estimatedFeeBillList(pageConfig.current);
    };
    // 清空
    const empty = () => {
      queryForms.value = [];
      obj.value.service = null;
      obj.value.paymentStatus = null;
      obj.value.Chan = null;

      resourcePackageFeeBill(1);
    };
    const emptys = () => {
      queryForm.value = [];
      obj.value.payms = null;
      obj.value.chanList = null;
      estimatedFeeBillList(pageConfig.current);
    };
    return {
      activeKey,
      columns,
      Postes,
      columnsl,
      char,
      RangePickerIcon,
      moment,
      //   options,
      pays,
      //   channel,
      Payment,
      obj,
      serviceList,
      channelList,
      pageConfig,
      trigger,
      queryForm,
      queryForms,
      pageConfigure,
      bills,
      showSize,
      Pager,
      empty,
      emptys,
      mediaRef,
      showLoading,
    };
  },
});
