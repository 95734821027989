import { RouteRecordRaw } from 'vue-router';

// 资源中心权限路由
export const asyncRoleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/wallet/overview',
    name: 'Overview',
    component: () => import('../views/wallet/resource/Overview.vue'),
    meta: {
      title: '资源概览',
      icon: '',
      routeParent: 'Wallet',
      applink: true,
      funcId: 735,
    },
  },
  {
    // path: '/account/accountRecharge',
    path: '/wallet/resource',
    name: 'ResourcePurchase',
    component: () => import('../views/wallet/ResourcePkg.vue'),
    meta: {
      title: '资源购买',
      icon: '',
      routeParent: 'Wallet',
      applink: true,
      funcId: 755,
    },
  },
  {
    path: '/wallet/recharge',
    // path: '/accountRecharge', // 站外链接
    name: 'WalletRecharge',
    component: () => import('../views/wallet/Recharge.vue'),
    meta: {
      title: '账户充值',
      icon: '',
      routeParent: 'Wallet',
      funcId: 624,
      // applink: false, // 站外链接 标识
    },
  },
  {
    path: '/wallet/version',
    name: 'WalletVersion',
    component: () => import('../views/wallet/Version.vue'),
    meta: {
      title: '版本购买',
      icon: '',
      routeParent: 'Wallet',
      funcId: 777,
    },
  },
  {
    path: '/wallet/bill/:name?',
    name: 'baseBill',
    component: () => import('../views/wallet/bill/Bill.vue'),
    meta: {
      title: '账单统计',
      icon: '',
      routeParent: 'Wallet',
      customMode: true,
      funcId: 369,
    },
  },
  {
    path: '/personal/sub',
    name: 'SubManagement',
    component: () => import('../views/account/SubManagement.vue'),
    meta: {
      title: '临时账号',
      icon: '',
      routeParent: 'Personal',
      funcId: 464,
    },
  },
  {
    path: '/personal/subor',
    name: 'Subordinate',
    component: () => import('../views/account/Subordinate/Subordinate.vue'),
    meta: {
      title: '子账号',
      icon: '',
      routeParent: 'Personal',
      customMode: true,
      funcId: 857,
    },
  },
  {
    path: '/personal/custom/:name?',
    name: 'customServe',
    component: () => import('../views/account/customServe/CustomServe.vue'),
    meta: {
      title: '定制服务',
      icon: '',
      routeParent: 'Personal',
      customMode: true,
      funcId: 783,
    },
  },
  {
    path: '/live/sub',
    name: 'LiveSub',
    component: () => import('../views/live/Subt.vue'),
    meta: {
      title: '临时账号列表',
      icon: '',
      routeParent: 'Live',
      funcId: 133,
    },
  },
  // {
  //   path: '/coupon',
  //   name: 'accountRecharge_test3',
  //   component: () => import('../views/wallet/cardBag/CardBag.vue'),
  //   meta: {
  //     title: '卡包/优惠券',
  //     icon: '',
  //     routeParent: 'Wallet',
  //     applink: false,
  //     funcId: 749,
  //   },
  // },
];

export const mediaMenuHelp: Array<RouteRecordRaw> = [
  {
    path: '/media',
    name: 'Media',
    component: () => import('../views/mediaLibrary/home.vue'),
    redirect: '/media/cache',
    meta: {
      title: '媒体库',
      icon: 'menu_media',
      menuType: 'live',
      role: ['helper'],
      helperKey: 'set', // 临时设置
    },
    children: [
      {
        path: '/media/videos/:studioId',
        name: 'MediaVideos',
        component: () => import('../views/mediaLibrary/Media.vue'),
        meta: {
          title: '视频库',
          icon: '',
          routeParent: 'Media',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
      {
        path: '/media/cache/:studioId',
        name: 'LiveCache',
        component: () => import('../views/mediaLibrary/LiveCache.vue'),
        meta: {
          title: '直播缓存',
          icon: '',
          routeParent: 'Media',
          role: ['helper'],
          helperKey: 'set', // 临时设置
        },
      },
    ],
  },
];
