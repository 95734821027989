
import { computed, defineComponent, reactive, ref, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message, Input, Form, Spin } from 'ant-design-vue';
import http from '@/api/login';
import SingHeader from '@/components/SingHeader.vue';
import Rotation from '@/components/Rotation.vue';
import CookieVue from '@/components/Cookie.vue';
import { getVersionName, grtPrivacyAgreePc } from '@/plugins/cookie';
import { formatRoutes } from '@/router';
import Socket from '@/utils/socket';
import { useSocketStore } from '@/pinia/socket';
import EventBus from '@/components/EventBus';
import { useQRCode } from '@vueuse/integrations/useQRCode';

interface Ilogin {
  username: number | string;
  password: string;
  code: string;
}
export default defineComponent({
  components: {
    aInput: Input,
    aInputPassword: Input.Password,
    aForm: Form,
    SingHeader,
    Rotation,
    aSpin: Spin,
    // CookieVue,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const TIME_COUNT = 60;
    const customData = computed(() => store.state.home.customData);
    const clientId = ref();

    const CodeShake = ref<boolean>(false);
    const formData: Ilogin = reactive({
      username: '',
      password: '',
      code: '',
    });
    // 登录
    const isShowform = ref(true);
    // 扫码登陆
    const socketStore = useSocketStore();
    const isShowQrcode = ref(false);
    const loginQrCode = ref('');
    // 显示二维码
    const cutQrcodeLogin = () => {
      if (!socketStore.socket) {
        socketStore.socket = new Socket(process.env.VUE_APP_WSS_LINK as string);
      }
      http.getLoginQrCode().then((res) => {
        loginQrCode.value = res.data.data;
        isShowQrcode.value = true;
      });
    };
    const QRcode = computed(() => loginQrCode.value + '&id=' + clientId.value);
    const qrcodeUrl = useQRCode(QRcode, { margin: 0 });
    // 返回密码登陆 断开ws连接
    const resCutQrcodeLogin = () => {
      isShowQrcode.value = false;
      clearInterval(codeLoginTimer.value);
      if (socketStore.socket) {
        socketStore.socket.closeWebsocket();
        loginQrCode.value = '';
        clientId.value = '';
        codeLoginData.value.status = 0;
      }
    };
    // 加入ws
    const onJoinSocket = (data: any) => {
      clientId.value = data.client_id;
    };
    const codeLoginData = ref({
      status: 0,
      cancel: -1,
      determine: -1,
      showMask: false,
    });
    let codeLoginTimer: any = ref(0);
    const codeLoginCount = ref(TIME_COUNT);
    // APP端扫码后 PC收到通知 1 扫码完成  2 二维码已过期  3 二维码已被使用
    const getCodeLoginInfo = (data: any) => {
      codeLoginData.value.status = data.status;
      if (codeLoginData.value.status === 1) {
        codeLoginCount.value = TIME_COUNT;
        codeLoginTimer.value = 0;
        codeLoginTimer.value = setInterval(() => {
          if (codeLoginCount.value > 0 && codeLoginCount.value <= TIME_COUNT) {
            codeLoginCount.value--;
          } else {
            clearInterval(codeLoginTimer.value);
            codeLoginTimer.value = 0;
            socketStore.socket?.closeWebsocket();
          }
        }, 1000);
      }
      codeLoginData.value.showMask = true;
    };
    // 确认登陆
    const codeLoginOk = async (data: any) => {
      const resData = data;
      store.commit('user/loginSaveUserInfo', resData);
      await formatRoutes();
      router.addRoute({
        path: '/:catchAll(.*)',
        redirect: '/404',
      });
      message.success('登陆成功！');
      store.dispatch('home/getDefaultCustomized').finally(() => {
        if (getVersionName()) {
          getVersionName() == 'resource_purchase'
            ? router.push('/wallet/resource')
            : getVersionName() == 'versionUrchase'
            ? router.push('/wallet/version')
            : router.push('/newlive');
        } else {
          router.push({ name: 'Layout' });
        }
      });
    };

    // 刷新二维码
    const clickRefresh = () => {
      if (socketStore.socket) {
        socketStore.socket.closeWebsocket();
        loginQrCode.value = '';
        clientId.value = '';
        codeLoginData.value.status = 0;
      }
      cutQrcodeLogin();
      codeLoginData.value.showMask = false;
    };
    const codeLoginCancel = (data: any) => {
      if (data.login_rt === 0) clickRefresh();
    };
    EventBus.on('init', onJoinSocket);
    EventBus.on('qrCodeLoginInfo', getCodeLoginInfo);
    EventBus.on('qrCodeLoginOk', codeLoginOk);
    EventBus.on('qrCodeLoginCancel', codeLoginCancel);

    onUnmounted(() => {
      EventBus.off('init', onJoinSocket);
      EventBus.off('qrCodeLoginInfo', getCodeLoginInfo);
      EventBus.off('qrCodeLoginOk', codeLoginOk);
      EventBus.off('qrCodeLoginCancel', codeLoginCancel);

      if (socketStore.socket) {
        socketStore.socket.closeWebsocket();
      }
    });
    // 倒计时
    let isShowcode = ref(true);
    let timer: any = ref(0);
    let count = ref(TIME_COUNT);
    const getCode = () => {
      // 正则表达式
      var reg = /^1[0-9]{10}$/;
      if (formData.username != '') {
        if (!reg.test(formData.username as string)) {
          message.info('您输入的手机号不合法，请重新输入');
          return false;
        }
        if (!timer.value) {
          count.value = TIME_COUNT;
          isShowcode.value = false;
          timer.value = setInterval(() => {
            if (count.value > 0 && count.value <= TIME_COUNT) {
              count.value--;
            } else {
              isShowcode.value = true;
              clearInterval(timer.value);
              timer.value = 0;
            }
          }, 1000);
        }
      } else {
        message.info('请输入手机号');
      }
      if (CodeShake.value) {
        return;
      }
      if (formData.username == '') {
        return;
      }
      let send = {
        type: -1,
        mobile: formData.username,
      };
      http
        .getSendCaptcha(send)
        .then(() => {
          CodeShake.value = false;
        })
        .catch(() => {
          CodeShake.value = false;
        });
    };
    const dragStatus = ref(1);
    const getDragData = (data: any) => {
      aliVerification(data);
    };
    const aliVerification = (data: any) => {
      let params = {
        session_id: data.sessionId,
        sig: data.sig,
        token: data.token,
        scene: 1,
      };
      http
        .aliLoginVerification(params)
        .then(() => {
          dragStatus.value = 0;
        })
        .catch((res) => {
          dragStatus.value = 2;
          (window as any).ic.reset();
          message.error(res.data.error);
        });
    };
    const loginShake = ref<boolean>(false);
    const transmission = ref(false);
    watch(
      () => transmission.value,
      () => {
        return transmission.value;
      }
    );
    // 客管登陆接口
    const login = () => {
      if (loginShake.value == true) {
        return;
      }
      if (formData.username == '') {
        message.info('手机号不能为空');
        return;
      }
      if (isShowform.value == true && formData.password == '') {
        message.info('密码不能为空');
        return;
      }
      if (isShowform.value == false && formData.code == '') {
        message.info('验证码不能为空');
        return;
      }
      if (!agreementCheckbox.value) {
        message.info('请先勾选隐私协议！');
        return;
      }
      // if (!grtPrivacyAgreePc()) {
      //   Modal.confirm({
      //     title: '提示',
      //     icon: createVNode(ExclamationCircleOutlined),
      //     content: '为了保障用户操作流程正常运行,需同意本网站存储Cookie操作',
      //     centered: true,
      //     okText: '确认',
      //     cancelText: '取消',
      //     onOk() {
      //       transmission.value = true;
      //     },
      //   });

      //   return;
      // }
      // if (dragStatus.value) {
      //   message.info('请先点击验证！');
      //   return;
      // }
      let enroll = {
        mobile: formData.username,
        password: formData.password,
        type: 1,
      };
      let valid = {
        mobile: formData.username,
        type: 0,
        code: formData.code,
      };
      http
        .getLogin(isShowform.value ? enroll : valid)
        .then(async (res) => {
          const resData = res.data.data;
          store.commit('user/loginSaveUserInfo', resData);
          await formatRoutes();
          router.addRoute({
            path: '/:catchAll(.*)',
            redirect: '/404',
          });
          message.success(res.data.message);
          store.dispatch('home/getDefaultCustomized').finally(() => {
            if (getVersionName()) {
              getVersionName() == 'resource_purchase'
                ? router.push('/wallet/resource')
                : getVersionName() == 'versionUrchase'
                ? router.push('/wallet/version')
                : router.push('/newlive');
            } else {
              router.push({ name: 'Layout' });
            }
            loginShake.value = false;
          });
        })
        .catch((err) => {
          message.error(err.data.error);
          loginShake.value = false;
        });
    };
    const newTime = Date.parse(new Date().toString()) / 1000;
    const adminUserVisit = () => {
      http.adminUserVisit({ params: { entry_time: newTime } }).then(() => {
        sessionStorage.setItem('visit', 'pc_login');
      });
    };
    const user_visit = sessionStorage.getItem('visit');
    const exitChange = () => {
      let data = { entry_time: newTime, exit_page: 1 };
      http.adminUserVisit({ params: data });
    };
    if (user_visit == null) {
      adminUserVisit();
      window.onbeforeunload = () => {
        exitChange();
      };
    }
    const agreementCheckbox = ref(true);

    return {
      customData,
      isShowform,
      formData,
      getCode,
      isShowcode,
      count,
      login,
      getDragData,
      transmission,
      isShowQrcode,
      cutQrcodeLogin,
      resCutQrcodeLogin,
      qrcodeUrl,
      codeLoginData,
      clickRefresh,
      clientId,
      loginQrCode,
      codeLoginCount,
      agreementCheckbox,
    };
  },
});
