
import { defineComponent } from '@vue/runtime-core';
import { VueDraggableNext } from 'vue-draggable-next';
import { Upload, message } from 'ant-design-vue';
import { ref } from 'vue';
import { getToken } from '@/plugins/cookie';
import http from '@/api/live';
import CUpload from '@/components/CUpload.vue';
import { dragDrop } from '@/utils/format/dragDrop';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
    [Upload.name]: Upload,
    CUpload,
  },
  setup() {
    // 上传图片
    // const fileList = ref();
    const image = ref();
    const imageid = ref();
    const multiList = ref([
      {
        img: '',
        loading: false,
        language_id: 0,
        studio_id: 0,
        studio_name: '',
        image_player_cover_id: 0,
        studio_stream_type: 1,
        title_sub: '',
        videoCover_loading: false,
        selectLive: 0,
        select: undefined,
        input: undefined,
        studio_index: null,
      },
    ]);
    const getMulti = (e: any) => {
      console.log(multiList.value, e);
    };
    // const { fileList, loading, handleChange, imageDel } = useUpload(imageUrl1, imageUrl1);
    // 上传图片
    const fileList = ref();
    const handleChange = (info: FileInfo, index: number) => {
      if (info.file?.response?.code === 200) {
        multiList.value[index].img = info.file?.response?.data.url;
        multiList.value[index].image_player_cover_id = info.file?.response ? info.file?.response?.data.id : 0;
      }
      if (info.file.status === 'uploading') {
        multiList.value[index].loading = true;
        return;
      } else multiList.value[index].loading = false;
      if (info.file.status === 'error') {
        multiList.value[index].loading = false;
        message.error('上传失败');
      }
    };
    const imageDel = (info: FileInfo, index: number) => {
      multiList.value[index].img = '';
      multiList.value[index].image_player_cover_id = 0;
    };
    // 获取直播间列表
    let options: any = ref([]);
    const getStudioList = () => {
      http
        .getStreamStudioList()
        .then((res) => {
          const resData = res.data.data;
          options.value = [...resData];
        })
        .catch(() => {
          message.destroy();
        });
    };
    getStudioList();

    const getSelectLive = (num: number, index: number) => {
      if (num == 1) {
        multiList.value[index].selectLive = 1;
      } else {
        const studio_index = multiList.value[index].studio_index;
        if (studio_index !== null) {
          options.value[studio_index].chat_type = 2;
        }
        multiList.value[index].selectLive = 0;
      }
    };
    // 输入直播间名称
    const getInput = (index: number) => {
      if (multiList.value[index].select) {
        multiList.value[index].studio_name = multiList.value[index].input as any;
      }
    };
    // 选择直播间名称
    const getSelect = (index: number, resIndex: any) => {
      let studio_index = multiList.value[index].studio_index;
      if (studio_index !== null) {
        options.value[studio_index].chat_type = 2;
      }
      options.value[resIndex].chat_type = 1;
      multiList.value[index].studio_index = resIndex;
      multiList.value[index].studio_id = options.value[resIndex].id;
      multiList.value[index].studio_name = options.value[resIndex].title;
    };
    // 选择直播间获取焦点
    const getFocus = (index: number) => {
      const studio_index = multiList.value[index].studio_index;
      if (studio_index !== null) {
        options.value[studio_index].chat_type = 2;
      }
    };
    // 选择直播间失去焦点
    const getBlur = (index: number) => {
      const studio_index = multiList.value[index].studio_index;
      if (studio_index !== null) {
        options.value[studio_index].chat_type = 1;
      }
    };
    // 添加
    const toAdd = () => {
      multiList.value.push({
        img: '',
        language_id: 0,
        studio_id: 0,
        studio_name: '',
        image_player_cover_id: 0,
        studio_stream_type: 1,
        title_sub: '',
        videoCover_loading: false,
        selectLive: 0,
        select: undefined,
        input: undefined,
        studio_index: null,
        loading: false,
      });
    };
    // 删除
    const toDelete = (index: number) => {
      multiList.value.splice(index, 1);
    };
    let token = getToken();

    return {
      enabled: true,
      dragging: false,
      multiList,
      getMulti,
      fileList,
      handleChange,
      options,
      toAdd,
      toDelete,
      serial: ref(true),
      token,
      getSelect,
      getInput,
      getFocus,
      getBlur,
      getSelectLive,
      imageDel,
      dragDrop,
    };
  },
});
