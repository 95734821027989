import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';

class Api {
  getFabulousData(data: AxiosRequestConfig) {
    return http.get('/api/v1/live_like/get_like_config', data);
  }
  setFabulousData(data: unknown) {
    return http.post('/api/v1/live_like/save_like_config', data);
  }
}

export default new Api();
