import { reactive } from 'vue';
import { dateFormats } from '@/utils/format/date';
export class DataClass {
  casterId: string;
  name: string;
  status: string;
  type: string;
  billingPlan: string;
  latestTime: string;
  expirationTime: string;
  duration: string;
  clientToken: string;
  constructor(data: IData) {
    this.casterId = data.CasterId;
    this.name = data.CasterName;
    this.status = data.Status ? '导播中' : '空闲中';
    this.type = data.NormType === 1 ? '通用型' : '--';
    this.billingPlan = data.ChargeType === 'PostPaid' ? '按时长' : '--';
    this.latestTime = data.LastModified;
    this.expirationTime = '长期有效';
    this.duration = data.Duration || '--';
    this.clientToken = data.ClientToken;
  }
}

export interface IData {
  CasterId: string; //导播台id
  CasterName: string; // 导播台名称
  ChannelEnable: number; //
  ChargeType: string; // 付费方式   PrePaid  包月     PostPaid 按时长；
  CreateTime: string; // 创建时间
  Duration: string; // 上次导播时长
  LastModified: string; // 最新操作时间
  NormType: number; // 导播台规格类型 1.通用型
  Status: number; //状态 0：空闲；1：导播中
  ClientToken: string;
}

export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '状态',
    dataIndex: 'status',
  },
  {
    title: '类型',
    dataIndex: 'type',
  },
  {
    title: '计费方式',
    dataIndex: 'billingPlan',
  },
  {
    title: '最新操作时间',
    dataIndex: 'latestTime',
  },
  {
    title: '到期时间',
    dataIndex: 'expirationTime',
  },
  {
    title: '上次导播时长',
    dataIndex: 'duration',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '200',
    fixed: 'right',
    slots: {
      customRender: 'operation',
    },
  },
];

export const pageConfig = reactive({
  current: 1, // 当前页
  pageSize: 10, // 每页条数
  totalSize: 0, // 总页数
  options: ['10', '24', '36', '48', '60'], // 选项
  size: 'small', // 尺寸
  showJumper: false, // 是否显示跳页
  showSize: false, // 是否显示条数切换
  loading: false,
});

export const stripingColumns = [
  {
    title: '直播剪辑名称',
    dataIndex: 'title',
  },
  {
    title: '状态',
    dataIndex: 'status',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    width: '200',
    fixed: 'right',
    slots: {
      customRender: 'operation',
    },
  },
];

export interface StripData {
  Title: string;
  BusinessStatus: string;
  StorageLocation: string;
  RegionId: string;
  ProjectId: string;
  ModifiedTime: string;
  CreateTime: string;
}

export class StripDataClass {
  title: string;
  status: string;
  createTime: string;
  projectId: string;
  constructor(data: StripData) {
    this.title = data.Title;
    this.status = getStatus(data.BusinessStatus);
    this.createTime = dateFormats(data.CreateTime);
    this.projectId = data.ProjectId;
  }
}

const getStatus = (status: string) => {
  let message: any;
  switch (status) {
    case 'Reserving':
      message = '预约中';
      break;
    case 'ReservationCanceled':
      message = '预约取消';
      break;
    case 'BroadCasting':
      message = '直播中';
      break;
    case 'LoadingFailed':
      message = '加载失败';
      break;
    case 'LiveFinished':
      message = '直播结束';
      break;
  }
  return message;
};

export interface CStripinData {
  name: string;
  url: string;
  modeStorage: string;
  selectValue: undefined | number;
  options: any[];
  timeType: number;
  starTime: string;
}
