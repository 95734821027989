import http from '../plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 所有问卷
  getQuestList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_quest_list', data);
  }
  getLiveStudioHead(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_live_studio_head', data);
  }
  getLiveInfo(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/get_live_info', data);
  }
  getQuestItemsInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quest_items_info', data);
  }
  // 删除
  getquestInfoDel(data: unknown) {
    return http.post(' /api/v1/live/quest_info_del', data);
  }
  // 问卷调查表格
  questList(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/quest_list', data);
  }
  // 复制创建问卷触发的接口
  getQuestCopy(data: unknown) {
    return http.post('/api/v1/live/quest_copy', data);
  }
  // 查看报告接口
  getQuestOverview(data: AxiosRequestConfig) {
    return http.get(' /api/v1/live/quest_overview', data);
  }
  // 问卷计时任务
  getQuestTimeTask(data: unknown) {
    return http.post(' /api/v1/live/quest_time_task', data);
  }

  //创建问卷
  questCreate(data: unknown) {
    return http.post('/api/v1/live/quest_create', data);
  }

  //编辑问卷
  questSave(data: unknown) {
    return http.post('/api/v1/live/quest_save', data);
  }

  //删除问卷
  questDelete(data: unknown) {
    return http.post('/api/v1/live/quest_delete', data);
  }

  //发布问卷
  questStatus(data: unknown) {
    return http.post('/api/v1/live/quest_status', data);
  }

  //功能开关
  questShow(data: unknown) {
    return http.post('/api/v1/live/quest_show', data);
  }

  // 复制问卷
  questCopy(data: unknown) {
    return http.post('/api/v1/live/quest_copy', data);
  }

  // 编辑问卷
  questView(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quest_view', data);
  }

  // 获取问卷答案详情
  questItemInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quest_item_info', data);
  }

  // 修改问卷答案
  questItemsAnswer(data: unknown) {
    return http.post('/api/v1/live/quest_items_answer', data);
  }

  // 弹出
  questionPopup(data: unknown) {
    return http.post('/api/v1/live/quest_popup', data);
  }
}
export default new Api();
