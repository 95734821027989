import { RouteRecordRaw } from 'vue-router';
/**
 * 首页菜单
 *
 * @descriptionmeta 包含相关关键字作用：
 *  父组件
 * @title - 标题
 * @icon - 菜单图标
 * @menuType - 菜单分类 首页菜单与进入直播间后的菜单区分
 * @userMenu {boolean} 是否在右上角菜单显示
 *
 * 子组件
 * @routeParent - 子选项的父级菜单标识
 * @hidden {boolean} 是否在左侧菜单显示
 * @customMode {boolean} 自定义mode层
 * @applink {boolean} 是否是站内路由
 * @role {boolean} 是否有权限
 * @only {boolean} 只能在对应role显示
 * @helperKey {string} 关键字对应助手接口返回关键字
 * @hideMenu {boolean} 拥有子路由的菜单不展开menu
 */
export const homeMenu: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/HomeInfo.vue'),
    meta: {
      title: '',
      icon: '',
    },
  },
  {
    path: '/home/info',
    name: 'HomeInfo',
    component: () => import('../views/Home.vue'),
    redirect: '/home',
    children:[]
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('../views/live/Live.vue'),
    redirect: '/live/list',
    meta: {
      title: '直播管理',
      icon: 'menu_live',
      menuType: 'home',
    },
    children: [
      {
        path: '/live/list',
        name: 'LiveList',
        component: () => import('../views/live/List.vue'),
        meta: {
          title: '直播列表',
          icon: '',
          routeParent: 'Live',
        },
      },
      {
        path: '/live/type',
        name: 'LiveType',
        component: () => import('../views/live/LiveType.vue'),
        meta: {
          title: '直播分类',
          icon: '',
          routeParent: 'Live',
        },
      },
      {
        path: '/live/sub',
        name: 'LiveSub',
        component: () => import('../views/live/Subt.vue'),
        meta: {
          title: '临时账号列表',
          icon: '',
          routeParent: 'Live',
          funcId: 133,
        },
      },
      {
        path: '/live/down_center',
        name: 'DownCenter',
        component: () => import('../views/dataCenter/DownCenter.vue'),
        meta: {
          title: '下载中心',
          icon: '',
          routeParent: 'Live',
        },
      },
      {
        path: '/newlive',
        name: 'newLive',
        component: () => import('../views/newLive/newlive.vue'),
        meta: {
          title: '新建直播',
          routeParent: 'Live',
          icon: '',
          hidden: true,
          customMode: true,
        },
      },
    ],
  },
  {
    path: '/site',
    name: 'Site',
    component: () => import('../views/gateway/home.vue'),
    meta: {
      title: '视频门户',
      icon: 'menu_gateway',
      menuType: 'home',
      userMenu: true,
    },
    redirect: '/gateway',
    children: [
      {
        path: '/gateway',
        name: 'Gateway',
        component: () => import('../views/gateway/articalCategory/portalStatistic.vue'),
        meta: {
          title: '门户列表',
          icon: '',
          routeParent: 'Site',
          customMode: true,
          funcId: 905,
        },
      },
    ],
  },
  {
    path: '/application',
    name: 'Application',
    component: () => import('../views/application/index.vue'),
    meta: {
      title: '应用中心',
      icon: 'menu_application',
      menuType: 'home',
      userMenu: true,
      hidden: false,
      hideMenu: true,
      routeParent: 'Application',
      funcId: 1146,
    },
    children: [
      {
        path: '',
        component: () => import('../views/application/list.vue'),
        meta: {
          title: '快速导航',
        },
      },
      {
        path: '/application/guide',
        name: 'CloudGuide',
        component: () => import('../views/application/CloudGuide.vue'),
        meta: {
          title: '云导播',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: '/application/striping',
        name: 'CloudStriping',
        component: () => import('../views/application/CloudStriping.vue'),
        meta: {
          title: '云拆条',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: '/application/signWall',
        name: 'SignWall',
        component: () => import('../views/application/Sign/SignWall.vue'),
        meta: {
          title: '签到上墙',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: '/application/createSignWall',
        name: 'CreateSignWall',
        component: () => import('../views/application/Sign/CreateSignWall.vue'),
        meta: {
          title: '新建活动',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: '/application/editSignWall',
        name: 'EditSignWall',
        component: () => import('../views/application/Sign/CreateSignWall.vue'),
        meta: {
          title: '编辑活动',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: '/application/configSign',
        name: 'ConfigSign',
        component: () => import('../views/application/Sign/ConfigSign.vue'),
        meta: {
          title: '配置H5',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'five_lucky',
        name: 'FiveLuckyGame',
        component: () => import('../views/application/FiveLuckyGame/list.vue'),
        meta: {
          title: '集五福',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'five_lucky/add',
        name: 'FiveLuckyAdd',
        component: () => import('../views/application/FiveLuckyGame/add.vue'),
        meta: {
          title: '新建活动',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'five_lucky/edit',
        name: 'FiveLuckyEdit',
        component: () => import('../views/application/FiveLuckyGame/add.vue'),
        meta: {
          title: '编辑活动',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'lucky_card',
        name: 'LuckyCard',
        component: () => import('../views/application/LuckyCard/list.vue'),
        meta: {
          title: '福卡管理',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'lucky_card_record',
        name: 'LuckyCardRecord',
        component: () => import('../views/application/FiveLuckyGame/LuckyCardRecord.vue'),
        meta: {
          title: '福卡报告',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
      {
        path: 'lucky_record',
        name: 'LuckyRecord',
        component: () => import('../views/application/FiveLuckyGame/LuckyRecord.vue'),
        meta: {
          title: '中奖报告',
          icon: '',
          routeParent: 'Application',
          customMode: true,
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('../views/mediaLibrary/home.vue'),
    redirect: '/media/videos',
    meta: {
      title: '媒体库',
      icon: 'menu_media',
      menuType: 'home',
    },
    children: [
      {
        path: '/media/videos',
        name: 'MediaVideos',
        component: () => import('../views/mediaLibrary/Media.vue'),
        meta: {
          title: '视频库',
          icon: '',
          routeParent: 'Media',
        },
      },
      {
        path: '/media/clip',
        name: 'VideoClip',
        component: () => import('../views/mediaLibrary/VideoClip.vue'),
        meta: {
          title: '视频剪辑',
          icon: '',
          hidden: true,
          routeParent: 'Media',
        },
      },
      {
        path: '/media/editor',
        name: 'VideoEditor',
        component: () => import('../views/mediaLibrary/VideoEditor.vue'),
        meta: {
          title: '视频打点',
          icon: '',
          hidden: true,
          routeParent: 'Media',
        },
      },
      {
        path: '/media/merge',
        name: 'VideoMerge',
        component: () => import('../views/mediaLibrary/VideoMerge.vue'),
        meta: {
          title: '视频合并',
          icon: '',
          hidden: true,
          routeParent: 'Media',
        },
      },
      {
        path: '/media/docs',
        name: 'MediaDocs',
        component: () => import('../views/mediaLibrary/MediaDocs.vue'),
        meta: {
          title: '文档库',
          icon: '',
          routeParent: 'Media',
        },
      },
      {
        path: '/media/cache',
        name: 'LiveCache',
        component: () => import('../views/mediaLibrary/LiveCache.vue'),
        meta: {
          title: '直播缓存',
          icon: '',
          routeParent: 'Media',
        },
      },
    ],
  },
  {
    path: '/wallet',
    name: 'Wallet',
    // component: () => import('../views/account/MessageCenter/index.vue'),
    component: () => import('../views/wallet/index.vue'),
    redirect: '/wallet/overview',
    meta: {
      title: '资源配置',
      icon: 'menu_account',
      menuType: 'home',
      userMenu: true,
    },
    children: [
      {
        path: '/wallet/overview',
        name: 'Overview',
        component: () => import('../views/wallet/resource/Overview.vue'),
        meta: {
          title: '资源概览',
          icon: '',
          routeParent: 'Wallet',
          applink: true,
          funcId: 736,
        },
      },
      {
        // path: '/account/accountRecharge',
        path: '/wallet/resource',
        name: 'ResourcePurchase',
        component: () => import('../views/wallet/ResourcePkg.vue'),
        meta: {
          title: '资源购买',
          icon: '',
          routeParent: 'Wallet',
          applink: true,
          funcId: 774,
        },
      },
      {
        path: '/wallet/recharge',
        // path: '/accountRecharge', // 站外链接
        name: 'WalletRecharge',
        component: () => import('../views/wallet/Recharge.vue'),
        meta: {
          title: '账户充值',
          icon: '',
          routeParent: 'Wallet',
          funcId: 624,
          // applink: false, // 站外链接 标识
        },
      },
      {
        path: '/wallet/version',
        name: 'WalletVersion',
        component: () => import('../views/wallet/Version.vue'),
        meta: {
          title: '版本升级',
          icon: '',
          routeParent: 'Wallet',
          funcId: 777,
        },
      },
      {
        path: '/wallet/bill/:name?',
        name: 'baseBill',
        component: () => import('../views/wallet/bill/Bill.vue'),
        meta: {
          title: '账单统计',
          icon: '',
          routeParent: 'Wallet',
          customMode: true,
          funcId: 369,
        },
      },
      {
        path: '/wallet/coupon',
        name: 'CardBag',
        component: () => import('../views/wallet/cardBag/CardBag.vue'),
        meta: {
          title: '我的卡包',
          icon: '',
          routeParent: 'Wallet',
          funcId: 749,
        },
      },
      {
        path: '/wallet/pay/:goods_id',
        name: 'WalletPay',
        component: () => import('../views/wallet/Pay.vue'),
        meta: {
          title: '确认订单',
          icon: '',
          routeParent: 'Wallet',
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/account/index.vue'),
    meta: {
      title: '账号设置',
      icon: 'menu_personal',
      menuType: 'home',
      userMenu: true,
    },
    redirect: '/personal/set/base',
    children: [
      {
        path: '/personal/set/:name?',
        name: 'PersonalSet',
        component: () => import('../views/account/personalSet/PersonalSet.vue'),
        meta: {
          title: '账户设置',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
        },
      },
      {
        path: '/modify',
        name: 'Modify',
        component: () => import('../views/account/personalSet/Modify.vue'),
        meta: {
          title: '安全设置',
          icon: '',
          routeParent: 'Personal',
          hidden: true,
        },
      },
      {
        path: '/personal/sub',
        name: 'SubManagement',
        component: () => import('../views/account/SubManagement.vue'),
        meta: {
          title: '临时账号',
          icon: '',
          routeParent: 'Personal',
          funcId: 464,
        },
      },
      {
        path: '/personal/subor',
        name: 'Subordinate',
        component: () => import('../views/account/Subordinate/Subordinate.vue'),
        meta: {
          title: '子账号',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          funcId: 857,
        },
      },
      {
        path: '/personal/custom/:name?',
        name: 'customServe',
        component: () => import('../views/account/customServe/CustomServe.vue'),
        meta: {
          title: '定制服务',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          funcId: 783,
        },
      },
      {
        path: '/personal/message',
        name: 'MessageCenter',
        component: () => import('../views/account/MessageCenter/Message.vue'),
        meta: {
          title: '消息通知',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          userMenu: true,
          funcId: 577,
        },
      },
      {
        path: '/personal/sensitive',
        name: 'Sensitive',
        component: () => import('../views/account/Sensitive/Sensitive.vue'),
        meta: {
          title: '敏感词设置',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          funcId: 1001,
        },
      },
      {
        path: '/personal/review',
        name: 'RobotReview',
        component: () => import('../views/account/Robot/RobotReview.vue'),
        meta: {
          title: '机器人评论',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          funcId: 576,
        },
      },
      {
        path: '/personal/population',
        name: 'Population',
        component: () => import('../views/account/Population.vue'),
        meta: {
          title: '全局设置',
          icon: '',
          routeParent: 'Personal',
          customMode: true,
          funcId: 1092,
        },
      },
    ],
  },
  {
    path: '/user/list',
    name: 'userList',
    component: () => import('../views/user/userList.vue'),
    meta: {
      title: '用户列表',
      icon: 'menu_personal',
      menuType: 'home',
      userMenu: true,
    },
  }
];
