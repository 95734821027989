export interface expressDom {
  content: string;
  elastic_type_title: string;
  id: number;
  title: string;
  type: number;
  length: number;
}

export interface TableList {
  id: number;
  theme: string; //标题
  status: number; // 开关
}

export class TableListClass {
  id: number;

  theme: string;

  status: boolean;

  constructor(data: TableList) {
    this.id = data.id;
    this.theme = data.theme;
    this.status = data.status ? true : false;
  }
}

export interface SelList {
  id: number;
  title: string;
}

// 上传头像
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
  type?: string;
  size: number;
  originFileObj: never;
}
export interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export interface IDataList {
  id: number;
  name: string;
  avatar_id: number;
  avatar_url: string;
}

export interface ICommConfig {
  send_type: number; //发送类型0.自动发送；1.手动发送
  increase_pv: number; //增长PV量
  pv_send_count: number; //发送UV量
  time_span: number; //时间跨度
  ts_send_count: number; //发送条数
  type_id: number;
}
