import { getCustomTheme, setCustomTheme } from '@/plugins/cookie';
import { defineStore } from 'pinia';

export const useHomeStore = defineStore('home', {
  state: (): {
    clientId: string; // 客户端ID
    adminId: string;
    funcData: any; // 返回的权限对象
    theme: 'dark' | 'light'; //自定义主题
    concurrencyChartVisible: boolean; // true C类用户｜ false B类用户
    menuVisible: boolean; // 隐藏菜单 用于跨站隐藏自带菜单使用
    role_id: number;
    collapsed: boolean; //左侧菜单是否展开 用于适配打点视频轨道渲染
    isFullScreen: boolean; //打点全屏隐藏左侧头部菜单
    isNewVersion: boolean; //是否显示2022版本
    onVideojs: boolean; // 是否加载远程videojs
  } => ({
    clientId: '',
    adminId: '',
    funcData: {},
    theme: 'dark', // 开启主题切换按钮时使用 getCustomTheme()
    concurrencyChartVisible: false,
    menuVisible: true,
    role_id: 0,
    collapsed: false,
    isFullScreen: false,
    isNewVersion: false,
    onVideojs: false,
  }),
  actions: {
    setTheme() {
      setCustomTheme(this.theme);
    },
  },
});
