import { BaseSearchClass } from '@/types/base';

export interface cacheLive {
  cover_url: string;
  created_at: string;
  definition: string;
  duration: string;
  format: string;
  id: number;
  is_over: number;
  resolution: string;
  size: string;
  source: number;
  status: number;
  studio_id: number;
  title: string;
  type: number;
  updated_at: string;
  user_id: number;
  video_id: string;
  video_url: string;
  choice: boolean;
}

export class SearchCacheLive extends BaseSearchClass {
  state_time?: string; // 开始时间
  end_time?: string; // 结束时间

  // 设置日期
  setDate(liveDate: [string, string]): void {
    this.state_time = liveDate[0] ? liveDate[0] : '';
    this.end_time = liveDate[1] ? liveDate[1] : '';
  }

  constructor(data: SearchCacheLive) {
    super(data);
    this.state_time = data.state_time;
    this.end_time = data.end_time;
  }
}
