import { numberToCurrency } from '@/utils/format/number';

export interface LiveChartType {
  type: string;
  value: number;
}

export interface LiveInfo {
  all_number: number;
  living: number;
  wait_live: number;
}
interface Money {
  total_money: number;
  surplus_money: number;
}
function formatDate(dateStr: string, sqlitStr = '-', joinStr = ' / ') {
  let strArr = [];
  strArr = dateStr.split(sqlitStr);
  return strArr.join(joinStr);
}
/**
 * @admin_id 用户id
 * @end_at 到期时间
 * @version 版本名称
 * @role_id 版本id
 * @level_up 是否可升级版本
 * @certification_status 认证状态 0.未认证 1.审核中 2.认证成功
 * @certification_type 认证类型 0.未认证 1.个人认证  2.企业认证
 * @certification_view 认证查看的权限 0.不允许查看 1.允许查看
 */
export interface AccountInfoType {
  admin_id: number;
  end_at: string;
  version: string;
  role_id: number;
  money?: string;
  level_up: number;
  certification_status: number;
  certification_type: number;
  certification_view: number;
}

export class AccountInfoClass {
  admin_id: number;
  end_at: string;
  version: string;
  role_id: number;
  money?: string;
  level_up: number;
  certification_status: number;
  certification_type: number;
  certification_view: number;

  setMoney(money: string | number): void {
    this.money = numberToCurrency(money);
  }
  constructor(data: AccountInfoType) {
    this.admin_id = data.admin_id;
    this.end_at = data.end_at ? formatDate(data.end_at) : '永久有效';
    this.version = data.version;
    this.role_id = data.role_id;
    this.level_up = data.level_up;
    this.certification_status = data.certification_status;
    this.certification_type = data.certification_type;
    this.certification_view = data.certification_view;
  }
}
// 观看时长
export interface Watch {
  total: number;
  unused: number;
  used: number;
  status: number;
  unused_true:number
}

// 并发数
export interface Concurrent {
  used_count: number;
  total: number;
  unused: number;
  used_en: number;
  used: number;
  status: number;
}
// 媒体库
export interface StoreType {
  vid: number;
  doc: number;
  pic: number;
  used: number;
  unused: number;
  total: number;
  status: number;
}
export interface ConfigAdmin {
  concurrent_num: number;
  store_size: number;
  toll_mode: number;
}
export interface Resource {
  config_admin: ConfigAdmin;
  end_at: string;
  role_id: number;
  version: string;
}
export interface HomeInfo {
  liveInfo: LiveInfo;
  userInfo: AccountInfoType;
  resource: Resource;
}
export interface ClientSize {
  width: number;
  height: number;
  menuSize: number;
  minWidth: number;
}
