import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取观看总览
  getViewOverview(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_view_overview', data);
  }

  // 获取 观看统计  数据
  getViewStatistics(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_viewing_statistics', data);
  }

  // 获取观看排名
  getViewsRank(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_views_rank', data);
  }

  // 获取访问来源
  getAccessSource(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_access_source', data);
  }

  // 获取设备占比
  getEquipmentRatio(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_equipment_ratio', data);
  }

  // 获取地区排名 
  getRegionalRank(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_regional_ranking', data);
  }

  // 获取观看流水
  getVisitRecord(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_visit_record', data);
  }

  // 获取用户下载列表
  userDownloadList(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/user_download_list', data);
  }
  // 删除下载数据
  delDownloadInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/del_download_info', data);
  }
}
export default new Api();
