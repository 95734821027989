import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 获取直播间信息接口
  getLiveInfo(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_live_info', data);
  }
  // 直播信息接口
  getAccessListData(data: AxiosRequestConfig) {
    return http.get(' /api/v1/common/get_access_list_data', data);
  }
  // 在线观众时间分布接口
  getHistoryOnline(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/history_online', data);
  }
  //    观看流水接口
  getAccessRunningWater(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/get_access_running_water', data);
  }
  // 批量导出Excel
  getExcelRegionalDistribution(data: AxiosRequestConfig) {
    return http.get('/api/v1/common/excel_regional_distribution', data);
  }
  // 获取快捷设置列表
  getQuickSettings(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/quick_settings', data);
  }
}
export default new Api();
