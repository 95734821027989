import http from '@/plugins/http';
import { AxiosRequestConfig } from 'axios';
class Api {
  // 彩排模式开关
  rehearsalSwitch(data: unknown) {
    return http.post('/api/v1/live/rehearsal_switch', data);
  }
  //   历史在线人数
  getHistoryOnline(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/history_online', data);
  }
  //  成员列表
  getMemberList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/member_list', data);
  }
  //   直播间违规列表
  getViolationList(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/violation_list', data);
  }

  // 成员列表显示开关
  getMemberSwitch(data: unknown) {
    return http.post(' /api/v1/live/member_switch', data);
  }
  // 群用户禁言
  getGroupMute(data: unknown) {
    return http.post('/api/v1/live/group_mute', data);
  }
  // 直播间拉黑用户
  getPullBlack(data: unknown) {
    return http.post('/api/v1/live/pull_black', data);
  }
  // 获取自定义菜单
  getMenu(data: AxiosRequestConfig) {
    return http.get('/api/v1/live/get_menu', data);
  }
}
export default new Api();
