import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getToken, getSubToken, removeToken, getExamineStatus, getExamine } from '@/plugins/cookie';
import { message } from 'ant-design-vue';
import router from '@/router';
import qs from 'qs';
import { getSign } from './sign';

const showStatus = (status: number) => {
  let message = '';
  switch (status) {
    case 400:
      message = '请求错误(400)';
      break;
    case 401:
      message = '未授权，请重新登录(401)';
      break;
    case 403:
      message = '拒绝访问(403)';
      break;
    case 404:
      message = '请求出错(404)';
      break;
    case 408:
      message = '请求超时(408)';
      break;
    case 500:
      message = '服务器错误(500)';
      break;
    case 501:
      message = '服务未实现(501)';
      break;
    case 502:
      message = '网络错误(502)';
      break;
    case 503:
      message = '服务不可用(503)';
      break;
    case 504:
      message = '网络超时(504)';
      break;
    case 505:
      message = 'HTTP版本不受支持(505)';
      break;
    default:
      message = `连接出错(${status})!`;
  }
  return `${message}，请检查网络或联系管理员！`;
};

const service = axios.create({
  baseURL: '',
  headers: {
    get: {
      //   "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
    post: {
      //   "Content-Type": "application/json;charset=utf-8",
    },
  },
  // 是否跨站点访问控制请求
  //   withCredentials: true,
  // timeout: 20000,
  //   transformRequest: [
  //     (data) => {
  //       data = JSON.stringify(data);
  //       return data;
  //     },
  //   ],
  //   validateStatus() {
  //     // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
  //     return true;
  //   },
  //   transformResponse: [
  //     (data) => {
  //       if (typeof data === "string" && data.startsWith("{")) {
  //         data = JSON.parse(data);
  //       }
  //       return data;
  //     },
  //   ],
});

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map();
/**
 * 添加请求
 * @param {Object} config
 */
const addPending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url, qs.stringify(config.params), qs.stringify(config.data)].join('&');
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pending.has(url)) {
        // 如果 pending 中不存在当前请求，则添加进去
        pending.set(url, cancel);
      }
    });
};

// 路由白名单， 不会被移除
const urlWhiteList = [
  '/api/v1/live/get_live_info',
  '/api/v1/bill/balance_recharge_callback',
  '/api/v1/wechat/package_polling_order',
  '/api/v1/wechat/polling_order',
  '/api/v1/live/red_pac_polling_order',
  '/api/v1/live/get_from_list', // 注册观看自定义模版
];
/**
 * 移除请求
 * @param {Object} config
 */
const removePending = (config: AxiosRequestConfig) => {
  const url = [config.method, config.url, qs.stringify(config.params), qs.stringify(config.data)].join('&');
  // 直播统计改页面与请求出现冲突，待修复后可去除
  if (pending.has(url) && !urlWhiteList.includes(config.url as string)) {
    // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = (): void => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};
// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    removePending(config); // 在请求开始前，对之前的请求做检查取消操作
    addPending(config); // 将当前请求添加到 pending 中
    // getSign(config);
    //获取token，并将其添加至请求头中
    if (getToken()) {
      config.headers['token'] = getToken();
    } else {
      config.headers['token'] = getSubToken();
    }
    return config;
  },
  (error) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '服务器异常，请联系管理员！';
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const status = response.status;
    const res = response.data;
    const isExamine = getExamineStatus(); //是否是第三方审核登录
    const Examine = getExamine(); //审核登录地址信息
    // const resquestURL: string = response.request.responseURL;  // 请求地址
    let msg = '';
    switch (true) {
      case status < 200 || status >= 300:
        msg = showStatus(status);
        console.error(msg);
        break;
      case res.code === 40003:
        break;
      case res.code === 200:
        // 暂时不做操作
        break;
      case res.code === '200':
        // 暂时不做操作
        break;
      case res.code === 203: // 凭证过期
        message.destroy();
        message.error(res.error);
        if (!isExamine) {
          removeToken();
          router.removeRoute('Layout');
          router.replace({ name: 'Login' });
        } else {
          router.replace(`${'/loginExamine' + '?studio_id=' + Examine.studioId}`);
          removeToken();
        }
        return false;
        break;
      case res.code === 204: // 访问失败
        message.error(res.error);
        setTimeout(() => {
          router.replace({ name: '404' });
          // router.go(-1);
        }, 300);
        break;
      // 临时拦截器
      case res.code === 205: // 无权访问
        return Promise.reject(response);
        break;
      default:
        // message.error(res.error);
        if ((res?.error as string).includes('空空') || (res?.error as string).includes('暂无')) {
          // return Promise.resolve(response);
        }
        return Promise.reject(response);
        break;
    }
    return response;
  },
  (error) => {
    message.destroy();
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error.message);
    } else {
      // handle error code
      // 错误抛到业务代码
      error.data = {};
      error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！';
      message.error({
        title: '错误',
        content: error.data.msg,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
