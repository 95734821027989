import { RouteRecordRaw } from 'vue-router';

/**
 * 门户菜单
 *
 * @descriptionmeta 包含相关关键字作用：
 *  父组件
 * @title - 标题
 * @icon - 菜单图标
 * @menuType - 菜单分类 首页菜单与进入直播间后的菜单区分
 * @userMenu {boolean} 是否在右上角菜单显示
 *
 * 子组件
 * @routeParent - 子选项的父级菜单标识
 * @hidden {boolean} 是否在左侧菜单显示
 * @customMode {boolean} 自定义mode层
 * @applink {boolean} 是否是站内路由
 * @role {boolean} 是否有权限
 * @only {boolean} 只能在对应role显示
 * @helperKey {string} 关键字对应助手接口返回关键字
 */
export const gatewayMenu: Array<RouteRecordRaw> = [
  {
    path: '/site/decorate/:gatewayId',
    name: 'Decorate',
    component: () => import('../views/gateway/index.vue'),
    meta: {
      title: '门户装修',
      icon: 'menu_gateway_decorate',
      menuType: 'gateway',
      routeParent: 'Decorate',
    },
  },
  {
    path: '/site/article/:gatewayId',
    name: 'Content',
    component: () => import('../views/gateway/articalCategory/index.vue'),
    meta: {
      title: '内容中心',
      icon: 'menu_gateway_content',
      menuType: 'gateway',
    },
    children: [
      {
        path: '/site/article/:gatewayId',
        name: 'Article',
        component: () => import('../views/gateway/articalCategory/articleList.vue'),
        meta: {
          title: '文章分类',
          icon: '',
          routeParent: 'Content',
          role: [],
        },
      },
      {
        path: '/site/picture/:gatewayId',
        name: 'Picture',
        component: () => import('../views/gateway/articalCategory/photograph.vue'),
        meta: {
          title: '图片分类',
          icon: '',
          routeParent: 'Content',
          role: [],
        },
      },
      {
        path: '/site/live_classify/:gatewayId',
        name: 'liveClassify',
        component: () => import('../views/gateway/articalCategory/liveClassify.vue'),
        meta: {
          title: '直播分类',
          icon: '',
          routeParent: 'Content',
          role: [],
        },
      },
      {
        path: '/site/document/:gatewayId',
        name: 'Document',
        component: () => import('../views/gateway/articalCategory/documentCategorize.vue'),
        meta: {
          title: '文档分类',
          icon: '',
          routeParent: 'Content',
          role: [],
        },
      },
    ],
  },
];